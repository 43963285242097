import React, { useState } from "react";
import { Dietician } from "../../model/DieticianDataModel";
import { User } from "../../model/UserModel";
import { getColumnsByType } from "../../constants/DummyData";
import DieticianDetails from "../Modals/DieticianDetails";

interface TableProps {
  data?: Dietician[];
  users?: User[];
  dieticians?: Dietician[];
  background: string;
  type: string;
  isDownloadable: boolean;
  totalPages: number;
  setCurrentPage: Function;
  currentPage: number;
}

const Table: React.FC<TableProps> = ({
  data,
  background,
  type,
  isDownloadable,
  totalPages,
  setCurrentPage,
  currentPage,
}) => {
  const columns = getColumnsByType(type);

  const [isOpen, setIsOpen] = useState(false);
  const [dieticianData, setDieticianData] = useState<Dietician | undefined>(
    undefined,
  );

  function getDietitianEnrollmentStatus(status: any) {
    return status === 0
      ? "Not filled"
      : status === 1
        ? "Pending"
        : status === 2
          ? "Approved"
          : status === 3
            ? "Rejected"
            : "Unknown status";
  }

  const handleDieticiansDetailsModal = (data: Dietician) => {
    setIsOpen(true);
    setDieticianData(data);
  };

  const getPaginationButtons = () => {
    const pageNumbers = [];
    const delta = 2;
    let startPage = Math.max(1, currentPage - delta);
    let endPage = Math.min(totalPages, currentPage + delta);

    if (endPage - startPage < 2 * delta) {
      if (currentPage - delta < 1) {
        endPage = Math.min(
          totalPages,
          endPage + (2 * delta - (endPage - startPage)),
        );
      } else {
        startPage = Math.max(
          1,
          startPage - (2 * delta - (endPage - startPage)),
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const paginationButtons = getPaginationButtons();

  function formatDate(isoString: string) {
    const date = new Date(isoString);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  }

  return (
    <div className="bg-[#FFFFF5]">
      <div className="relative overflow-x-auto sm:rounded-lg p-8">
        <table
          className=" w-full text-[10px] lg:text-[15px] text-left rounded-lg shadow-lg text-[#355E3B]"
          style={{ background: background }}
        >
          <thead>
            <tr>
              {columns?.map((column, index) => (
                <th
                  key={index}
                  className="px-6 py-3 mr-2 text-[10px] lg:text-[20px] font-bold border-b-[1px] border-[#355E3B] whitespace-nowrap "
                >
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((d, rowIndex) => (
              <tr
                key={rowIndex}
                onClick={() => handleDieticiansDetailsModal(d)}
                className="cursor-pointer hover:bg-neutral-50 border-b-[1px] border-[#EFEFEA]"
              >
                <td className="px-6 py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                  {(currentPage - 1) * 7 + rowIndex + 1}
                </td>
                <td className="px-6 py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                  {d.user?.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                  {formatDate(d.createdAt)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                  {getDietitianEnrollmentStatus(
                    d.user?.dietitianEnrollmentStatus,
                  )}
                  {/* {d.user?.dietitianEnrollmentStatus === 0 ? "Not filled" : "Pending"} */}
                </td>
              </tr>
            ))}

            {/* Similar rendering for `users` and `dieticians` */}
          </tbody>
        </table>
        <div
          className={`flex items-center flex-col lg:flex-row ${
            isDownloadable ? "justify-between" : "justify-end"
          } pt-4`}
        >
          {isDownloadable && (
            <div className="text-[12px] lg:text-[24px] rounded-lg font-medium bg-[#355E3B] text-[#FFFFF5] py-2 px-3 mb-2 cursor-pointer">
              Download
            </div>
          )}
          <ul className="inline-flex">
            {paginationButtons.map((pageNumber) => (
              <li key={pageNumber} className="mx-0.5">
                <div
                  onClick={() => setCurrentPage(pageNumber)}
                  className={`flex items-center justify-center w-6 h-6 lg:w-10 lg:h-10 rounded-lg font-medium text-[12px] lg:text-[24px] ${
                    currentPage === pageNumber
                      ? "bg-[#355E3B] text-[#FFFFF5]"
                      : "bg-white border border-[#355E3B] hover:bg-[#355E3B] cursor-pointer text-[#355E3B] hover:text-[#FFFFF5]"
                  } `}
                >
                  {pageNumber}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <DieticianDetails
        isOpen={isOpen}
        DieticianData={dieticianData}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default Table;
