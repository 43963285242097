import { relative } from "path";
import "./header.css"
import { useEffect, useState } from "react";
// import ""
const Section1 = ({ expertise, user }: any) => {
  const[language, setLanguage] =useState("")
  const formatLanguages = (languages: string[]) => {
    console.log("ex", expertise)
    console.log("llll", languages);
    if (languages?.length === 0) return "No languages listed";
    if (languages?.length === 1) return `Fluent in ${languages[0]}`;
    const lastLanguage = languages[languages.length - 1]; // Access the last language without modifying the array
    console.log("last",lastLanguage)
    return `Fluent in ${languages.slice(0, -1).join(", ")} and ${lastLanguage}`;
  };
  useEffect(() => {
    console.log("exper",expertise)
    if(expertise.spokenLanguages) {
      setLanguage(formatLanguages(expertise?.spokenLanguages))
    }
  },[expertise])
  return (
    <section className="w-full">   
    {/* Windows View */}
    {/* <div className="hidden md:block">
    <div className="w-full h-[0rem] lg:h-[15rem] flex items-center justify-center relative mt-32 lg:mt-36">
      <div className=" h-auto lg:h-[17rem] w-full relative flex ">
        <img
          className=" lg:block w-[calc(100%-147px)] lg:w-[calc(100%-550px)] h-[75%] lg:h-[100%] absolute left-0"
          src="/GreenRectLeft.png"
          alt="img-loading..."
        />
        <img
          className=" w-[147px] lg:w-[550px] h-[75%] lg:h-[100%] absolute right-0"
          src="/GreenRectRight.png"
          alt="img-loading..."
        />
        <div className=" absolute top-[-10%] right-[40px] lg:top-[-40%] lg:right-[150px]">
          <img
            className="rounded-full object-cover w-[4rem] h-[4rem] lg:h-[15rem] lg:w-[15rem]"
            src={expertise?.professionalImage}
            alt="profile"
          />
        </div>

        <div className="w-[55%] relative z-[1] flex items-start flex-col justify-between text-white  ml-4 lg:ml-10 mt-[0] lg:mt-[1.5rem] mb-[1.5rem]">
          <div className=" text-xs lg:text-4xl font-nunito font-semibold">
            Grab your spot with{" "}
            <span className="text-[18px] lg:text-4xl">
                 {expertise?.user?.gender === "Male" ? "Mr" : "Ms"}{" "}
                 {expertise?.user?.name}
            </span>
          </div>
          <div className="text-[8px] lg:text-xl">
               {expertise?.areaOfExpertise?.length > 0 &&
                expertise.areaOfExpertise.map((item: any, index: any) => {
                  const isLastItem =
                    index === expertise.areaOfExpertise.length - 1;
                  const isSecondLastItem =
                    index === expertise.areaOfExpertise.length - 2;
                  return (
                    <span key={index}>
                      {item}
                      {isSecondLastItem
                        ? " and "
                        : !isLastItem
                        ? ", "
                        : " Expert | Fluent in "}
                    </span>
                  );
                })}
              {expertise?.spokenLanguages?.length > 0 &&
                expertise.spokenLanguages.map((item: any, index: any) => {
                  const isLastItem =
                    index === expertise.spokenLanguages.length - 1;
                  const isSecondLastItem =
                    index === expertise.spokenLanguages.length - 2;

                  return (
                    <span key={index}>
                      {item}
                      {isSecondLastItem ? " and " : !isLastItem ? ", " : ""}
                    </span>
                  );
                })}
            </div>
          <div>
             <ul>
               <li className="text-[7px] lg:text-xl">
                 {expertise?.yearOfExperience} years of experience{" "}
               </li>
             </ul>
           </div>
          
          <div className="  justify-center md:justify-start hidden md:flex  my-4 md:my-2">
  <div className="font-poppins text-[#315145] whitespace-nowrap rounded-[50px] bg-[#D9D9D9] py-1.5 px-2 pe-[10px] font-medium text-[0.5rem] md:text-[0.75rem] lg:text-[0.75vw] mr-4 text-center w-full md:w-fit flex justify-center items-center">
    <span className="mr-1 text-[#325243] bg-white  flex justify-center items-center h-[20px] w-[20px] rounded-[100%]  p-0.5">
      <img className="w-3" src="/star1.png" alt="star" />
    </span>
    Top-Rated
  </div>
  <div className="font-poppins text-[#315145] whitespace-nowrap rounded-[3.125rem] bg-[#D9D9D9] py-1.5 px-2 pe-[10px] font-medium  text-[0.5rem] md:text-[0.75rem] lg:text-[0.75vw] mr-4 text-center w-full md:w-fit flex justify-center items-center">
    <span className="flex justify-center items-center h-[20px] w-[20px] bg-white rounded-[100%] mr-1 text-[#325243]   p-0.5 ">
      <img className="w-3" src="/HeartVector.png" alt="star" />
    </span>
    {expertise?.noOfConsultations} happy client sessions
  </div>
  <div className="font-poppins text-[#315145] whitespace-nowrap rounded-[3.125rem] bg-[#D9D9D9] py-1.5 px-2 pe-[10px] font-medium  text-[0.5rem] md:text-[0.75rem] lg:text-[0.75vw] mr-4 text-center w-full md:w-fit flex justify-center items-center">
    <span className="flex justify-center items-center h-[20px] w-[20px] bg-white rounded-[100%] mr-1 text-[#325243]   p-0.5 ">
      <img className="w-3" src="/Logo_Real.png" alt="star" />
    </span>
    Betternow Certified
  </div>
</div>
        </div>
      </div>
    </div>
    </div> */}
    {/* Mobile View */}
    <div className="mobile-card">
        <div className="w-full flex items-center justify-center relative mt-4">
          <div className=" w-full relative flex ">
            <img
              className="absolute w-[70vw] h-[100%]"
              src="/GreenRectLeft.png"
              alt="img-loading..."
            />
            <div className=" absolute top-[-1%] right-[0px] lg:top-[-40%] lg:right-[150px]">
                <img
                  className="rounded-full object-cover w-[8rem] xs:w-[9em] xs:h-[9em] h-[8rem] border-l-[10px] border-t-[10px] border-b-[10px] border-r-[10px] border-[#FFFFF5] "
                  src={expertise?.professionalImage}
                  alt="profile"
                />
            </div>

            <div className="w-[calc(100%-147px)] lg:w-[calc(100%-550px)] relative z-9 flex items-start flex-col justify-center text-white gap-y-2 xs:gap-y-1 lg:gap-y-6 ml-4 lg:ml-10 mt-[0] lg:mt-5 pt-4 lg:pt-5 pb-5">
              <div className=" text-xs lg:text-4xl font-nunito font-semibold">
                <p className="text-[12px] mb-[6px]">Grab your spot with</p> 
                <p className="text-[20px] mb-[6px]">{expertise?.user?.name}</p>
              </div>
              <div className="text-[8px]">
              {expertise?.areaOfExpertise?.length > 0 &&
                expertise.areaOfExpertise.map((item: any, index: any) => {
                  const isLastItem =
                    index === expertise.areaOfExpertise.length - 1;
                  const isSecondLastItem =
                    index === expertise.areaOfExpertise.length - 2;
                  return (
                    <span key={index}>
                      {item}
                      {isSecondLastItem
                        ? " and "
                        : !isLastItem
                        ? ", "
                        : " Expert | Fluent in "}
                    </span>
                  );
                })}
              {expertise?.spokenLanguages?.length > 0 &&
                expertise.spokenLanguages.map((item: any, index: any) => {
                  const isLastItem =
                    index === expertise.spokenLanguages.length - 1;
                  const isSecondLastItem =
                    index === expertise.spokenLanguages.length - 2;

                  return (
                    <span key={index}>
                      {item}
                      {isSecondLastItem ? " and " : !isLastItem ? ", " : ""}
                    </span>
                  );
                })}
            </div>
              
                <div className="flex gap-[0.25em] lg:gap-x-4 w-[105%] mt-[2px] xs:flex-wrap">
            
                  <div className="p-0.5 bg-[#D9D9D9]  h-[1.4rem] xs:h-[0.8rem] lg:h-[33px] min-w-9 w-[] lg:w-[109px] rounded-[50px] flex items-center justify-evenly text-black font-medium lg:font-semibold font-poppins text-[5px] xs:text-[4px] lg:text-xs">
              <div className="bg-white h-[15px] xs:h-[8px] w-[15px] xs:w-[8px] rounded-[100%] flex justify-center items-center">
                <img
                  className="w-2.5 xs:w-1  lg:w-6"
                  src="/star1.png"
                  alt="rating"
                />
              </div>
              <div className="p-0.5 text-[5px] xs:text-[6px] whitespace-nowrap">Top-Rated</div>
            </div>
            
            <div className=" p-0.5 whitespace-nowrap bg-[#D9D9D9] pe-[4px] h-[1.4rem] xs:h-[0.8rem] lg:h-[33px] min-w-13 w-30 lg:w-[198px] rounded-[50px] flex items-center justify-evenly text-black font-medium lg:font-semibold font-poppins text-[5px] xs:text-[4px] lg:text-xs">
              <div className=" bg-white h-[15px] xs:h-[8px] w-[15px] xs:w-[8px] rounded-[100%] flex justify-center items-center">
                <img className="w-2.5 xs:w-1 lg:w-6" src="/HeartVector.png" alt="rating" />
              </div>
            <div className="p-0.5 text-[5px] xs:text-[6px]">
              {" "}
              {expertise?.noOfConsultations} happy client sessions
            </div>
          </div>
          <div className="p-0.5 whitespace-nowrap bg-[#D9D9D9] pe-[5px] xs:h-[0.8rem] h-[1.4rem] lg:h-[33px] min-w-13 w-30 lg:w-[168px] rounded-[50px] flex items-center justify-evenly text-black font-medium lg:font-semibold font-poppins text-[5px] xs:text-[4px] lg:text-xs">
                    <div className="bg-white h-[15px] xs:h-[8px] w-[15px] xs:w-[8px] rounded-[100%] flex justify-center items-center">
                      <img
                        className="w-2.5 xs:w-1 lg:w-6"
                        src="/Logo_Real.png"
                        alt="rating"
                      />
                    </div>
                    <div className="p-0.5 text-[5px] xs:text-[6px]">Betternow Certified</div>
                  </div>
        
          
    </div>
  </div>
</div>
</div>
    </div>
    {/* <div className="header-container">
      <svg width="1020" height="251" viewBox="0 0 1220 251" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path style={{position: 'relative', zIndex:'0'}} d="M0 10C0 4.47715 4.47715 0 10 0H610H729.866C776.267 0 819.369 24 843.806 63.4456L845.629 66.3876C897.745 150.512 1020.49 149.441 1071.13 64.419C1094.92 24.4732 1138.26 0 1184.75 0H1210C1215.52 0 1220 4.47715 1220 10V241C1220 246.523 1215.52 251 1210 251H9.99999C4.47714 251 0 246.523 0 241V10Z" fill="#315145">
          <div className="details">
            <h2>Grab your spot with{""} {expertise?.user?.gender === "Male" ? "Mr" : "Ms"}{" "}
            {expertise?.user?.name}</h2>
            <h3>{expertise?.description}</h3>
          </div>

        </path>
      </svg>
        
    </div> */}
   
      <div className="header-container">
        <svg
          width="1020"
          height="251"
          viewBox="0 0 1220 251"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="svg-path"
            d="M0 10C0 4.47715 4.47715 0 10 0H610H729.866C776.267 0 819.369 24 843.806 63.4456L845.629 66.3876C897.745 150.512 1020.49 149.441 1071.13 64.419C1094.92 24.4732 1138.26 0 1184.75 0H1210C1215.52 0 1220 4.47715 1220 10V241C1220 246.523 1215.52 251 1210 251H9.99999C4.47714 251 0 246.523 0 241V10Z"
            fill="#315145"
          />
        </svg>
       

        <div className="details">
          <h2>
            Grab your spot with{""} {expertise?.user?.gender === "Male" ? "Mr" : "Ms"}{" "}
            {expertise?.user?.name}
          </h2>
          <h3>{expertise?.areaOfExpertise?.length > 0 &&
            expertise.areaOfExpertise.map((item: any, index: any) => {
              const isLastItem =
                index === expertise.areaOfExpertise.length - 1;
              const isSecondLastItem =
                index === expertise.areaOfExpertise.length - 2;
              return (
                <span key={index}>
                  {item}
                  {isSecondLastItem
                    ? " and "
                    : !isLastItem
                      ? ", "
                      : ` Expert`}
                </span>
              );
            })}</h3>
          <h3>
            <span>
            {/* {formatLanguages(expertise?.spokenLanguages)} */}
            {language}
            </span>
          </h3>
          <h3>
            <span>
              {expertise?.yearOfExperience} years of experience{" "}
            </span>
          </h3>
          <div className="highlights-container mt-4">
            <div className="highlights-profiles">
              <div className="highlight-icon">
                <img className="w-3" src="/star1.png" alt="star" />
              </div>
              <div>
                Top-Rated
              </div>
            </div>
            <div className="highlights-profiles">
              <div className="highlight-icon">
                <img className="w-3" src="/heartnew.svg" alt="star" />
              </div>
              <div>
                {expertise?.noOfConsultations} happy client sessions
              </div>
            </div>
            <div className="highlights-profiles">
              <div className="highlight-icon">
                <img className="w-[12px] h-[12px]" src="/betternow.svg" alt="star" />
              </div>
              <div>
                Betternow Certified
              </div>
            </div>
          </div>
        </div>
        <div className="profile-image-container">
            <img
            className="profile-image"
            src={expertise?.professionalImage}
            alt="profile"
            />
         </div>
         
      </div>



    </section>
  );
};

export default Section1;
