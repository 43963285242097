import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  enrollDietician,
  filterDieticianByCategory,
  getAllDieticians,
  getDieticiansById,
  getEnrolledDieticians,
  getLoggidInDietician,
} from "../services/dieticianService";
import { EnrollmentData } from "../../model/Enrollment";

export const enrollDieticianAction = createAsyncThunk(
  "dietician/register",
  async (formData: EnrollmentData, { rejectWithValue }) => {
    try {
      const msg = await enrollDietician(formData);
      return msg;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getDieticianByAreaOfExperties = createAsyncThunk(
  "dietician/expertise",
  async (data: any, { rejectWithValue }) => {
    try {
      const dieticians = await filterDieticianByCategory(data);
      console.log(dieticians);

      return dieticians;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getDieticiansByIdAction = createAsyncThunk(
  "dietician/Id",
  async (Id: string, { rejectWithValue }) => {
    try {
      const dieticians = await getDieticiansById(Id);
      console.log(dieticians);

      return dieticians;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getAllDieticiansAction = createAsyncThunk(
  "dieticians/all",
  async ({ page, limit }: any, { rejectWithValue }) => {
    try {
      const dieticians = await getAllDieticians(page, limit);
      console.log(dieticians);

      return dieticians;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getEnrolledDieticiansAction = createAsyncThunk(
  "dieticians/enrolled",
  async ({ page, limit }: any, { rejectWithValue }) => {
    try {
      const dieticians = await getEnrolledDieticians(page, limit);
      console.log(dieticians);

      return dieticians;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getLoggidInDieticianAction = createAsyncThunk(
  "dietician/loggedin",
  async (_, { rejectWithValue }) => {
    try {
      const dietician = await getLoggidInDietician();
      return dietician;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
