import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { featureListHome } from "../constants/FeatureOffered";

const ComparisonTableHome: React.FC = () => {
  return (
    <section className="w-full lg:w-fit relative text-[#FFFFF5]">
      <div className="w-full relative lg:min-h-[700px] lg:w-[95vw] py-10  flex flex-wrap">
        <div className="w-full lg-absolute lg:w-full mt-[96px]">
          <div>
            {featureListHome.map((item, index) => (
              <div
                key={index}
                className="w-full relative h-16 flex items-center justify-start border-[#EEEDED] border-b-[0.8px] text-[11px] lg:text-[18px] text-left gap-x-0.2 gap-x-2 md:gap-x-3"
              >
                <p className="w-[9rem]  md:w-[18rem] px-0 md:pl-8 py-6 xs:w-[6rem]">
                  {item.name}
                </p>

                <div className="relative flex items-center group">
                  <img
                    src="/infoSymbol.png"
                    alt="info"
                    className="w-[14px] max-w-[22px] md:w-[22px] cursor-pointer "
                  />
                  <div className="w-[8rem] absolute left-full top-0 ml-2 text-[black] text-[12px] text-center hidden group-hover:block bg-white border border-gray-300 p-2 text-xs rounded shadow-lg z-10">
                    {item.info} {/* Add the info text or content here */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="px-4 absolute right-[0] lg:right-10 flex w-1/2 gap-x-2 lg:gap-x-6 xs:pl-0">
          <div className="lg:w-1/2 w-[70px]  bg-[#FFFFF5] pb-10 lg:pb-20 rounded-lg md:rounded-3xl max-h-[650px] xs:max-h-unset">
            <div className="px-2 h-[96px] flex justify-center items-center">
              <div className="text-[#FFA800] text-[12px] lg:text-[20px] md:text-[20px]  font-semibold text-center">
                <p className="text-[12px] lg:text-[20px]">Premium Plan</p>
              </div>
            </div>
            {featureListHome.map((item, index) => (
              <p
                key={index}
                className="h-16 text-center px-2 flex items-center justify-center "
              >
                {item.offeredByBetterNow ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color="#315145"
                    size="lg"
                    className="bg-[#c5e7b7] p-2 rounded-lg"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faXmark}
                    color="red"
                    size="lg"
                    className="p-2"
                  />
                )}
              </p>
            ))}
          </div>
          <div className="lg:w-1/2 w-[92px] rounded-lg md:rounded-3xl bg-[#538D3B] max-h-[650px] xs:max-h-unset">
            <div className="px-[8px] h-[96px] flex justify-center items-center text-[12px] lg:text-[20px] font-semibold text-center">
              One Time Consultation
            </div>
            {featureListHome.map((item, index) => (
              <p
                key={index}
                className="h-16 text-center px-2 flex items-center justify-center "
              >
                {item.offerByInOffice ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color="#315145"
                    size="lg"
                    className="bg-[#FFFFF5] p-2 rounded-lg"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faXmark}
                    color="red"
                    size="lg"
                    className="p-2 text-[26px]"
                  />
                )}
              </p>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComparisonTableHome;
