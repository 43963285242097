import axios from "axios";

const createAxiosInstance = () => {
  const baseURL = "https://backend.betternow.co.in";
  // process.env.NODE_ENV === "production"
  //   ? "https://backend.betternow.co.in"
  //   : "http://localhost:8080";

  return axios.create({
    baseURL,
  });
};

const axiosInstance = createAxiosInstance();

export default axiosInstance;
