import { useSelector } from "react-redux";
import {
  loggedInUserSelector,
  userNameSelector,
  userRoleSelector,
} from "../../redux/selectors/userSelector";
import { useEffect, useState } from "react";
import axios from "axios";

interface User {
  _id: string;
  name: string;
  email: string;
  phone: number;
  gender: string;
  dietitianEnrollmentStatus: number;
}

interface Dietitian {
  _id: string;
  user: User;
  // Include other fields based on your data structure
}

const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
}) => {
  const profileData = useSelector(loggedInUserSelector);
  const role = useSelector(userRoleSelector);
  const userName = useSelector(userNameSelector);

  const [userId, setUserId] = useState<string | null>(null);
  const [dietitian, setDietitian] = useState<Dietitian | null>(null);

  // useEffect(() => {
  //   const getUserId = () => {
  //     const token = localStorage.getItem("token");
  //     let config = {
  //       method: "get",
  //       maxBodyLength: Infinity,
  //       url: "https://backend.betternow.co.in/users/auth-status",
  //       headers: {
  //         Authorization: token,
  //       },
  //     };

  //     axios
  //       .request(config)
  //       .then((response) => {
  //         console.log("AUTH", response.data.data._id);
  //         setUserId(response.data.data._id);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };
  //   getUserId();

  //   if (userId) {
  //     const getDietitian = () => {
  //       let config = {
  //         method: "get",
  //         maxBodyLength: Infinity,
  //         url: `https://backend.betternow.co.in/dietitian/${userId}`,
  //         headers: {},
  //       };

  //       axios
  //         .request(config)
  //         .then((response) => {
  //           console.log("My dietician", response.data.data);
  //           setDietitian(response.data.data);
  //         })
  //         .catch((error) => {
  //           console.log("Dietitian Error", error);
  //         });
  //     };
  //     getDietitian();
  //   }
  // }, [userId]);

  return (
    <div className="sticky top-0 lg:hidden z-[100]">
    <header className="sticky top-0 z-[100] flex w-full bg-[#315145] lg:bg-[#FFFFF5] drop-shadow-1 ">
      <div className="flex flex-grow h-[5vh] items-center justify-between  px-4 shadow-2 md:px-6 2xl:px-11">
        <div className="flex items-center gap-2 sm:gap-4 lg:hidden ">
          <button
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              props.setSidebarOpen(!props.sidebarOpen);
            }}
            className="z-99999 block p-1.5  lg:hidden "
          >
            <svg
              className="w-4 h-5"
              aria-hidden="true"
              fill="none"
              viewBox="0 0 17 14"
              style={{ color: "#FFFFF5" }}
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
      </div>
    </header>
    </div>

  );
};

export default Header;
