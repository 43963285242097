import { useEffect } from "react";
import { createPortal } from "react-dom";
import LoginForm from "../Forms/LoginForm";
interface LoginModalProps {
  isOpenLoginModal: boolean;
  setIsOpenLoginModal: Function;
}
function LoginModal({
  isOpenLoginModal,
  setIsOpenLoginModal,
}: LoginModalProps) {
  useEffect(() => {
    if (document) {
      document.body.style.overflowY = isOpenLoginModal ? "hidden" : "scroll";
    }
  }, [isOpenLoginModal]);
  if (!isOpenLoginModal) return null;
  return createPortal(
    <div className="z-50 fixed top-0 left-0 w-full h-full flex justify-center items-center overflow-auto bg-modal-container">
      <div
        onClick={() => setIsOpenLoginModal(false)}
        className="fixed w-full h-full bg-modal-container z-10"
      ></div>
      <div className="bg-[#FFFFF5] rounded-lg relative z-20 mx-5 w-[500px]">
        <div className="p-1 w-full flex justify-center">
          <LoginForm setIsOpen={setIsOpenLoginModal} />
        </div>
        <button
          onClick={() => setIsOpenLoginModal(false)}
          className="z-50 absolute top-2 text-black text-xl cursor-pointer hover:text-red-500 right-5"
        >
          x
        </button>
      </div>
    </div>,
    document.getElementById("login-portal")!,
  );
}

export default LoginModal;
