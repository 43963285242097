import React, { useState } from "react";
import {
  booleanOptions,
  languageOptions,
  areaOfExpertiseOptions,
  stateOptions,
  cityOptions,
} from "../../constants/DropDownOptions";
import DropDown from "../DropDown";
import ChipInput from "../ChipInput/ChipInput";
import { EnrollmentData } from "../../model/Enrollment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  dieticianEnrollmentDataSelector,
  loggedUserDetailSelector,
} from "../../redux/selectors/userSelector";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import ImageUpload from "./ImageUpload";
import {
  ADDRESS1_REQUIRED,
  AREA_OF_EXPERTISE,
  GENERAL_ERROR,
  ONE_TIME_FEE,
  MONTHLY_FEE,
  QUALIFICATIONS,
  PROFESSIONAL_IMAGE,
  ADDRESS2_REQUIRED,
  STATE_REQUIRED,
  CITY_REQUIRED,
  PINCODE_REQUIRED,
  LANGUAGES_REQUIRED,
} from "../../constants/ErrorMessage";
import { Option } from "react-tailwindcss-select/dist/components/type";
// import { State, City } from "react-country-state-city"; 
interface FormInputProps {
  label: string;
  type: string;
  placeholder: string;
  required: boolean;
  value: any;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  maxlength?: number;
  error?: string;
  pattern?: string
  title?: string
}

interface FormTextAreaProps {
  label: string;
  placeholder: string;
  required: boolean;
  value: any;
  name: string;
  onChange: any;
  error?: string;
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  type,
  placeholder,
  required,
  onChange,
  value,
  name,
  maxlength,
  error,
  pattern,
}) => {
  return (
    <div className="flex flex-col">
      <label className="block  text-[20px] mb-2  text-[#315145] xs:text-[18px]">{label}</label>
      <input
        type={type}
        className="bg-[#EFEFEA]  text-lg rounded-lg  block w-full h-[57px] p-2.5"
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
        name={name}
        maxLength={maxlength}
        pattern={pattern}
      />
      {error && <span className=" text-red-500 text-sm mt-[6px]">{error}</span>}
    </div>
  );
};

const FormTextArea: React.FC<FormTextAreaProps> = ({
  label,
  placeholder,
  required,
  onChange,
  value,
  name,
  error,
}) => {
  return (
    <div className="flex flex-col">
      <label className="block  text-[20px] mb-2  text-[#315145] xs:text-[18px]">{label}</label>
      <textarea
        rows={3}
        className=" bg-[#EFEFEA]  text-lg rounded-lg  block w-full p-2.5"
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
        name={name}
      />
      {error && <span className=" text-red-500 text-sm mt-[6px]">{error}</span>}
    </div>
  );
};

const EnrollmentForm: React.FC = () => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [selectedState, setSelectedState] = useState<string | null>(null);
  const [filteredCities, setFilteredCities] = useState<Option[]>([]);

  const handleCityValueChange = (fieldName: string, selectedValue: string | string[]) => {
    console.log("fi", fieldName, selectedValue);
    if (fieldName === "stateName" && typeof selectedValue === "string") {
      setSelectedState(selectedValue);
      setFilteredCities(cityOptions[selectedValue] || []); // Filter cities based on the selected state
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: selectedValue,
    }));
    if (errors[fieldName]) {
      setErrors({ ...errors, [fieldName]: "" });
    }
    // Handle city dropdown change here...
};
  const dieticianData = useSelector(dieticianEnrollmentDataSelector);
  const userData = useSelector(loggedUserDetailSelector);
  console.log(userData);
  const navigate = useNavigate();
  console.log(dieticianData);
  const [showAvailabilityOptions, setShowAvailabilityOptions] = useState(false);
  const handleInputFocus = () => {
    setShowAvailabilityOptions(true);
  };
  const [formData, setFormData] = useState<EnrollmentData>({
    yearOfExperience: (dieticianData?.yearOfExperience ?? 0) as number,
    addressLine1: "",
    addressLine2: "",
    generalAvailability: "",
    areaOfExpertise: dieticianData?.areaOfExpertise || [],
    spokenLanguages: dieticianData?.spokenLanguages || [],
    cityName: "",
    stateName: "",
    socialMediaProfiles: dieticianData?.socialMediaProfiles || [],
    privatePractice:
      dieticianData?.privatePractice === "Yes" ||
      dieticianData?.privatePractice === "No"
        ? dieticianData?.privatePractice
        : null,
    working:
      dieticianData?.working === "Yes" || dieticianData?.working === "No"
        ? dieticianData?.working
        : null,
    noOfConsultations: (dieticianData?.noOfConsultations ?? 0) as number,
    oneTimeConsultationFee: 0,
    monthlyConsultationFee: 0,
    qualifications: dieticianData?.qualifications || [],
    fee: (dieticianData?.fee ?? 0) as number,
    dietPlanPrice: (dieticianData?.dietPlanPrice ?? 0) as number,
    availability: "",
    selfCare: dieticianData?.selfCare || "",
    timeAbleToContributePerWeek:
      dieticianData?.timeAbleToContributePerWeek || 0,
    interestInAnyOtherActivityForUs:
      dieticianData?.interestInAnyOtherActivityForUs || "",
    intro: dieticianData?.intro || "",
    cv: "www.gdrive.com/abcd-efg",
    professionalImage: null,
    pinCode: undefined,
    upiId: dieticianData?.upiId || "",
  });

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    let regex = new RegExp(/^[a-zA-Z0-9.-]{2, 256}@[a-zA-Z][a-zA-Z]{2, 64}$/);
    if (!formData.addressLine1) newErrors.addressLine1 = ADDRESS1_REQUIRED;
    if (!formData.addressLine2) newErrors.addressLine2 = ADDRESS2_REQUIRED;
    if (!formData.stateName) newErrors.stateName = STATE_REQUIRED;
    if (!formData.cityName) newErrors.cityName = CITY_REQUIRED;
    if (!formData.pinCode) newErrors.pinCode = PINCODE_REQUIRED;
    if (
      formData.areaOfExpertise === undefined ||
      formData.spokenLanguages.length === 0
    )
      newErrors.spokenLanguages = LANGUAGES_REQUIRED;
    if (!formData.yearOfExperience) newErrors.yearOfExperience = GENERAL_ERROR;
    if (
      formData.areaOfExpertise === undefined ||
      formData.areaOfExpertise.length === 0
    )
      newErrors.areaOfExpertise = AREA_OF_EXPERTISE;
    if (!formData.privatePractice) newErrors.privatePractice = GENERAL_ERROR;
    if (!formData.working) newErrors.working = GENERAL_ERROR;
    if (!formData.noOfConsultations)
      newErrors.noOfConsultations = GENERAL_ERROR;
    if (!formData.oneTimeConsultationFee)
      newErrors.oneTimeConsultationFee = ONE_TIME_FEE;
    if (!formData.monthlyConsultationFee)
      newErrors.monthlyConsultationFee = MONTHLY_FEE;
    if (!formData.intro) newErrors.intro = GENERAL_ERROR;
    if (formData.qualifications.length === 0)
      newErrors.qualifications = QUALIFICATIONS;
    if (!formData.selfCare) newErrors.selfCare = GENERAL_ERROR;
    if (!formData.timeAbleToContributePerWeek)
      newErrors.timeAbleToContributePerWeek = GENERAL_ERROR;
    if (!formData.interestInAnyOtherActivityForUs)
      newErrors.interestInAnyOtherActivityForUs = GENERAL_ERROR;
    // if (formData.socialMediaProfiles.length === 0)
    //   newErrors.socialMediaProfiles = SOCIAL_MEDIA_PROFILES;
    if (formData.professionalImage === null)
      newErrors.professionalImage = PROFESSIONAL_IMAGE;
    console.log(newErrors);
    console.log("bahr",formData.upiId)
    if(!formData.upiId.includes('@')){
      newErrors.upiId = "Invalid Upi Id";
    }
      
    return newErrors;
  };

  const handleDropdownValueChange = (fieldName: string, newValue: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
    if (errors[fieldName]) {
      setErrors({ ...errors, [fieldName]: "" });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(name);
    const upiPattern = /^[a-zA-Z0-9.-]{2, 256}@[a-zA-Z][a-zA-Z]{2, 64}$/;
    
    setFormData({ ...formData, [name]: value });
    console.log("FormData", formData);
    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleAvailibilityChange = (value: string) => {
    console.log("Availability", JSON.parse(value));
    setFormData((prevFormData) => ({
      ...prevFormData,
      generalAvailability: JSON.stringify(value),
      availability: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors, // Preserve existing errors
      generalAvailability: "", // Clear error for gender
    }));
  };

  const handleChipInputChange = (newValue: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      socialMediaProfiles: newValue,
    }));
    setErrors({ socialMediaProfiles: "" });
  };

  const handleQualificationChange = (newValue: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      qualifications: newValue,
    }));
    setErrors({ qualifications: "" });
  };

  const handleImageUpload = (imageFile: File) => {
    console.log("Uploaded file:", imageFile);
    setFormData((prevFormData) => ({
      ...prevFormData,
      professionalImage: imageFile,
    }));
    setErrors({ professionalImage: "" });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("FormData", formData);
    console.log("Submit request");
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      console.log("new", newErrors)
      setErrors(newErrors);
      return;
    }
    try {
      const { ...payload } = formData;
      console.log("Payload", payload);
      const token = localStorage.getItem("token");
      axios
        .post(
          "https://backend.betternow.co.in/dietitian/registerMetadata",
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          },
        )
        .then((response: any) => {
          const dietitianEnrollmentStatus =
            response.data.data.dietitianEnrollmentStatus;
          console.log("Response", response);
          console.log(
            "Response status",
            response.data.data.dietitianEnrollmentStatus,
          );
          switch (dietitianEnrollmentStatus) {
            case 1:
              console.log("Pending");
              navigate("/enrollmentStatus", {
                state: { status: dietitianEnrollmentStatus },
              });
              break;
            case 2:
              console.log("Approved");
              navigate("/dashboard", {
                state: { status: dietitianEnrollmentStatus },
              });
              break;
            case 3:
              console.log("Rejected");
              navigate("/enrollmentStatus", {
                state: { status: dietitianEnrollmentStatus },
              });
              break;
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    } catch (error) {
      console.log("Error:", error);
      toast.error("An error occured please try again later.");
    }
  };

  return (
    <section>
      <Toaster position="top-center" reverseOrder={false} />

      <div className="py-4 lg:py-8 px-6 mx-auto max-w-screen-lg">
        <div className="mb-8 text-[50px] font-medium text-center text-[#315145] xs:text-[32px] ">
          Enrollment Form
        </div>

        <form className="space-y-8" onSubmit={handleSubmit}>
          <div className=" grid grid-cols-1 gap-6 md:grid-cols-2">
            <FormInput
              label="Address line 1"
              type="text"
              placeholder=""
              required={false}
              onChange={handleInputChange}
              name="addressLine1"
              value={formData.addressLine1}
              error={errors.addressLine1} // Update here to match the key in the `errors` object
            />

            <FormInput
              label="Address line 2"
              type="text"
              placeholder=""
              required={false}
              onChange={handleInputChange}
              name="addressLine2"
              value={formData.addressLine2}
              error={errors.addressLine2}
            />
            {/* <div className="flex gap-x-2"> */}
              <div className="flex flex-col">
                <label className="text-[20px] mb-2  text-[#315145] xs:text-[18px]">
                  State
                </label>
                <DropDown
                  // required
                  options={stateOptions}
                  isClearable={true}
                  isMultiple={false}
                  isSearchable={true}
                  onSelectChange={handleCityValueChange}
                  fieldName="stateName"
                  error={errors.stateName}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[20px] mb-2  text-[#315145] xs:text-[18px]">City</label>
                <DropDown
                  options={filteredCities}
                  isClearable={true}
                  isMultiple={false}
                  isSearchable={true}
                  onSelectChange={handleDropdownValueChange}
                  fieldName="cityName"
                  error={errors.cityName}
                />
              </div>
               
            {/* </div> */}
            {/* <div className="flex gap-x-2"> */}
              <div className="">
                <FormInput
                  label="Pin code"
                  type="tel"
                  placeholder=""
                  required={false}
                  onChange={handleInputChange}
                  name="pinCode"
                  value={formData.pinCode}
                  maxlength={6}
                  error={errors.pinCode}
                />
              </div>
              <div className="flex flex-col ">
                <label className="text-[20px] mb-2  text-[#315145] xs:text-[18px]">
                  Languages
                </label>
                <DropDown
                  // required
                  options={languageOptions}
                  isClearable={true}
                  isMultiple={true}
                  isSearchable={true}
                  onSelectChange={handleDropdownValueChange}
                  fieldName="spokenLanguages"
                  error={errors.spokenLanguages}
                />
              </div>
            {/* </div> */}
            <FormInput
              label="Year of Experience"
              type="number"
              placeholder=""
              required={false}
              onChange={handleInputChange}
              name="yearOfExperience"
              value={formData.yearOfExperience}
              error={errors.yearOfExperience}
            />

            <div className="flex flex-col">
              <label className="text-[20px] mb-2  text-[#315145] xs:text-[18px]">
                Area of Expertise
              </label>
              <DropDown
                // required
                options={areaOfExpertiseOptions}
                isClearable={true}
                isMultiple={true}
                isSearchable={true}
                onSelectChange={handleDropdownValueChange}
                fieldName="areaOfExpertise"
                error={errors.areaOfExpertise}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-[20px] mb-2  text-[#315145] xs:text-[18px]">
                Do you have a private practice?
              </label>
              <DropDown
                // required
                options={booleanOptions}
                isClearable={true}
                isMultiple={false}
                isSearchable={false}
                onSelectChange={handleDropdownValueChange}
                fieldName="privatePractice"
                error={errors.privatePractice}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-[20px] mb-2  text-[#315145] xs:text-[18px]">
                Have you worked for an organization?
              </label>
              <DropDown
                // required
                options={booleanOptions}
                isClearable={true}
                isMultiple={false}
                isSearchable={false}
                onSelectChange={handleDropdownValueChange}
                fieldName="working"
                error={errors.working}
              />
            </div>
            <FormInput
              label="Number of consultations done till now"
              type="number"
              placeholder=""
              required={false}
              onChange={handleInputChange}
              name="noOfConsultations"
              value={formData.noOfConsultations}
              error={errors.noOfConsultations}
            />
            {/* <div>
              <label className="block  text-[20px] mb-2 text-[#315145]">
                Availability
              </label>
              <input
                type="text"
                className="bg-[#EFEFEA] text-lg rounded-lg block w-full h-[57px] p-2.5 cursor-pointer"
                placeholder="e.g. Monday to Sunday"
                onFocus={handleInputFocus}
                readOnly
                // required
                value={
                  formData.availability && JSON.parse(formData.availability)
                    ? JSON.parse(formData.availability)
                        .map((item: any, index: number, array: any[]) => {
                          const day = Object.keys(item)[0];
                          const timeRange = item[day];
                          return `${day}: ${timeRange}${
                            index < array.length - 1 ? ", " : ""
                          }`;
                        })
                        .join("\n")
                    : ""
                }
              />
              {showAvailabilityOptions && (
                <AvailabilityPicker
                  close={() => setShowAvailabilityOptions(false)}
                  save={handleAvailibilityChange}
                />
              )}
              {errors && (
                <span className=" text-red-500 text-sm mt-[6px]">
                  {errors.generalAvailability}
                </span>
              )}
            </div> */}
            <FormInput
              label="One time consultation fee?"
              type="number"
              placeholder=""
              required={false}
              onChange={handleInputChange}
              name="oneTimeConsultationFee"
              value={formData.oneTimeConsultationFee}
              error={errors.oneTimeConsultationFee}
            />
            <FormInput
              label="Monthly plan charges?"
              type="number"
              placeholder=""
              required={false}
              onChange={handleInputChange}
              name="monthlyConsultationFee"
              value={formData.monthlyConsultationFee}
              error={errors.monthlyConsultationFee}
            />
              <FormInput
                label="UPI Id for payments"
                type="text"
                placeholder=""
                required={true}
                onChange={handleInputChange}
                name="upiId"
                value={formData.upiId}
                error={errors.upiId}
                
              />
          </div>
          <div className="flex flex-col">
              <label className="text-[20px] mb-2  text-[#315145] xs:text-[18px]">
                Add Qualifications
              </label>
              <ChipInput
                onInputChange={handleQualificationChange}
                error={errors.qualifications}
                placeholder="Add a qualification"
              />
            </div>
          <FormTextArea
            label="Introduction"
            required={false}
            placeholder=""
            onChange={handleInputChange}
            name="intro"
            value={formData.intro}
            error={errors.intro}
          />
          
          <FormTextArea
            label="What do you do for your own selfCare?"
            required={false}
            placeholder=""
            onChange={handleInputChange}
            name="selfCare"
            value={formData.selfCare}
            error={errors.selfCare}
          />
          <FormInput
            label="How many hours can you contribute to BetterNow each week? (There is no minimum requirement.)"
            required={false}
            placeholder="Please write in hours"
            onChange={handleInputChange}
            type="number"
            name="timeAbleToContributePerWeek"
            value={formData.timeAbleToContributePerWeek}
            error={errors.timeAbleToContributePerWeek}
          />
          <FormTextArea
            label="Apart from consulting/sessions, would you be interested in
              contributing in any other capacity to BetterNow such as content
              creation, blogging, awareness programs, community services, etc. ?"
            required={false}
            placeholder=""
            onChange={handleInputChange}
            name="interestInAnyOtherActivityForUs"
            value={formData.interestInAnyOtherActivityForUs}
            error={errors.interestInAnyOtherActivityForUs}
          />
          <div className="flex flex-col">
            <label className="text-[20px] mb-2  text-[#315145] xs:text-[18px]">
              Social Media Profiles
            </label>
            <ChipInput
              onInputChange={handleChipInputChange}
              // error={errors.socialMediaProfiles}
              placeholder="Add a profile"
              required={false}
            />
          </div>
          <div>
            <h1 className="text-[20px] mb-2  text-[#315145] xs:text-[18px]">
              Upload Professional Image
            </h1>
            <ImageUpload
              onImageUpload={handleImageUpload}
              maxSizeInMB={2}
              error={errors.ImageUpload}
            />
            {errors && (
              <span className=" text-red-500 text-sm mt-[6px]">
                {errors.professionalImage}
              </span>
            )}
          </div>
          <div className="flex flex-col md:flex-row md: justify-between items-center">
            <button
              type="submit"
              className="bg-[#315145] text-white  py-2 px-4 rounded-lg  text-[20px] font-medium w-full md:w-fit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default EnrollmentForm;

