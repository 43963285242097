export const getColumnsByType = (type: string) => {
  switch (type) {
    case "Enrolled Dietitians":
      return enrolledDieticianData.columns;

    case "Dietitian Evaluation":
      return dieticianEvaluationData.columns;

    case "Enrolled Patient":
      return enrolledPatientData.columns;

    default:
      return [];
  }
};

export const dieticianEvaluationData = {
  columns: ["S.No.", "Name", "Filled Date", "Action"],
  rows: [
    [1, "John Doe", "2024-01-01", "Approved"],
    [2, "Jane Smith", "2024-01-02", "Rejected"],
    [3, "Alice Johnson", "2024-01-03", "Approved"],
    [4, "Bob Brown", "2024-01-04", "Rejected"],
    [5, "Charlie Davis", "2024-01-05", "Approved"],
    [6, "David Wilson", "2024-01-06", "Rejected"],
    [7, "Emily Clark", "2024-01-07", "Approved"],
    [8, "Frank Wright", "2024-01-08", "Rejected"],
    [9, "Grace Hill", "2024-01-09", "Approved"],
    [10, "Henry Baker", "2024-01-10", "Rejected"],
  ],
  isDownloadable: false,
};

export const enrolledPatientData = {
  columns: [
    "S.No.",
    "Patient ID",
    "Pt. Name",
    "Plan Type",
    "Category",
    "Assigned Dietitian",
    "Enrolled Date",
    "Start Date",
    "End Date",
    "Plan Duration",
  ],
  rows: [
    [
      1,
      "P001",
      "John Doe",
      "Basic",
      "Weight Loss",
      "Dr. Smith",
      "2024-01-01",
      "2024-01-02",
      "2024-07-01",
      "6 months",
    ],
    [
      2,
      "P002",
      "Jane Smith",
      "Premium",
      "Diabetes",
      "Dr. Brown",
      "2024-01-05",
      "2024-01-06",
      "2024-07-05",
      "6 months",
    ],
    [
      3,
      "P003",
      "Alice Johnson",
      "Standard",
      "Cardiac",
      "Dr. Wilson",
      "2024-01-10",
      "2024-01-11",
      "2024-07-10",
      "6 months",
    ],
    [
      4,
      "P004",
      "Bob Brown",
      "Basic",
      "Weight Loss",
      "Dr. Davis",
      "2024-01-15",
      "2024-01-16",
      "2024-07-15",
      "6 months",
    ],
    [
      5,
      "P005",
      "Charlie Davis",
      "Premium",
      "Diabetes",
      "Dr. Clark",
      "2024-01-20",
      "2024-01-21",
      "2024-07-20",
      "6 months",
    ],
    [
      6,
      "P006",
      "David Wilson",
      "Standard",
      "Cardiac",
      "Dr. Wright",
      "2024-01-25",
      "2024-01-26",
      "2024-07-25",
      "6 months",
    ],
    [
      7,
      "P007",
      "Emily Clark",
      "Basic",
      "Weight Loss",
      "Dr. Hill",
      "2024-01-30",
      "2024-01-31",
      "2024-07-30",
      "6 months",
    ],
    [
      8,
      "P008",
      "Frank Wright",
      "Premium",
      "Diabetes",
      "Dr. Baker",
      "2024-02-01",
      "2024-02-02",
      "2024-08-01",
      "6 months",
    ],
    [
      9,
      "P009",
      "Grace Hill",
      "Standard",
      "Cardiac",
      "Dr. Scott",
      "2024-02-05",
      "2024-02-06",
      "2024-08-05",
      "6 months",
    ],
    [
      10,
      "P010",
      "Henry Baker",
      "Basic",
      "Weight Loss",
      "Dr. Lewis",
      "2024-02-10",
      "2024-02-11",
      "2024-08-10",
      "6 months",
    ],
  ],
  isDownloadable: true,
};

export const enrolledDieticianData = {
  columns: [
    "S.No.",
    "Dietitian ID",
    "Dt Name",
    "Phone",
    "E-mail",
    "Enrolled Date",
    "City",
    "Languages",
    "OT Price",
    "Premium Price",
    "OT Paid Patient",
    "Premium Paid Patient",
  ],
  rows: [
    [
      1,
      "D001",
      "John Doe",
      "123-456-7890",
      "johndoe@example.com",
      "2024-01-01",
      "New York",
      "English, Spanish",
      "$50",
      "$100",
      10,
      5,
    ],
    [
      2,
      "D002",
      "Jane Smith",
      "123-456-7891",
      "janesmith@example.com",
      "2024-01-05",
      "Los Angeles",
      "English",
      "$60",
      "$120",
      12,
      6,
    ],
    [
      3,
      "D003",
      "Alice Johnson",
      "123-456-7892",
      "alicejohnson@example.com",
      "2024-01-10",
      "Chicago",
      "English, French",
      "$55",
      "$110",
      11,
      5,
    ],
    [
      4,
      "D004",
      "Bob Brown",
      "123-456-7893",
      "bobbrown@example.com",
      "2024-01-15",
      "Houston",
      "English",
      "$50",
      "$100",
      10,
      4,
    ],
    [
      5,
      "D005",
      "Charlie Davis",
      "123-456-7894",
      "charliedavis@example.com",
      "2024-01-20",
      "Phoenix",
      "English, Spanish",
      "$60",
      "$120",
      13,
      7,
    ],
    [
      6,
      "D006",
      "David Wilson",
      "123-456-7895",
      "davidwilson@example.com",
      "2024-01-25",
      "Philadelphia",
      "English",
      "$55",
      "$110",
      12,
      6,
    ],
    [
      7,
      "D007",
      "Emily Clark",
      "123-456-7896",
      "emilyclark@example.com",
      "2024-01-30",
      "San Antonio",
      "English",
      "$50",
      "$100",
      11,
      5,
    ],
    [
      8,
      "D008",
      "Frank Wright",
      "123-456-7897",
      "frankwright@example.com",
      "2024-02-01",
      "San Diego",
      "English, French",
      "$60",
      "$120",
      14,
      8,
    ],
    [
      9,
      "D009",
      "Grace Hill",
      "123-456-7898",
      "gracehill@example.com",
      "2024-02-05",
      "Dallas",
      "English",
      "$55",
      "$110",
      13,
      7,
    ],
    [
      10,
      "D010",
      "Henry Baker",
      "123-456-7899",
      "henrybaker@example.com",
      "2024-02-10",
      "San Jose",
      "English, Spanish",
      "$50",
      "$100",
      10,
      4,
    ],
  ],
  isDownloadable: false,
};

export const rows = [
  {
    name: "John Doe",
    date: "Aug 17, 2024", 
    time:"2:34 PM",},
  {
    name: "Jane Smith",
    date: "Aug 18, 2024", 
    time:"2:34 PM",},
  {
    name: "Alice Johnson",
    date: "Aug 19, 2024", 
    time:"2:34 PM",
  },
  {
    name: "Bob Brown",
    date: "Aug 20, 2024", 
    time:"2:34 PM",},
  {
    name: "Charlie Davis",
    date: "Aug 21, 2024", 
    time:"2:34 PM",},
  {
    name: "David Wilson",
    date: "Aug 22, 2024", 
    time:"2:34 PM",},
  {
    name: "Emily Clark",
    date: "Aug 23, 2024", 
    time:"2:34 PM",},
  {
    name: "Frank Wright",
    date: "Aug 24, 2024", 
    time:"2:34 PM",},
  {
    name: "Grace Hill",
    date: "Aug 25, 2024", 
    time:"2:34 PM",},
  {
    name: "Henry Baker",
    date: "Aug 26, 2024", 
    time:"2:34 PM",},
];
