import React from "react";
import Navbar from "../Navbar/Navbar";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import Footer from "../Footer";

const JoinUs: React.FC = () => {
  return (
    <div className="font-poppins">
      <Navbar
        backgroundColor="#315145"
        textColor="#FFFFF5"
        logo="/betternowLogo.png"
      />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      {/* <SectionFour /> */}
      <Footer />
    </div>
  );
};

export default JoinUs;
