import React from "react";
import { useNavigate } from "react-router-dom";

const Section3 = ({ id }: any) => {
  const navigate = useNavigate();
  const redirectToCheckout = (id: string) => {
    navigate(`/checkout/${id}`);
  };
  return (
    <div>
      <>
        <div className="w-full flex items-center justify-center relative lg:mt-6 font-poppins ">
          <div className="w-full bg-[#F3F3F3] py-4  px-8 rounded-xl flex flex-col gap-y-3 lg:gap-y-6">
            <div className="flex w-full justify-between items-center">
              <div className="font-semibold text-[10px] lg:text-[18px]">
                GRAB YOUR SPOT
              </div>
              <div className="flex items-center gap-x-2 md:hidden">
                <div>
                  <img
                    className="w-[12px] md:w-[20px]"
                    src="/notify.png"
                    alt="notification"
                  />
                </div>
                <div className="text-[6px] md:text-[10px] text-[#6E6D6D]">
                  Instant Confirmation
                </div>
              </div>
            </div>
            <div className="h-[1px] w-[100%] bg-[#8D8D8D]"></div>
            <div>
              <div>
                <div className="flex flex-col md:flex-row md:justify-between gap-y-4 md:gap-y-0">
                  <div className="flex">
                    {/* <input
                      type="radio"
                      name="sessionMode"
                      value="googleMeet"
                      className="w-5 h-5 bg-gray-100 border-[#484747] accent-[#315145]"
                    /> */}
                    <label className="ms-2 text-[0.9rem] md:text-[1.125rem] text-[#505050]  flex gap-x-4 pl-2">
                      <div>
                        <img src="/Gmeet.svg" alt="Google meet" />
                      </div>
                      <div className="flex flex-col gap-y-2">
                        <div className="text-[14px] font-medium">
                          Google Meet
                        </div>
                        <div className="text-[6px] lg:text-[10px]">
                          Web conferencing details provided upon <br />
                          confirmation.
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="md:mr-12 md:flex md:flex-col md:items-center">
                    <button
                      onClick={() => redirectToCheckout(id)}
                      className="bg-[#315145] text-[#FFFFFF] w-[100%] md:w-[12vw] py-2 rounded-3xl"
                    >
                      Book Session
                    </button>
                    <div className=" hidden md:flex items-center gap-x-2 md:mr-3">
                      <div>
                        <img
                          className="w-[12px] md:w-[20px]"
                          src="/notify.png"
                          alt="notification"
                        />
                      </div>
                      <div className="text-[6px] md:text-[10px] text-[#6E6D6D]">
                        Instant Confirmation
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Section3;
