import React from "react";
import Navbar from "../Navbar/Navbar";
import MainSection from "./MainSection";

const PatientProfile: React.FC = () => {
  return (
    <div className="font-poppins overflow-x-hidden">
      <Navbar
        backgroundColor="#FFFFF5"
        textColor="#325243"
        logo="/betterNowLogodark.png"
      />
      <MainSection />
    </div>
  );
};

export default PatientProfile;
