import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  enrolledPatients,
  loggedInUser,
  login,
  loginWithGoogle,
  logout,
  signup,
} from "../services/userService";
import { LoginCredentials } from "../../model/CredentialPayloadModel";
import { SignupPayload } from "../../model/SignupFormPayload";

export const loginUserAction = createAsyncThunk(
  "user/login",
  async (credentials: LoginCredentials, { rejectWithValue }) => {
    try {
      const user = await login(credentials);
      return user;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const loggedInUserAction = createAsyncThunk(
  "user/loggedIn",
  async (_, { rejectWithValue }) => {
    try {
      const res = await loggedInUser();
      console.log(res);

      return res.userData;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const loginUserWithGoogleAction = createAsyncThunk(
  "user/login/google",
  async (_, { rejectWithValue }) => {
    try {
      const user = await loginWithGoogle();
      return user;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const logoutUserAction = createAsyncThunk(
  "user/logout",
  async (_, { rejectWithValue }) => {
    try {
      const res = await logout();
      return res;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

// export const signupUser = createAsyncThunk(
//   "user",
//   async (credentials: SignupPayload, { rejectWithValue }) => {
//     try {
//       const msg = await signup(credentials);
//       return msg;
//     } catch (error: any) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

export const getEnrolledPatientsAction = createAsyncThunk(
  "user/patients/enrolled",
  async ({ page, limit }: any, { rejectWithValue }) => {
    try {
      const msg = await enrolledPatients(page, limit);
      return msg;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
