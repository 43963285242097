export const HomeOptionsList = [
  {
    id: 1,
    img_src: "/bannerTwo.png",
    img_heading: "Gut Health",
    redirectUrl: "/guthealth",
    animate: "fade-right",
  },
  {
    id: 2,
    img_src: "/bannerTwo.png",
    img_heading: "Diabetes",
    animate: "fade-up",
    redirectUrl: "/diabetes",
  },
  {
    id: 3,
    img_src: "/bannerTwo.png",
    img_heading: "PCOS",
    animate: "fade-left",
    redirectUrl: "/pcos",
  },
  {
    id: 4,
    img_src: "/bannerTwo.png",
    img_heading: "Cholesterol",
    animate: "fade-right",
    redirectUrl: "/Cholestrol",
  },
  {
    id: 5,
    img_src: "/bannerTwo.png",
    img_heading: "Weight Management",
    animate: "fade-left",
    redirectUrl: "/Weightmanagement",
  },
];
