import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/Home";
import DieticianHome from "./components/DieticianHome";
import FAQ from "./components/FAQ";
import ContactUs from "./components/ContactUs";
import Enrollment from "./components/Enrollment";
import PageTitle from "./components/PageTitle";
import Dashboard from "./components/Dashboard/Dashboard";
import Profile from "./components/Dashboard/Profile";
import Chat from "./components/Dashboard/Chat";
// import Settings from "./components/Dashboard/Settings";
import JoinUs from "./components/JoinUs";
import AdminDashboard from "./components/AdminDashboard";
import PatientMedicalHistory from "./components/Dashboard/PatientMedicalHistory";
import Checkout from "./pages/CheckoutPage/CheckoutPage";
import Appointment from "./components/Dashboard/Appointment";
import Terms from "./pages/Terms of Services/terms";
import Privacy from "./pages/Privacy Policy/Privacy";
import Refund from "./pages/Refund Policy/Refund";
import DoctorProfile from "./components/DoctorProfile";
import { useEffect, useLayoutEffect } from "react";
import EditProfile from "./components/Dashboard/EditProfile";
import PatientProfile from "./components/Profiles/PatientProfile";
import EnrollmentStatus from "./components/EnrollmentStatus/EnrollmentStatus";
import PatientProtectedRoute from "./utils/PatientProtectedRoute";
import AdminProtectedRoute from "./utils/AdminProtectedRoute";
import DietitianProtectedRoute from "./utils/DietitianProtectedRoute";
import PendingDietitianProtectedRoute from "./utils/PendingDietitianProtectedRoute";
import NotFilledDietitianProtectedRoute from "./utils/NotFilledDietitianProtectedRoute";
import Settings from "./components/Dashboard/Settings";
import Congo from "./pages/CheckoutPage/Congo";
import ChatApp from "./components/Dashboard/Chat3";

function App() {
  const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return <>{children}</>;
  };
  // const userId = localStorage.getItem("token")
  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route path="/" Component={Home} />
          <Route
            path="/diabetes"
            Component={() => <DieticianHome expertise={"Diabetes"} />}
          />
          <Route
            path="/pcos"
            Component={() => <DieticianHome expertise={"PCOS"} />}
          />
          <Route
            path="/weightmanagement"
            Component={() => <DieticianHome expertise={"Weight Management"} />}
          />
          <Route
            path="/guthealth"
            Component={() => <DieticianHome expertise={"Gut Health"} />}
          />
          <Route
            path="/cholestrol"
            Component={() => <DieticianHome expertise={"Cholestrol"} />}
          />
          <Route path="/faq" Component={FAQ} />
          <Route path="/contact" Component={ContactUs} />

          <Route path="/terms-of-services" Component={Terms} />
          <Route path="/privacy-policy" Component={Privacy} />
          <Route path="/refund-policy" Component={Refund} />
          <Route path={`/doctor-profile/:userId`} Component={DoctorProfile} />
          <Route path="/dietitian-jobs" Component={JoinUs} />
          <Route path="/checkout/:userId" Component={Checkout} />
          <Route path="/congo" Component={Congo} />

          {/* This has to be protected route */}
          <Route element={<PatientProtectedRoute />}>
            <Route path="/profile" Component={PatientProfile} />
          </Route>

          {/* <Route path="/congrats" Component={Congo} /> */}

          <Route element={<NotFilledDietitianProtectedRoute />}>
            <Route path="/register" Component={Enrollment} />
          </Route>

          <Route element={<PendingDietitianProtectedRoute />}>
            <Route path="/enrollmentStatus" Component={EnrollmentStatus} />
          </Route>
          {/* Dashboard Routes 
          
          Needs to be protected routes*/}
          <Route element={<DietitianProtectedRoute />}>
            <Route
              path="/dashboard"
              element={
                <>
                  <PageTitle title="Dashboard" />
                  <Dashboard />
                </>
              }
            />
            <Route
              path="/dashboard/edit"
              element={
                <>
                  <PageTitle title="Edit profile" />
                  <EditProfile />
                </>
              }
            />
            <Route
              path="/dashboard/profile"
              element={
                <>
                  <PageTitle title="Profile" />
                  <Profile />
                </>
              }
            />
            <Route
              path="/dashboard/chat"
              element={
                <>
                  <PageTitle title="Chat" />
                  <ChatApp />
                </>
              }
            />
            <Route
              path="/dashboard/settings"
              element={
                <>
                  <PageTitle title="Settings" />
                  <Settings />
                </>
              }
            />
            <Route
              path="/dashboard/appointment"
              element={
                <>
                  <PageTitle title="Appointment" />
                  <Appointment />
                </>
              }
            />
            <Route
              path="/dashboard/patient-medical-form"
              element={
                <>
                  <PageTitle title="PatientMedicalHistory" />
                  <PatientMedicalHistory />
                </>
              }
            />
          </Route>

          <Route element={<AdminProtectedRoute />}>
            <Route path="/dashboard/admin" Component={AdminDashboard} />
          </Route>
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
