import React, { useState } from "react";
import { languageOptions } from "../../constants/DropDownOptions";

interface DropDownProps {
  areaOfExpertise: string | null;
  setLanguage: (language: string) => void;
}

const DropDown: React.FC<DropDownProps> = ({
  areaOfExpertise,
  setLanguage,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (label: string) => {
    setLanguage(label);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className="rounded-[2.5rem] bg-[#FFFFF5] cursor-pointer py-1.5 px-3 text-[0.8rem] md:text-[1rem] text-center flex flex-row items-center"
        onClick={toggleDropdown}
      >
        Languages
        <svg
          className={`w-2.5 h-2.5 ms-3 transform transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </div>

      <div
        className={`${
          isOpen ? "block" : "hidden"
        } z-[1] bg-[#FFFFF5] divide-y divide-gray-100 rounded-lg shadow w-44 absolute md:left-0 md:ml-[6vw] mt-10`}
      >
        <ul
          className="py-2 text-[0.9rem] text-gray-700"
          aria-labelledby="dropdownDefaultButton"
        >
          {languageOptions.map((item, index) => (
            <li
              key={index}
              className="px-4 py-2 hover:bg-gray-100 text-[#315145] "
              onClick={() => handleOptionClick(item.label)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default DropDown;
