import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar/Navbar";

const Terms = () => {
  return (
    <>
      <Navbar
        backgroundColor="#FFFFF5"
        textColor="rgb(50, 82, 67)"
        logo="/betterNowLogodark.png"
      />
      <div className=" pl-8 pr-8 md:pl-20 pt-10 pb-20 md:pr-20 text-justify bg-[#FFFFF5]">
        <h1 className="text-[38px] font-semibold text-[#2B2B2B]">
        Terms and Conditions
        </h1>
        <p className="text-[#A1A1A1] text-[18px] mt-3">
        By using our website, web/desktop and services, you agree to the following terms.
        </p>
        <h3 className="text-[24px] font-semibold text-[#565656] mt-3">
        Welcome to betternow (’We, Our, Us’’)

        </h3>
        <div className="text-[#A1A1A1] text-[18px] mt-3">
        This website/web/desktop or any other interface through which User accesses or may access betternow (the “Service”). This website also enables all users to learn about the range of betternow services or products including.

        </div>
        
        <div className="mt-5 ml-6">
          <ol className=" list-decimal">
            <li className="text-[18px] text-[#555555] ">Definitions</li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              “Website" or “web” means the online portal, website, interface or any other means the User utilizes to access betternow and/or any range of betternow products.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              “User” means any individual or business entity including but not limited to practitioners, doctors, hospitals and clinics that have subscribed to or utilizes the betternow website/services, through its website.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              “User Data” means information (including Personal Information) relating to a User, including order, payment, or account information.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              “End User” means any person, including users and their relatives and next of kin, whose personal information, including sensitive personal information would be stored in betternow’s databases.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              “Service” means any services offered by the betternow or betternow related entities, provided through means including.

              </li>
              
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">Introduction</li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              The website is managed and operated by betternow, having its registered office at 1sr floor, 780, Sector 40, Haryana -122001, India on behalf of betternow.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              The Terms of Service (hereinafter referred to as “TOS”) provides an overview of the terms of use and the services of betternow & its range of products through website or any other interface.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              The TOS, Privacy Policy, Refund Policy, along with other ancillary policies, of the betternow firm together serve as our policies.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              The following TOS governs this particular website and also the relationship between the user of the betternow services and the service providers.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              This TOS also applies to all viewers and users of this website.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              By continuing to read this text, you agree to be bound by our policies and consent to the following requisitions mentioned below.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">Registration</li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
                {" "}
                In order to view or access complete information and services offered by us on the website or similar interfaces, the User shall be required to make an account with the website online or with help of an authorized betternow representative or associate.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              By registering themselves, the User agrees to be contacted by the us through call, email and/or any other means of communication.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
                {" "}
                The User may be contacted for service-related reminders, updates, promotions, and latest schemes offered by the us.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
              Terms of Service
            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              The User may use the Service in executable format for its own use after subscribing to the Service, and shall not translate or modify the service code and programs or incorporate them.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              The User shall not also, transfer or sub-license the Service to any third party, in whole or in part or in any other form, whether modified or unmodified.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              The Service of Electronic Health Record shall only be utilized for the intended purpose of easing the process of documentation related to non-medical services as per requirement, and maintenance of records of all End Users by the User. The said Services are offered for the purpose of maintaining a one-stop database of the End User (patient), containing information including but not limited to personal information, sensitive personal information and medical information, etc., for the ease of Users.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              Any unauthorized use of the Service will result in the termination of the User’s subscription to the Service and shall warrant appropriate legal action against the User.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              betternow is a subscription-based Service wherein the User would be required to hold a registered account with an active subscription to access the said Service.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              The subscription may be purchased for periods as specified in the payment gateway, or as communicated on our website/emails and representatives.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              User gives consent to us to send, either through itself or through any third party service provider, from time to time various information / alerts / SMS / other messages or calls or commercial communication or any other services on the users telephone number, whether user’s number is registered with National Do Not Call Registry/ listed in National Customer Preference Register or not.  User also confirms that by sending any of such messages / calls, user will not hold its third party provider liable / institute complaint under the Telecom Commercial Communications Customer Preference (TRAI) Regulations, 2010 or such other applicable regulations including any amendment thereof,  as may be applicable from time to time.
              </li>
             
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">No Liability</li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              A Service is a sophisticated tool which can be used by the Users for use cases including but not limited to simplifying and streamlining the process of maintaining health records & documentation related to issuing prescriptions and creating an End User-specific database, however, the Service cannot be a substitute for diagnosis provided the User.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              We do not take responsibility for any inaccuracies and incorrect information entered through the Service by the User.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              We do not guarantee the accuracy of the information entered by the User in the database and will not be liable for any loss, injury or damage incurred by the User or the End User during the subscription period and after the subscription has expired.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              To prevent any inaccuracies, the Service provides the User with the three options while entering/storing the information/data of the End User in the database viz. –(i) accept data as entered; or(ii) edit data entered and then accept, or(iii) discard the data entered

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              Notwithstanding the above, possible inaccuracies may arise due to various factors including but not limited to unavailability of communication systems i.e. non-functional or low-speed internet/network, or virus in the Service or website or any other interface offered by betternow or cyber-attack/ hacking, or any unauthorized access to computer data and storage devices, computer crashes, malfunctioning in the computer terminal, or the systems getting affected by any malicious, destructive or corrupting code or program, mechanical or technical errors/failures or power shutdown, etc. We do not bear any liability in respect of inaccuracies and any loss, injury or damage that may be caused due to such inaccuracy.
              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">No- Endorsement</li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              We do not recommend, endorse or solicit business for any particular health-care provider mentioned on its website.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
              Third-Party Links and Advertisements
            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              We do not endorse or advertise any content from any other website / App but its own.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
              Transfer Of Information Prohibited
            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              This website prohibits the User to send or transfer information or data to any third-parties.
              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
              Right to block Users
            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              We reserve its rights to block users who are found to be misusing the content of the website or using the Service against the service terms specified in this policy or are found to be causing any harm, loss or damage including but not limited to defamation of the website, in any manner.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">Indemnification</li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              The Users agree to indemnify us against all losses, damages, costs, and expenses incurred by us by way of breach of any of the oue policies.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
              Intellectual Property Rights
            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              The Application seriously takes into view its intellectual property. All the content produced on this website is protected under the Copyright Act, 1957 and the Trademark Act, 1999. Any person/entity found to be misusing its content or using the same for commercial purposes will be held liable under the applicable laws.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">Severability</li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              If any provisions of our policies shall be declared invalid or illegal for any reason whatsoever, then, notwithstanding such invalidity or illegality, the remaining terms and provisions of the said policies shall remain in full force and effect in the same manner as if the invalid or illegal provisions had been contained herein.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
              Waiver Of Rights
            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              Non-exercise of rights by us would not mean the waiver of its rights and remedies.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
              Amendment of the TOS
            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              Although every effort has been made to ensure the accuracy of the information contained online, we cannot accept responsibility for any errors or omissions and reserve its rights to vary, amend, supplement or cancel any of the information or offers featured online at any time.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              These terms may be modified from time to time at our sole instance without any prior intimation.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
              Dispute Resolution
            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              This website and any disputes/issues arising out of it shall be governed by, and construed in accordance with, the laws of the Union of India.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              Unless otherwise mandated by applicable law, any and all dispute, claim or controversy arising out of, or relating to this Agreement, including the determination of the scope or applicability of this TOS and/or any of our policies, shall be settled by arbitration in India, before a sole arbitrator appointed by us.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              The said Arbitration shall be conducted in accordance with the rules of arbitration of the Indian Arbitration and Conciliation Act, 1996 as in effect on the date hereof, with the seat of the arbitration at New Delhi, India. The proceedings of the arbitration, including, but not limited to, any awards, interim or final, shall be in the English language. The award will be final and binding on the parties to the dispute.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              The award shall be in writing and be a reasoned award. The prevailing party shall be entitled to claim its costs and expenses (including reasonable counsel fees), but the arbitral panel shall not be required to award costs and expenses. The award shall be final and conclusive and judgment thereon may be entered in a court having jurisdiction for its enforcement.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              Neither party shall be entitled to commence or maintain any action in a court of law upon any matter in dispute until such matter shall have been submitted and decided by the arbitral pane as herein provided and then only for the enforcement of the arbitral panel’s award; provided, however, that the parties hereto shall be permitted to seek temporary or preliminary equitable relief in a court located in New Delhi, India pending resolution of any arbitration proceeding hereunder. The parties agree to use the courts in New Delhi exclusively to seek temporary or preliminary equitable relief in connection with this Agreement, irrespective of where the cause of action has arisen. User /End User expressly waives objections to the exclusive jurisdiction of the courts in New Delhi on the grounds that the courts in New Delhi are inconvenient or inappropriate.
              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">Termination</li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              Without prejudice to the rights of the User/ End User to initiate any legal action, We may terminate and suspend the delivery of any of the Services offered to the User.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
            Miscellaneous Provisions

            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              Malware/viruses – substantial consideration and investment have been vested into this website and the Service by us to ensure the smooth and virus/malware-free functioning of the same. However, We cannot take the liability for unwarranted activity caused on the website/ Application.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              All transactions facilitated by us could either through the designated online payment gateway available on the betternow.co.in

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              Force Majeure – We shall not be made liable for any transaction that does not fructify, or be completed, or for any failure on the part of us to perform any of its obligations under the Our policies, or those applicable specifically to its services/facilities if performance is prevented, hindered or delayed by a Force Majeure event[1]. In such a case, its obligations shall be suspended for so long as the Force Majeure event continues. [1]“Force Majeure – means any event due to any cause beyond the reasonable control of us including and not limited to the unavailability of communication systems, or virus in the processes or payment or delivery mechanism, fire, flood, explosion, acts of God, civil commotions, strikes or industrial action of any kind, riots, insurrection, war, acts of the government, computer hacking, unauthorized access to computer data and storage devices, computer crashes, malfunctioning in the computer terminal, or the systems getting affected by any malicious, destructive or corrupting code or program, mechanical or technical errors/failures or power shutdown etc.

              </li>
            </ol>
           

          </ol>
        </div>
        <div className="text-[#A1A1A1] text-[18px] mt-3">
        Note- betternow is a partnership firm.
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
