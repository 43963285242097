import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  enrollDieticianAction,
  getAllDieticiansAction,
  getDieticianByAreaOfExperties,
  getDieticiansByIdAction,
  getEnrolledDieticiansAction,
  getLoggidInDieticianAction,
} from "../actions/dieticianActions";
import { Dietician } from "../../model/DieticianDataModel";

interface dieticianState {
  dieticians: Dietician[];
  totalPage: number;
  loading: boolean;
  error: boolean;
}

const initialState: dieticianState = {
  dieticians: [],
  totalPage: 0,
  loading: false,
  error: false,
};

const dieticianSlice = createSlice({
  name: "dietician",
  initialState,
  reducers: {
    // Other synchronous reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(enrollDieticianAction.pending, (state) => {})
      .addCase(
        enrollDieticianAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          //Need to create a state for dietician to show data from redux in dashboard
        },
      )
      .addCase(enrollDieticianAction.rejected, (state) => {})
      .addCase(
        getDieticianByAreaOfExperties.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.totalPage = action.payload.totalPage;
          state.dieticians = [...action.payload.data.dietician];
          state.error = false;
          // console.log(action.payload);
          return state;
        },
      )
      .addCase(getDieticianByAreaOfExperties.pending, (state) => {
        state.error = false;
        state.loading = true;
        return state;
      })
      .addCase(getDieticianByAreaOfExperties.rejected, (state) => {
        state.loading = false;
        state.error = true;
        return state;
      })
      .addCase(
        getDieticiansByIdAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.dieticians = [action.payload.data.dietician];
          return state;
        },
      )
      .addCase(getDieticiansByIdAction.pending, (state) => {
        state.error = false;
        state.loading = true;
        return state;
      })
      .addCase(getDieticiansByIdAction.rejected, (state) => {
        state.error = true;
        state.loading = false;
        return state;
      })
      .addCase(
        getAllDieticiansAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = false;
          state.dieticians = [...action.payload.dieticians];
          return state;
        },
      )
      .addCase(getAllDieticiansAction.pending, (state) => {
        state.error = false;
        state.loading = true;
        return state;
      })
      .addCase(getAllDieticiansAction.rejected, (state) => {
        state.error = true;
        state.loading = false;
        return state;
      })
      .addCase(
        getEnrolledDieticiansAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.dieticians = [...action.payload.dieticians];
          return state;
        },
      )
      .addCase(getEnrolledDieticiansAction.pending, (state) => {
        state.error = false;
        state.loading = true;
        return state;
      })
      .addCase(getEnrolledDieticiansAction.rejected, (state) => {
        state.error = true;
        state.loading = false;
        return state;
      })
      .addCase(getLoggidInDieticianAction.pending, (state) => {
        state.error = false;
        state.loading = true;
        return state;
      })
      .addCase(
        getLoggidInDieticianAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.error = false;
          state.loading = false;
          state.dieticians = [action.payload];
          return state;
        },
      );
  },
});

export default dieticianSlice.reducer;
