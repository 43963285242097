import React, { useEffect, useState } from "react";
import { Dietician } from "../../model/DieticianDataModel";
import { createPortal } from "react-dom";
import { approveDietician } from "../../utils/ApproveDieticianService";
import { useSelector } from "react-redux";
import { loggedInUserSelector } from "../../redux/selectors/userSelector";
import axios from "axios";
import { rejectDietician } from "../../utils/RejectDieticianService";

interface DieticianDetailsModalProps {
  isOpen: boolean;
  DieticianData: Dietician | undefined | null;
  setIsOpen: Function;
}

function DieticianDetails({
  isOpen,
  DieticianData,
  setIsOpen,
}: DieticianDetailsModalProps) {
  const user = useSelector(loggedInUserSelector);
  const [role, setRole] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen || !DieticianData) return;

    const fetchRole = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const config = {
            method: "get",
            url: "https://backend.betternow.co.in/users/auth-status",
            headers: {
              Authorization: token,
            },
          };
          const response = await axios.request(config);
          console.log("Role:", response.data.data.role); // Assuming response contains role
          setRole(response.data.data.role);
        }
      } catch (error) {
        console.log("Error fetching role:", error);
      }
    };

    fetchRole();
  }, [isOpen, DieticianData]);

  const verifyDietician = async (id: string) => {
    setLoading(true);
    const userId = DieticianData?.user?._id; // Using optional chaining to safely access _id
    if (role !== "dietitian-admin") {
      console.log("After role", role);
      alert("Only admin has access to this functionality");
      setLoading(false);
      return;
    }

    if (!userId) {
      alert("User ID is missing.");
      setLoading(false);
      return;
    }

    try {
      await approveDietician(userId, role);
      window.location.reload();
    } catch (error: any) {
      alert("Error approving the dietician.");
    }
    setLoading(false);
  };
  const rejectDieticianService = async (id: string) => {
    setLoading(true);
    const userId = DieticianData?.user?._id; // Using optional chaining to safely access _id
    if (role !== "dietitian-admin") {
      console.log("After role", role);
      alert("Only admin has access to this functionality");
      setLoading(false);
      return;
    }

    if (!userId) {
      alert("User ID is missing.");
      setLoading(false);
      return;
    }

    try {
      await rejectDietician(userId, role);
      window.location.reload();
    } catch (error: any) {
      alert("Error approving the dietician.");
    }
    setLoading(false);
  };

  if (!isOpen) return null;
  if (!DieticianData) return null;

  return createPortal(
    <div
      onClick={() => setIsOpen(false)}
      className="z-10 fixed top-0 left-0 w-full h-full flex justify-center items-center overflow-auto bg-modal-container"
    >
      <div className="w-fit relative h-fit bg-white px-6 rounded-xl py-10 justify-center items-center">
        <div className="flex gap-6 items-center">
          <img
            className="w-60 h-60 rounded-full object-cover"
            src={DieticianData.professionalImage}
            alt="Image"
          />
          <div className="flex flex-col justify-between">
            <div className="flex gap-2">
              <p className="font-semibold">Name:</p>
              <p>{DieticianData.user?.name || "Undefined"}</p>
            </div>
            <div className="flex gap-2">
              <p className="font-semibold">Availability:</p>
              {/* Your availability logic here */}
            </div>
            <div className="flex gap-2">
              <p className="font-semibold">Expertise:</p>
              <p>
                {DieticianData.areaOfExpertise.map((a: string, ind: number) => (
                  <span key={ind}>
                    {a}
                    {ind < DieticianData.areaOfExpertise.length - 1 && ", "}
                  </span>
                ))}
              </p>
            </div>
            <div className="flex gap-2">
              <p className="font-semibold">Experience:</p>
              <p>{DieticianData.yearOfExperience + ""}</p>
            </div>
            <div className="flex gap-2">
              <p className="font-semibold">Interested in:</p>
              <p>{DieticianData.interestInAnyOtherActivityForUs}</p>
            </div>
            <div className="flex gap-2">
              <p className="font-semibold">Consultations:</p>
              <p>{DieticianData.noOfConsultations + ""}</p>
            </div>
            <div className="flex gap-2">
              <p className="font-semibold">Private Practise:</p>
              <p>{DieticianData.privatePractice}</p>
            </div>
            <div className="flex gap-2">
              <p className="font-semibold">Time able to Contribute:</p>
              <p>{DieticianData.timeAbleToContributePerWeek}/week</p>
            </div>
            <div className="flex gap-2">
              <p className="font-semibold">Spoken Languages:</p>
              <p>
                {DieticianData.spokenLanguages.map((l: string, ind: number) => (
                  <span key={ind}>
                    {l}
                    {ind < DieticianData.spokenLanguages.length - 1 && ", "}
                  </span>
                ))}
              </p>
            </div>
            <div className="flex gap-2">
              <p className="font-semibold">Fee:</p>
              <p>
                {DieticianData.currency === "INR" && "₹"}{" "}
                {DieticianData.monthlyConsultationFee + ""}
              </p>
            </div>
            <div className="flex gap-2">
              <p className="font-semibold">Diet plan price:</p>
              <p>
                {DieticianData.currency === "INR" && "₹"}{" "}
                {DieticianData.oneTimeConsultationFee + ""}/month
              </p>
            </div>
            <div className="flex gap-2">
              <p className="font-semibold">Verification status:</p>
              <p>{DieticianData.isDieticianVerified ? "Yes" : "No"}</p>
            </div>
            {!DieticianData.isDieticianVerified && (
              <button
                onClick={() => verifyDietician(DieticianData._id)}
                className="bg-green-800 hover:bg-green-900 text-white mt-3 py-2 rounded-lg"
              >
                {loading ? "Loading..." : "Verify Dietician"}
              </button>
            )}
            {!DieticianData.isDieticianVerified && (
              <button
                onClick={() => rejectDieticianService(DieticianData._id)}
                className="bg-red-800 hover:bg-red-900 text-white mt-3 py-2 rounded-lg"
              >
                {loading ? "Loading..." : "Reject Dietician"}
              </button>
            )}
          </div>
        </div>
        <button
          className="absolute top-2 text-black text-xl cursor-pointer hover:text-red-500 right-5"
          onClick={() => setIsOpen(false)}
        >
          x
        </button>
      </div>
    </div>,
    document.getElementById("dietician-detail-modal")!,
  );
}

export default DieticianDetails;
