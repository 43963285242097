import axiosInstance from "../../config/axiosConfig";
import { LoginCredentials } from "../../model/CredentialPayloadModel";
import { LoginResponse } from "../../model/LoginResponseModel";
import { SignupPayload } from "../../model/SignupFormPayload";
import { SignupResponse } from "../../model/SignupResponse";

export const login = async (
  credentials: LoginCredentials,
): Promise<LoginResponse> => {
  try {
    const response = await axiosInstance.post<LoginResponse>(
      "/auth/login",
      credentials,
      {
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const loginWithGoogle = async (): Promise<LoginResponse> => {
  try {
    const response = await axiosInstance.get<LoginResponse>(
      "auth/login/success",
      { withCredentials: true },
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const logout = async (): Promise<LoginResponse> => {
  try {
    const response = await axiosInstance.get<LoginResponse>("auth/logout", {
      withCredentials: true,
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const signup = async (
  credentials: SignupPayload,
): Promise<SignupResponse> => {
  try {
    const response = await axiosInstance.post<SignupResponse>("", credentials);
    console.log(response);
    return response.data;
  } catch (error: any) {
    console.log(error);

    throw new Error(error.response.data.message);
  }
};

export const loggedInUser = async (): Promise<any> => {
  try {
    const res = await axiosInstance.get("/auth/getUserSessionData", {
      withCredentials: true,
    });
    console.log(res);

    return res.data;
  } catch (error: any) {
    console.log(error);
    throw new Error(error.response.data.message);
  }
};

//service to fetch enrolled patients
export const enrolledPatients = async (
  page: number,
  limit: number,
): Promise<any> => {
  try {
    const response = await axiosInstance.get<any>(
      `/dietician/admin/getEnrolledPatients?page=${page}&limit=${limit}`,
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};
