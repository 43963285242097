import React from "react";

const Loader: React.FC = () => {
  return (
    <div className="flex justify-center items-center h-24">
      <div className="w-8 h-8 border-4 border-dotted border-t-4 border-[#355E3B] rounded-full animate-spin"></div>
    </div>
  );
};

export default Loader;
