const getFormatedDate = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const min = date.getMinutes();
  const sec = date.getSeconds();

  let formatedDate = "" + year + "-";
  if (month < 10) formatedDate += "0" + month + "-";
  else formatedDate += month + "-";
  if (day < 10) formatedDate += "0" + day;
  else formatedDate += day;

  let formatedTime = "";
  if (hour < 10) formatedTime += "0" + hour;
  else formatedTime += hour;
  if (min < 10) formatedTime += "0" + min;
  else formatedTime += min;
  if (sec < 10) formatedTime += "0" + sec;
  else formatedTime += sec;

  return `${formatedDate} ${formatedTime}`;
};
export default getFormatedDate;
