import axiosInstance from "../config/axiosConfig";

export const updateMetaData = async (data: any) => {
  try {
    const update = await axiosInstance.put("/dietician/edit", data, {
      withCredentials: true,
    });
    console.log(update.data);

    return update.data;
  } catch (error: any) {
    console.log(error);
    throw new Error("An error occured updating you data.");
  }
};
