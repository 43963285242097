import React, { useContext, useEffect, useState } from "react";
import Section1 from "./Section1";
import Navbar from "../Navbar/Navbar";
import Section2 from "./Section2";
import Footer from "../Footer";
import Section3 from "./Section3";
import Section4 from "./Section4";
import toast, { Toaster } from "react-hot-toast";
import { getDieticiansByIdAction } from "../../redux/actions/dieticianActions";
import { useAppDispatch } from "../../redux/hooks";
import { User } from "../../model/UserModel";
import { Dietician } from "../../model/DieticianDataModel";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { DietitianContext } from "../../utils/DietitianProtectedRoute";
import styled from "styled-components";

const DoctorProfile = () => {
  const [d, setDietician] = useState<Dietician>();
  const [doctor, setDoctor] = useState({});
  const { userId } = useParams();

  useEffect(() => {
    if (userId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://backend.betternow.co.in/dietitian/${userId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log("My doctor", response.data);
          setDoctor(response.data.data);
          // setDietician(response.data.data);  // Assuming the data contains dietician info
          // setUser(response.data.data.user); // Assuming the data contains user info
        })
        .catch((error) => {
          console.log("Axios error", error);
        });
    }
  }, [userId]);
  // console.log("DOOOO", doctor);
  // console.log(user);
  return (
    <div className="bg-[#FFFFF5]">
      <Toaster position="top-center" reverseOrder={false} />
      <div>
        <Navbar
          backgroundColor="#FFFFF5"
          textColor="#315145"
          logo="/betterNowLogodark.png"
        />
      </div>
      <StyledDiv className="bg-[#FFFFF5] mx-8 md:pt-[139px] overflow-x-hidden flex flex-col gap-[1.5rem] lg:gap-y-6 md:ml-12 md:mr-12 lg:ml-20 lg:mr-20">
        <div>
          <Section1 expertise={doctor} />
        </div>

        <div >
          <Section2  intro={d?.intro} expertise={doctor} />
        </div>
        <div>
          <Section3 id={userId} />
        </div>
      </StyledDiv>
      <StyledDiv className="bg-[#FFFFF5] mx-8 md:mx-20">
        <Section4 expertise={doctor} />
      </StyledDiv>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default DoctorProfile;

const StyledDiv = styled.div`
  @media (max-width: 390px) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
`