import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";


interface StatCardProps {
  title: string,
  value: number,
  isRevenue: boolean,
  oneTime?: number,
  premium?: number
}

const StatCard: React.FC<StatCardProps> = ({
  title,
  value,
  isRevenue,
  oneTime,
  premium
}) => {
  return (

    // <div
    //   className={` flex rounded-lg md:p-4  xs:h-[62.22px] justify-between ${
    //     activeTab === tabName ? "bg-[#FCCE72]" : "bg-[#EFEFEA]"
    //   } text-[#315145] hover:${activeTab === tabName ? "" : "bg-[#dbdbcf]"}`}
    //   onClick={() => onClick(tabName)}
    // > 

    //   <div className="w-12 h-12">
    //     <img
    //     className="w-full"
    //       src="/DashboardDoctorIcon.png"
    //       alt=""
    //     />

    //   </div>
    //   <div>
    //   <div className="text-[17px] leading-none lg:text-[35px] font-bold mt-[0]">
    //     {value}
    //   </div>
    //   <div className="overflow-hidden text-[10px] md:text-[20px] lg:text-[20px] font-medium whitespace-pre-line">
    //       {title}
    //     </div>
    //   {/* Render additional data if it's the 4th card */}
    //   {tabName === "total-revenue" && (
    //     <div className="flex flex-row justify-between ">
    //       <div className="text-[8px] md:text-[12px] font-semibold text-[#315145]">
    //         <p className="semibold">One Time</p>
    //         <p className="text-[16px] md:text-[20px]"><b>{OneTime}</b></p>
    //       </div>
    //       <div className="text-[8px] md:text-[12px] font-semibold text-[#315145]">
    //       <p className="semibold">Premium</p>
    //       <p className="text-[16px] md:text-[20px]"><b>{Premium}</b></p>
    //       </div>
    //     </div>
    //   )}
    //   </div>
    //     {/* Mobile view */}
    //   <div className="hidden xs:block">
    //   <div className="flex flex-row justify-start xs:justify-between  ">

    //     <div className="pl-4 pt-2 overflow-hidden text-[15px] md:text-[20px] lg:text-[20px] font-medium whitespace-pre-line">
    //       {title}
    //     </div>
    //     <div className="hidden xs:block">

    //     <div className="hidden xs:block">
    //     {tabName !== "total-revenue" && (
    //       <div className="overflow-hidden text-[28px]  pt-2 pr-4 ">
    //       <b>{value}</b>
    //     </div>
    //     )}
    //     {tabName === "total-revenue" && (
    //     <div>
    //     <div className="overflow-hidden text-[22px]">
    //     <b>{value}</b>
    //     <div className="flex flex-row justify-between ">
    //     <div className="text-[6px] font-semibold text-[#315145] ">
    //       <p className="semibold pr-5">One Time</p>
    //       <p className="text-[8px]"><b>{OneTime}</b></p>
    //     </div>
    //     <div className="text-[6px] font-semibold text-[#315145]">
    //     <p className="semibold pr-5">Premium</p>
    //     <p className="text-[8px]"><b>{Premium}</b></p>
    //     </div>
    //   </div>
    //     </div>

    //     </div>
    //   )}

    //     </div>
    //     {/* {tabName === "total-revenue" && (

    //   )} */}
    //     </div>
    //   </div>
    //  </div>
    // </div>

    <div className={`rounded-[10px] ${isRevenue ? "bg-[#FCCE72]" : "bg-[#EFEFEA]"} grid p-4 text-[#315145] xs:flex`} style={{ gridTemplateColumns: '0.8fr 2fr' }}>
      <div className="w-full xs:hidden">
        <img className="w-full min-w-[29px] max-w-[42px] xs:hidden"
          src="/dashboardPatient.svg"
          alt="" ></img>
      </div>
      <div className={`flex ${isRevenue? "flex-row":"flex-col"} justify-between xs:flex-row xs:w-full`}>
        <div className="text-[18px] font-medium xs:max-w-[82px]">
          {title}
        </div>
        <div>
        <div className="font-bold text-[1.5em] xs:text-end">
          {value}
        </div>
        {isRevenue &&
            <div className="hidden xs:flex justify-between gap-[10px]">
              <div>
                <div className="text-[0.75em] font-medium flex flex-col justify-between">One Time</div>
                <div className="text-[1.2em] font-semibold">{oneTime}</div>
              </div>
              <div>
                <div className="text-[0.75em] font-medium flex flex-col justify-between">Premium</div>
                <div className="text-[1.2em] font-semibold">{premium}</div>
              </div>
            </div>
        }
        </div>
        
      </div>
      <div></div>
      {isRevenue &&
          <><div className="xs:hidden flex justify-between">
            <div>
              <div className="text-[0.75em] font-medium flex flex-col justify-between">One Time</div>
              <div className="text-[1.2em] font-semibold">{oneTime}</div>
            </div>
            <div>
              <div className="text-[0.75em] font-medium flex flex-col justify-between">Premium</div>
              <div className="text-[1.2em] font-semibold">{premium}</div>
            </div>
          </div>
          </>
        }
    </div>

  )
}


export default StatCard;
