import axiosInstance from "../../config/axiosConfig";
import { EnrollmentData } from "../../model/Enrollment";

export const enrollDietician = async (
  formData: EnrollmentData,
): Promise<any> => {
  try {
    const response = await axiosInstance.post<any>(
      "/dietician/registerMetadata",
      formData,
      {
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error: any) {
    console.log("Error", error);

    throw new Error(error.response.data.message);
  }
};

//service to filter dieticians by area of expertise and page no.
export const filterDieticianByCategory = async (data: any): Promise<any> => {
  try {
    const response = await axiosInstance.get<any>(
      `/dietician/getDieticianByAreaOfExperties`,
      { params: data },
    );
    return response.data;
  } catch (error: any) {
    console.log("Error", error);

    throw new Error(error.response.data.message);
  }
};

//service to get all dieticians by limiting the response by page no. and result per page
export const getDieticiansById = async (Id: string): Promise<any> => {
  try {
    const dietician = await axiosInstance.get<any>(`/dietician/` + Id);

    return dietician.data;
  } catch (error: any) {
    console.log("Error", error);

    throw new Error(error.response.data.message);
  }
};

//service to fetch all dieticians
export const getAllDieticians = async (
  page: number,
  limit: number,
): Promise<any> => {
  try {
    const dieticians = await axiosInstance.get<any>(
      `/dietician/admin/getAllDieticians?page=${page}&limit=${limit}`,
    );
    return dieticians.data;
  } catch (error: any) {
    console.log("Error", error);

    throw new Error(error.response.data.message);
  }
};

//service to fetch enrolled dieticians
export const getEnrolledDieticians = async (
  page: number,
  limit: number,
): Promise<any> => {
  try {
    const dieticians = await axiosInstance.get<any>(
      `/dietician/admin/getEnrolledDieticians?page=${page}&limit=${limit}`,
    );
    return dieticians.data;
  } catch (error: any) {
    console.log("Error", error);

    throw new Error(error.response.data.message);
  }
};

export const getLoggidInDietician = async (): Promise<any> => {
  try {
    const res = await axiosInstance.get("/auth/getUserSessionData", {
      withCredentials: true,
    });
    console.log(res);

    return res.data.userData.dieticianData || {};
  } catch (error: any) {
    console.log("Error", error);

    throw new Error(error.response.data.message);
  }
};
