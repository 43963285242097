import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Dietician } from "../model/DieticianDataModel";

export const DietitianContext = createContext<any>(null);

const DietitianProtectedRoute = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true); // To handle loading state
  const [dietitianDetails, setDetails] = useState<any>(null);
  
  const value = {
    dietitianDetails
  };
  const token = localStorage.getItem("token");

  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = localStorage.getItem("token");

      try {     
        if (!token) {
          setLoading(false);
          return;
        }
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: "https://backend.betternow.co.in/users/auth-status",
          headers: {
            Authorization: token,
          },
        };
        const response = await axios.request(config);
        if (
          response.data.data.role === "dietitian" &&
          response.data.data.isEmailVerified &&
          response.data.data.dietitianEnrollmentStatus === 2
        ) {
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // Set loading to false once the request is done
      }
    };
    checkAuthStatus();
  },[]); // Empty dependency array to run only once on mount

  if (loading) return <div>Loading...</div>; // Loading state

  return isLoggedIn ? (
    <DietitianContext.Provider value={value}>
      <Outlet />
    </DietitianContext.Provider>
  ) : (
    <Navigate to="/" />
  );
};

export default DietitianProtectedRoute;
