import React, { useState, useEffect } from "react";
import InputField from "./InputField";
import { formFields } from "../../constants/HealthFormFields";
import DefaultLayout from "../Layout/DefaultLayout";
import { jwtDecode, JwtPayload } from "jwt-decode";

const PatientMedicalHistory: React.FC = () => {
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [patients, setPatients] = useState<{ label: string; value: string }[]>([]);
  const token = localStorage.getItem('token');
  const decoded: any = token? jwtDecode<JwtPayload>(token): null;
  const dietiticianId = decoded?.id;

  useEffect(() => {
    // Fetch enrolled patients from API
    const fetchPatients = async () => {
      try {
        const response = await fetch(
          `https://backend.betternow.co.in/dietitian/enrolledPatients/${dietiticianId}`,
          {
            method: "GET",
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const formattedPatients = data.data.map((patient: any) => ({
            label: `${patient.name} ${patient.phone}`,
            value: patient._id,
          }));
          setPatients(formattedPatients);
        } else {
          console.error("Failed to fetch patients");
        }
      } catch (error) {
        console.error("Error fetching patients:", error);
      }
    };

    fetchPatients();
  }, []);

  const handleChange = (name: string, value: string | string[]) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" })); // Clear error on change
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let formIsValid = true;
    const newErrors: { [key: string]: string } = {};

    formFields.forEach((field) => {
      if (field.required && !formValues[field.name]) {
        formIsValid = false;
        newErrors[field.name] = `${field.label} is required`;
      }
    });

    setErrors(newErrors);

    if (formIsValid) {
      console.log("Form submitted successfully", formValues);
    } else {
      console.log("Form has errors", newErrors);
    }
  };

  return (
    <DefaultLayout>
      <div className="font-poppins bg-[#FFFFF5] min-h-screen flex items-center justify-center">
        <form
          className="bg-white shadow-md rounded-lg p-6 w-full"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
            Patient Medical History
          </h2>
          {formFields.map((field, index) => (
            <InputField
              key={index}
              label={field.label}
              inputType={field.inputType}
              name={field.name}
              value={formValues[field.name] || ""}
              onChange={handleChange}
              options={field.inputType === "searchable-dropdown" ? patients : field.options}
              required={field.required}
            />
          ))}
          <div className="flex justify-center">
            <button
              type="submit"
              className="mt-4 px-6 py-3 bg-teal-600 hover:bg-teal-700 text-white rounded-full focus:outline-none text-sm lg:text-base font-semibold shadow-lg transition duration-300"
            >
              Submit
            </button>
          </div>
          {Object.values(errors).map(
            (error, index) =>
              error && (
                <div key={index} className="text-red-500 text-sm mt-2 text-center">
                  {error}
                </div>
              )
          )}
        </form>
      </div>
    </DefaultLayout>
  );
};

export default PatientMedicalHistory;
