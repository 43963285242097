import React from "react";
import DieticianDetailsCard from "../Cards/DietiecianDetailsCard";

interface ExpertiseProp {
  expertise: any;
}

const DieticianCarousel: React.FC<ExpertiseProp> = ({ expertise }) => {
  return (
    <div>
      <DieticianDetailsCard
        key={expertise._id}
        imageUrl={expertise.professionalImage}
        title={expertise.user.name}
        description={expertise.intro}
        language={expertise.spokenLanguages}
        id={expertise._id}
        yearOfExperience={expertise.yearOfExperience}
        clients={expertise.noOfConsultations}
        oneTimeFee={expertise.oneTimeConsultationFee}
        monthlyFee={expertise.monthlyConsultationFee}
        userId={expertise.user._id}
        areaOfExpertise={expertise.areaOfExpertise}
        gender={expertise.user.gender}
      />
    </div>
  );
};

export default DieticianCarousel;
