import React from "react";
import styled from "styled-components";
import { CSVLink } from "react-csv";

interface PatientTableProps {
  data: {
    dashboardData: {
      patientData: Array<{
        patientId: string;
        patientName: string;
        planType: string;
        startDate: string;
        endDate: string;
        planDuration: string;
      }>;
    };
  };
}

const PatientTable = ({ data }: PatientTableProps) => {
  const patientData = data?.dashboardData?.patientData || [];

  // Define CSV headers
  const headers = [
    { label: "Patient ID", key: "patientId" },
    { label: "Patient Name", key: "patientName" },
    { label: "Plan Type", key: "planType" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" },
    { label: "Plan Duration", key: "planDuration" },
  ];

  // Prepare CSV data
  const csvData = patientData.map((item) => ({
    patientId: item.patientId,
    patientName: item.patientName,
    planType: item.planType,
    startDate: item.startDate,
    endDate: item.endDate,
    planDuration: item.planDuration,
  }));

  return (
    <div>
      <StyledDiv className="shadow-lg bg-[#EFEFEA] rounded-lg overflow-x-auto my-7">
        <div className="min-w-max">
          <div className="bg-[#EFEFEA] grid grid-cols-6 items-center gap-4 p-4 text-center rounded-lg shadow-sm border border-gray-200 xs:gap-[12px] xs:p-[12px]">
            <p className="text-[13px] font-poppins text-[#315145] md:text-[15px]">
              <b>Patient ID</b>
            </p>
            <p className="text-[13px] font-poppins text-[#315145] md:text-[15px]">
              <b>Pt. Name</b>
            </p>
            <p className="text-[13px] font-poppins text-[#315145] md:text-[15px]">
              <b>Plan Type</b>
            </p>
            <p className="text-[13px] font-poppins text-[#315145] md:text-[15px]">
              <b>Start Date</b>
            </p>
            <p className="text-[13px] font-poppins text-[#315145] md:text-[15px]">
              <b>End Date</b>
            </p>
            <p className="text-[13px] font-poppins text-[#315145] md:text-[15px]">
              <b>Plan Duration</b>
            </p>
          </div>

          <div className="overflow-x-auto max-h-[40vh]">
            <div className="min-w-max">
              {patientData.map((item, index) => (
                <div
                  className="bg-[#EFEFEA] grid grid-cols-6 gap-4 p-4 text-center border-b border-gray-200 xs:p-[12px] xs:gap-[12px]"
                  key={index}
                >
                  <p className="text-[11px] md:text-[13px] font-poppins text-[#355E3B]">
                    {item.patientId}
                  </p>
                  <p className="text-[11px] md:text-[13px] font-poppins text-[#355E3B]">
                    {item.patientName}
                  </p>
                  <p className="text-[11px] md:text-[13px] font-poppins text-[#355E3B]">
                    {item.planType}
                  </p>
                  <p className="text-[11px] md:text-[13px] font-poppins text-[#355E3B]">
                    {item.startDate}
                  </p>
                  <p className="text-[11px] md:text-[13px] font-poppins text-[#355E3B]">
                    {item.endDate}
                  </p>
                  <p className="text-[11px] md:text-[13px] font-poppins text-[#355E3B]">
                    {item.planDuration}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </StyledDiv>
      <div className="flex justify-center mt-4">
        {/* CSV Download Link */}
        <CSVLink
          data={csvData}
          headers={headers}
          filename="patient_data.csv"
          className="bg-[#315145] text-white font-semibold py-2 px-4 rounded-full"
        >
          Download
        </CSVLink>
      </div>
    </div>
  );
};

export default PatientTable;

const StyledDiv = styled.div`
  /* Ensure scrollbars are always visible */
  .scrollbar-visible {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1); /* For Firefox */
  }

  .scrollbar-visible::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }

  .scrollbar-visible::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); /* Background of the scrollbar track */
  }

  .scrollbar-visible::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3); /* Color of the scrollbar handle */
  }

  .scrollbar-visible::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5); /* Color of the scrollbar handle on hover */
  }
`;
