import { createPortal } from "react-dom";
import SignupForm from "../Forms/SignupForm";
import { useEffect } from "react";
interface signupModalProps {
  isOpenModal: boolean;
  setIsOpenModal: Function;
  userRole: string;
}
function SignupModal({
  isOpenModal,
  setIsOpenModal,
  userRole,
}: signupModalProps) {
  useEffect(() => {
    if (document) {
      document.body.style.overflowY = isOpenModal ? "hidden" : "scroll";
    }
  }, [isOpenModal]);
  if (!isOpenModal) return null;

  return createPortal(
    <div className="z-50 fixed top-0 left-0 w-full h-full flex justify-center items-center overflow-auto">
      <div
        onClick={() => setIsOpenModal(false)}
        className="fixed w-full h-full bg-modal-container z-1"
      ></div>
      <div className="bg-[#FFFFF5] w-10/12 z-20 md:9/12 w-[500px] mx-5 flex p-1 rounded-lg relative">
        <div className="w-full flex justify-center">
          <SignupForm setIsOpen={setIsOpenModal} userRole={userRole} />
        </div>
        <button
          onClick={() => setIsOpenModal(false)}
          className="z-50 absolute top-2 text-black text-xl cursor-pointer hover:text-red-500 right-5"
        >
          x
        </button>
      </div>
    </div>,
    document.getElementById("signup-portal")!,
  );
}

export default SignupModal;
