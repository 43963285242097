import React from "react";

const Section4 = ({ expertise }: any) => {

  console.log(expertise);


  return (
    <>
      <div className="bg w-full font-poppins">
        <div className="flex flex-col  lg:justify-between lg:pr-[10%] w-[100%]">
          <div>
            <div className="pb-2 text-[16px] md:text-[24px] font-semibold mb-2 mt-10 ">
              About {expertise?.user?.gender === "Female" ? "Ms" : "Mr"}{" "}
              {expertise?.user?.name}
            </div>
            <div className="pb-2 flex gap-x-2 items-center">
              <div>
                <img className="w-[20px]" src="/chatgreen.png" alt="" />
              </div>
              <div className="pl-2 text-[12px] lg:text-[20px]">
                Fluent in{" "}
                {expertise?.spokenLanguages?.map((l: any, ind: any) => {
                  let str = l;
                  if (ind < expertise?.spokenLanguages?.length - 1) str += ", ";
                  return str;
                })}
              </div>
            </div>
            <div className="flex gap-x-2 items-center">
              <div>
                <img className="w-[20px]" src="/Profilegreen.png" alt="" />
              </div>
              <div className="pl-2 text-[12px] lg:text-[20px]">
                I work with Individuals, Couples & Families
              </div>
            </div>

            <div>
              <div className="pt-4 text-[16px] md:text-[24px] font-semibold mb-2 mt-6">
                Fees
              </div>
              <ul className=" list-disc ml-5 text-[12px] lg:text-[20px]">
                <li className="pb-2 pl-2">
                  One Time Consultation Fee: ₹{" "}
                  {expertise?.oneTimeConsultationFee
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                </li>
                <li className="pl-2">
                  Premium Plan: ₹{" "}
                  {expertise?.monthlyConsultationFee
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <div className="pt-4 text-[16px] md:text-[24px] font-semibold mb-2 mt-6 lg:mt-8">
                Education
              </div>
              <ul className="list-disc ml-5 text-[12px] lg:text-[20px]">
                {expertise?.qualifications?.map((item: any, index: any) => (
                  <li className="pb-2 pl-2" key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <div>
              <div className="pt-4 text-[16px] md:text-[24px] font-semibold mb-2 mt-6 lg:mt-2">
                Expertise
              </div>
              <ul className="list-disc ml-5 text-[12px] lg:text-[20px]">
                {expertise?.areaOfExpertise?.map((e: any, index: any) => (
                  <li className="pl-2" key={index}>{e}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="h-[1px] w-[100%] bg-[#8D8D8D] my-10"></div>
        
      </div>
    </>
  );
};

export default Section4;
