import React, { useEffect, useState } from "react";
import {
  areaOfExpertiseOptions,
} from "../../constants/DropDownOptions";
import DropDown from "../DropDown";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import ImageUpload from "./ImageUpload";
import { getDietitian } from "../../utils/DietitianApis";

interface FormInputProps {
  label: string;
  type: string;
  placeholder: string;
  required: boolean;
  value: any;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  maxlength?: number;
  error?: string;
}

interface FormTextAreaProps {
  label: string;
  placeholder: string;
  required: boolean;
  value: any;
  name: string;
  onChange: any;
  error?: string;
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  type,
  placeholder,
  required,
  onChange,
  value,
  name,
  maxlength,
  error,
}) => {
  return (
    <div className="flex flex-col">
      <label className="block  text-[20px] mb-2  text-[#315145]">{label}</label>
      <input
        type={type}
        className="bg-[#EFEFEA]  text-lg rounded-lg  block w-full h-[57px] p-2.5"
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
        name={name}
        maxLength={maxlength}
      />
      {error && <span className=" text-red-500 text-sm">{error}</span>}
    </div>
  );
};

const FormTextArea: React.FC<FormTextAreaProps> = ({
  label,
  placeholder,
  required,
  onChange,
  value,
  name,
  error,
}) => {
  return (
    <div className="flex flex-col">
      <label className="block  text-[20px] mb-2  text-[#315145]">{label}</label>
      <textarea
        rows={3}
        className=" bg-[#EFEFEA]  text-lg rounded-lg  block w-full p-2.5"
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
        name={name}
      />
      {error && <span className=" text-red-500 text-sm">{error}</span>}
    </div>
  );
};
interface EnrollmentFormProps {
  data: any;
    onClose: () => void;
    onUpdate: (updatedUserData: any) => void;
  }
const EnrollmentForm: React.FC<EnrollmentFormProps> = ({
  data,
  onClose,
  onUpdate,
}) => {
  const [formData, setFormData] = useState<any>(data);
  const [initialData, setInitialData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  // useEffect(() => {

  //   const fetchData = async () => {
  //     try {
  //       const dietitianData = await getDietitian();
  //       if (dietitianData) {
  //         setFormData(dietitianData);
  //         setInitialData(dietitianData); // Store initial state
  //       }
  //     } catch (error) {
  //       console.error('Error fetching dietitian data:', error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDropdownValueChange = (fieldName: any, selectedvalue: any) => {
    setFormData({ ...formData, [fieldName]: selectedvalue });
  };

  const handleImageUpload = (imageFile: File) => {
    console.log("Uploaded file:", imageFile);
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      professionalImage: imageFile,
    }));
    setErrors({ professionalImage: "" });
    console.log("f", formData)
  };

  const getChangedData = () => {
    const changedData: any = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== initialData[key]) {
        changedData[key] = formData[key];
      }
    });
    return changedData;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    // Get only the changed fields
    const changedData = getChangedData();
    console.log("ch", changedData)
  
    if (Object.keys(changedData).length === 0) {
      toast.error('No changes detected.');
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      const config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `http://backend.betternow.co.in/dietitian/updateMetadata`,
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
        // Send only the changed data
        data: changedData ,
      };
  
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          toast.success('Profile updated successfully!');
          setInitialData({ ...initialData, ...changedData }); // Update initial data after successful submission
          onUpdate(response.data)
          onClose();
        })
        .catch((error) => {
          console.log("Axios error", error);
          toast.error('An error occurred while updating the profile.');
        });
    } catch (error) {
      console.log('Error:', error);
      toast.error('An error occurred, please try again later.');
    }
  };
  
  return (
    <section>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-6 mx-auto max-w-screen-lg">
        <form className="space-y-8" onSubmit={handleSubmit}>
          {/* Drop-down for Area of Expertise */}
          <div className="flex flex-col">
            {/* <label className="text-[20px] mb-2 text-[#315145]">Area of Expertise</label>
            <DropDown
              options={areaOfExpertiseOptions}
              isClearable={true}
              isMultiple={true}
              isSearchable={true}
              onSelectChange={handleDropdownValueChange}
              fieldName="areaOfExpertise"
              error={errors.areaOfExpertise}
            /> */}
          </div>
          {/* Form Input for Fees */}
          {/* <FormInput
            label="Name"
            type="string"            
            onChange={handleInputChange}
            placeholder=""
            required={false}
            name="user.name"
            value={formData.user.name || ''}
            error={errors.name}
          /> */}
           <FormInput
            label="One-time consultation fee?"
            type="number"            
            onChange={handleInputChange}
            placeholder=""
            required={false}
            name="oneTimeConsultationFee"
            value={formData.oneTimeConsultationFee || ''}
            error={errors.oneTimeConsultationFee}
          />
          <FormInput
          placeholder=""
          required={false}
            label="Monthly consultation fee?"
            type="number"
            onChange={handleInputChange}
            name="monthlyConsultationFee"
            value={formData.monthlyConsultationFee || ''}
            error={errors.monthlyConsultationFee}
          />
          {/* Form TextArea for Introduction */}
          <FormTextArea
          placeholder=""
          required={false}
            label="Introduction"
            onChange={handleInputChange}
            name="intro"
            value={formData.intro || ''}
            error={errors.intro}
          />
          {/* Image Upload */}
          <div>
            <h1 className="text-[20px] mb-2 text-[#315145]">Upload Professional Image</h1>
            <ImageUpload onImageUpload={handleImageUpload} maxSizeInMB={2} error={errors.ImageUpload} />
            {errors.professionalImage && (
              <span className="text-red-500 text-sm">{errors.professionalImage}</span>
            )}
          </div>
          {/* Submit Button */}
          <div className="flex flex-col md:flex-row md:justify-center items-center">
            <button
              type="submit"
              className="bg-[#315145] text-white py-2 px-4 rounded-[2rem] text-[20px] font-medium w-full md:w-[40%]"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default EnrollmentForm;