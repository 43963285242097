let DieticianHomeText = [
  {
    mainText: "Living with Diabetes?",
    spanText: "You are not ALONE!",
    image: "/Diabetes-011.png",
    description: `Diabetes, also known as "sugar," is a major health concern. India has the world's second-highest number of diabetes patients. To manage diabetes, plan regular, balanced meals and eat healthily to control your blood glucose levels. It takes effort, but it's worth it for a chance to live your healthiest life with diabetes.`,
  },
  {
    mainText: "Feeling overwhelmed by",
    spanText: "PCOD/PCOS?",
    image: "/pcos-01.png",
    description: `PCOS can have a widespread impact on your health, affecting sleep, fertility, metabolism, weight, heart health, skin, and mood. You don’t have to face these challenges alone. Our team of dedicated dietitians will collaborate with you to develop a personalized nutrition plan aimed at restoring balance, boosting energy levels, and achieving hormone equilibrium. It’s time to take charge of your PCOS journey and embark on a path to sustainable wellness together!`,
  },
  {
    mainText: "Achieve Lasting Results with ",
    spanText: "Smart Eating Habits",
    image: "/wei-01.png",
    description: `Did you know? Over 1.9 billion adults worldwide are overweight, and 650 million struggle with obesity. Taking control of your weight is key to a healthier you. Discover the transformative power of smart eating. By making small adjustments to your diet, you can supercharge your weight loss journey. Ditch mindless snacking and embrace mindful eating. Learn to manage portion sizes, savor every bite, and listen to your body's natural hunger cues.`,
  },
  {
    mainText: "Unlock the Power of Your Gut ",
    spanText: "Improve Digestion, Mood and Overall Health",
    image: "/gut-01.png",
    description: `Embrace the power of your gut microbiome, often referred to as the "second brain," in influencing digestion, mood, and overall well-being. If you struggle with digestive issues like acidity, constipation, or IBS, enhancing your gut health could be the cornerstone of a happier, healthier you. By integrating gut-friendly choices into your diet, you can cultivate a harmonious gut microbiome and open the door to a life filled with boundless vitality and joy.`,
  },
  {
    mainText: "Right choice of Diet",
    spanText: "A healthy heart",
    image: "/chol-01.png",
    description: `Your heart is the relentless engine that drives your life, and it deserves only the finest fuel! Yet, there's a pesky obstacle called "Cholesterol" that can dull its brilliance. Embracing recommended dietary changes, savoring delicious choices, and prioritizing lifestyle management will empower you to keep your heart robust and thriving with the unwavering support of your trusted healthcare professionals.`,
  },
];

export default DieticianHomeText;
