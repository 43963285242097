import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PendingDietitianProtectedRoute = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true); // To handle loading state

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setLoading(false);
          return;
        }
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: "https://backend.betternow.co.in/users/auth-status",
          headers: {
            Authorization: token,
          },
        };
        const response = await axios.request(config);
        if (
          response.data.data.role === "dietitian" &&
          response.data.data.isEmailVerified &&
          (response.data.data.dietitianEnrollmentStatus === 1 ||
            response.data.data.dietitianEnrollmentStatus === 3)
        ) {
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // Set loading to false once the request is done
      }
    };

    checkAuthStatus();
  }, []); // Empty dependency array to run only once on mount

  if (loading) return <div>Loading...</div>; // Loading state

  return isLoggedIn ? <Outlet /> : <Navigate to="/" />;
};

export default PendingDietitianProtectedRoute;
