import React, { useEffect } from "react";
import ComparisonTable from "../ComparisonTable";
// import AOS from "aos";

const SectionThree: React.FC = () => {
  // useEffect(() => {
  //   AOS.init({
  //     duration: 1200
  //   });
  // }, []);
  return (
    <div className="bg-[#315145] pb-8 pt-10 lg:pt-0 flex flex-col justify-center items-center px-10 lg:h-[auto] xs:px-[1rem]">
      <div className="text-[3.4vw] lg:text-[46px] text-[#FFFFF5] font-medium lg:mt-16 text-center lg:text-left">
        Betternow vs traditional in-office Work
      </div>
      <ComparisonTable />
    </div>
  );
};

export default SectionThree;
