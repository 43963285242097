import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  getEnrolledPatientsAction,
  loggedInUserAction,
  loginUserAction,
  loginUserWithGoogleAction,
  logoutUserAction,
} from "../actions/userActions";
import { User } from "../../model/UserModel";
import { LoginResponse } from "../../model/LoginResponseModel";
import { Dietician } from "../../model/DieticianDataModel";
import { stat } from "fs";

interface UserState {
  user: User | null;
  dietitian?: Dietician | null;
  loading: boolean;
  error: boolean;
  users: User[] | [];
}

const initialState: UserState = {
  user: null,
  dietitian: null,
  loading: false,
  error: false,
  users: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Other synchronous reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAction.pending, (state) => {
        // Set loading state while logging in
        state.loading = true;
        state.error = false;
      })
      .addCase(
        loginUserAction.fulfilled,
        (state, action: PayloadAction<LoginResponse>) => {
          // Set user data and clear loading state upon successful login
          console.log(action.payload);

          state.user = action.payload.user;
          state.dietitian = action.payload.dieticianData;
          state.loading = false;
        },
      )
      .addCase(loginUserAction.rejected, (state) => {
        // Set error message and clear loading state if login fails
        state.error = true;
        state.loading = false;
      })
      .addCase(loginUserWithGoogleAction.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(
        loginUserWithGoogleAction.fulfilled,
        (state, action: PayloadAction<LoginResponse>) => {
          state.user = action.payload.user;
          state.loading = false;
        },
      )
      .addCase(loginUserWithGoogleAction.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(logoutUserAction.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(logoutUserAction.fulfilled, (state) => {
        state.user = null;
        state.loading = false;
      })
      .addCase(logoutUserAction.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(
        getEnrolledPatientsAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.error = false;
          state.loading = false;
          state.users = [...action.payload.patients];
          return state;
        },
      )
      .addCase(getEnrolledPatientsAction.rejected, (state) => {
        state.error = true;
        state.loading = false;
        return state;
      })
      .addCase(getEnrolledPatientsAction.pending, (state) => {
        state.error = false;
        state.loading = true;
        return state;
      })
      .addCase(loggedInUserAction.pending, (state) => {
        state.error = false;
        state.loading = true;
        return state;
      })
      .addCase(
        loggedInUserAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.error = false;
          state.loading = false;
          console.log(action.payload);

          state.user = action.payload.user;
          state.dietitian = action.payload.dieticianData;
          console.log("state", state.user);

          return state;
        },
      )
      .addCase(loggedInUserAction.rejected, (state) => {
        state.error = true;
        state.loading = false;
        return state;
      });
  },
});

export default userSlice.reducer;

// Export synchronous actions if needed
