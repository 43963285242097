export const featureList = [
  {
    name: "Work from anywhere",
    offeredByBetterNow: true,
    offerByInOffice: false,
  },
  {
    name: "Set your own hours",
    offeredByBetterNow: true,
    offerByInOffice: false,
  },
  {
    name: "See clients in-person",
    offeredByBetterNow: false,
    offerByInOffice: true,
  },
  {
    name: "Clinical autonomy",
    offeredByBetterNow: true,
    offerByInOffice: true,
  },
  {
    name: "No overhead or marketing costs",
    offeredByBetterNow: true,
    offerByInOffice: false,
  },
  {
    name: "No insurance paneling or paperwork",
    offeredByBetterNow: true,
    offerByInOffice: false,
  },
  {
    name: "Get paid biweekly",
    offeredByBetterNow: true,
    offerByInOffice: false,
  },
  {
    name: "Free Betternow membership",
    offeredByBetterNow: true,
    offerByInOffice: false,
  },
  {
    name: "Access to the world's largest peer network",
    offeredByBetterNow: true,
    offerByInOffice: false,
  },
];

export const featureListHome = [
  {
    name: "Personalised Diet Plan",
    offeredByBetterNow: true,
    offerByInOffice: false,
    info: "Provides diet plan in every 3 weeks",
  },
  {
    name: "Video Consultation",
    offeredByBetterNow: true,
    offerByInOffice: true,
    info: "Enjoy a 45-60 minute one-on-one video consultation tailored just for you",
  },
  {
    name: "Follow Ups",
    offeredByBetterNow: true,
    offerByInOffice: false,
    info: "Get personalized follow-ups on weekly basis to address your daily queries and keep you on track",
  },
  {
    name: "Chat Support",
    offeredByBetterNow: true,
    offerByInOffice: true,
    info: "Receive real-time solutions to your queries through our instant chat support",
  },
  {
    name: "Life Style Recommendation",
    offeredByBetterNow: true,
    offerByInOffice: false,
    info: "Receive daily lifestyle tips and recommendations to help you stay healthy and vibrant",
  },
  {
    name: "Confidential Health Records",
    offeredByBetterNow: true,
    offerByInOffice: true,
    info: "Rest assured, your health goals and medical history are safe and secure with us",
  },
  // {
  //   name: "Special/ Surprise Sessions",
  //   offeredByBetterNow: true,
  //   offerByInOffice: false,
  //   info: "Enjoy a free, personalized special session designed to meet your unique needs",
  // },
  {
    name: "Overall Journey update",
    offeredByBetterNow: true,
    offerByInOffice: false,
    info: "Stay informed with monthly updates on your health progress and achievements",
  },
];
