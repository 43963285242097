export const USER_EXIST =
  "User already exists with this email or contact. Please use different email ID or contact.";
export const PHONE_INVALID = "Phone number must be 10 digits";
export const PINCODE_INVALID = "Pincode must be 6 digits";
export const CONFIRM_PASSWORD = "Password and confirm password do not match";

export const FULL_NAME_REQUIRED = "Full Name is required";
export const PHONE_NUMBER_REQUIRED = "Phone Number is required";
export const OTP_REQUIRED = "OTP is required";
export const DATE_OF_BIRTH_REQUIRED = "Date of Birth is required";
export const EMAIL_REQUIRED = "Email is required";
export const PASSWORD_REQUIRED = "Password is required";
export const GENDER_REQUIRED = "Gender is required";
export const ADDRESS1_REQUIRED = "Address line 1 is required";
export const ADDRESS2_REQUIRED = "Address line 2 is required";
export const STATE_REQUIRED = "State is required";
export const CITY_REQUIRED = "City is required";
export const PINCODE_REQUIRED = "Pin code is required";
export const LANGUAGES_REQUIRED = "Please select languages";
export const YEAR_OF_EXPERIENCE = "Please select year of experience";
export const AREA_OF_EXPERTISE = "Please select area of expertise";
export const GENERAL_ERROR = "Please fill this input";
export const AVAILABILITY = "Please select availability";
export const ONE_TIME_FEE = "Please select one tomeconsultation fee";
export const MONTHLY_FEE = "Please selct monthly consultation fee";
export const QUALIFICATIONS = "Please add qualifications";
export const SOCIAL_MEDIA_PROFILES = "Please give social media profile links";
export const PROFESSIONAL_IMAGE = "Please upload your professional image";
export const MINIMUM_LENGTH_PHONE = "Please enter a valid phone number";
export const USERNAME_REQUIRED = "Username is required";
