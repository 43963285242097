// import React, { useState } from "react";
// import "./styles.css";

// interface ChipInputProps {
//   onInputChange: (value: string[]) => void;
//   error?: string;
// }

// const ChipInput: React.FC<ChipInputProps> = ({ onInputChange, error }) => {
//   const [selected, setSelected] = useState<string[]>([]); // Initialize as an empty array
//   const [inputValue, setInputValue] = useState<string>("");

//   const handleAddQualification = (val: string) => {
//     const trimmedValue = val.trim();
//     if (trimmedValue !== "" && !selected.includes(trimmedValue)) {
//       const updatedValues = [...selected, trimmedValue];
//       setSelected(updatedValues);
//       onInputChange(updatedValues); // Pass updated value
//     }
//     setInputValue(""); // Clear input after adding
//   };

//   const handleDeleteQualification = (val: string) => {
//     const updatedValues = selected.filter((item) => item !== val);
//     setSelected(updatedValues);
//     onInputChange(updatedValues);
//   };

//   return (
//     <div>
//       <div className="flex items-center rounded-lg bg-[#EFEFEA] p-2.5">
//         <input
//           type="text"
//           value={inputValue}
//           onChange={(e) => setInputValue(e.target.value)}
//           placeholder="Add a qualification"
//           className="flex-grow bg-[#EFEFEA] text-lg rounded-lg p-2.5"
//           onKeyDown={(e) => {
//             if (e.key === "Enter") {
//               e.preventDefault();
//               handleAddQualification(inputValue);
//             }
//           }}
//           onBlur={() => handleAddQualification(inputValue)} // Add onBlur event handler
//         />
//         <button
//           onClick={() => handleAddQualification(inputValue)}
//           className="ml-2 px-2 py-1 bg-[#315145] text-white rounded-full"
//           title="Add Qualification"
//         >
//           +
//         </button>
//       </div>
//       <div className="flex flex-wrap mt-2">
//         {selected.map((item) => (
//           <div
//             key={item}
//             className="flex items-center bg-[#EFEFEA] text-lg rounded-lg p-2 m-1"
//           >
//             {item}
//             <button
//               onClick={() => handleDeleteQualification(item)}
//               className="ml-2 text-red-500"
//               title="Remove Qualification"
//             >
//               &times;
//             </button>
//           </div>
//         ))}
//       </div>
//       {error && <span className="absolute text-red-500 text-sm">{error}</span>}
//     </div>
//   );
// };

// export default ChipInput;
import React, { useState } from "react";
import "./styles.css";
import { toBeRequired } from "@testing-library/jest-dom/matchers";

interface ChipInputProps {
  onInputChange: (value: string[]) => void;
  error?: string;
  placeholder?: string;
  required?: boolean;
}

const ChipInput: React.FC<ChipInputProps> = ({ onInputChange, error, placeholder, required }) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  const handleAddQualification = (val: string) => {
    const trimmedValue = val.trim();
    if (trimmedValue !== "" && !selected.includes(trimmedValue)) {
      const updatedValues = [...selected, trimmedValue];
      setSelected(updatedValues);
      onInputChange(updatedValues); // Pass updated value
    }
    setInputValue(""); // Clear input after adding
  };

  const handleDeleteQualification = (val: string) => {
    const updatedValues = selected.filter((item) => item !== val);
    setSelected(updatedValues);
    onInputChange(updatedValues);
  };

  return (
    <div>
      <div className="flex items-center rounded-lg bg-[#EFEFEA] p-2.5 h-[57px]">
        <input
          type="text"
          required={required}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder}
          className="flex-grow bg-[#EFEFEA] text-lg rounded-lg p-2.5 w-full"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleAddQualification(inputValue);
            }
          }}
          onBlur={() => handleAddQualification(inputValue)}
        />
        <button
          type="button" // Add this line to prevent form submission
          onClick={() => handleAddQualification(inputValue)}
          className="ml-2 px-2 py-1 bg-[#315145] text-white rounded-full"
          title="Add Qualification"
        >
          +
        </button>
      </div>
      <div className="flex flex-wrap mt-2">
        {selected.map((item) => (
          <div
            key={item}
            className="flex items-center bg-[#EFEFEA] text-lg rounded-lg p-2 m-1"
          >
            {item}
            <button
              type="button"
              onClick={() => handleDeleteQualification(item)}
              className="ml-2 text-red-500"
              title="Remove Qualification"
            >
              &times;
            </button>
          </div>
        ))}
      </div>
      {error && <span className="absolute text-red-500 text-sm">{error}</span>}
    </div>
  );
};

export default ChipInput;
