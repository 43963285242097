import React from "react";
import { HomeOptionsList } from "../../constants/HomeOptionsList";
import { Link } from "react-router-dom";
import "./Cards.css"
const SectionThree: React.FC = () => {
  let images = [
    "/gutheallth.png",
    "/diabetes.png",
    "/pcos.png",
    "/cholestrol.png",
    "/weightmanage.png",
  ];

  return (
    <div className="bg-[#FFFFF5] flex flex-col h-fit">
      <div className="flex flex-col py-10 xs:pb-0 px-5 justify-center">
        <div className="text-[1.50rem] md:text-[3.125rem] font-semibold text-center">
          You deserve to be happy
        </div>
        <div className="text-[0.918rem] md:text-[1.75rem] mt-2 text-center w-[100vw] md:w-fit flex self-center justify-center">
          What is troubling you?
        </div>
      </div>
      {/* <div className="bg-[#FFFFF5] flex md:flex-row flex-wrap gap-y-10 md:gap-y-20 md:w-[70rem] lg:w-[85rem] lg:gap-x-[7rem] self-center justify-center mb-20">
        {HomeOptionsList.map((i, index) => (
          <Link
            to={i.redirectUrl}
            className="w-[9rem] h-[10rem] md:w-[18.75rem] md:h-[21.875rem] bg-[#FFFFF5] m-5"
            key={index}
          >
            <img
              src={images[index]}
              className="bg-[#FFFFF5] rounded-[1.25rem] w-[9rem] h-[10.5rem] md:w-[18.75rem] md:h-[21.875rem]  cursor-pointer  hover:shadow-2xl transition-all duration-500 ease-in-out"
              alt={i.img_heading}
            />
            <div className="w-[100%] text-center mt-5 md:mt-10 mb-10 text-[14px] font-normal md:text-[18px] md:font-medium">
              {i.img_heading}
            </div>
          </Link>
        ))}
      </div> */}
      {/* <div className="grid-container">
        {HomeOptionsList.map((i, index) => (
          <div className="item">
            <Link
              to={i.redirectUrl}
              className="w-[9rem] h-[10rem] md:w-[18.75rem] md:h-[21.875rem] bg-[#FFFFF5] m-5"
            >
              <img
                src={images[index]}
                className="bg-[#FFFFF5] rounded-[1.25rem] w-[9rem] h-[10.5rem] md:w-[18.75rem] md:h-[21.875rem]  cursor-pointer  hover:shadow-2xl transition-all duration-500 ease-in-out"
                alt={i.img_heading}
              />
              <div className="w-[100%] text-center mt-5 md:mt-10 mb-10 text-[14px] font-normal md:text-[18px] md:font-medium">
                {i.img_heading}
              </div>
            </Link>
          </div>
        ))}
      </div> */}
      <div className="grid-container">
        {HomeOptionsList.map((i, index) => (
          <div className="item">
            <Link
              to={i.redirectUrl}
              // className="w-[9rem] h-[10rem] md:w-[18.75rem] md:h-[21.875rem] bg-[#FFFFF5] m-5"
            >
              <img
                src={images[index]}
                // className="bg-[#FFFFF5] rounded-[1.25rem] w-[9rem] h-[10.5rem] md:w-[18.75rem] md:h-[21.875rem]  cursor-pointer  hover:shadow-2xl transition-all duration-500 ease-in-out"
                className="bg-[#FFFFF5] rounded-[1.25rem]"
                alt={i.img_heading}
              />
              <div className="w-[100%] text-center mt-5 sm:mb-0 md:mt-10 mb-10 text-[18px] md:text-[24px] font-medium xs:mb-0 xs:text-[16px]">
                {i.img_heading}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionThree;
