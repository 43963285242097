import React from "react";
import ComparisonTableHome from "../ComparisonTableHome";

const SectionThreeNew: React.FC = () => {
  return (
  <div className="bg-[#315145] pb-8 pt-10 lg:pt-0 flex flex-col justify-center items-center px-[1.5em]">
      <div className="text-[23px] lg:text-[46px] text-[#FFFFF5] font-medium lg:mt-16 text-center lg:text-left">
        Our Offerings
      </div>
      <ComparisonTableHome />
    </div>
  );
};

export default SectionThreeNew;
