import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import DefaultLayout from '../Layout/DefaultLayout';
import { io } from "socket.io-client";
import { jwtDecode, JwtPayload } from 'jwt-decode';

const Chat = () => {
  const [conversations, setConversations] = useState<any[]>([]);
  const [selectedConversationId, setSelectedConversationId] = useState<string | null>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const socketRef = useRef<any>(null);
  const sortedMessages = [...messages].sort((a, b) => a.sent_at - b.sent_at);
  const token = localStorage.getItem('token');
  const decoded: any = token? jwtDecode<JwtPayload>(token): null;
  const dietiticianId = decoded?.id;
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   // Initialize socket.io connection
  //   socketRef.current = io("ws://backend.betternow.co.in");

  //   socketRef.current.on('connect', () => {
  //     console.log('WebSocket connected:', socketRef.current.id);
  //   });

  //   socketRef.current.on('newMessage', (newMassage: any) => {
  //     // Using functional update to ensure latest state is used
  //     console.log(newMassage)
  //     console.log("message before", messages.length)
  //     setMessages((prevMessages) => [...prevMessages, newMassage]);
  //     console.log("message beafter", messages.length)
  //   });

  //   return () => {
  //     // Clean up WebSocket connection
  //     socketRef.current.disconnect();
  //     console.log('WebSocket disconnected');
  //   };
  // }, []);
  useEffect(() => {
    // Initialize socket.io connection
    socketRef.current = io("ws://backend.betternow.co.in");

    socketRef.current.on('connect', () => {
      console.log('WebSocket connected:', socketRef.current.id);
    });

    socketRef.current.on('newMessage', (newMessage: any) => {
      // Use functional update to ensure latest state is used
      console.log("newwww",newMessage)
      if(newMessage.sender === 'USER') {
        setMessages((prevMessages) => [...prevMessages, {...newMessage, messageContent: JSON.parse(newMessage.messageContent)}]);

      }
    });

    return () => {
      // Clean up WebSocket connection
      socketRef.current.disconnect();
      console.log('WebSocket disconnected');
    };
  }, []); 

  useEffect(() => {
    if (selectedConversationId && socketRef.current) {
      socketRef.current.emit('joinConversation', selectedConversationId);
    }
  }, [selectedConversationId]);

  useEffect(() => {
    const fetchConversations = async () => {
      console.log(messages.length, "in fetch convo")
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(
         `https://backend.betternow.co.in/webhook/getConversations/${dietiticianId}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        setConversations(response.data);
      } catch (err) {
        console.error('Failed to fetch conversations:', err);
        setError('Failed to fetch conversations');
      }
    };
    fetchConversations();
  }, []);

  useEffect(() => {
    if (selectedConversationId) {
      const fetchMessages = async () => {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get(
            `https://backend.betternow.co.in/webhook/getMessagesByConversation/${selectedConversationId}`,
            {
              headers: {
                Authorization: `${token}`,
              },
            }
          );
          setMessages(response.data);
        } catch (err) {
          console.error('Failed to fetch messages:', err);
          setError('Failed to fetch messages');
        }
      };

      fetchMessages();
    }
  }, [selectedConversationId]);
  
  useEffect(() => {
    if (messagesEndRef.current) {
      // Scroll to the bottom
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [selectedConversationId, sortedMessages]);

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const token = localStorage.getItem('token');
      const conversation = conversations.find((conv) => conv._id === selectedConversationId);
      const newMsg = {
        _id: `msg${Date.now()}`, 
        conversation: selectedConversationId,
        sender: 'System',
        messageContent: {
          text: newMessage,
        },
        sentAt: Date.now(),
      };

      setLoading(true);
      setError(null);

      try {
        await axios.post(
          'https://apis.aisensy.com/project-apis/v1/project/66c314dc6f26ab0b762a0491/messages',
          {
            to: (conversation?.user.phone).toString(),
            type: 'text',
            recipient_type: 'individual',
            text: {
              body:newMessage.toString(),
            },
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-AiSensy-Project-API-Pwd': '1a8643f4c89d945c98160',
            },
          }
        );

        // Optimistically update UI with new message
        setMessages((prevMessages) => [...prevMessages, newMsg]);
        setNewMessage('');
      } catch (err) {
        console.error('Failed to send message:', err);
        setError('Failed to send message');
      } finally {
        setLoading(false);
      }
    }
  };
  
  const handleSelectConversation = (conversationId: string) => {
    setSelectedConversationId(conversationId);
  };

  // Handle the back button click
  const handleBackToConversations = () => {
    setSelectedConversationId(null);
  };
  return (
    <DefaultLayout>
      {/* <div className='flex h-[95vh] md:h-[100vh]'>
        <div className={`w-[100%] overflow-y-scroll ${selectedConversationId?'hidden':''} md:block md:w-[30%] h-full bg-[#fff]`}>
        <div className="flex p-4 bg-[#F0F2F5]">
              <input
                type="search"
                className="w-full p-2 h-[40px] text-[12px] lg:text-[20px] text-[#315145] rounded-lg  focus:outline-none"
                placeholder="Search"
              >
              
              </input>
            </div>
          {conversations.map((conv) => (
                <><div
              key={conv._id}
              onClick={() => handleSelectConversation(conv._id)}
              className={`p-4 border-b border-gray-200 cursor-pointer ${selectedConversationId === conv._id ? 'bg-gray-100' : ''}`}
            >
              <p className="font-semibold text-gray-800">{conv.user.name}</p>
              <p className="text-gray-600 text-sm">
                {messages
                  .filter((msg) => msg.conversation === conv._id)
                  .slice(-1)[0]?.messageContent.text || ''}
              </p>
            </div>
            </>
              ))}
        </div>
        <div className={`w-[100%] ${selectedConversationId?'':'hidden'} md:block  md:w-[70%]  h-full bg-[#EFEAE2] overflow-y-scroll`}>
          {selectedConversationId &&
           <>
            <div className="flex bg-[#F0F2F5] items-center border-b h-[8vh] border-gray-300 p-4 sticky top-0">
              <button onClick={handleBackToConversations} className="mr-2 text-gray-800">
                ←
              </button>
              <img src="/profileIcon.svg" className="w-10 h-10 rounded-full bg-gray-300" alt="profile" />
              <p className="ml-3 font-semibold text-gray-800">
                {conversations.find((conv) => conv._id === selectedConversationId)?.user.name}
              </p>
            </div>
            <div className='p-4 h-[84vh] overflow-y-scroll'>
        {sortedMessages.map((msg) => (
                    <div
                      key={msg._id}
                      className={`my-2 p-3 rounded-lg max-w-xs ${msg.sender === 'USER' ? 'bg-white text-gray-800 self-start' : 'bg-green-500 text-white self-end ml-auto'}`}
                    >
                      {msg.messageContent.text}
                    </div>
          ))}
           </div>
           <div className="border-t border-gray-300 bg-white h-[8vh] flex items-center p-4 sticky bottom-0">
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Type a message"
                  className="flex-1 p-2 border rounded-lg border-gray-300 focus:outline-none" />
                <button
                  onClick={handleSendMessage}
                  className="ml-4 p-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                  disabled={loading}
                >
                  {loading ? 'Sending...' : 'Send'}
                </button>
            </div></>
          }
        </div>
      </div> */}
  <div className='flex h-[95vh] md:h-[100vh]'>
      <div className={`w-[100%] overflow-y-scroll ${selectedConversationId ? 'hidden' : ''} md:block md:w-[30%] h-full bg-[#fff]`}>
        <div className="flex p-4 bg-[#F0F2F5]">
          <input
            type="search"
            className="w-full p-2 h-[40px] text-[12px] lg:text-[20px] text-[#315145] rounded-lg focus:outline-none"
            placeholder="Search"
          />
        </div>
        {conversations.length>0 ?
        <div>
          {conversations.map((conv) => (
            <div
              key={conv._id}
              onClick={() => handleSelectConversation(conv._id)}
              className={`p-4 border-b border-gray-200 cursor-pointer ${selectedConversationId === conv._id ? 'bg-gray-100' : ''}`}
            >
              <p className="font-semibold text-gray-800">{conv.user.name}</p>
              <p className="text-gray-600 text-sm">
                {messages.filter((msg) => msg.conversation === conv._id).slice(-1)[0]?.messageContent.text || ''}
              </p>
            </div>
          ))}
        </div>
          :
          <div className='text-center p-[16px]'>
            No patients yet.
          </div>
        }
      </div>
      <div className={`w-[100%] ${selectedConversationId ? '' : 'hidden'} md:block md:w-[70%] h-full bg-[#EFEAE2] overflow-y-scroll`}>
        {selectedConversationId && (
          <>
            <div className="flex bg-[#F0F2F5] items-center border-b h-[8vh] border-gray-300 p-4 sticky top-0">
              <button onClick={handleBackToConversations} className="mr-2 text-gray-800">
                ←
              </button>
              <img src="/profileIcon.svg" className="w-10 h-10 rounded-full bg-gray-300" alt="profile" />
              <p className="ml-3 font-semibold text-gray-800">
                {conversations.find((conv) => conv._id === selectedConversationId)?.user.name}
              </p>
            </div>
            <div ref={messagesEndRef} className='p-4 h-[84vh] overflow-y-scroll'>
              {sortedMessages.map((msg) => (
                <div
                  key={msg._id}
                  className={`my-2 p-3 rounded-lg max-w-xs ${msg.sender === 'USER' ? 'bg-white text-gray-800 self-start' : 'bg-green-500 text-white self-end ml-auto'}`}
                >
                  {msg.messageContent.text}
                </div>
              ))}
            </div>
            <div className="border-t border-gray-300 bg-white h-[8vh] flex items-center p-4 sticky bottom-0">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message"
                className="flex-1 p-2 border rounded-lg border-gray-300 focus:outline-none"
              />
              <button
                onClick={handleSendMessage}
                className="ml-4 p-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Send'}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
    </DefaultLayout>
  );
};

export default Chat;