import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "../Navbar_dropdown";

interface NavItemProps {
  title: string;
  textColor: string;
  backgroundColor: string;
  redirectUrl: string;
  dropdownItems?: { text: string; path: string }[];
  // isLoggedIn: boolean;
}

const NavItem: React.FC<NavItemProps> = ({
  title,
  textColor,
  redirectUrl,
  dropdownItems,
  backgroundColor,
  // isLoggedIn,
}) => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <li className="relative">
      <Link
        to={redirectUrl}
        className={`block py-2 px-1 hover:border-b-2 border-yellow-500 lg: text-[18px] md:text-[17px] text-2xl ${pathname === redirectUrl || (title === "Healthcare Challenge" && pathname.includes("/dietician/")) ? "border-b-2" : "border-none"}`}
        style={{ color: textColor }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {title}
      </Link>
      {dropdownItems && isOpen && (
        <div
          ref={dropdownRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Dropdown
            items={dropdownItems}
            textColor={textColor}
            backgroundColor={backgroundColor}
          />
        </div>
      )}
    </li>
  );
};

export default NavItem;
