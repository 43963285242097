import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";

const ContactUs: React.FC = () => {
  return (
    <div className="font-poppins">
      <Navbar
        backgroundColor="#FFFFF5"
        textColor="#325243"
        logo="/betterNowLogodark.png"
      />
      <SectionOne />
      <SectionTwo />
      <Footer />
    </div>
  );
};

export default ContactUs;
