import { Option } from "react-tailwindcss-select/dist/components/type";

export const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Others", label: "Others" },
];
export const booleanOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
export const languageOptions = [
  { value: "Hindi", label: "Hindi" },
  { value: "English", label: "English" },
  { value: "Punjabi", label: "Punjabi" },
  { value: "Bengali", label: "Bengali" },
  { value: "Telugu", label: "Telugu" },
  { value: "Marathi", label: "Marathi" },
  { value: "Tamil", label: "Tamil" },
  { value: "Urdu", label: "Urdu" },
  { value: "Gujarati", label: "Gujarati" },
  { value: "Kannada", label: "Kannada" },
];

export const areaOfExpertiseOptions = [
  { value: "PCOS", label: "PCOS" },
  { value: "Diabetes", label: "Diabetes" },
  { value: "Cholestrol", label: "Cholestrol" },
  { value: "Gut Health", label: "Gut Health" },
  { value: "Weight Management", label: "Weight Management" },
];

export const stateOptions = [
  { value: "Andhra Pradesh", label: "Andhra Pradesh" },
  { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
  { value: "Assam", label: "Assam" },
  { value: "Bihar", label: "Bihar" },
  { value: "Chhattisgarh", label: "Chhattisgarh" },
  { value: "Goa", label: "Goa" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Haryana", label: "Haryana" },
  { value: "Himachal Pradesh", label: "Himachal Pradesh" },
  { value: "Jharkhand", label: "Jharkhand" },
  { value: "Karnataka", label: "Karnataka" },
  { value: "Kerala", label: "Kerala" },
  { value: "Madhya Pradesh", label: "Madhya Pradesh" },
  { value: "Maharashtra", label: "Maharashtra" },
  { value: "Manipur", label: "Manipur" },
  { value: "Meghalaya", label: "Meghalaya" },
  { value: "Mizoram", label: "Mizoram" },
  { value: "Nagaland", label: "Nagaland" },
  { value: "Odisha", label: "Odisha" },
  { value: "Punjab", label: "Punjab" },
  { value: "Rajasthan", label: "Rajasthan" },
  { value: "Sikkim", label: "Sikkim" },
  { value: "Tamil Nadu", label: "Tamil Nadu" },
  { value: "Telangana", label: "Telangana" },
  { value: "Tripura", label: "Tripura" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  { value: "Uttarakhand", label: "Uttarakhand" },
  { value: "West Bengal", label: "West Bengal" },
  { value: "Delhi", label: "Delhi" },
  { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
  { value: "Ladakh", label: "Ladakh" },
];

export const cityOptions: { [key: string]: Option[] } = {
  "Andaman & Nicobar": [
    {
      value: "Alipur",
      label: "Alipur"
    },
    {
      value: "Andaman Island",
      label: "Andaman Island"
    },
    {
      value: "Anderson Island",
      label: "Anderson Island"
    },
    {
      value: "Arainj-Laka-Punga",
      label: "Arainj-Laka-Punga"
    },
    {
      value: "Austinabad",
      label: "Austinabad"
    },
    {
      value: "Bamboo Flat",
      label: "Bamboo Flat"
    },
    {
      value: "Barren Island",
      label: "Barren Island"
    },
    {
      value: "Beadonabad",
      label: "Beadonabad"
    },
    {
      value: "Betapur",
      label: "Betapur"
    },
    {
      value: "Bindraban",
      label: "Bindraban"
    },
    {
      value: "Bonington",
      label: "Bonington"
    },
    {
      value: "Brookesabad",
      label: "Brookesabad"
    },
    {
      value: "Cadell Point",
      label: "Cadell Point"
    },
    {
      value: "Calicut",
      label: "Calicut"
    },
    {
      value: "Chetamale",
      label: "Chetamale"
    },
    {
      value: "Cinque Islands",
      label: "Cinque Islands"
    },
    {
      value: "Defence Island",
      label: "Defence Island"
    },
    {
      value: "Digilpur",
      label: "Digilpur"
    },
    {
      value: "Dolyganj",
      label: "Dolyganj"
    },
    {
      value: "Flat Island",
      label: "Flat Island"
    },
    {
      value: "Geinyale",
      label: "Geinyale"
    },
    {
      value: "Great Coco Island",
      label: "Great Coco Island"
    },
    {
      value: "Haddo",
      label: "Haddo"
    },
    {
      value: "Havelock Island",
      label: "Havelock Island"
    },
    {
      value: "Henry Lawrence Island",
      label: "Henry Lawrence Island"
    },
    {
      value: "Herbertabad",
      label: "Herbertabad"
    },
    {
      value: "Hobdaypur",
      label: "Hobdaypur"
    },
    {
      value: "Ilichar",
      label: "Ilichar"
    },
    {
      value: "Ingoie",
      label: "Ingoie"
    },
    {
      value: "Inteview Island",
      label: "Inteview Island"
    },
    {
      value: "Jangli Ghat",
      label: "Jangli Ghat"
    },
    {
      value: "Jhon Lawrence Island",
      label: "Jhon Lawrence Island"
    },
    {
      value: "Karen",
      label: "Karen"
    },
    {
      value: "Kartara",
      label: "Kartara"
    },
    {
      value: "KYD Islannd",
      label: "KYD Islannd"
    },
    {
      value: "Landfall Island",
      label: "Landfall Island"
    },
    {
      value: "Little Andmand",
      label: "Little Andmand"
    },
    {
      value: "Little Coco Island",
      label: "Little Coco Island"
    },
    {
      value: "Long Island",
      label: "Long Island"
    },
    {
      value: "Maimyo",
      label: "Maimyo"
    },
    {
      value: "Malappuram",
      label: "Malappuram"
    },
    {
      value: "Manglutan",
      label: "Manglutan"
    },
    {
      value: "Manpur",
      label: "Manpur"
    },
    {
      value: "Mitha Khari",
      label: "Mitha Khari"
    },
    {
      value: "Neill Island",
      label: "Neill Island"
    },
    {
      value: "Nicobar Island",
      label: "Nicobar Island"
    },
    {
      value: "North Brother Island",
      label: "North Brother Island"
    },
    {
      value: "North Passage Island",
      label: "North Passage Island"
    },
    {
      value: "North Sentinel Island",
      label: "North Sentinel Island"
    },
    {
      value: "Nothen Reef Island",
      label: "Nothen Reef Island"
    },
    {
      value: "Outram Island",
      label: "Outram Island"
    },
    {
      value: "Pahlagaon",
      label: "Pahlagaon"
    },
    {
      value: "Palalankwe",
      label: "Palalankwe"
    },
    {
      value: "Passage Island",
      label: "Passage Island"
    },
    {
      value: "Phaiapong",
      label: "Phaiapong"
    },
    {
      value: "Phoenix Island",
      label: "Phoenix Island"
    },
    {
      value: "Port Blair",
      label: "Port Blair"
    },
    {
      value: "Preparis Island",
      label: "Preparis Island"
    },
    {
      value: "Protheroepur",
      label: "Protheroepur"
    },
    {
      value: "Rangachang",
      label: "Rangachang"
    },
    {
      value: "Rongat",
      label: "Rongat"
    },
    {
      value: "Rutland Island",
      label: "Rutland Island"
    },
    {
      value: "Sabari",
      label: "Sabari"
    },
    {
      value: "Saddle Peak",
      label: "Saddle Peak"
    },
    {
      value: "Shadipur",
      label: "Shadipur"
    },
    {
      value: "Smith Island",
      label: "Smith Island"
    },
    {
      value: "Sound Island",
      label: "Sound Island"
    },
    {
      value: "South Sentinel Island",
      label: "South Sentinel Island"
    },
    {
      value: "Spike Island",
      label: "Spike Island"
    },
    {
      value: "Tarmugli Island",
      label: "Tarmugli Island"
    },
    {
      value: "Taylerabad",
      label: "Taylerabad"
    },
    {
      value: "Titaije",
      label: "Titaije"
    },
    {
      value: "Toibalawe",
      label: "Toibalawe"
    },
    {
      value: "Tusonabad",
      label: "Tusonabad"
    },
    {
      value: "West Island",
      label: "West Island"
    },
    {
      value: "Wimberleyganj",
      label: "Wimberleyganj"
    },
    {
      value: "Yadita",
      label: "Yadita"
    }
  ],
  "Andhra Pradesh": [
    {
      value: "Achampet",
      label: "Achampet"
    },
    {
      value: "Adilabad",
      label: "Adilabad"
    },
    {
      value: "Adoni",
      label: "Adoni"
    },
    {
      value: "Alampur",
      label: "Alampur"
    },
    {
      value: "Allagadda",
      label: "Allagadda"
    },
    {
      value: "Alur",
      label: "Alur"
    },
    {
      value: "Amalapuram",
      label: "Amalapuram"
    },
    {
      value: "Amangallu",
      label: "Amangallu"
    },
    {
      value: "Anakapalle",
      label: "Anakapalle"
    },
    {
      value: "Anantapur",
      label: "Anantapur"
    },
    {
      value: "Andole",
      label: "Andole"
    },
    {
      value: "Araku",
      label: "Araku"
    },
    {
      value: "Armoor",
      label: "Armoor"
    },
    {
      value: "Asifabad",
      label: "Asifabad"
    },
    {
      value: "Aswaraopet",
      label: "Aswaraopet"
    },
    {
      value: "Atmakur",
      label: "Atmakur"
    },
    {
      value: "B. Kothakota",
      label: "B. Kothakota"
    },
    {
      value: "Badvel",
      label: "Badvel"
    },
    {
      value: "Banaganapalle",
      label: "Banaganapalle"
    },
    {
      value: "Bandar",
      label: "Bandar"
    },
    {
      value: "Bangarupalem",
      label: "Bangarupalem"
    },
    {
      value: "Banswada",
      label: "Banswada"
    },
    {
      value: "Bapatla",
      label: "Bapatla"
    },
    {
      value: "Bellampalli",
      label: "Bellampalli"
    },
    {
      value: "Bhadrachalam",
      label: "Bhadrachalam"
    },
    {
      value: "Bhainsa",
      label: "Bhainsa"
    },
    {
      value: "Bheemunipatnam",
      label: "Bheemunipatnam"
    },
    {
      value: "Bhimadole",
      label: "Bhimadole"
    },
    {
      value: "Bhimavaram",
      label: "Bhimavaram"
    },
    {
      value: "Bhongir",
      label: "Bhongir"
    },
    {
      value: "Bhooragamphad",
      label: "Bhooragamphad"
    },
    {
      value: "Boath",
      label: "Boath"
    },
    {
      value: "Bobbili",
      label: "Bobbili"
    },
    {
      value: "Bodhan",
      label: "Bodhan"
    },
    {
      value: "Chandoor",
      label: "Chandoor"
    },
    {
      value: "Chavitidibbalu",
      label: "Chavitidibbalu"
    },
    {
      value: "Chejerla",
      label: "Chejerla"
    },
    {
      value: "Chepurupalli",
      label: "Chepurupalli"
    },
    {
      value: "Cherial",
      label: "Cherial"
    },
    {
      value: "Chevella",
      label: "Chevella"
    },
    {
      value: "Chinnor",
      label: "Chinnor"
    },
    {
      value: "Chintalapudi",
      label: "Chintalapudi"
    },
    {
      value: "Chintapalle",
      label: "Chintapalle"
    },
    {
      value: "Chirala",
      label: "Chirala"
    },
    {
      value: "Chittoor",
      label: "Chittoor"
    },
    {
      value: "Chodavaram",
      label: "Chodavaram"
    },
    {
      value: "Cuddapah",
      label: "Cuddapah"
    },
    {
      value: "Cumbum",
      label: "Cumbum"
    },
    {
      value: "Darsi",
      label: "Darsi"
    },
    {
      value: "Devarakonda",
      label: "Devarakonda"
    },
    {
      value: "Dharmavaram",
      label: "Dharmavaram"
    },
    {
      value: "Dichpalli",
      label: "Dichpalli"
    },
    {
      value: "Divi",
      label: "Divi"
    },
    {
      value: "Donakonda",
      label: "Donakonda"
    },
    {
      value: "Dronachalam",
      label: "Dronachalam"
    },
    {
      value: "East Godavari",
      label: "East Godavari"
    },
    {
      value: "Eluru",
      label: "Eluru"
    },
    {
      value: "Eturnagaram",
      label: "Eturnagaram"
    },
    {
      value: "Gadwal",
      label: "Gadwal"
    },
    {
      value: "Gajapathinagaram",
      label: "Gajapathinagaram"
    },
    {
      value: "Gajwel",
      label: "Gajwel"
    },
    {
      value: "Garladinne",
      label: "Garladinne"
    },
    {
      value: "Giddalur",
      label: "Giddalur"
    },
    {
      value: "Godavari",
      label: "Godavari"
    },
    {
      value: "Gooty",
      label: "Gooty"
    },
    {
      value: "Gudivada",
      label: "Gudivada"
    },
    {
      value: "Gudur",
      label: "Gudur"
    },
    {
      value: "Guntur",
      label: "Guntur"
    },
    {
      value: "Hindupur",
      label: "Hindupur"
    },
    {
      value: "Hunsabad",
      label: "Hunsabad"
    },
    {
      value: "Huzurabad",
      label: "Huzurabad"
    },
    {
      value: "Huzurnagar",
      label: "Huzurnagar"
    },
    {
      value: "Hyderabad",
      label: "Hyderabad"
    },
    {
      value: "Ibrahimpatnam",
      label: "Ibrahimpatnam"
    },
    {
      value: "Jaggayyapet",
      label: "Jaggayyapet"
    },
    {
      value: "Jagtial",
      label: "Jagtial"
    },
    {
      value: "Jammalamadugu",
      label: "Jammalamadugu"
    },
    {
      value: "Jangaon",
      label: "Jangaon"
    },
    {
      value: "Jangareddygudem",
      label: "Jangareddygudem"
    },
    {
      value: "Jannaram",
      label: "Jannaram"
    },
    {
      value: "Kadiri",
      label: "Kadiri"
    },
    {
      value: "Kaikaluru",
      label: "Kaikaluru"
    },
    {
      value: "Kakinada",
      label: "Kakinada"
    },
    {
      value: "Kalwakurthy",
      label: "Kalwakurthy"
    },
    {
      value: "Kalyandurg",
      label: "Kalyandurg"
    },
    {
      value: "Kamalapuram",
      label: "Kamalapuram"
    },
    {
      value: "Kamareddy",
      label: "Kamareddy"
    },
    {
      value: "Kambadur",
      label: "Kambadur"
    },
    {
      value: "Kanaganapalle",
      label: "Kanaganapalle"
    },
    {
      value: "Kandukuru",
      label: "Kandukuru"
    },
    {
      value: "Kanigiri",
      label: "Kanigiri"
    },
    {
      value: "Karimnagar",
      label: "Karimnagar"
    },
    {
      value: "Kavali",
      label: "Kavali"
    },
    {
      value: "Khammam",
      label: "Khammam"
    },
    {
      value: "Khanapur (AP)",
      label: "Khanapur (AP)"
    },
    {
      value: "Kodangal",
      label: "Kodangal"
    },
    {
      value: "Koduru",
      label: "Koduru"
    },
    {
      value: "Koilkuntla",
      label: "Koilkuntla"
    },
    {
      value: "Kollapur",
      label: "Kollapur"
    },
    {
      value: "Kothagudem",
      label: "Kothagudem"
    },
    {
      value: "Kovvur",
      label: "Kovvur"
    },
    {
      value: "Krishna",
      label: "Krishna"
    },
    {
      value: "Krosuru",
      label: "Krosuru"
    },
    {
      value: "Kuppam",
      label: "Kuppam"
    },
    {
      value: "Kurnool",
      label: "Kurnool"
    },
    {
      value: "Lakkireddipalli",
      label: "Lakkireddipalli"
    },
    {
      value: "Madakasira",
      label: "Madakasira"
    },
    {
      value: "Madanapalli",
      label: "Madanapalli"
    },
    {
      value: "Madhira",
      label: "Madhira"
    },
    {
      value: "Madnur",
      label: "Madnur"
    },
    {
      value: "Mahabubabad",
      label: "Mahabubabad"
    },
    {
      value: "Mahabubnagar",
      label: "Mahabubnagar"
    },
    {
      value: "Mahadevapur",
      label: "Mahadevapur"
    },
    {
      value: "Makthal",
      label: "Makthal"
    },
    {
      value: "Mancherial",
      label: "Mancherial"
    },
    {
      value: "Mandapeta",
      label: "Mandapeta"
    },
    {
      value: "Mangalagiri",
      label: "Mangalagiri"
    },
    {
      value: "Manthani",
      label: "Manthani"
    },
    {
      value: "Markapur",
      label: "Markapur"
    },
    {
      value: "Marturu",
      label: "Marturu"
    },
    {
      value: "Medachal",
      label: "Medachal"
    },
    {
      value: "Medak",
      label: "Medak"
    },
    {
      value: "Medarmetla",
      label: "Medarmetla"
    },
    {
      value: "Metpalli",
      label: "Metpalli"
    },
    {
      value: "Mriyalguda",
      label: "Mriyalguda"
    },
    {
      value: "Mulug",
      label: "Mulug"
    },
    {
      value: "Mylavaram",
      label: "Mylavaram"
    },
    {
      value: "Nagarkurnool",
      label: "Nagarkurnool"
    },
    {
      value: "Nalgonda",
      label: "Nalgonda"
    },
    {
      value: "Nallacheruvu",
      label: "Nallacheruvu"
    },
    {
      value: "Nampalle",
      label: "Nampalle"
    },
    {
      value: "Nandigama",
      label: "Nandigama"
    },
    {
      value: "Nandikotkur",
      label: "Nandikotkur"
    },
    {
      value: "Nandyal",
      label: "Nandyal"
    },
    {
      value: "Narasampet",
      label: "Narasampet"
    },
    {
      value: "Narasaraopet",
      label: "Narasaraopet"
    },
    {
      value: "Narayanakhed",
      label: "Narayanakhed"
    },
    {
      value: "Narayanpet",
      label: "Narayanpet"
    },
    {
      value: "Narsapur",
      label: "Narsapur"
    },
    {
      value: "Narsipatnam",
      label: "Narsipatnam"
    },
    {
      value: "Nazvidu",
      label: "Nazvidu"
    },
    {
      value: "Nelloe",
      label: "Nelloe"
    },
    {
      value: "Nellore",
      label: "Nellore"
    },
    {
      value: "Nidamanur",
      label: "Nidamanur"
    },
    {
      value: "Nirmal",
      label: "Nirmal"
    },
    {
      value: "Nizamabad",
      label: "Nizamabad"
    },
    {
      value: "Nuguru",
      label: "Nuguru"
    },
    {
      value: "Ongole",
      label: "Ongole"
    },
    {
      value: "Outsarangapalle",
      label: "Outsarangapalle"
    },
    {
      value: "Paderu",
      label: "Paderu"
    },
    {
      value: "Pakala",
      label: "Pakala"
    },
    {
      value: "Palakonda",
      label: "Palakonda"
    },
    {
      value: "Paland",
      label: "Paland"
    },
    {
      value: "Palmaneru",
      label: "Palmaneru"
    },
    {
      value: "Pamuru",
      label: "Pamuru"
    },
    {
      value: "Pargi",
      label: "Pargi"
    },
    {
      value: "Parkal",
      label: "Parkal"
    },
    {
      value: "Parvathipuram",
      label: "Parvathipuram"
    },
    {
      value: "Pathapatnam",
      label: "Pathapatnam"
    },
    {
      value: "Pattikonda",
      label: "Pattikonda"
    },
    {
      value: "Peapalle",
      label: "Peapalle"
    },
    {
      value: "Peddapalli",
      label: "Peddapalli"
    },
    {
      value: "Peddapuram",
      label: "Peddapuram"
    },
    {
      value: "Penukonda",
      label: "Penukonda"
    },
    {
      value: "Piduguralla",
      label: "Piduguralla"
    },
    {
      value: "Piler",
      label: "Piler"
    },
    {
      value: "Pithapuram",
      label: "Pithapuram"
    },
    {
      value: "Podili",
      label: "Podili"
    },
    {
      value: "Polavaram",
      label: "Polavaram"
    },
    {
      value: "Prakasam",
      label: "Prakasam"
    },
    {
      value: "Proddatur",
      label: "Proddatur"
    },
    {
      value: "Pulivendla",
      label: "Pulivendla"
    },
    {
      value: "Punganur",
      label: "Punganur"
    },
    {
      value: "Putturu",
      label: "Putturu"
    },
    {
      value: "Rajahmundri",
      label: "Rajahmundri"
    },
    {
      value: "Rajampeta",
      label: "Rajampeta"
    },
    {
      value: "Ramachandrapuram",
      label: "Ramachandrapuram"
    },
    {
      value: "Ramannapet",
      label: "Ramannapet"
    },
    {
      value: "Rampachodavaram",
      label: "Rampachodavaram"
    },
    {
      value: "Rangareddy",
      label: "Rangareddy"
    },
    {
      value: "Rapur",
      label: "Rapur"
    },
    {
      value: "Rayachoti",
      label: "Rayachoti"
    },
    {
      value: "Rayadurg",
      label: "Rayadurg"
    },
    {
      value: "Razole",
      label: "Razole"
    },
    {
      value: "Repalle",
      label: "Repalle"
    },
    {
      value: "Saluru",
      label: "Saluru"
    },
    {
      value: "Sangareddy",
      label: "Sangareddy"
    },
    {
      value: "Sathupalli",
      label: "Sathupalli"
    },
    {
      value: "Sattenapalle",
      label: "Sattenapalle"
    },
    {
      value: "Satyavedu",
      label: "Satyavedu"
    },
    {
      value: "Shadnagar",
      label: "Shadnagar"
    },
    {
      value: "Siddavattam",
      label: "Siddavattam"
    },
    {
      value: "Siddipet",
      label: "Siddipet"
    },
    {
      value: "Sileru",
      label: "Sileru"
    },
    {
      value: "Sircilla",
      label: "Sircilla"
    },
    {
      value: "Sirpur Kagaznagar",
      label: "Sirpur Kagaznagar"
    },
    {
      value: "Sodam",
      label: "Sodam"
    },
    {
      value: "Sompeta",
      label: "Sompeta"
    },
    {
      value: "Srikakulam",
      label: "Srikakulam"
    },
    {
      value: "Srikalahasthi",
      label: "Srikalahasthi"
    },
    {
      value: "Srisailam",
      label: "Srisailam"
    },
    {
      value: "Srungavarapukota",
      label: "Srungavarapukota"
    },
    {
      value: "Sudhimalla",
      label: "Sudhimalla"
    },
    {
      value: "Sullarpet",
      label: "Sullarpet"
    },
    {
      value: "Tadepalligudem",
      label: "Tadepalligudem"
    },
    {
      value: "Tadipatri",
      label: "Tadipatri"
    },
    {
      value: "Tanduru",
      label: "Tanduru"
    },
    {
      value: "Tanuku",
      label: "Tanuku"
    },
    {
      value: "Tekkali",
      label: "Tekkali"
    },
    {
      value: "Tenali",
      label: "Tenali"
    },
    {
      value: "Thungaturthy",
      label: "Thungaturthy"
    },
    {
      value: "Tirivuru",
      label: "Tirivuru"
    },
    {
      value: "Tirupathi",
      label: "Tirupathi"
    },
    {
      value: "Tuni",
      label: "Tuni"
    },
    {
      value: "Udaygiri",
      label: "Udaygiri"
    },
    {
      value: "Ulvapadu",
      label: "Ulvapadu"
    },
    {
      value: "Uravakonda",
      label: "Uravakonda"
    },
    {
      value: "Utnor",
      label: "Utnor"
    },
    {
      value: "V.R. Puram",
      label: "V.R. Puram"
    },
    {
      value: "Vaimpalli",
      label: "Vaimpalli"
    },
    {
      value: "Vayalpad",
      label: "Vayalpad"
    },
    {
      value: "Venkatgiri",
      label: "Venkatgiri"
    },
    {
      value: "Venkatgirikota",
      label: "Venkatgirikota"
    },
    {
      value: "Vijayawada",
      label: "Vijayawada"
    },
    {
      value: "Vikrabad",
      label: "Vikrabad"
    },
    {
      value: "Vinjamuru",
      label: "Vinjamuru"
    },
    {
      value: "Vinukonda",
      label: "Vinukonda"
    },
    {
      value: "Visakhapatnam",
      label: "Visakhapatnam"
    },
    {
      value: "Vizayanagaram",
      label: "Vizayanagaram"
    },
    {
      value: "Vizianagaram",
      label: "Vizianagaram"
    },
    {
      value: "Vuyyuru",
      label: "Vuyyuru"
    },
    {
      value: "Wanaparthy",
      label: "Wanaparthy"
    },
    {
      value: "Warangal",
      label: "Warangal"
    },
    {
      value: "Wardhannapet",
      label: "Wardhannapet"
    },
    {
      value: "Yelamanchili",
      label: "Yelamanchili"
    },
    {
      value: "Yelavaram",
      label: "Yelavaram"
    },
    {
      value: "Yeleswaram",
      label: "Yeleswaram"
    },
    {
      value: "Yellandu",
      label: "Yellandu"
    },
    {
      value: "Yellanuru",
      label: "Yellanuru"
    },
    {
      value: "Yellareddy",
      label: "Yellareddy"
    },
    {
      value: "Yerragondapalem",
      label: "Yerragondapalem"
    },
    {
      value: "Zahirabad",
      label: "Zahirabad"
    }
  ],
  "Arunachal Pradesh": [
    {
      value: "Along",
      label: "Along"
    },
    {
      value: "Anini",
      label: "Anini"
    },
    {
      value: "Anjaw",
      label: "Anjaw"
    },
    {
      value: "Bameng",
      label: "Bameng"
    },
    {
      value: "Basar",
      label: "Basar"
    },
    {
      value: "Changlang",
      label: "Changlang"
    },
    {
      value: "Chowkhem",
      label: "Chowkhem"
    },
    {
      value: "Daporizo",
      label: "Daporizo"
    },
    {
      value: "Dibang Valley",
      label: "Dibang Valley"
    },
    {
      value: "Dirang",
      label: "Dirang"
    },
    {
      value: "Hayuliang",
      label: "Hayuliang"
    },
    {
      value: "Huri",
      label: "Huri"
    },
    {
      value: "Itanagar",
      label: "Itanagar"
    },
    {
      value: "Jairampur",
      label: "Jairampur"
    },
    {
      value: "Kalaktung",
      label: "Kalaktung"
    },
    {
      value: "Kameng",
      label: "Kameng"
    },
    {
      value: "Khonsa",
      label: "Khonsa"
    },
    {
      value: "Kolaring",
      label: "Kolaring"
    },
    {
      value: "Kurung Kumey",
      label: "Kurung Kumey"
    },
    {
      value: "Lohit",
      label: "Lohit"
    },
    {
      value: "Lower Dibang Valley",
      label: "Lower Dibang Valley"
    },
    {
      value: "Lower Subansiri",
      label: "Lower Subansiri"
    },
    {
      value: "Mariyang",
      label: "Mariyang"
    },
    {
      value: "Mechuka",
      label: "Mechuka"
    },
    {
      value: "Miao",
      label: "Miao"
    },
    {
      value: "Nefra",
      label: "Nefra"
    },
    {
      value: "Pakkekesang",
      label: "Pakkekesang"
    },
    {
      value: "Pangin",
      label: "Pangin"
    },
    {
      value: "Papum Pare",
      label: "Papum Pare"
    },
    {
      value: "Passighat",
      label: "Passighat"
    },
    {
      value: "Roing",
      label: "Roing"
    },
    {
      value: "Sagalee",
      label: "Sagalee"
    },
    {
      value: "Seppa",
      label: "Seppa"
    },
    {
      value: "Siang",
      label: "Siang"
    },
    {
      value: "Tali",
      label: "Tali"
    },
    {
      value: "Taliha",
      label: "Taliha"
    },
    {
      value: "Tawang",
      label: "Tawang"
    },
    {
      value: "Tezu",
      label: "Tezu"
    },
    {
      value: "Tirap",
      label: "Tirap"
    },
    {
      value: "Tuting",
      label: "Tuting"
    },
    {
      value: "Upper Siang",
      label: "Upper Siang"
    },
    {
      value: "Upper Subansiri",
      label: "Upper Subansiri"
    },
    {
      value: "Yiang Kiag",
      label: "Yiang Kiag"
    }
  ],
  "Assam": [
    {
      value: "Abhayapuri",
      label: "Abhayapuri"
    },
    {
      value: "Baithalangshu",
      label: "Baithalangshu"
    },
    {
      value: "Barama",
      label: "Barama"
    },
    {
      value: "Barpeta Road",
      label: "Barpeta Road"
    },
    {
      value: "Bihupuria",
      label: "Bihupuria"
    },
    {
      value: "Bijni",
      label: "Bijni"
    },
    {
      value: "Bilasipara",
      label: "Bilasipara"
    },
    {
      value: "Bokajan",
      label: "Bokajan"
    },
    {
      value: "Bokakhat",
      label: "Bokakhat"
    },
    {
      value: "Boko",
      label: "Boko"
    },
    {
      value: "Bongaigaon",
      label: "Bongaigaon"
    },
    {
      value: "Cachar",
      label: "Cachar"
    },
    {
      value: "Cachar Hills",
      label: "Cachar Hills"
    },
    {
      value: "Darrang",
      label: "Darrang"
    },
    {
      value: "Dhakuakhana",
      label: "Dhakuakhana"
    },
    {
      value: "Dhemaji",
      label: "Dhemaji"
    },
    {
      value: "Dhubri",
      label: "Dhubri"
    },
    {
      value: "Dibrugarh",
      label: "Dibrugarh"
    },
    {
      value: "Digboi",
      label: "Digboi"
    },
    {
      value: "Diphu",
      label: "Diphu"
    },
    {
      value: "Goalpara",
      label: "Goalpara"
    },
    {
      value: "Gohpur",
      label: "Gohpur"
    },
    {
      value: "Golaghat",
      label: "Golaghat"
    },
    {
      value: "Guwahati",
      label: "Guwahati"
    },
    {
      value: "Hailakandi",
      label: "Hailakandi"
    },
    {
      value: "Hajo",
      label: "Hajo"
    },
    {
      value: "Halflong",
      label: "Halflong"
    },
    {
      value: "Hojai",
      label: "Hojai"
    },
    {
      value: "Howraghat",
      label: "Howraghat"
    },
    {
      value: "Jorhat",
      label: "Jorhat"
    },
    {
      value: "Kamrup",
      label: "Kamrup"
    },
    {
      value: "Karbi Anglong",
      label: "Karbi Anglong"
    },
    {
      value: "Karimganj",
      label: "Karimganj"
    },
    {
      value: "Kokarajhar",
      label: "Kokarajhar"
    },
    {
      value: "Kokrajhar",
      label: "Kokrajhar"
    },
    {
      value: "Lakhimpur",
      label: "Lakhimpur"
    },
    {
      value: "Maibong",
      label: "Maibong"
    },
    {
      value: "Majuli",
      label: "Majuli"
    },
    {
      value: "Mangaldoi",
      label: "Mangaldoi"
    },
    {
      value: "Mariani",
      label: "Mariani"
    },
    {
      value: "Marigaon",
      label: "Marigaon"
    },
    {
      value: "Moranhat",
      label: "Moranhat"
    },
    {
      value: "Morigaon",
      label: "Morigaon"
    },
    {
      value: "Nagaon",
      label: "Nagaon"
    },
    {
      value: "Nalbari",
      label: "Nalbari"
    },
    {
      value: "Rangapara",
      label: "Rangapara"
    },
    {
      value: "Sadiya",
      label: "Sadiya"
    },
    {
      value: "Sibsagar",
      label: "Sibsagar"
    },
    {
      value: "Silchar",
      label: "Silchar"
    },
    {
      value: "Sivasagar",
      label: "Sivasagar"
    },
    {
      value: "Sonitpur",
      label: "Sonitpur"
    },
    {
      value: "Tarabarihat",
      label: "Tarabarihat"
    },
    {
      value: "Tezpur",
      label: "Tezpur"
    },
    {
      value: "Tinsukia",
      label: "Tinsukia"
    },
    {
      value: "Udalgiri",
      label: "Udalgiri"
    },
    {
      value: "Udalguri",
      label: "Udalguri"
    },
    {
      value: "UdarbondhBarpeta",
      label: "UdarbondhBarpeta"
    }
  ],
  "Bihar": [
    {
      value: "Adhaura",
      label: "Adhaura"
    },
    {
      value: "Amarpur",
      label: "Amarpur"
    },
    {
      value: "Araria",
      label: "Araria"
    },
    {
      value: "Areraj",
      label: "Areraj"
    },
    {
      value: "Arrah",
      label: "Arrah"
    },
    {
      value: "Arwal",
      label: "Arwal"
    },
    {
      value: "Aurangabad",
      label: "Aurangabad"
    },
    {
      value: "Bagaha",
      label: "Bagaha"
    },
    {
      value: "Banka",
      label: "Banka"
    },
    {
      value: "Banmankhi",
      label: "Banmankhi"
    },
    {
      value: "Barachakia",
      label: "Barachakia"
    },
    {
      value: "Barauni",
      label: "Barauni"
    },
    {
      value: "Barh",
      label: "Barh"
    },
    {
      value: "Barosi",
      label: "Barosi"
    },
    {
      value: "Begusarai",
      label: "Begusarai"
    },
    {
      value: "Benipatti",
      label: "Benipatti"
    },
    {
      value: "Benipur",
      label: "Benipur"
    },
    {
      value: "Bettiah",
      label: "Bettiah"
    },
    {
      value: "Bhabhua",
      label: "Bhabhua"
    },
    {
      value: "Bhagalpur",
      label: "Bhagalpur"
    },
    {
      value: "Bhojpur",
      label: "Bhojpur"
    },
    {
      value: "Bidupur",
      label: "Bidupur"
    },
    {
      value: "Biharsharif",
      label: "Biharsharif"
    },
    {
      value: "Bikram",
      label: "Bikram"
    },
    {
      value: "Bikramganj",
      label: "Bikramganj"
    },
    {
      value: "Birpur",
      label: "Birpur"
    },
    {
      value: "Buxar",
      label: "Buxar"
    },
    {
      value: "Chakai",
      label: "Chakai"
    },
    {
      value: "Champaran",
      label: "Champaran"
    },
    {
      value: "Chapara",
      label: "Chapara"
    },
    {
      value: "Dalsinghsarai",
      label: "Dalsinghsarai"
    },
    {
      value: "Danapur",
      label: "Danapur"
    },
    {
      value: "Darbhanga",
      label: "Darbhanga"
    },
    {
      value: "Daudnagar",
      label: "Daudnagar"
    },
    {
      value: "Dhaka",
      label: "Dhaka"
    },
    {
      value: "Dhamdaha",
      label: "Dhamdaha"
    },
    {
      value: "Dumraon",
      label: "Dumraon"
    },
    {
      value: "Ekma",
      label: "Ekma"
    },
    {
      value: "Forbesganj",
      label: "Forbesganj"
    },
    {
      value: "Gaya",
      label: "Gaya"
    },
    {
      value: "Gogri",
      label: "Gogri"
    },
    {
      value: "Gopalganj",
      label: "Gopalganj"
    },
    {
      value: "H.Kharagpur",
      label: "H.Kharagpur"
    },
    {
      value: "Hajipur",
      label: "Hajipur"
    },
    {
      value: "Hathua",
      label: "Hathua"
    },
    {
      value: "Hilsa",
      label: "Hilsa"
    },
    {
      value: "Imamganj",
      label: "Imamganj"
    },
    {
      value: "Jahanabad",
      label: "Jahanabad"
    },
    {
      value: "Jainagar",
      label: "Jainagar"
    },
    {
      value: "Jamshedpur",
      label: "Jamshedpur"
    },
    {
      value: "Jamui",
      label: "Jamui"
    },
    {
      value: "Jehanabad",
      label: "Jehanabad"
    },
    {
      value: "Jhajha",
      label: "Jhajha"
    },
    {
      value: "Jhanjharpur",
      label: "Jhanjharpur"
    },
    {
      value: "Kahalgaon",
      label: "Kahalgaon"
    },
    {
      value: "Kaimur (Bhabua)",
      label: "Kaimur (Bhabua)"
    },
    {
      value: "Katihar",
      label: "Katihar"
    },
    {
      value: "Katoria",
      label: "Katoria"
    },
    {
      value: "Khagaria",
      label: "Khagaria"
    },
    {
      value: "Kishanganj",
      label: "Kishanganj"
    },
    {
      value: "Korha",
      label: "Korha"
    },
    {
      value: "Lakhisarai",
      label: "Lakhisarai"
    },
    {
      value: "Madhepura",
      label: "Madhepura"
    },
    {
      value: "Madhubani",
      label: "Madhubani"
    },
    {
      value: "Maharajganj",
      label: "Maharajganj"
    },
    {
      value: "Mahua",
      label: "Mahua"
    },
    {
      value: "Mairwa",
      label: "Mairwa"
    },
    {
      value: "Mallehpur",
      label: "Mallehpur"
    },
    {
      value: "Masrakh",
      label: "Masrakh"
    },
    {
      value: "Mohania",
      label: "Mohania"
    },
    {
      value: "Monghyr",
      label: "Monghyr"
    },
    {
      value: "Motihari",
      label: "Motihari"
    },
    {
      value: "Motipur",
      label: "Motipur"
    },
    {
      value: "Munger",
      label: "Munger"
    },
    {
      value: "Muzaffarpur",
      label: "Muzaffarpur"
    },
    {
      value: "Nabinagar",
      label: "Nabinagar"
    },
    {
      value: "Nalanda",
      label: "Nalanda"
    },
    {
      value: "Narkatiaganj",
      label: "Narkatiaganj"
    },
    {
      value: "Naugachia",
      label: "Naugachia"
    },
    {
      value: "Nawada",
      label: "Nawada"
    },
    {
      value: "Pakribarwan",
      label: "Pakribarwan"
    },
    {
      value: "Pakridayal",
      label: "Pakridayal"
    },
    {
      value: "Patna",
      label: "Patna"
    },
    {
      value: "Phulparas",
      label: "Phulparas"
    },
    {
      value: "Piro",
      label: "Piro"
    },
    {
      value: "Pupri",
      label: "Pupri"
    },
    {
      value: "Purena",
      label: "Purena"
    },
    {
      value: "Purnia",
      label: "Purnia"
    },
    {
      value: "Rafiganj",
      label: "Rafiganj"
    },
    {
      value: "Rajauli",
      label: "Rajauli"
    },
    {
      value: "Ramnagar",
      label: "Ramnagar"
    },
    {
      value: "Raniganj",
      label: "Raniganj"
    },
    {
      value: "Raxaul",
      label: "Raxaul"
    },
    {
      value: "Rohtas",
      label: "Rohtas"
    },
    {
      value: "Rosera",
      label: "Rosera"
    },
    {
      value: "S.Bakhtiarpur",
      label: "S.Bakhtiarpur"
    },
    {
      value: "Saharsa",
      label: "Saharsa"
    },
    {
      value: "Samastipur",
      label: "Samastipur"
    },
    {
      value: "Saran",
      label: "Saran"
    },
    {
      value: "Sasaram",
      label: "Sasaram"
    },
    {
      value: "Seikhpura",
      label: "Seikhpura"
    },
    {
      value: "Sheikhpura",
      label: "Sheikhpura"
    },
    {
      value: "Sheohar",
      label: "Sheohar"
    },
    {
      value: "Sherghati",
      label: "Sherghati"
    },
    {
      value: "Sidhawalia",
      label: "Sidhawalia"
    },
    {
      value: "Singhwara",
      label: "Singhwara"
    },
    {
      value: "Sitamarhi",
      label: "Sitamarhi"
    },
    {
      value: "Siwan",
      label: "Siwan"
    },
    {
      value: "Sonepur",
      label: "Sonepur"
    },
    {
      value: "Supaul",
      label: "Supaul"
    },
    {
      value: "Thakurganj",
      label: "Thakurganj"
    },
    {
      value: "Triveniganj",
      label: "Triveniganj"
    },
    {
      value: "Udakishanganj",
      label: "Udakishanganj"
    },
    {
      value: "Vaishali",
      label: "Vaishali"
    },
    {
      value: "Wazirganj",
      label: "Wazirganj"
    }
  ],
  "Chandigarh": [
    {
      value: "Chandigarh",
      label: "Chandigarh"
    },
    {
      value: "Mani Marja",
      label: "Mani Marja"
    }
  ],
  "Chhattisgarh": [
    {
      value: "Ambikapur",
      label: "Ambikapur"
    },
    {
      value: "Antagarh",
      label: "Antagarh"
    },
    {
      value: "Arang",
      label: "Arang"
    },
    {
      value: "Bacheli",
      label: "Bacheli"
    },
    {
      value: "Bagbahera",
      label: "Bagbahera"
    },
    {
      value: "Bagicha",
      label: "Bagicha"
    },
    {
      value: "Baikunthpur",
      label: "Baikunthpur"
    },
    {
      value: "Balod",
      label: "Balod"
    },
    {
      value: "Balodabazar",
      label: "Balodabazar"
    },
    {
      value: "Balrampur",
      label: "Balrampur"
    },
    {
      value: "Barpalli",
      label: "Barpalli"
    },
    {
      value: "Basana",
      label: "Basana"
    },
    {
      value: "Bastanar",
      label: "Bastanar"
    },
    {
      value: "Bastar",
      label: "Bastar"
    },
    {
      value: "Bderajpur",
      label: "Bderajpur"
    },
    {
      value: "Bemetara",
      label: "Bemetara"
    },
    {
      value: "Berla",
      label: "Berla"
    },
    {
      value: "Bhairongarh",
      label: "Bhairongarh"
    },
    {
      value: "Bhanupratappur",
      label: "Bhanupratappur"
    },
    {
      value: "Bharathpur",
      label: "Bharathpur"
    },
    {
      value: "Bhatapara",
      label: "Bhatapara"
    },
    {
      value: "Bhilai",
      label: "Bhilai"
    },
    {
      value: "Bhilaigarh",
      label: "Bhilaigarh"
    },
    {
      value: "Bhopalpatnam",
      label: "Bhopalpatnam"
    },
    {
      value: "Bijapur",
      label: "Bijapur"
    },
    {
      value: "Bilaspur",
      label: "Bilaspur"
    },
    {
      value: "Bodla",
      label: "Bodla"
    },
    {
      value: "Bokaband",
      label: "Bokaband"
    },
    {
      value: "Chandipara",
      label: "Chandipara"
    },
    {
      value: "Chhinagarh",
      label: "Chhinagarh"
    },
    {
      value: "Chhuriakala",
      label: "Chhuriakala"
    },
    {
      value: "Chingmut",
      label: "Chingmut"
    },
    {
      value: "Chuikhadan",
      label: "Chuikhadan"
    },
    {
      value: "Dabhara",
      label: "Dabhara"
    },
    {
      value: "Dallirajhara",
      label: "Dallirajhara"
    },
    {
      value: "Dantewada",
      label: "Dantewada"
    },
    {
      value: "Deobhog",
      label: "Deobhog"
    },
    {
      value: "Dhamda",
      label: "Dhamda"
    },
    {
      value: "Dhamtari",
      label: "Dhamtari"
    },
    {
      value: "Dharamjaigarh",
      label: "Dharamjaigarh"
    },
    {
      value: "Dongargarh",
      label: "Dongargarh"
    },
    {
      value: "Durg",
      label: "Durg"
    },
    {
      value: "Durgakondal",
      label: "Durgakondal"
    },
    {
      value: "Fingeshwar",
      label: "Fingeshwar"
    },
    {
      value: "Gariaband",
      label: "Gariaband"
    },
    {
      value: "Garpa",
      label: "Garpa"
    },
    {
      value: "Gharghoda",
      label: "Gharghoda"
    },
    {
      value: "Gogunda",
      label: "Gogunda"
    },
    {
      value: "Ilamidi",
      label: "Ilamidi"
    },
    {
      value: "Jagdalpur",
      label: "Jagdalpur"
    },
    {
      value: "Janjgir",
      label: "Janjgir"
    },
    {
      value: "Janjgir-Champa",
      label: "Janjgir-Champa"
    },
    {
      value: "Jarwa",
      label: "Jarwa"
    },
    {
      value: "Jashpur",
      label: "Jashpur"
    },
    {
      value: "Jashpurnagar",
      label: "Jashpurnagar"
    },
    {
      value: "Kabirdham-Kawardha",
      label: "Kabirdham-Kawardha"
    },
    {
      value: "Kanker",
      label: "Kanker"
    },
    {
      value: "Kasdol",
      label: "Kasdol"
    },
    {
      value: "Kathdol",
      label: "Kathdol"
    },
    {
      value: "Kathghora",
      label: "Kathghora"
    },
    {
      value: "Kawardha",
      label: "Kawardha"
    },
    {
      value: "Keskal",
      label: "Keskal"
    },
    {
      value: "Khairgarh",
      label: "Khairgarh"
    },
    {
      value: "Kondagaon",
      label: "Kondagaon"
    },
    {
      value: "Konta",
      label: "Konta"
    },
    {
      value: "Korba",
      label: "Korba"
    },
    {
      value: "Korea",
      label: "Korea"
    },
    {
      value: "Kota",
      label: "Kota"
    },
    {
      value: "Koyelibeda",
      label: "Koyelibeda"
    },
    {
      value: "Kuakunda",
      label: "Kuakunda"
    },
    {
      value: "Kunkuri",
      label: "Kunkuri"
    },
    {
      value: "Kurud",
      label: "Kurud"
    },
    {
      value: "Lohadigundah",
      label: "Lohadigundah"
    },
    {
      value: "Lormi",
      label: "Lormi"
    },
    {
      value: "Luckwada",
      label: "Luckwada"
    },
    {
      value: "Mahasamund",
      label: "Mahasamund"
    },
    {
      value: "Makodi",
      label: "Makodi"
    },
    {
      value: "Manendragarh",
      label: "Manendragarh"
    },
    {
      value: "Manpur",
      label: "Manpur"
    },
    {
      value: "Marwahi",
      label: "Marwahi"
    },
    {
      value: "Mohla",
      label: "Mohla"
    },
    {
      value: "Mungeli",
      label: "Mungeli"
    },
    {
      value: "Nagri",
      label: "Nagri"
    },
    {
      value: "Narainpur",
      label: "Narainpur"
    },
    {
      value: "Narayanpur",
      label: "Narayanpur"
    },
    {
      value: "Neora",
      label: "Neora"
    },
    {
      value: "Netanar",
      label: "Netanar"
    },
    {
      value: "Odgi",
      label: "Odgi"
    },
    {
      value: "Padamkot",
      label: "Padamkot"
    },
    {
      value: "Pakhanjur",
      label: "Pakhanjur"
    },
    {
      value: "Pali",
      label: "Pali"
    },
    {
      value: "Pandaria",
      label: "Pandaria"
    },
    {
      value: "Pandishankar",
      label: "Pandishankar"
    },
    {
      value: "Parasgaon",
      label: "Parasgaon"
    },
    {
      value: "Pasan",
      label: "Pasan"
    },
    {
      value: "Patan",
      label: "Patan"
    },
    {
      value: "Pathalgaon",
      label: "Pathalgaon"
    },
    {
      value: "Pendra",
      label: "Pendra"
    },
    {
      value: "Pratappur",
      label: "Pratappur"
    },
    {
      value: "Premnagar",
      label: "Premnagar"
    },
    {
      value: "Raigarh",
      label: "Raigarh"
    },
    {
      value: "Raipur",
      label: "Raipur"
    },
    {
      value: "Rajnandgaon",
      label: "Rajnandgaon"
    },
    {
      value: "Rajpur",
      label: "Rajpur"
    },
    {
      value: "Ramchandrapur",
      label: "Ramchandrapur"
    },
    {
      value: "Saraipali",
      label: "Saraipali"
    },
    {
      value: "Saranggarh",
      label: "Saranggarh"
    },
    {
      value: "Sarona",
      label: "Sarona"
    },
    {
      value: "Semaria",
      label: "Semaria"
    },
    {
      value: "Shakti",
      label: "Shakti"
    },
    {
      value: "Sitapur",
      label: "Sitapur"
    },
    {
      value: "Sukma",
      label: "Sukma"
    },
    {
      value: "Surajpur",
      label: "Surajpur"
    },
    {
      value: "Surguja",
      label: "Surguja"
    },
    {
      value: "Tapkara",
      label: "Tapkara"
    },
    {
      value: "Toynar",
      label: "Toynar"
    },
    {
      value: "Udaipur",
      label: "Udaipur"
    },
    {
      value: "Uproda",
      label: "Uproda"
    },
    {
      value: "Wadrainagar",
      label: "Wadrainagar"
    }
  ],
  "Dadra & Nagar Haveli": [
    {
      value: "Amal",
      label: "Amal"
    },
    {
      value: "Amli",
      label: "Amli"
    },
    {
      value: "Bedpa",
      label: "Bedpa"
    },
    {
      value: "Chikhli",
      label: "Chikhli"
    },
    {
      value: "Dadra & Nagar Haveli",
      label: "Dadra & Nagar Haveli"
    },
    {
      value: "Dahikhed",
      label: "Dahikhed"
    },
    {
      value: "Dolara",
      label: "Dolara"
    },
    {
      value: "Galonda",
      label: "Galonda"
    },
    {
      value: "Kanadi",
      label: "Kanadi"
    },
    {
      value: "Karchond",
      label: "Karchond"
    },
    {
      value: "Khadoli",
      label: "Khadoli"
    },
    {
      value: "Kharadpada",
      label: "Kharadpada"
    },
    {
      value: "Kherabari",
      label: "Kherabari"
    },
    {
      value: "Kherdi",
      label: "Kherdi"
    },
    {
      value: "Kothar",
      label: "Kothar"
    },
    {
      value: "Luari",
      label: "Luari"
    },
    {
      value: "Mashat",
      label: "Mashat"
    },
    {
      value: "Rakholi",
      label: "Rakholi"
    },
    {
      value: "Rudana",
      label: "Rudana"
    },
    {
      value: "Saili",
      label: "Saili"
    },
    {
      value: "Sili",
      label: "Sili"
    },
    {
      value: "Silvassa",
      label: "Silvassa"
    },
    {
      value: "Sindavni",
      label: "Sindavni"
    },
    {
      value: "Udva",
      label: "Udva"
    },
    {
      value: "Umbarkoi",
      label: "Umbarkoi"
    },
    {
      value: "Vansda",
      label: "Vansda"
    },
    {
      value: "Vasona",
      label: "Vasona"
    },
    {
      value: "Velugam",
      label: "Velugam"
    }
  ],
  "Daman & Diu": [
    {
      value: "Brancavare",
      label: "Brancavare"
    },
    {
      value: "Dagasi",
      label: "Dagasi"
    },
    {
      value: "Daman",
      label: "Daman"
    },
    {
      value: "Diu",
      label: "Diu"
    },
    {
      value: "Magarvara",
      label: "Magarvara"
    },
    {
      value: "Nagwa",
      label: "Nagwa"
    },
    {
      value: "Pariali",
      label: "Pariali"
    },
    {
      value: "Passo Covo",
      label: "Passo Covo"
    }
  ],
  "Delhi": [
    {
      value: "Central Delhi",
      label: "Central Delhi"
    },
    {
      value: "East Delhi",
      label: "East Delhi"
    },
    {
      value: "New Delhi",
      label: "New Delhi"
    },
    {
      value: "North Delhi",
      label: "North Delhi"
    },
    {
      value: "North East Delhi",
      label: "North East Delhi"
    },
    {
      value: "North West Delhi",
      label: "North West Delhi"
    },
    {
      value: "South Delhi",
      label: "South Delhi"
    },
    {
      value: "South West Delhi",
      label: "South West Delhi"
    },
    {
      value: "West Delhi",
      label: "West Delhi"
    }
  ],
  "Goa": [
    {
      value: "Canacona",
      label: "Canacona"
    },
    {
      value: "Candolim",
      label: "Candolim"
    },
    {
      value: "Chinchinim",
      label: "Chinchinim"
    },
    {
      value: "Cortalim",
      label: "Cortalim"
    },
    {
      value: "Goa",
      label: "Goa"
    },
    {
      value: "Jua",
      label: "Jua"
    },
    {
      value: "Madgaon",
      label: "Madgaon"
    },
    {
      value: "Mahem",
      label: "Mahem"
    },
    {
      value: "Mapuca",
      label: "Mapuca"
    },
    {
      value: "Marmagao",
      label: "Marmagao"
    },
    {
      value: "Panji",
      label: "Panji"
    },
    {
      value: "Ponda",
      label: "Ponda"
    },
    {
      value: "Sanvordem",
      label: "Sanvordem"
    },
    {
      value: "Terekhol",
      label: "Terekhol"
    }
  ],
  "Gujarat": [
    {
      value: "Ahmedabad",
      label: "Ahmedabad"
    },
    {
      value: "Ahwa",
      label: "Ahwa"
    },
    {
      value: "Amod",
      label: "Amod"
    },
    {
      value: "Amreli",
      label: "Amreli"
    },
    {
      value: "Anand",
      label: "Anand"
    },
    {
      value: "Anjar",
      label: "Anjar"
    },
    {
      value: "Ankaleshwar",
      label: "Ankaleshwar"
    },
    {
      value: "Babra",
      label: "Babra"
    },
    {
      value: "Balasinor",
      label: "Balasinor"
    },
    {
      value: "Banaskantha",
      label: "Banaskantha"
    },
    {
      value: "Bansada",
      label: "Bansada"
    },
    {
      value: "Bardoli",
      label: "Bardoli"
    },
    {
      value: "Bareja",
      label: "Bareja"
    },
    {
      value: "Baroda",
      label: "Baroda"
    },
    {
      value: "Barwala",
      label: "Barwala"
    },
    {
      value: "Bayad",
      label: "Bayad"
    },
    {
      value: "Bhachav",
      label: "Bhachav"
    },
    {
      value: "Bhanvad",
      label: "Bhanvad"
    },
    {
      value: "Bharuch",
      label: "Bharuch"
    },
    {
      value: "Bhavnagar",
      label: "Bhavnagar"
    },
    {
      value: "Bhiloda",
      label: "Bhiloda"
    },
    {
      value: "Bhuj",
      label: "Bhuj"
    },
    {
      value: "Billimora",
      label: "Billimora"
    },
    {
      value: "Borsad",
      label: "Borsad"
    },
    {
      value: "Botad",
      label: "Botad"
    },
    {
      value: "Chanasma",
      label: "Chanasma"
    },
    {
      value: "Chhota Udaipur",
      label: "Chhota Udaipur"
    },
    {
      value: "Chotila",
      label: "Chotila"
    },
    {
      value: "Dabhoi",
      label: "Dabhoi"
    },
    {
      value: "Dahod",
      label: "Dahod"
    },
    {
      value: "Damnagar",
      label: "Damnagar"
    },
    {
      value: "Dang",
      label: "Dang"
    },
    {
      value: "Danta",
      label: "Danta"
    },
    {
      value: "Dasada",
      label: "Dasada"
    },
    {
      value: "Dediapada",
      label: "Dediapada"
    },
    {
      value: "Deesa",
      label: "Deesa"
    },
    {
      value: "Dehgam",
      label: "Dehgam"
    },
    {
      value: "Deodar",
      label: "Deodar"
    },
    {
      value: "Devgadhbaria",
      label: "Devgadhbaria"
    },
    {
      value: "Dhandhuka",
      label: "Dhandhuka"
    },
    {
      value: "Dhanera",
      label: "Dhanera"
    },
    {
      value: "Dharampur",
      label: "Dharampur"
    },
    {
      value: "Dhari",
      label: "Dhari"
    },
    {
      value: "Dholka",
      label: "Dholka"
    },
    {
      value: "Dhoraji",
      label: "Dhoraji"
    },
    {
      value: "Dhrangadhra",
      label: "Dhrangadhra"
    },
    {
      value: "Dhrol",
      label: "Dhrol"
    },
    {
      value: "Dwarka",
      label: "Dwarka"
    },
    {
      value: "Fortsongadh",
      label: "Fortsongadh"
    },
    {
      value: "Gadhada",
      label: "Gadhada"
    },
    {
      value: "Gandhi Nagar",
      label: "Gandhi Nagar"
    },
    {
      value: "Gariadhar",
      label: "Gariadhar"
    },
    {
      value: "Godhra",
      label: "Godhra"
    },
    {
      value: "Gogodar",
      label: "Gogodar"
    },
    {
      value: "Gondal",
      label: "Gondal"
    },
    {
      value: "Halol",
      label: "Halol"
    },
    {
      value: "Halvad",
      label: "Halvad"
    },
    {
      value: "Harij",
      label: "Harij"
    },
    {
      value: "Himatnagar",
      label: "Himatnagar"
    },
    {
      value: "Idar",
      label: "Idar"
    },
    {
      value: "Jambusar",
      label: "Jambusar"
    },
    {
      value: "Jamjodhpur",
      label: "Jamjodhpur"
    },
    {
      value: "Jamkalyanpur",
      label: "Jamkalyanpur"
    },
    {
      value: "Jamnagar",
      label: "Jamnagar"
    },
    {
      value: "Jasdan",
      label: "Jasdan"
    },
    {
      value: "Jetpur",
      label: "Jetpur"
    },
    {
      value: "Jhagadia",
      label: "Jhagadia"
    },
    {
      value: "Jhalod",
      label: "Jhalod"
    },
    {
      value: "Jodia",
      label: "Jodia"
    },
    {
      value: "Junagadh",
      label: "Junagadh"
    },
    {
      value: "Junagarh",
      label: "Junagarh"
    },
    {
      value: "Kalawad",
      label: "Kalawad"
    },
    {
      value: "Kalol",
      label: "Kalol"
    },
    {
      value: "Kapad Wanj",
      label: "Kapad Wanj"
    },
    {
      value: "Keshod",
      label: "Keshod"
    },
    {
      value: "Khambat",
      label: "Khambat"
    },
    {
      value: "Khambhalia",
      label: "Khambhalia"
    },
    {
      value: "Khavda",
      label: "Khavda"
    },
    {
      value: "Kheda",
      label: "Kheda"
    },
    {
      value: "Khedbrahma",
      label: "Khedbrahma"
    },
    {
      value: "Kheralu",
      label: "Kheralu"
    },
    {
      value: "Kodinar",
      label: "Kodinar"
    },
    {
      value: "Kotdasanghani",
      label: "Kotdasanghani"
    },
    {
      value: "Kunkawav",
      label: "Kunkawav"
    },
    {
      value: "Kutch",
      label: "Kutch"
    },
    {
      value: "Kutchmandvi",
      label: "Kutchmandvi"
    },
    {
      value: "Kutiyana",
      label: "Kutiyana"
    },
    {
      value: "Lakhpat",
      label: "Lakhpat"
    },
    {
      value: "Lakhtar",
      label: "Lakhtar"
    },
    {
      value: "Lalpur",
      label: "Lalpur"
    },
    {
      value: "Limbdi",
      label: "Limbdi"
    },
    {
      value: "Limkheda",
      label: "Limkheda"
    },
    {
      value: "Lunavada",
      label: "Lunavada"
    },
    {
      value: "M.M.Mangrol",
      label: "M.M.Mangrol"
    },
    {
      value: "Mahuva",
      label: "Mahuva"
    },
    {
      value: "Malia-Hatina",
      label: "Malia-Hatina"
    },
    {
      value: "Maliya",
      label: "Maliya"
    },
    {
      value: "Malpur",
      label: "Malpur"
    },
    {
      value: "Manavadar",
      label: "Manavadar"
    },
    {
      value: "Mandvi",
      label: "Mandvi"
    },
    {
      value: "Mangrol",
      label: "Mangrol"
    },
    {
      value: "Mehmedabad",
      label: "Mehmedabad"
    },
    {
      value: "Mehsana",
      label: "Mehsana"
    },
    {
      value: "Miyagam",
      label: "Miyagam"
    },
    {
      value: "Modasa",
      label: "Modasa"
    },
    {
      value: "Morvi",
      label: "Morvi"
    },
    {
      value: "Muli",
      label: "Muli"
    },
    {
      value: "Mundra",
      label: "Mundra"
    },
    {
      value: "Nadiad",
      label: "Nadiad"
    },
    {
      value: "Nakhatrana",
      label: "Nakhatrana"
    },
    {
      value: "Nalia",
      label: "Nalia"
    },
    {
      value: "Narmada",
      label: "Narmada"
    },
    {
      value: "Naswadi",
      label: "Naswadi"
    },
    {
      value: "Navasari",
      label: "Navasari"
    },
    {
      value: "Nizar",
      label: "Nizar"
    },
    {
      value: "Okha",
      label: "Okha"
    },
    {
      value: "Paddhari",
      label: "Paddhari"
    },
    {
      value: "Padra",
      label: "Padra"
    },
    {
      value: "Palanpur",
      label: "Palanpur"
    },
    {
      value: "Palitana",
      label: "Palitana"
    },
    {
      value: "Panchmahals",
      label: "Panchmahals"
    },
    {
      value: "Patan",
      label: "Patan"
    },
    {
      value: "Pavijetpur",
      label: "Pavijetpur"
    },
    {
      value: "Porbandar",
      label: "Porbandar"
    },
    {
      value: "Prantij",
      label: "Prantij"
    },
    {
      value: "Radhanpur",
      label: "Radhanpur"
    },
    {
      value: "Rahpar",
      label: "Rahpar"
    },
    {
      value: "Rajaula",
      label: "Rajaula"
    },
    {
      value: "Rajkot",
      label: "Rajkot"
    },
    {
      value: "Rajpipla",
      label: "Rajpipla"
    },
    {
      value: "Ranavav",
      label: "Ranavav"
    },
    {
      value: "Sabarkantha",
      label: "Sabarkantha"
    },
    {
      value: "Sanand",
      label: "Sanand"
    },
    {
      value: "Sankheda",
      label: "Sankheda"
    },
    {
      value: "Santalpur",
      label: "Santalpur"
    },
    {
      value: "Santrampur",
      label: "Santrampur"
    },
    {
      value: "Savarkundla",
      label: "Savarkundla"
    },
    {
      value: "Savli",
      label: "Savli"
    },
    {
      value: "Sayan",
      label: "Sayan"
    },
    {
      value: "Sayla",
      label: "Sayla"
    },
    {
      value: "Shehra",
      label: "Shehra"
    },
    {
      value: "Sidhpur",
      label: "Sidhpur"
    },
    {
      value: "Sihor",
      label: "Sihor"
    },
    {
      value: "Sojitra",
      label: "Sojitra"
    },
    {
      value: "Sumrasar",
      label: "Sumrasar"
    },
    {
      value: "Surat",
      label: "Surat"
    },
    {
      value: "Surendranagar",
      label: "Surendranagar"
    },
    {
      value: "Talaja",
      label: "Talaja"
    },
    {
      value: "Thara",
      label: "Thara"
    },
    {
      value: "Tharad",
      label: "Tharad"
    },
    {
      value: "Thasra",
      label: "Thasra"
    },
    {
      value: "Una-Diu",
      label: "Una-Diu"
    },
    {
      value: "Upleta",
      label: "Upleta"
    },
    {
      value: "Vadgam",
      label: "Vadgam"
    },
    {
      value: "Vadodara",
      label: "Vadodara"
    },
    {
      value: "Valia",
      label: "Valia"
    },
    {
      value: "Vallabhipur",
      label: "Vallabhipur"
    },
    {
      value: "Valod",
      label: "Valod"
    },
    {
      value: "Valsad",
      label: "Valsad"
    },
    {
      value: "Vanthali",
      label: "Vanthali"
    },
    {
      value: "Vapi",
      label: "Vapi"
    },
    {
      value: "Vav",
      label: "Vav"
    },
    {
      value: "Veraval",
      label: "Veraval"
    },
    {
      value: "Vijapur",
      label: "Vijapur"
    },
    {
      value: "Viramgam",
      label: "Viramgam"
    },
    {
      value: "Visavadar",
      label: "Visavadar"
    },
    {
      value: "Visnagar",
      label: "Visnagar"
    },
    {
      value: "Vyara",
      label: "Vyara"
    },
    {
      value: "Waghodia",
      label: "Waghodia"
    },
    {
      value: "Wankaner",
      label: "Wankaner"
    }
  ],
  "Haryana": [
    {
      value: "Adampur Mandi",
      label: "Adampur Mandi"
    },
    {
      value: "Ambala",
      label: "Ambala"
    },
    {
      value: "Assandh",
      label: "Assandh"
    },
    {
      value: "Bahadurgarh",
      label: "Bahadurgarh"
    },
    {
      value: "Barara",
      label: "Barara"
    },
    {
      value: "Barwala",
      label: "Barwala"
    },
    {
      value: "Bawal",
      label: "Bawal"
    },
    {
      value: "Bawanikhera",
      label: "Bawanikhera"
    },
    {
      value: "Bhiwani",
      label: "Bhiwani"
    },
    {
      value: "Charkhidadri",
      label: "Charkhidadri"
    },
    {
      value: "Cheeka",
      label: "Cheeka"
    },
    {
      value: "Chhachrauli",
      label: "Chhachrauli"
    },
    {
      value: "Dabwali",
      label: "Dabwali"
    },
    {
      value: "Ellenabad",
      label: "Ellenabad"
    },
    {
      value: "Faridabad",
      label: "Faridabad"
    },
    {
      value: "Fatehabad",
      label: "Fatehabad"
    },
    {
      value: "Ferojpur Jhirka",
      label: "Ferojpur Jhirka"
    },
    {
      value: "Gharaunda",
      label: "Gharaunda"
    },
    {
      value: "Gohana",
      label: "Gohana"
    },
    {
      value: "Gurgaon",
      label: "Gurgaon"
    },
    {
      value: "Hansi",
      label: "Hansi"
    },
    {
      value: "Hisar",
      label: "Hisar"
    },
    {
      value: "Jagadhari",
      label: "Jagadhari"
    },
    {
      value: "Jatusana",
      label: "Jatusana"
    },
    {
      value: "Jhajjar",
      label: "Jhajjar"
    },
    {
      value: "Jind",
      label: "Jind"
    },
    {
      value: "Julana",
      label: "Julana"
    },
    {
      value: "Kaithal",
      label: "Kaithal"
    },
    {
      value: "Kalanaur",
      label: "Kalanaur"
    },
    {
      value: "Kalanwali",
      label: "Kalanwali"
    },
    {
      value: "Kalka",
      label: "Kalka"
    },
    {
      value: "Karnal",
      label: "Karnal"
    },
    {
      value: "Kosli",
      label: "Kosli"
    },
    {
      value: "Kurukshetra",
      label: "Kurukshetra"
    },
    {
      value: "Loharu",
      label: "Loharu"
    },
    {
      value: "Mahendragarh",
      label: "Mahendragarh"
    },
    {
      value: "Meham",
      label: "Meham"
    },
    {
      value: "Mewat",
      label: "Mewat"
    },
    {
      value: "Mohindergarh",
      label: "Mohindergarh"
    },
    {
      value: "Naraingarh",
      label: "Naraingarh"
    },
    {
      value: "Narnaul",
      label: "Narnaul"
    },
    {
      value: "Narwana",
      label: "Narwana"
    },
    {
      value: "Nilokheri",
      label: "Nilokheri"
    },
    {
      value: "Nuh",
      label: "Nuh"
    },
    {
      value: "Palwal",
      label: "Palwal"
    },
    {
      value: "Panchkula",
      label: "Panchkula"
    },
    {
      value: "Panipat",
      label: "Panipat"
    },
    {
      value: "Pehowa",
      label: "Pehowa"
    },
    {
      value: "Ratia",
      label: "Ratia"
    },
    {
      value: "Rewari",
      label: "Rewari"
    },
    {
      value: "Rohtak",
      label: "Rohtak"
    },
    {
      value: "Safidon",
      label: "Safidon"
    },
    {
      value: "Sirsa",
      label: "Sirsa"
    },
    {
      value: "Siwani",
      label: "Siwani"
    },
    {
      value: "Sonipat",
      label: "Sonipat"
    },
    {
      value: "Tohana",
      label: "Tohana"
    },
    {
      value: "Tohsam",
      label: "Tohsam"
    },
    {
      value: "Yamunanagar",
      label: "Yamunanagar"
    }
  ],
  "Himachal Pradesh": [
    {
      value: "Amb",
      label: "Amb"
    },
    {
      value: "Arki",
      label: "Arki"
    },
    {
      value: "Banjar",
      label: "Banjar"
    },
    {
      value: "Bharmour",
      label: "Bharmour"
    },
    {
      value: "Bilaspur",
      label: "Bilaspur"
    },
    {
      value: "Chamba",
      label: "Chamba"
    },
    {
      value: "Churah",
      label: "Churah"
    },
    {
      value: "Dalhousie",
      label: "Dalhousie"
    },
    {
      value: "Dehra Gopipur",
      label: "Dehra Gopipur"
    },
    {
      value: "Hamirpur",
      label: "Hamirpur"
    },
    {
      value: "Jogindernagar",
      label: "Jogindernagar"
    },
    {
      value: "Kalpa",
      label: "Kalpa"
    },
    {
      value: "Kangra",
      label: "Kangra"
    },
    {
      value: "Kinnaur",
      label: "Kinnaur"
    },
    {
      value: "Kullu",
      label: "Kullu"
    },
    {
      value: "Lahaul",
      label: "Lahaul"
    },
    {
      value: "Mandi",
      label: "Mandi"
    },
    {
      value: "Nahan",
      label: "Nahan"
    },
    {
      value: "Nalagarh",
      label: "Nalagarh"
    },
    {
      value: "Nirmand",
      label: "Nirmand"
    },
    {
      value: "Nurpur",
      label: "Nurpur"
    },
    {
      value: "Palampur",
      label: "Palampur"
    },
    {
      value: "Pangi",
      label: "Pangi"
    },
    {
      value: "Paonta",
      label: "Paonta"
    },
    {
      value: "Pooh",
      label: "Pooh"
    },
    {
      value: "Rajgarh",
      label: "Rajgarh"
    },
    {
      value: "Rampur Bushahar",
      label: "Rampur Bushahar"
    },
    {
      value: "Rohru",
      label: "Rohru"
    },
    {
      value: "Shimla",
      label: "Shimla"
    },
    {
      value: "Sirmaur",
      label: "Sirmaur"
    },
    {
      value: "Solan",
      label: "Solan"
    },
    {
      value: "Spiti",
      label: "Spiti"
    },
    {
      value: "Sundernagar",
      label: "Sundernagar"
    },
    {
      value: "Theog",
      label: "Theog"
    },
    {
      value: "Udaipur",
      label: "Udaipur"
    },
    {
      value: "Una",
      label: "Una"
    }
  ],
  "Jammu and Kashmir": [
    {
      value: "Akhnoor",
      label: "Akhnoor"
    },
    {
      value: "Anantnag",
      label: "Anantnag"
    },
    {
      value: "Badgam",
      label: "Badgam"
    },
    {
      value: "Bandipur",
      label: "Bandipur"
    },
    {
      value: "Baramulla",
      label: "Baramulla"
    },
    {
      value: "Basholi",
      label: "Basholi"
    },
    {
      value: "Bedarwah",
      label: "Bedarwah"
    },
    {
      value: "Budgam",
      label: "Budgam"
    },
    {
      value: "Doda",
      label: "Doda"
    },
    {
      value: "Gulmarg",
      label: "Gulmarg"
    },
    {
      value: "Jammu",
      label: "Jammu"
    },
    {
      value: "Kalakot",
      label: "Kalakot"
    },
    {
      value: "Kargil",
      label: "Kargil"
    },
    {
      value: "Karnah",
      label: "Karnah"
    },
    {
      value: "Kathua",
      label: "Kathua"
    },
    {
      value: "Kishtwar",
      label: "Kishtwar"
    },
    {
      value: "Kulgam",
      label: "Kulgam"
    },
    {
      value: "Kupwara",
      label: "Kupwara"
    },
    {
      value: "Leh",
      label: "Leh"
    },
    {
      value: "Mahore",
      label: "Mahore"
    },
    {
      value: "Nagrota",
      label: "Nagrota"
    },
    {
      value: "Nobra",
      label: "Nobra"
    },
    {
      value: "Nowshera",
      label: "Nowshera"
    },
    {
      value: "Nyoma",
      label: "Nyoma"
    },
    {
      value: "Padam",
      label: "Padam"
    },
    {
      value: "Pahalgam",
      label: "Pahalgam"
    },
    {
      value: "Patnitop",
      label: "Patnitop"
    },
    {
      value: "Poonch",
      label: "Poonch"
    },
    {
      value: "Pulwama",
      label: "Pulwama"
    },
    {
      value: "Rajouri",
      label: "Rajouri"
    },
    {
      value: "Ramban",
      label: "Ramban"
    },
    {
      value: "Ramnagar",
      label: "Ramnagar"
    },
    {
      value: "Reasi",
      label: "Reasi"
    },
    {
      value: "Samba",
      label: "Samba"
    },
    {
      value: "Srinagar",
      label: "Srinagar"
    },
    {
      value: "Udhampur",
      label: "Udhampur"
    },
    {
      value: "Vaishno Devi",
      label: "Vaishno Devi"
    }
  ],
  "Jharkhand": [
    {
      value: "Bagodar",
      label: "Bagodar"
    },
    {
      value: "Baharagora",
      label: "Baharagora"
    },
    {
      value: "Balumath",
      label: "Balumath"
    },
    {
      value: "Barhi",
      label: "Barhi"
    },
    {
      value: "Barkagaon",
      label: "Barkagaon"
    },
    {
      value: "Barwadih",
      label: "Barwadih"
    },
    {
      value: "Basia",
      label: "Basia"
    },
    {
      value: "Bermo",
      label: "Bermo"
    },
    {
      value: "Bhandaria",
      label: "Bhandaria"
    },
    {
      value: "Bhawanathpur",
      label: "Bhawanathpur"
    },
    {
      value: "Bishrampur",
      label: "Bishrampur"
    },
    {
      value: "Bokaro",
      label: "Bokaro"
    },
    {
      value: "Bolwa",
      label: "Bolwa"
    },
    {
      value: "Bundu",
      label: "Bundu"
    },
    {
      value: "Chaibasa",
      label: "Chaibasa"
    },
    {
      value: "Chainpur",
      label: "Chainpur"
    },
    {
      value: "Chakardharpur",
      label: "Chakardharpur"
    },
    {
      value: "Chandil",
      label: "Chandil"
    },
    {
      value: "Chatra",
      label: "Chatra"
    },
    {
      value: "Chavparan",
      label: "Chavparan"
    },
    {
      value: "Daltonganj",
      label: "Daltonganj"
    },
    {
      value: "Deoghar",
      label: "Deoghar"
    },
    {
      value: "Dhanbad",
      label: "Dhanbad"
    },
    {
      value: "Dumka",
      label: "Dumka"
    },
    {
      value: "Dumri",
      label: "Dumri"
    },
    {
      value: "Garhwa",
      label: "Garhwa"
    },
    {
      value: "Garu",
      label: "Garu"
    },
    {
      value: "Ghaghra",
      label: "Ghaghra"
    },
    {
      value: "Ghatsila",
      label: "Ghatsila"
    },
    {
      value: "Giridih",
      label: "Giridih"
    },
    {
      value: "Godda",
      label: "Godda"
    },
    {
      value: "Gomia",
      label: "Gomia"
    },
    {
      value: "Govindpur",
      label: "Govindpur"
    },
    {
      value: "Gumla",
      label: "Gumla"
    },
    {
      value: "Hazaribagh",
      label: "Hazaribagh"
    },
    {
      value: "Hunterganj",
      label: "Hunterganj"
    },
    {
      value: "Ichak",
      label: "Ichak"
    },
    {
      value: "Itki",
      label: "Itki"
    },
    {
      value: "Jagarnathpur",
      label: "Jagarnathpur"
    },
    {
      value: "Jamshedpur",
      label: "Jamshedpur"
    },
    {
      value: "Jamtara",
      label: "Jamtara"
    },
    {
      value: "Japla",
      label: "Japla"
    },
    {
      value: "Jharmundi",
      label: "Jharmundi"
    },
    {
      value: "Jhinkpani",
      label: "Jhinkpani"
    },
    {
      value: "Jhumaritalaiya",
      label: "Jhumaritalaiya"
    },
    {
      value: "Kathikund",
      label: "Kathikund"
    },
    {
      value: "Kharsawa",
      label: "Kharsawa"
    },
    {
      value: "Khunti",
      label: "Khunti"
    },
    {
      value: "Koderma",
      label: "Koderma"
    },
    {
      value: "Kolebira",
      label: "Kolebira"
    },
    {
      value: "Latehar",
      label: "Latehar"
    },
    {
      value: "Lohardaga",
      label: "Lohardaga"
    },
    {
      value: "Madhupur",
      label: "Madhupur"
    },
    {
      value: "Mahagama",
      label: "Mahagama"
    },
    {
      value: "Maheshpur Raj",
      label: "Maheshpur Raj"
    },
    {
      value: "Mandar",
      label: "Mandar"
    },
    {
      value: "Mandu",
      label: "Mandu"
    },
    {
      value: "Manoharpur",
      label: "Manoharpur"
    },
    {
      value: "Muri",
      label: "Muri"
    },
    {
      value: "Nagarutatri",
      label: "Nagarutatri"
    },
    {
      value: "Nala",
      label: "Nala"
    },
    {
      value: "Noamundi",
      label: "Noamundi"
    },
    {
      value: "Pakur",
      label: "Pakur"
    },
    {
      value: "Palamu",
      label: "Palamu"
    },
    {
      value: "Palkot",
      label: "Palkot"
    },
    {
      value: "Patan",
      label: "Patan"
    },
    {
      value: "Rajdhanwar",
      label: "Rajdhanwar"
    },
    {
      value: "Rajmahal",
      label: "Rajmahal"
    },
    {
      value: "Ramgarh",
      label: "Ramgarh"
    },
    {
      value: "Ranchi",
      label: "Ranchi"
    },
    {
      value: "Sahibganj",
      label: "Sahibganj"
    },
    {
      value: "Saraikela",
      label: "Saraikela"
    },
    {
      value: "Simaria",
      label: "Simaria"
    },
    {
      value: "Simdega",
      label: "Simdega"
    },
    {
      value: "Singhbhum",
      label: "Singhbhum"
    },
    {
      value: "Tisri",
      label: "Tisri"
    },
    {
      value: "Torpa",
      label: "Torpa"
    }
  ],
  "Karnataka": [
    {
      value: "Afzalpur",
      label: "Afzalpur"
    },
    {
      value: "Ainapur",
      label: "Ainapur"
    },
    {
      value: "Aland",
      label: "Aland"
    },
    {
      value: "Alur",
      label: "Alur"
    },
    {
      value: "Anekal",
      label: "Anekal"
    },
    {
      value: "Ankola",
      label: "Ankola"
    },
    {
      value: "Arsikere",
      label: "Arsikere"
    },
    {
      value: "Athani",
      label: "Athani"
    },
    {
      value: "Aurad",
      label: "Aurad"
    },
    {
      value: "Bableshwar",
      label: "Bableshwar"
    },
    {
      value: "Badami",
      label: "Badami"
    },
    {
      value: "Bagalkot",
      label: "Bagalkot"
    },
    {
      value: "Bagepalli",
      label: "Bagepalli"
    },
    {
      value: "Bailhongal",
      label: "Bailhongal"
    },
    {
      value: "Bangalore",
      label: "Bangalore"
    },
    {
      value: "Bangalore Rural",
      label: "Bangalore Rural"
    },
    {
      value: "Bangarpet",
      label: "Bangarpet"
    },
    {
      value: "Bantwal",
      label: "Bantwal"
    },
    {
      value: "Basavakalyan",
      label: "Basavakalyan"
    },
    {
      value: "Basavanabagewadi",
      label: "Basavanabagewadi"
    },
    {
      value: "Basavapatna",
      label: "Basavapatna"
    },
    {
      value: "Belgaum",
      label: "Belgaum"
    },
    {
      value: "Bellary",
      label: "Bellary"
    },
    {
      value: "Belthangady",
      label: "Belthangady"
    },
    {
      value: "Belur",
      label: "Belur"
    },
    {
      value: "Bhadravati",
      label: "Bhadravati"
    },
    {
      value: "Bhalki",
      label: "Bhalki"
    },
    {
      value: "Bhatkal",
      label: "Bhatkal"
    },
    {
      value: "Bidar",
      label: "Bidar"
    },
    {
      value: "Bijapur",
      label: "Bijapur"
    },
    {
      value: "Biligi",
      label: "Biligi"
    },
    {
      value: "Chadchan",
      label: "Chadchan"
    },
    {
      value: "Challakere",
      label: "Challakere"
    },
    {
      value: "Chamrajnagar",
      label: "Chamrajnagar"
    },
    {
      value: "Channagiri",
      label: "Channagiri"
    },
    {
      value: "Channapatna",
      label: "Channapatna"
    },
    {
      value: "Channarayapatna",
      label: "Channarayapatna"
    },
    {
      value: "Chickmagalur",
      label: "Chickmagalur"
    },
    {
      value: "Chikballapur",
      label: "Chikballapur"
    },
    {
      value: "Chikkaballapur",
      label: "Chikkaballapur"
    },
    {
      value: "Chikkanayakanahalli",
      label: "Chikkanayakanahalli"
    },
    {
      value: "Chikkodi",
      label: "Chikkodi"
    },
    {
      value: "Chikmagalur",
      label: "Chikmagalur"
    },
    {
      value: "Chincholi",
      label: "Chincholi"
    },
    {
      value: "Chintamani",
      label: "Chintamani"
    },
    {
      value: "Chitradurga",
      label: "Chitradurga"
    },
    {
      value: "Chittapur",
      label: "Chittapur"
    },
    {
      value: "Cowdahalli",
      label: "Cowdahalli"
    },
    {
      value: "Davanagere",
      label: "Davanagere"
    },
    {
      value: "Deodurga",
      label: "Deodurga"
    },
    {
      value: "Devangere",
      label: "Devangere"
    },
    {
      value: "Devarahippargi",
      label: "Devarahippargi"
    },
    {
      value: "Dharwad",
      label: "Dharwad"
    },
    {
      value: "Doddaballapur",
      label: "Doddaballapur"
    },
    {
      value: "Gadag",
      label: "Gadag"
    },
    {
      value: "Gangavathi",
      label: "Gangavathi"
    },
    {
      value: "Gokak",
      label: "Gokak"
    },
    {
      value: "Gowribdanpur",
      label: "Gowribdanpur"
    },
    {
      value: "Gubbi",
      label: "Gubbi"
    },
    {
      value: "Gulbarga",
      label: "Gulbarga"
    },
    {
      value: "Gundlupet",
      label: "Gundlupet"
    },
    {
      value: "H.B.Halli",
      label: "H.B.Halli"
    },
    {
      value: "H.D. Kote",
      label: "H.D. Kote"
    },
    {
      value: "Haliyal",
      label: "Haliyal"
    },
    {
      value: "Hampi",
      label: "Hampi"
    },
    {
      value: "Hangal",
      label: "Hangal"
    },
    {
      value: "Harapanahalli",
      label: "Harapanahalli"
    },
    {
      value: "Hassan",
      label: "Hassan"
    },
    {
      value: "Haveri",
      label: "Haveri"
    },
    {
      value: "Hebri",
      label: "Hebri"
    },
    {
      value: "Hirekerur",
      label: "Hirekerur"
    },
    {
      value: "Hiriyur",
      label: "Hiriyur"
    },
    {
      value: "Holalkere",
      label: "Holalkere"
    },
    {
      value: "Holenarsipur",
      label: "Holenarsipur"
    },
    {
      value: "Honnali",
      label: "Honnali"
    },
    {
      value: "Honnavar",
      label: "Honnavar"
    },
    {
      value: "Hosadurga",
      label: "Hosadurga"
    },
    {
      value: "Hosakote",
      label: "Hosakote"
    },
    {
      value: "Hosanagara",
      label: "Hosanagara"
    },
    {
      value: "Hospet",
      label: "Hospet"
    },
    {
      value: "Hubli",
      label: "Hubli"
    },
    {
      value: "Hukkeri",
      label: "Hukkeri"
    },
    {
      value: "Humnabad",
      label: "Humnabad"
    },
    {
      value: "Hungund",
      label: "Hungund"
    },
    {
      value: "Hunsagi",
      label: "Hunsagi"
    },
    {
      value: "Hunsur",
      label: "Hunsur"
    },
    {
      value: "Huvinahadagali",
      label: "Huvinahadagali"
    },
    {
      value: "Indi",
      label: "Indi"
    },
    {
      value: "Jagalur",
      label: "Jagalur"
    },
    {
      value: "Jamkhandi",
      label: "Jamkhandi"
    },
    {
      value: "Jewargi",
      label: "Jewargi"
    },
    {
      value: "Joida",
      label: "Joida"
    },
    {
      value: "K.R. Nagar",
      label: "K.R. Nagar"
    },
    {
      value: "Kadur",
      label: "Kadur"
    },
    {
      value: "Kalghatagi",
      label: "Kalghatagi"
    },
    {
      value: "Kamalapur",
      label: "Kamalapur"
    },
    {
      value: "Kanakapura",
      label: "Kanakapura"
    },
    {
      value: "Kannada",
      label: "Kannada"
    },
    {
      value: "Kargal",
      label: "Kargal"
    },
    {
      value: "Karkala",
      label: "Karkala"
    },
    {
      value: "Karwar",
      label: "Karwar"
    },
    {
      value: "Khanapur",
      label: "Khanapur"
    },
    {
      value: "Kodagu",
      label: "Kodagu"
    },
    {
      value: "Kolar",
      label: "Kolar"
    },
    {
      value: "Kollegal",
      label: "Kollegal"
    },
    {
      value: "Koppa",
      label: "Koppa"
    },
    {
      value: "Koppal",
      label: "Koppal"
    },
    {
      value: "Koratageri",
      label: "Koratageri"
    },
    {
      value: "Krishnarajapet",
      label: "Krishnarajapet"
    },
    {
      value: "Kudligi",
      label: "Kudligi"
    },
    {
      value: "Kumta",
      label: "Kumta"
    },
    {
      value: "Kundapur",
      label: "Kundapur"
    },
    {
      value: "Kundgol",
      label: "Kundgol"
    },
    {
      value: "Kunigal",
      label: "Kunigal"
    },
    {
      value: "Kurugodu",
      label: "Kurugodu"
    },
    {
      value: "Kustagi",
      label: "Kustagi"
    },
    {
      value: "Lingsugur",
      label: "Lingsugur"
    },
    {
      value: "Madikeri",
      label: "Madikeri"
    },
    {
      value: "Madugiri",
      label: "Madugiri"
    },
    {
      value: "Malavalli",
      label: "Malavalli"
    },
    {
      value: "Malur",
      label: "Malur"
    },
    {
      value: "Mandya",
      label: "Mandya"
    },
    {
      value: "Mangalore",
      label: "Mangalore"
    },
    {
      value: "Manipal",
      label: "Manipal"
    },
    {
      value: "Manvi",
      label: "Manvi"
    },
    {
      value: "Mashal",
      label: "Mashal"
    },
    {
      value: "Molkalmuru",
      label: "Molkalmuru"
    },
    {
      value: "Mudalgi",
      label: "Mudalgi"
    },
    {
      value: "Muddebihal",
      label: "Muddebihal"
    },
    {
      value: "Mudhol",
      label: "Mudhol"
    },
    {
      value: "Mudigere",
      label: "Mudigere"
    },
    {
      value: "Mulbagal",
      label: "Mulbagal"
    },
    {
      value: "Mundagod",
      label: "Mundagod"
    },
    {
      value: "Mundargi",
      label: "Mundargi"
    },
    {
      value: "Murugod",
      label: "Murugod"
    },
    {
      value: "Mysore",
      label: "Mysore"
    },
    {
      value: "Nagamangala",
      label: "Nagamangala"
    },
    {
      value: "Nanjangud",
      label: "Nanjangud"
    },
    {
      value: "Nargund",
      label: "Nargund"
    },
    {
      value: "Narsimrajapur",
      label: "Narsimrajapur"
    },
    {
      value: "Navalgund",
      label: "Navalgund"
    },
    {
      value: "Nelamangala",
      label: "Nelamangala"
    },
    {
      value: "Nimburga",
      label: "Nimburga"
    },
    {
      value: "Pandavapura",
      label: "Pandavapura"
    },
    {
      value: "Pavagada",
      label: "Pavagada"
    },
    {
      value: "Puttur",
      label: "Puttur"
    },
    {
      value: "Raibag",
      label: "Raibag"
    },
    {
      value: "Raichur",
      label: "Raichur"
    },
    {
      value: "Ramdurg",
      label: "Ramdurg"
    },
    {
      value: "Ranebennur",
      label: "Ranebennur"
    },
    {
      value: "Ron",
      label: "Ron"
    },
    {
      value: "Sagar",
      label: "Sagar"
    },
    {
      value: "Sakleshpur",
      label: "Sakleshpur"
    },
    {
      value: "Salkani",
      label: "Salkani"
    },
    {
      value: "Sandur",
      label: "Sandur"
    },
    {
      value: "Saundatti",
      label: "Saundatti"
    },
    {
      value: "Savanur",
      label: "Savanur"
    },
    {
      value: "Sedam",
      label: "Sedam"
    },
    {
      value: "Shahapur",
      label: "Shahapur"
    },
    {
      value: "Shankarnarayana",
      label: "Shankarnarayana"
    },
    {
      value: "Shikaripura",
      label: "Shikaripura"
    },
    {
      value: "Shimoga",
      label: "Shimoga"
    },
    {
      value: "Shirahatti",
      label: "Shirahatti"
    },
    {
      value: "Shorapur",
      label: "Shorapur"
    },
    {
      value: "Siddapur",
      label: "Siddapur"
    },
    {
      value: "Sidlaghatta",
      label: "Sidlaghatta"
    },
    {
      value: "Sindagi",
      label: "Sindagi"
    },
    {
      value: "Sindhanur",
      label: "Sindhanur"
    },
    {
      value: "Sira",
      label: "Sira"
    },
    {
      value: "Sirsi",
      label: "Sirsi"
    },
    {
      value: "Siruguppa",
      label: "Siruguppa"
    },
    {
      value: "Somwarpet",
      label: "Somwarpet"
    },
    {
      value: "Sorab",
      label: "Sorab"
    },
    {
      value: "Sringeri",
      label: "Sringeri"
    },
    {
      value: "Sriniwaspur",
      label: "Sriniwaspur"
    },
    {
      value: "Srirangapatna",
      label: "Srirangapatna"
    },
    {
      value: "Sullia",
      label: "Sullia"
    },
    {
      value: "T. Narsipur",
      label: "T. Narsipur"
    },
    {
      value: "Tallak",
      label: "Tallak"
    },
    {
      value: "Tarikere",
      label: "Tarikere"
    },
    {
      value: "Telgi",
      label: "Telgi"
    },
    {
      value: "Thirthahalli",
      label: "Thirthahalli"
    },
    {
      value: "Tiptur",
      label: "Tiptur"
    },
    {
      value: "Tumkur",
      label: "Tumkur"
    },
    {
      value: "Turuvekere",
      label: "Turuvekere"
    },
    {
      value: "Udupi",
      label: "Udupi"
    },
    {
      value: "Virajpet",
      label: "Virajpet"
    },
    {
      value: "Wadi",
      label: "Wadi"
    },
    {
      value: "Yadgiri",
      label: "Yadgiri"
    },
    {
      value: "Yelburga",
      label: "Yelburga"
    },
    {
      value: "Yellapur",
      label: "Yellapur"
    }
  ],
  "Kerala": [
    {
      value: "Adimaly",
      label: "Adimaly"
    },
    {
      value: "Adoor",
      label: "Adoor"
    },
    {
      value: "Agathy",
      label: "Agathy"
    },
    {
      value: "Alappuzha",
      label: "Alappuzha"
    },
    {
      value: "Alathur",
      label: "Alathur"
    },
    {
      value: "Alleppey",
      label: "Alleppey"
    },
    {
      value: "Alwaye",
      label: "Alwaye"
    },
    {
      value: "Amini",
      label: "Amini"
    },
    {
      value: "Androth",
      label: "Androth"
    },
    {
      value: "Attingal",
      label: "Attingal"
    },
    {
      value: "Badagara",
      label: "Badagara"
    },
    {
      value: "Bitra",
      label: "Bitra"
    },
    {
      value: "Calicut",
      label: "Calicut"
    },
    {
      value: "Cannanore",
      label: "Cannanore"
    },
    {
      value: "Chetlet",
      label: "Chetlet"
    },
    {
      value: "Ernakulam",
      label: "Ernakulam"
    },
    {
      value: "Idukki",
      label: "Idukki"
    },
    {
      value: "Irinjalakuda",
      label: "Irinjalakuda"
    },
    {
      value: "Kadamath",
      label: "Kadamath"
    },
    {
      value: "Kalpeni",
      label: "Kalpeni"
    },
    {
      value: "Kalpetta",
      label: "Kalpetta"
    },
    {
      value: "Kanhangad",
      label: "Kanhangad"
    },
    {
      value: "Kanjirapally",
      label: "Kanjirapally"
    },
    {
      value: "Kannur",
      label: "Kannur"
    },
    {
      value: "Karungapally",
      label: "Karungapally"
    },
    {
      value: "Kasargode",
      label: "Kasargode"
    },
    {
      value: "Kavarathy",
      label: "Kavarathy"
    },
    {
      value: "Kiltan",
      label: "Kiltan"
    },
    {
      value: "Kochi",
      label: "Kochi"
    },
    {
      value: "Koduvayur",
      label: "Koduvayur"
    },
    {
      value: "Kollam",
      label: "Kollam"
    },
    {
      value: "Kottayam",
      label: "Kottayam"
    },
    {
      value: "Kovalam",
      label: "Kovalam"
    },
    {
      value: "Kozhikode",
      label: "Kozhikode"
    },
    {
      value: "Kunnamkulam",
      label: "Kunnamkulam"
    },
    {
      value: "Malappuram",
      label: "Malappuram"
    },
    {
      value: "Mananthodi",
      label: "Mananthodi"
    },
    {
      value: "Manjeri",
      label: "Manjeri"
    },
    {
      value: "Mannarghat",
      label: "Mannarghat"
    },
    {
      value: "Mavelikkara",
      label: "Mavelikkara"
    },
    {
      value: "Minicoy",
      label: "Minicoy"
    },
    {
      value: "Munnar",
      label: "Munnar"
    },
    {
      value: "Muvattupuzha",
      label: "Muvattupuzha"
    },
    {
      value: "Nedumandad",
      label: "Nedumandad"
    },
    {
      value: "Nedumgandam",
      label: "Nedumgandam"
    },
    {
      value: "Nilambur",
      label: "Nilambur"
    },
    {
      value: "Palai",
      label: "Palai"
    },
    {
      value: "Palakkad",
      label: "Palakkad"
    },
    {
      value: "Palghat",
      label: "Palghat"
    },
    {
      value: "Pathaanamthitta",
      label: "Pathaanamthitta"
    },
    {
      value: "Pathanamthitta",
      label: "Pathanamthitta"
    },
    {
      value: "Payyanur",
      label: "Payyanur"
    },
    {
      value: "Peermedu",
      label: "Peermedu"
    },
    {
      value: "Perinthalmanna",
      label: "Perinthalmanna"
    },
    {
      value: "Perumbavoor",
      label: "Perumbavoor"
    },
    {
      value: "Punalur",
      label: "Punalur"
    },
    {
      value: "Quilon",
      label: "Quilon"
    },
    {
      value: "Ranni",
      label: "Ranni"
    },
    {
      value: "Shertallai",
      label: "Shertallai"
    },
    {
      value: "Shoranur",
      label: "Shoranur"
    },
    {
      value: "Taliparamba",
      label: "Taliparamba"
    },
    {
      value: "Tellicherry",
      label: "Tellicherry"
    },
    {
      value: "Thiruvananthapuram",
      label: "Thiruvananthapuram"
    },
    {
      value: "Thodupuzha",
      label: "Thodupuzha"
    },
    {
      value: "Thrissur",
      label: "Thrissur"
    },
    {
      value: "Tirur",
      label: "Tirur"
    },
    {
      value: "Tiruvalla",
      label: "Tiruvalla"
    },
    {
      value: "Trichur",
      label: "Trichur"
    },
    {
      value: "Trivandrum",
      label: "Trivandrum"
    },
    {
      value: "Uppala",
      label: "Uppala"
    },
    {
      value: "Vadakkanchery",
      label: "Vadakkanchery"
    },
    {
      value: "Vikom",
      label: "Vikom"
    },
    {
      value: "Wayanad",
      label: "Wayanad"
    }
  ],
  "Lakshadweep": [
    {
      value: "Agatti Island",
      label: "Agatti Island"
    },
    {
      value: "Bingaram Island",
      label: "Bingaram Island"
    },
    {
      value: "Bitra Island",
      label: "Bitra Island"
    },
    {
      value: "Chetlat Island",
      label: "Chetlat Island"
    },
    {
      value: "Kadmat Island",
      label: "Kadmat Island"
    },
    {
      value: "Kalpeni Island",
      label: "Kalpeni Island"
    },
    {
      value: "Kavaratti Island",
      label: "Kavaratti Island"
    },
    {
      value: "Kiltan Island",
      label: "Kiltan Island"
    },
    {
      value: "Lakshadweep Sea",
      label: "Lakshadweep Sea"
    },
    {
      value: "Minicoy Island",
      label: "Minicoy Island"
    },
    {
      value: "North Island",
      label: "North Island"
    },
    {
      value: "South Island",
      label: "South Island"
    }
  ],
  "Madhya Pradesh": [
    {
      value: "Agar",
      label: "Agar"
    },
    {
      value: "Ajaigarh",
      label: "Ajaigarh"
    },
    {
      value: "Alirajpur",
      label: "Alirajpur"
    },
    {
      value: "Amarpatan",
      label: "Amarpatan"
    },
    {
      value: "Amarwada",
      label: "Amarwada"
    },
    {
      value: "Ambah",
      label: "Ambah"
    },
    {
      value: "Anuppur",
      label: "Anuppur"
    },
    {
      value: "Arone",
      label: "Arone"
    },
    {
      value: "Ashoknagar",
      label: "Ashoknagar"
    },
    {
      value: "Ashta",
      label: "Ashta"
    },
    {
      value: "Atner",
      label: "Atner"
    },
    {
      value: "Babaichichli",
      label: "Babaichichli"
    },
    {
      value: "Badamalhera",
      label: "Badamalhera"
    },
    {
      value: "Badarwsas",
      label: "Badarwsas"
    },
    {
      value: "Badnagar",
      label: "Badnagar"
    },
    {
      value: "Badnawar",
      label: "Badnawar"
    },
    {
      value: "Badwani",
      label: "Badwani"
    },
    {
      value: "Bagli",
      label: "Bagli"
    },
    {
      value: "Baihar",
      label: "Baihar"
    },
    {
      value: "Balaghat",
      label: "Balaghat"
    },
    {
      value: "Baldeogarh",
      label: "Baldeogarh"
    },
    {
      value: "Baldi",
      label: "Baldi"
    },
    {
      value: "Bamori",
      label: "Bamori"
    },
    {
      value: "Banda",
      label: "Banda"
    },
    {
      value: "Bandhavgarh",
      label: "Bandhavgarh"
    },
    {
      value: "Bareli",
      label: "Bareli"
    },
    {
      value: "Baroda",
      label: "Baroda"
    },
    {
      value: "Barwaha",
      label: "Barwaha"
    },
    {
      value: "Barwani",
      label: "Barwani"
    },
    {
      value: "Batkakhapa",
      label: "Batkakhapa"
    },
    {
      value: "Begamganj",
      label: "Begamganj"
    },
    {
      value: "Beohari",
      label: "Beohari"
    },
    {
      value: "Berasia",
      label: "Berasia"
    },
    {
      value: "Berchha",
      label: "Berchha"
    },
    {
      value: "Betul",
      label: "Betul"
    },
    {
      value: "Bhainsdehi",
      label: "Bhainsdehi"
    },
    {
      value: "Bhander",
      label: "Bhander"
    },
    {
      value: "Bhanpura",
      label: "Bhanpura"
    },
    {
      value: "Bhikangaon",
      label: "Bhikangaon"
    },
    {
      value: "Bhimpur",
      label: "Bhimpur"
    },
    {
      value: "Bhind",
      label: "Bhind"
    },
    {
      value: "Bhitarwar",
      label: "Bhitarwar"
    },
    {
      value: "Bhopal",
      label: "Bhopal"
    },
    {
      value: "Biaora",
      label: "Biaora"
    },
    {
      value: "Bijadandi",
      label: "Bijadandi"
    },
    {
      value: "Bijawar",
      label: "Bijawar"
    },
    {
      value: "Bijaypur",
      label: "Bijaypur"
    },
    {
      value: "Bina",
      label: "Bina"
    },
    {
      value: "Birsa",
      label: "Birsa"
    },
    {
      value: "Birsinghpur",
      label: "Birsinghpur"
    },
    {
      value: "Budhni",
      label: "Budhni"
    },
    {
      value: "Burhanpur",
      label: "Burhanpur"
    },
    {
      value: "Buxwaha",
      label: "Buxwaha"
    },
    {
      value: "Chachaura",
      label: "Chachaura"
    },
    {
      value: "Chanderi",
      label: "Chanderi"
    },
    {
      value: "Chaurai",
      label: "Chaurai"
    },
    {
      value: "Chhapara",
      label: "Chhapara"
    },
    {
      value: "Chhatarpur",
      label: "Chhatarpur"
    },
    {
      value: "Chhindwara",
      label: "Chhindwara"
    },
    {
      value: "Chicholi",
      label: "Chicholi"
    },
    {
      value: "Chitrangi",
      label: "Chitrangi"
    },
    {
      value: "Churhat",
      label: "Churhat"
    },
    {
      value: "Dabra",
      label: "Dabra"
    },
    {
      value: "Damoh",
      label: "Damoh"
    },
    {
      value: "Datia",
      label: "Datia"
    },
    {
      value: "Deori",
      label: "Deori"
    },
    {
      value: "Deosar",
      label: "Deosar"
    },
    {
      value: "Depalpur",
      label: "Depalpur"
    },
    {
      value: "Dewas",
      label: "Dewas"
    },
    {
      value: "Dhar",
      label: "Dhar"
    },
    {
      value: "Dharampuri",
      label: "Dharampuri"
    },
    {
      value: "Dindori",
      label: "Dindori"
    },
    {
      value: "Gadarwara",
      label: "Gadarwara"
    },
    {
      value: "Gairatganj",
      label: "Gairatganj"
    },
    {
      value: "Ganjbasoda",
      label: "Ganjbasoda"
    },
    {
      value: "Garoth",
      label: "Garoth"
    },
    {
      value: "Ghansour",
      label: "Ghansour"
    },
    {
      value: "Ghatia",
      label: "Ghatia"
    },
    {
      value: "Ghatigaon",
      label: "Ghatigaon"
    },
    {
      value: "Ghorandogri",
      label: "Ghorandogri"
    },
    {
      value: "Ghughari",
      label: "Ghughari"
    },
    {
      value: "Gogaon",
      label: "Gogaon"
    },
    {
      value: "Gohad",
      label: "Gohad"
    },
    {
      value: "Goharganj",
      label: "Goharganj"
    },
    {
      value: "Gopalganj",
      label: "Gopalganj"
    },
    {
      value: "Gotegaon",
      label: "Gotegaon"
    },
    {
      value: "Gourihar",
      label: "Gourihar"
    },
    {
      value: "Guna",
      label: "Guna"
    },
    {
      value: "Gunnore",
      label: "Gunnore"
    },
    {
      value: "Gwalior",
      label: "Gwalior"
    },
    {
      value: "Gyraspur",
      label: "Gyraspur"
    },
    {
      value: "Hanumana",
      label: "Hanumana"
    },
    {
      value: "Harda",
      label: "Harda"
    },
    {
      value: "Harrai",
      label: "Harrai"
    },
    {
      value: "Harsud",
      label: "Harsud"
    },
    {
      value: "Hatta",
      label: "Hatta"
    },
    {
      value: "Hoshangabad",
      label: "Hoshangabad"
    },
    {
      value: "Ichhawar",
      label: "Ichhawar"
    },
    {
      value: "Indore",
      label: "Indore"
    },
    {
      value: "Isagarh",
      label: "Isagarh"
    },
    {
      value: "Itarsi",
      label: "Itarsi"
    },
    {
      value: "Jabalpur",
      label: "Jabalpur"
    },
    {
      value: "Jabera",
      label: "Jabera"
    },
    {
      value: "Jagdalpur",
      label: "Jagdalpur"
    },
    {
      value: "Jaisinghnagar",
      label: "Jaisinghnagar"
    },
    {
      value: "Jaithari",
      label: "Jaithari"
    },
    {
      value: "Jaitpur",
      label: "Jaitpur"
    },
    {
      value: "Jaitwara",
      label: "Jaitwara"
    },
    {
      value: "Jamai",
      label: "Jamai"
    },
    {
      value: "Jaora",
      label: "Jaora"
    },
    {
      value: "Jatara",
      label: "Jatara"
    },
    {
      value: "Jawad",
      label: "Jawad"
    },
    {
      value: "Jhabua",
      label: "Jhabua"
    },
    {
      value: "Jobat",
      label: "Jobat"
    },
    {
      value: "Jora",
      label: "Jora"
    },
    {
      value: "Kakaiya",
      label: "Kakaiya"
    },
    {
      value: "Kannod",
      label: "Kannod"
    },
    {
      value: "Kannodi",
      label: "Kannodi"
    },
    {
      value: "Karanjia",
      label: "Karanjia"
    },
    {
      value: "Kareli",
      label: "Kareli"
    },
    {
      value: "Karera",
      label: "Karera"
    },
    {
      value: "Karhal",
      label: "Karhal"
    },
    {
      value: "Karpa",
      label: "Karpa"
    },
    {
      value: "Kasrawad",
      label: "Kasrawad"
    },
    {
      value: "Katangi",
      label: "Katangi"
    },
    {
      value: "Katni",
      label: "Katni"
    },
    {
      value: "Keolari",
      label: "Keolari"
    },
    {
      value: "Khachrod",
      label: "Khachrod"
    },
    {
      value: "Khajuraho",
      label: "Khajuraho"
    },
    {
      value: "Khakner",
      label: "Khakner"
    },
    {
      value: "Khalwa",
      label: "Khalwa"
    },
    {
      value: "Khandwa",
      label: "Khandwa"
    },
    {
      value: "Khaniadhana",
      label: "Khaniadhana"
    },
    {
      value: "Khargone",
      label: "Khargone"
    },
    {
      value: "Khategaon",
      label: "Khategaon"
    },
    {
      value: "Khetia",
      label: "Khetia"
    },
    {
      value: "Khilchipur",
      label: "Khilchipur"
    },
    {
      value: "Khirkiya",
      label: "Khirkiya"
    },
    {
      value: "Khurai",
      label: "Khurai"
    },
    {
      value: "Kolaras",
      label: "Kolaras"
    },
    {
      value: "Kotma",
      label: "Kotma"
    },
    {
      value: "Kukshi",
      label: "Kukshi"
    },
    {
      value: "Kundam",
      label: "Kundam"
    },
    {
      value: "Kurwai",
      label: "Kurwai"
    },
    {
      value: "Kusmi",
      label: "Kusmi"
    },
    {
      value: "Laher",
      label: "Laher"
    },
    {
      value: "Lakhnadon",
      label: "Lakhnadon"
    },
    {
      value: "Lamta",
      label: "Lamta"
    },
    {
      value: "Lanji",
      label: "Lanji"
    },
    {
      value: "Lateri",
      label: "Lateri"
    },
    {
      value: "Laundi",
      label: "Laundi"
    },
    {
      value: "Maheshwar",
      label: "Maheshwar"
    },
    {
      value: "Mahidpurcity",
      label: "Mahidpurcity"
    },
    {
      value: "Maihar",
      label: "Maihar"
    },
    {
      value: "Majhagwan",
      label: "Majhagwan"
    },
    {
      value: "Majholi",
      label: "Majholi"
    },
    {
      value: "Malhargarh",
      label: "Malhargarh"
    },
    {
      value: "Manasa",
      label: "Manasa"
    },
    {
      value: "Manawar",
      label: "Manawar"
    },
    {
      value: "Mandla",
      label: "Mandla"
    },
    {
      value: "Mandsaur",
      label: "Mandsaur"
    },
    {
      value: "Manpur",
      label: "Manpur"
    },
    {
      value: "Mauganj",
      label: "Mauganj"
    },
    {
      value: "Mawai",
      label: "Mawai"
    },
    {
      value: "Mehgaon",
      label: "Mehgaon"
    },
    {
      value: "Mhow",
      label: "Mhow"
    },
    {
      value: "Morena",
      label: "Morena"
    },
    {
      value: "Multai",
      label: "Multai"
    },
    {
      value: "Mungaoli",
      label: "Mungaoli"
    },
    {
      value: "Nagod",
      label: "Nagod"
    },
    {
      value: "Nainpur",
      label: "Nainpur"
    },
    {
      value: "Narsingarh",
      label: "Narsingarh"
    },
    {
      value: "Narsinghpur",
      label: "Narsinghpur"
    },
    {
      value: "Narwar",
      label: "Narwar"
    },
    {
      value: "Nasrullaganj",
      label: "Nasrullaganj"
    },
    {
      value: "Nateran",
      label: "Nateran"
    },
    {
      value: "Neemuch",
      label: "Neemuch"
    },
    {
      value: "Niwari",
      label: "Niwari"
    },
    {
      value: "Niwas",
      label: "Niwas"
    },
    {
      value: "Nowgaon",
      label: "Nowgaon"
    },
    {
      value: "Pachmarhi",
      label: "Pachmarhi"
    },
    {
      value: "Pandhana",
      label: "Pandhana"
    },
    {
      value: "Pandhurna",
      label: "Pandhurna"
    },
    {
      value: "Panna",
      label: "Panna"
    },
    {
      value: "Parasia",
      label: "Parasia"
    },
    {
      value: "Patan",
      label: "Patan"
    },
    {
      value: "Patera",
      label: "Patera"
    },
    {
      value: "Patharia",
      label: "Patharia"
    },
    {
      value: "Pawai",
      label: "Pawai"
    },
    {
      value: "Petlawad",
      label: "Petlawad"
    },
    {
      value: "Pichhore",
      label: "Pichhore"
    },
    {
      value: "Piparia",
      label: "Piparia"
    },
    {
      value: "Pohari",
      label: "Pohari"
    },
    {
      value: "Prabhapattan",
      label: "Prabhapattan"
    },
    {
      value: "Punasa",
      label: "Punasa"
    },
    {
      value: "Pushprajgarh",
      label: "Pushprajgarh"
    },
    {
      value: "Raghogarh",
      label: "Raghogarh"
    },
    {
      value: "Raghunathpur",
      label: "Raghunathpur"
    },
    {
      value: "Rahatgarh",
      label: "Rahatgarh"
    },
    {
      value: "Raisen",
      label: "Raisen"
    },
    {
      value: "Rajgarh",
      label: "Rajgarh"
    },
    {
      value: "Rajpur",
      label: "Rajpur"
    },
    {
      value: "Ratlam",
      label: "Ratlam"
    },
    {
      value: "Rehli",
      label: "Rehli"
    },
    {
      value: "Rewa",
      label: "Rewa"
    },
    {
      value: "Sabalgarh",
      label: "Sabalgarh"
    },
    {
      value: "Sagar",
      label: "Sagar"
    },
    {
      value: "Sailana",
      label: "Sailana"
    },
    {
      value: "Sanwer",
      label: "Sanwer"
    },
    {
      value: "Sarangpur",
      label: "Sarangpur"
    },
    {
      value: "Sardarpur",
      label: "Sardarpur"
    },
    {
      value: "Satna",
      label: "Satna"
    },
    {
      value: "Saunsar",
      label: "Saunsar"
    },
    {
      value: "Sehore",
      label: "Sehore"
    },
    {
      value: "Sendhwa",
      label: "Sendhwa"
    },
    {
      value: "Seondha",
      label: "Seondha"
    },
    {
      value: "Seoni",
      label: "Seoni"
    },
    {
      value: "Seonimalwa",
      label: "Seonimalwa"
    },
    {
      value: "Shahdol",
      label: "Shahdol"
    },
    {
      value: "Shahnagar",
      label: "Shahnagar"
    },
    {
      value: "Shahpur",
      label: "Shahpur"
    },
    {
      value: "Shajapur",
      label: "Shajapur"
    },
    {
      value: "Sheopur",
      label: "Sheopur"
    },
    {
      value: "Sheopurkalan",
      label: "Sheopurkalan"
    },
    {
      value: "Shivpuri",
      label: "Shivpuri"
    },
    {
      value: "Shujalpur",
      label: "Shujalpur"
    },
    {
      value: "Sidhi",
      label: "Sidhi"
    },
    {
      value: "Sihora",
      label: "Sihora"
    },
    {
      value: "Silwani",
      label: "Silwani"
    },
    {
      value: "Singrauli",
      label: "Singrauli"
    },
    {
      value: "Sirmour",
      label: "Sirmour"
    },
    {
      value: "Sironj",
      label: "Sironj"
    },
    {
      value: "Sitamau",
      label: "Sitamau"
    },
    {
      value: "Sohagpur",
      label: "Sohagpur"
    },
    {
      value: "Sondhwa",
      label: "Sondhwa"
    },
    {
      value: "Sonkatch",
      label: "Sonkatch"
    },
    {
      value: "Susner",
      label: "Susner"
    },
    {
      value: "Tamia",
      label: "Tamia"
    },
    {
      value: "Tarana",
      label: "Tarana"
    },
    {
      value: "Tendukheda",
      label: "Tendukheda"
    },
    {
      value: "Teonthar",
      label: "Teonthar"
    },
    {
      value: "Thandla",
      label: "Thandla"
    },
    {
      value: "Tikamgarh",
      label: "Tikamgarh"
    },
    {
      value: "Timarani",
      label: "Timarani"
    },
    {
      value: "Udaipura",
      label: "Udaipura"
    },
    {
      value: "Ujjain",
      label: "Ujjain"
    },
    {
      value: "Umaria",
      label: "Umaria"
    },
    {
      value: "Umariapan",
      label: "Umariapan"
    },
    {
      value: "Vidisha",
      label: "Vidisha"
    },
    {
      value: "Vijayraghogarh",
      label: "Vijayraghogarh"
    },
    {
      value: "Waraseoni",
      label: "Waraseoni"
    },
    {
      value: "Zhirnia",
      label: "Zhirnia"
    }
  ],
  "Maharashtra": [
    {
      value: "Achalpur",
      label: "Achalpur"
    },
    {
      value: "Aheri",
      label: "Aheri"
    },
    {
      value: "Ahmednagar",
      label: "Ahmednagar"
    },
    {
      value: "Ahmedpur",
      label: "Ahmedpur"
    },
    {
      value: "Ajara",
      label: "Ajara"
    },
    {
      value: "Akkalkot",
      label: "Akkalkot"
    },
    {
      value: "Akola",
      label: "Akola"
    },
    {
      value: "Akole",
      label: "Akole"
    },
    {
      value: "Akot",
      label: "Akot"
    },
    {
      value: "Alibagh",
      label: "Alibagh"
    },
    {
      value: "Amagaon",
      label: "Amagaon"
    },
    {
      value: "Amalner",
      label: "Amalner"
    },
    {
      value: "Ambad",
      label: "Ambad"
    },
    {
      value: "Ambejogai",
      label: "Ambejogai"
    },
    {
      value: "Amravati",
      label: "Amravati"
    },
    {
      value: "Arjuni Merogaon",
      label: "Arjuni Merogaon"
    },
    {
      value: "Arvi",
      label: "Arvi"
    },
    {
      value: "Ashti",
      label: "Ashti"
    },
    {
      value: "Atpadi",
      label: "Atpadi"
    },
    {
      value: "Aurangabad",
      label: "Aurangabad"
    },
    {
      value: "Ausa",
      label: "Ausa"
    },
    {
      value: "Babhulgaon",
      label: "Babhulgaon"
    },
    {
      value: "Balapur",
      label: "Balapur"
    },
    {
      value: "Baramati",
      label: "Baramati"
    },
    {
      value: "Barshi Takli",
      label: "Barshi Takli"
    },
    {
      value: "Barsi",
      label: "Barsi"
    },
    {
      value: "Basmatnagar",
      label: "Basmatnagar"
    },
    {
      value: "Bassein",
      label: "Bassein"
    },
    {
      value: "Beed",
      label: "Beed"
    },
    {
      value: "Bhadrawati",
      label: "Bhadrawati"
    },
    {
      value: "Bhamregadh",
      label: "Bhamregadh"
    },
    {
      value: "Bhandara",
      label: "Bhandara"
    },
    {
      value: "Bhir",
      label: "Bhir"
    },
    {
      value: "Bhiwandi",
      label: "Bhiwandi"
    },
    {
      value: "Bhiwapur",
      label: "Bhiwapur"
    },
    {
      value: "Bhokar",
      label: "Bhokar"
    },
    {
      value: "Bhokardan",
      label: "Bhokardan"
    },
    {
      value: "Bhoom",
      label: "Bhoom"
    },
    {
      value: "Bhor",
      label: "Bhor"
    },
    {
      value: "Bhudargad",
      label: "Bhudargad"
    },
    {
      value: "Bhusawal",
      label: "Bhusawal"
    },
    {
      value: "Billoli",
      label: "Billoli"
    },
    {
      value: "Brahmapuri",
      label: "Brahmapuri"
    },
    {
      value: "Buldhana",
      label: "Buldhana"
    },
    {
      value: "Butibori",
      label: "Butibori"
    },
    {
      value: "Chalisgaon",
      label: "Chalisgaon"
    },
    {
      value: "Chamorshi",
      label: "Chamorshi"
    },
    {
      value: "Chandgad",
      label: "Chandgad"
    },
    {
      value: "Chandrapur",
      label: "Chandrapur"
    },
    {
      value: "Chandur",
      label: "Chandur"
    },
    {
      value: "Chanwad",
      label: "Chanwad"
    },
    {
      value: "Chhikaldara",
      label: "Chhikaldara"
    },
    {
      value: "Chikhali",
      label: "Chikhali"
    },
    {
      value: "Chinchwad",
      label: "Chinchwad"
    },
    {
      value: "Chiplun",
      label: "Chiplun"
    },
    {
      value: "Chopda",
      label: "Chopda"
    },
    {
      value: "Chumur",
      label: "Chumur"
    },
    {
      value: "Dahanu",
      label: "Dahanu"
    },
    {
      value: "Dapoli",
      label: "Dapoli"
    },
    {
      value: "Darwaha",
      label: "Darwaha"
    },
    {
      value: "Daryapur",
      label: "Daryapur"
    },
    {
      value: "Daund",
      label: "Daund"
    },
    {
      value: "Degloor",
      label: "Degloor"
    },
    {
      value: "Delhi Tanda",
      label: "Delhi Tanda"
    },
    {
      value: "Deogad",
      label: "Deogad"
    },
    {
      value: "Deolgaonraja",
      label: "Deolgaonraja"
    },
    {
      value: "Deori",
      label: "Deori"
    },
    {
      value: "Desaiganj",
      label: "Desaiganj"
    },
    {
      value: "Dhadgaon",
      label: "Dhadgaon"
    },
    {
      value: "Dhanora",
      label: "Dhanora"
    },
    {
      value: "Dharani",
      label: "Dharani"
    },
    {
      value: "Dhiwadi",
      label: "Dhiwadi"
    },
    {
      value: "Dhule",
      label: "Dhule"
    },
    {
      value: "Dhulia",
      label: "Dhulia"
    },
    {
      value: "Digras",
      label: "Digras"
    },
    {
      value: "Dindori",
      label: "Dindori"
    },
    {
      value: "Edalabad",
      label: "Edalabad"
    },
    {
      value: "Erandul",
      label: "Erandul"
    },
    {
      value: "Etapalli",
      label: "Etapalli"
    },
    {
      value: "Gadhchiroli",
      label: "Gadhchiroli"
    },
    {
      value: "Gadhinglaj",
      label: "Gadhinglaj"
    },
    {
      value: "Gaganbavada",
      label: "Gaganbavada"
    },
    {
      value: "Gangakhed",
      label: "Gangakhed"
    },
    {
      value: "Gangapur",
      label: "Gangapur"
    },
    {
      value: "Gevrai",
      label: "Gevrai"
    },
    {
      value: "Ghatanji",
      label: "Ghatanji"
    },
    {
      value: "Golegaon",
      label: "Golegaon"
    },
    {
      value: "Gondia",
      label: "Gondia"
    },
    {
      value: "Gondpipri",
      label: "Gondpipri"
    },
    {
      value: "Goregaon",
      label: "Goregaon"
    },
    {
      value: "Guhagar",
      label: "Guhagar"
    },
    {
      value: "Hadgaon",
      label: "Hadgaon"
    },
    {
      value: "Hatkangale",
      label: "Hatkangale"
    },
    {
      value: "Hinganghat",
      label: "Hinganghat"
    },
    {
      value: "Hingoli",
      label: "Hingoli"
    },
    {
      value: "Hingua",
      label: "Hingua"
    },
    {
      value: "Igatpuri",
      label: "Igatpuri"
    },
    {
      value: "Indapur",
      label: "Indapur"
    },
    {
      value: "Islampur",
      label: "Islampur"
    },
    {
      value: "Jalgaon",
      label: "Jalgaon"
    },
    {
      value: "Jalna",
      label: "Jalna"
    },
    {
      value: "Jamkhed",
      label: "Jamkhed"
    },
    {
      value: "Jamner",
      label: "Jamner"
    },
    {
      value: "Jath",
      label: "Jath"
    },
    {
      value: "Jawahar",
      label: "Jawahar"
    },
    {
      value: "Jintdor",
      label: "Jintdor"
    },
    {
      value: "Junnar",
      label: "Junnar"
    },
    {
      value: "Kagal",
      label: "Kagal"
    },
    {
      value: "Kaij",
      label: "Kaij"
    },
    {
      value: "Kalamb",
      label: "Kalamb"
    },
    {
      value: "Kalamnuri",
      label: "Kalamnuri"
    },
    {
      value: "Kallam",
      label: "Kallam"
    },
    {
      value: "Kalmeshwar",
      label: "Kalmeshwar"
    },
    {
      value: "Kalwan",
      label: "Kalwan"
    },
    {
      value: "Kalyan",
      label: "Kalyan"
    },
    {
      value: "Kamptee",
      label: "Kamptee"
    },
    {
      value: "Kandhar",
      label: "Kandhar"
    },
    {
      value: "Kankavali",
      label: "Kankavali"
    },
    {
      value: "Kannad",
      label: "Kannad"
    },
    {
      value: "Karad",
      label: "Karad"
    },
    {
      value: "Karjat",
      label: "Karjat"
    },
    {
      value: "Karmala",
      label: "Karmala"
    },
    {
      value: "Katol",
      label: "Katol"
    },
    {
      value: "Kavathemankal",
      label: "Kavathemankal"
    },
    {
      value: "Kedgaon",
      label: "Kedgaon"
    },
    {
      value: "Khadakwasala",
      label: "Khadakwasala"
    },
    {
      value: "Khamgaon",
      label: "Khamgaon"
    },
    {
      value: "Khed",
      label: "Khed"
    },
    {
      value: "Khopoli",
      label: "Khopoli"
    },
    {
      value: "Khultabad",
      label: "Khultabad"
    },
    {
      value: "Kinwat",
      label: "Kinwat"
    },
    {
      value: "Kolhapur",
      label: "Kolhapur"
    },
    {
      value: "Kopargaon",
      label: "Kopargaon"
    },
    {
      value: "Koregaon",
      label: "Koregaon"
    },
    {
      value: "Kudal",
      label: "Kudal"
    },
    {
      value: "Kuhi",
      label: "Kuhi"
    },
    {
      value: "Kurkheda",
      label: "Kurkheda"
    },
    {
      value: "Kusumba",
      label: "Kusumba"
    },
    {
      value: "Lakhandur",
      label: "Lakhandur"
    },
    {
      value: "Langa",
      label: "Langa"
    },
    {
      value: "Latur",
      label: "Latur"
    },
    {
      value: "Lonar",
      label: "Lonar"
    },
    {
      value: "Lonavala",
      label: "Lonavala"
    },
    {
      value: "Madangad",
      label: "Madangad"
    },
    {
      value: "Madha",
      label: "Madha"
    },
    {
      value: "Mahabaleshwar",
      label: "Mahabaleshwar"
    },
    {
      value: "Mahad",
      label: "Mahad"
    },
    {
      value: "Mahagaon",
      label: "Mahagaon"
    },
    {
      value: "Mahasala",
      label: "Mahasala"
    },
    {
      value: "Mahaswad",
      label: "Mahaswad"
    },
    {
      value: "Malegaon",
      label: "Malegaon"
    },
    {
      value: "Malgaon",
      label: "Malgaon"
    },
    {
      value: "Malgund",
      label: "Malgund"
    },
    {
      value: "Malkapur",
      label: "Malkapur"
    },
    {
      value: "Malsuras",
      label: "Malsuras"
    },
    {
      value: "Malwan",
      label: "Malwan"
    },
    {
      value: "Mancher",
      label: "Mancher"
    },
    {
      value: "Mangalwedha",
      label: "Mangalwedha"
    },
    {
      value: "Mangaon",
      label: "Mangaon"
    },
    {
      value: "Mangrulpur",
      label: "Mangrulpur"
    },
    {
      value: "Manjalegaon",
      label: "Manjalegaon"
    },
    {
      value: "Manmad",
      label: "Manmad"
    },
    {
      value: "Maregaon",
      label: "Maregaon"
    },
    {
      value: "Mehda",
      label: "Mehda"
    },
    {
      value: "Mekhar",
      label: "Mekhar"
    },
    {
      value: "Mohadi",
      label: "Mohadi"
    },
    {
      value: "Mohol",
      label: "Mohol"
    },
    {
      value: "Mokhada",
      label: "Mokhada"
    },
    {
      value: "Morshi",
      label: "Morshi"
    },
    {
      value: "Mouda",
      label: "Mouda"
    },
    {
      value: "Mukhed",
      label: "Mukhed"
    },
    {
      value: "Mul",
      label: "Mul"
    },
    {
      value: "Mumbai",
      label: "Mumbai"
    },
    {
      value: "Murbad",
      label: "Murbad"
    },
    {
      value: "Murtizapur",
      label: "Murtizapur"
    },
    {
      value: "Murud",
      label: "Murud"
    },
    {
      value: "Nagbhir",
      label: "Nagbhir"
    },
    {
      value: "Nagpur",
      label: "Nagpur"
    },
    {
      value: "Nahavara",
      label: "Nahavara"
    },
    {
      value: "Nanded",
      label: "Nanded"
    },
    {
      value: "Nandgaon",
      label: "Nandgaon"
    },
    {
      value: "Nandnva",
      label: "Nandnva"
    },
    {
      value: "Nandurbar",
      label: "Nandurbar"
    },
    {
      value: "Narkhed",
      label: "Narkhed"
    },
    {
      value: "Nashik",
      label: "Nashik"
    },
    {
      value: "Navapur",
      label: "Navapur"
    },
    {
      value: "Ner",
      label: "Ner"
    },
    {
      value: "Newasa",
      label: "Newasa"
    },
    {
      value: "Nilanga",
      label: "Nilanga"
    },
    {
      value: "Niphad",
      label: "Niphad"
    },
    {
      value: "Omerga",
      label: "Omerga"
    },
    {
      value: "Osmanabad",
      label: "Osmanabad"
    },
    {
      value: "Pachora",
      label: "Pachora"
    },
    {
      value: "Paithan",
      label: "Paithan"
    },
    {
      value: "Palghar",
      label: "Palghar"
    },
    {
      value: "Pali",
      label: "Pali"
    },
    {
      value: "Pandharkawada",
      label: "Pandharkawada"
    },
    {
      value: "Pandharpur",
      label: "Pandharpur"
    },
    {
      value: "Panhala",
      label: "Panhala"
    },
    {
      value: "Paranda",
      label: "Paranda"
    },
    {
      value: "Parbhani",
      label: "Parbhani"
    },
    {
      value: "Parner",
      label: "Parner"
    },
    {
      value: "Parola",
      label: "Parola"
    },
    {
      value: "Parseoni",
      label: "Parseoni"
    },
    {
      value: "Partur",
      label: "Partur"
    },
    {
      value: "Patan",
      label: "Patan"
    },
    {
      value: "Pathardi",
      label: "Pathardi"
    },
    {
      value: "Pathari",
      label: "Pathari"
    },
    {
      value: "Patoda",
      label: "Patoda"
    },
    {
      value: "Pauni",
      label: "Pauni"
    },
    {
      value: "Peint",
      label: "Peint"
    },
    {
      value: "Pen",
      label: "Pen"
    },
    {
      value: "Phaltan",
      label: "Phaltan"
    },
    {
      value: "Pimpalner",
      label: "Pimpalner"
    },
    {
      value: "Pirangut",
      label: "Pirangut"
    },
    {
      value: "Poladpur",
      label: "Poladpur"
    },
    {
      value: "Pune",
      label: "Pune"
    },
    {
      value: "Pusad",
      label: "Pusad"
    },
    {
      value: "Pusegaon",
      label: "Pusegaon"
    },
    {
      value: "Radhanagar",
      label: "Radhanagar"
    },
    {
      value: "Rahuri",
      label: "Rahuri"
    },
    {
      value: "Raigad",
      label: "Raigad"
    },
    {
      value: "Rajapur",
      label: "Rajapur"
    },
    {
      value: "Rajgurunagar",
      label: "Rajgurunagar"
    },
    {
      value: "Rajura",
      label: "Rajura"
    },
    {
      value: "Ralegaon",
      label: "Ralegaon"
    },
    {
      value: "Ramtek",
      label: "Ramtek"
    },
    {
      value: "Ratnagiri",
      label: "Ratnagiri"
    },
    {
      value: "Raver",
      label: "Raver"
    },
    {
      value: "Risod",
      label: "Risod"
    },
    {
      value: "Roha",
      label: "Roha"
    },
    {
      value: "Sakarwadi",
      label: "Sakarwadi"
    },
    {
      value: "Sakoli",
      label: "Sakoli"
    },
    {
      value: "Sakri",
      label: "Sakri"
    },
    {
      value: "Salekasa",
      label: "Salekasa"
    },
    {
      value: "Samudrapur",
      label: "Samudrapur"
    },
    {
      value: "Sangamner",
      label: "Sangamner"
    },
    {
      value: "Sanganeshwar",
      label: "Sanganeshwar"
    },
    {
      value: "Sangli",
      label: "Sangli"
    },
    {
      value: "Sangola",
      label: "Sangola"
    },
    {
      value: "Sanguem",
      label: "Sanguem"
    },
    {
      value: "Saoner",
      label: "Saoner"
    },
    {
      value: "Saswad",
      label: "Saswad"
    },
    {
      value: "Satana",
      label: "Satana"
    },
    {
      value: "Satara",
      label: "Satara"
    },
    {
      value: "Sawantwadi",
      label: "Sawantwadi"
    },
    {
      value: "Seloo",
      label: "Seloo"
    },
    {
      value: "Shahada",
      label: "Shahada"
    },
    {
      value: "Shahapur",
      label: "Shahapur"
    },
    {
      value: "Shahuwadi",
      label: "Shahuwadi"
    },
    {
      value: "Shevgaon",
      label: "Shevgaon"
    },
    {
      value: "Shirala",
      label: "Shirala"
    },
    {
      value: "Shirol",
      label: "Shirol"
    },
    {
      value: "Shirpur",
      label: "Shirpur"
    },
    {
      value: "Shirur",
      label: "Shirur"
    },
    {
      value: "Shirwal",
      label: "Shirwal"
    },
    {
      value: "Sholapur",
      label: "Sholapur"
    },
    {
      value: "Shri Rampur",
      label: "Shri Rampur"
    },
    {
      value: "Shrigonda",
      label: "Shrigonda"
    },
    {
      value: "Shrivardhan",
      label: "Shrivardhan"
    },
    {
      value: "Sillod",
      label: "Sillod"
    },
    {
      value: "Sinderwahi",
      label: "Sinderwahi"
    },
    {
      value: "Sindhudurg",
      label: "Sindhudurg"
    },
    {
      value: "Sindkheda",
      label: "Sindkheda"
    },
    {
      value: "Sindkhedaraja",
      label: "Sindkhedaraja"
    },
    {
      value: "Sinnar",
      label: "Sinnar"
    },
    {
      value: "Sironcha",
      label: "Sironcha"
    },
    {
      value: "Soyegaon",
      label: "Soyegaon"
    },
    {
      value: "Surgena",
      label: "Surgena"
    },
    {
      value: "Talasari",
      label: "Talasari"
    },
    {
      value: "Talegaon S.Ji Pant",
      label: "Talegaon S.Ji Pant"
    },
    {
      value: "Taloda",
      label: "Taloda"
    },
    {
      value: "Tasgaon",
      label: "Tasgaon"
    },
    {
      value: "Thane",
      label: "Thane"
    },
    {
      value: "Tirora",
      label: "Tirora"
    },
    {
      value: "Tiwasa",
      label: "Tiwasa"
    },
    {
      value: "Trimbak",
      label: "Trimbak"
    },
    {
      value: "Tuljapur",
      label: "Tuljapur"
    },
    {
      value: "Tumsar",
      label: "Tumsar"
    },
    {
      value: "Udgir",
      label: "Udgir"
    },
    {
      value: "Umarkhed",
      label: "Umarkhed"
    },
    {
      value: "Umrane",
      label: "Umrane"
    },
    {
      value: "Umrer",
      label: "Umrer"
    },
    {
      value: "Urlikanchan",
      label: "Urlikanchan"
    },
    {
      value: "Vaduj",
      label: "Vaduj"
    },
    {
      value: "Velhe",
      label: "Velhe"
    },
    {
      value: "Vengurla",
      label: "Vengurla"
    },
    {
      value: "Vijapur",
      label: "Vijapur"
    },
    {
      value: "Vita",
      label: "Vita"
    },
    {
      value: "Wada",
      label: "Wada"
    },
    {
      value: "Wai",
      label: "Wai"
    },
    {
      value: "Walchandnagar",
      label: "Walchandnagar"
    },
    {
      value: "Wani",
      label: "Wani"
    },
    {
      value: "Wardha",
      label: "Wardha"
    },
    {
      value: "Warlydwarud",
      label: "Warlydwarud"
    },
    {
      value: "Warora",
      label: "Warora"
    },
    {
      value: "Washim",
      label: "Washim"
    },
    {
      value: "Wathar",
      label: "Wathar"
    },
    {
      value: "Yavatmal",
      label: "Yavatmal"
    },
    {
      value: "Yawal",
      label: "Yawal"
    },
    {
      value: "Yeola",
      label: "Yeola"
    },
    {
      value: "Yeotmal",
      label: "Yeotmal"
    }
  ],
  "Manipur": [
    {
      value: "Bishnupur",
      label: "Bishnupur"
    },
    {
      value: "Chakpikarong",
      label: "Chakpikarong"
    },
    {
      value: "Chandel",
      label: "Chandel"
    },
    {
      value: "Chattrik",
      label: "Chattrik"
    },
    {
      value: "Churachandpur",
      label: "Churachandpur"
    },
    {
      value: "Imphal",
      label: "Imphal"
    },
    {
      value: "Jiribam",
      label: "Jiribam"
    },
    {
      value: "Kakching",
      label: "Kakching"
    },
    {
      value: "Kalapahar",
      label: "Kalapahar"
    },
    {
      value: "Mao",
      label: "Mao"
    },
    {
      value: "Mulam",
      label: "Mulam"
    },
    {
      value: "Parbung",
      label: "Parbung"
    },
    {
      value: "Sadarhills",
      label: "Sadarhills"
    },
    {
      value: "Saibom",
      label: "Saibom"
    },
    {
      value: "Sempang",
      label: "Sempang"
    },
    {
      value: "Senapati",
      label: "Senapati"
    },
    {
      value: "Sochumer",
      label: "Sochumer"
    },
    {
      value: "Taloulong",
      label: "Taloulong"
    },
    {
      value: "Tamenglong",
      label: "Tamenglong"
    },
    {
      value: "Thinghat",
      label: "Thinghat"
    },
    {
      value: "Thoubal",
      label: "Thoubal"
    },
    {
      value: "Ukhrul",
      label: "Ukhrul"
    }
  ],
  "Meghalaya": [
    {
      value: "Amlaren",
      label: "Amlaren"
    },
    {
      value: "Baghmara",
      label: "Baghmara"
    },
    {
      value: "Cherrapunjee",
      label: "Cherrapunjee"
    },
    {
      value: "Dadengiri",
      label: "Dadengiri"
    },
    {
      value: "Garo Hills",
      label: "Garo Hills"
    },
    {
      value: "Jaintia Hills",
      label: "Jaintia Hills"
    },
    {
      value: "Jowai",
      label: "Jowai"
    },
    {
      value: "Khasi Hills",
      label: "Khasi Hills"
    },
    {
      value: "Khliehriat",
      label: "Khliehriat"
    },
    {
      value: "Mariang",
      label: "Mariang"
    },
    {
      value: "Mawkyrwat",
      label: "Mawkyrwat"
    },
    {
      value: "Nongpoh",
      label: "Nongpoh"
    },
    {
      value: "Nongstoin",
      label: "Nongstoin"
    },
    {
      value: "Resubelpara",
      label: "Resubelpara"
    },
    {
      value: "Ri Bhoi",
      label: "Ri Bhoi"
    },
    {
      value: "Shillong",
      label: "Shillong"
    },
    {
      value: "Tura",
      label: "Tura"
    },
    {
      value: "Williamnagar",
      label: "Williamnagar"
    }
  ],
  "Mizoram": [
    {
      value: "Aizawl",
      label: "Aizawl"
    },
    {
      value: "Champhai",
      label: "Champhai"
    },
    {
      value: "Demagiri",
      label: "Demagiri"
    },
    {
      value: "Kolasib",
      label: "Kolasib"
    },
    {
      value: "Lawngtlai",
      label: "Lawngtlai"
    },
    {
      value: "Lunglei",
      label: "Lunglei"
    },
    {
      value: "Mamit",
      label: "Mamit"
    },
    {
      value: "Saiha",
      label: "Saiha"
    },
    {
      value: "Serchhip",
      label: "Serchhip"
    }
  ],
  "Nagaland": [
    {
      value: "Dimapur",
      label: "Dimapur"
    },
    {
      value: "Jalukie",
      label: "Jalukie"
    },
    {
      value: "Kiphire",
      label: "Kiphire"
    },
    {
      value: "Kohima",
      label: "Kohima"
    },
    {
      value: "Mokokchung",
      label: "Mokokchung"
    },
    {
      value: "Mon",
      label: "Mon"
    },
    {
      value: "Phek",
      label: "Phek"
    },
    {
      value: "Tuensang",
      label: "Tuensang"
    },
    {
      value: "Wokha",
      label: "Wokha"
    },
    {
      value: "Zunheboto",
      label: "Zunheboto"
    }
  ],
  "Odisha": [
    {
      value: "Anandapur",
      label: "Anandapur"
    },
    {
      value: "Angul",
      label: "Angul"
    },
    {
      value: "Anugul",
      label: "Anugul"
    },
    {
      value: "Aska",
      label: "Aska"
    },
    {
      value: "Athgarh",
      label: "Athgarh"
    },
    {
      value: "Athmallik",
      label: "Athmallik"
    },
    {
      value: "Attabira",
      label: "Attabira"
    },
    {
      value: "Bagdihi",
      label: "Bagdihi"
    },
    {
      value: "Balangir",
      label: "Balangir"
    },
    {
      value: "Balasore",
      label: "Balasore"
    },
    {
      value: "Baleswar",
      label: "Baleswar"
    },
    {
      value: "Baliguda",
      label: "Baliguda"
    },
    {
      value: "Balugaon",
      label: "Balugaon"
    },
    {
      value: "Banaigarh",
      label: "Banaigarh"
    },
    {
      value: "Bangiriposi",
      label: "Bangiriposi"
    },
    {
      value: "Barbil",
      label: "Barbil"
    },
    {
      value: "Bargarh",
      label: "Bargarh"
    },
    {
      value: "Baripada",
      label: "Baripada"
    },
    {
      value: "Barkot",
      label: "Barkot"
    },
    {
      value: "Basta",
      label: "Basta"
    },
    {
      value: "Berhampur",
      label: "Berhampur"
    },
    {
      value: "Betanati",
      label: "Betanati"
    },
    {
      value: "Bhadrak",
      label: "Bhadrak"
    },
    {
      value: "Bhanjanagar",
      label: "Bhanjanagar"
    },
    {
      value: "Bhawanipatna",
      label: "Bhawanipatna"
    },
    {
      value: "Bhubaneswar",
      label: "Bhubaneswar"
    },
    {
      value: "Birmaharajpur",
      label: "Birmaharajpur"
    },
    {
      value: "Bisam Cuttack",
      label: "Bisam Cuttack"
    },
    {
      value: "Boriguma",
      label: "Boriguma"
    },
    {
      value: "Boudh",
      label: "Boudh"
    },
    {
      value: "Buguda",
      label: "Buguda"
    },
    {
      value: "Chandbali",
      label: "Chandbali"
    },
    {
      value: "Chhatrapur",
      label: "Chhatrapur"
    },
    {
      value: "Chhendipada",
      label: "Chhendipada"
    },
    {
      value: "Cuttack",
      label: "Cuttack"
    },
    {
      value: "Daringbadi",
      label: "Daringbadi"
    },
    {
      value: "Daspalla",
      label: "Daspalla"
    },
    {
      value: "Deodgarh",
      label: "Deodgarh"
    },
    {
      value: "Deogarh",
      label: "Deogarh"
    },
    {
      value: "Dhanmandal",
      label: "Dhanmandal"
    },
    {
      value: "Dharamgarh",
      label: "Dharamgarh"
    },
    {
      value: "Dhenkanal",
      label: "Dhenkanal"
    },
    {
      value: "Digapahandi",
      label: "Digapahandi"
    },
    {
      value: "Dunguripali",
      label: "Dunguripali"
    },
    {
      value: "G. Udayagiri",
      label: "G. Udayagiri"
    },
    {
      value: "Gajapati",
      label: "Gajapati"
    },
    {
      value: "Ganjam",
      label: "Ganjam"
    },
    {
      value: "Ghatgaon",
      label: "Ghatgaon"
    },
    {
      value: "Gudari",
      label: "Gudari"
    },
    {
      value: "Gunupur",
      label: "Gunupur"
    },
    {
      value: "Hemgiri",
      label: "Hemgiri"
    },
    {
      value: "Hindol",
      label: "Hindol"
    },
    {
      value: "Jagatsinghapur",
      label: "Jagatsinghapur"
    },
    {
      value: "Jajpur",
      label: "Jajpur"
    },
    {
      value: "Jamankira",
      label: "Jamankira"
    },
    {
      value: "Jashipur",
      label: "Jashipur"
    },
    {
      value: "Jayapatna",
      label: "Jayapatna"
    },
    {
      value: "Jeypur",
      label: "Jeypur"
    },
    {
      value: "Jharigan",
      label: "Jharigan"
    },
    {
      value: "Jharsuguda",
      label: "Jharsuguda"
    },
    {
      value: "Jujumura",
      label: "Jujumura"
    },
    {
      value: "Kalahandi",
      label: "Kalahandi"
    },
    {
      value: "Kalimela",
      label: "Kalimela"
    },
    {
      value: "Kamakhyanagar",
      label: "Kamakhyanagar"
    },
    {
      value: "Kandhamal",
      label: "Kandhamal"
    },
    {
      value: "Kantabhanji",
      label: "Kantabhanji"
    },
    {
      value: "Kantamal",
      label: "Kantamal"
    },
    {
      value: "Karanjia",
      label: "Karanjia"
    },
    {
      value: "Kashipur",
      label: "Kashipur"
    },
    {
      value: "Kendrapara",
      label: "Kendrapara"
    },
    {
      value: "Kendujhar",
      label: "Kendujhar"
    },
    {
      value: "Keonjhar",
      label: "Keonjhar"
    },
    {
      value: "Khalikote",
      label: "Khalikote"
    },
    {
      value: "Khordha",
      label: "Khordha"
    },
    {
      value: "Khurda",
      label: "Khurda"
    },
    {
      value: "Komana",
      label: "Komana"
    },
    {
      value: "Koraput",
      label: "Koraput"
    },
    {
      value: "Kotagarh",
      label: "Kotagarh"
    },
    {
      value: "Kuchinda",
      label: "Kuchinda"
    },
    {
      value: "Lahunipara",
      label: "Lahunipara"
    },
    {
      value: "Laxmipur",
      label: "Laxmipur"
    },
    {
      value: "M. Rampur",
      label: "M. Rampur"
    },
    {
      value: "Malkangiri",
      label: "Malkangiri"
    },
    {
      value: "Mathili",
      label: "Mathili"
    },
    {
      value: "Mayurbhanj",
      label: "Mayurbhanj"
    },
    {
      value: "Mohana",
      label: "Mohana"
    },
    {
      value: "Motu",
      label: "Motu"
    },
    {
      value: "Nabarangapur",
      label: "Nabarangapur"
    },
    {
      value: "Naktideul",
      label: "Naktideul"
    },
    {
      value: "Nandapur",
      label: "Nandapur"
    },
    {
      value: "Narlaroad",
      label: "Narlaroad"
    },
    {
      value: "Narsinghpur",
      label: "Narsinghpur"
    },
    {
      value: "Nayagarh",
      label: "Nayagarh"
    },
    {
      value: "Nimapara",
      label: "Nimapara"
    },
    {
      value: "Nowparatan",
      label: "Nowparatan"
    },
    {
      value: "Nowrangapur",
      label: "Nowrangapur"
    },
    {
      value: "Nuapada",
      label: "Nuapada"
    },
    {
      value: "Padampur",
      label: "Padampur"
    },
    {
      value: "Paikamal",
      label: "Paikamal"
    },
    {
      value: "Palla Hara",
      label: "Palla Hara"
    },
    {
      value: "Papadhandi",
      label: "Papadhandi"
    },
    {
      value: "Parajang",
      label: "Parajang"
    },
    {
      value: "Pardip",
      label: "Pardip"
    },
    {
      value: "Parlakhemundi",
      label: "Parlakhemundi"
    },
    {
      value: "Patnagarh",
      label: "Patnagarh"
    },
    {
      value: "Pattamundai",
      label: "Pattamundai"
    },
    {
      value: "Phiringia",
      label: "Phiringia"
    },
    {
      value: "Phulbani",
      label: "Phulbani"
    },
    {
      value: "Puri",
      label: "Puri"
    },
    {
      value: "Puruna Katak",
      label: "Puruna Katak"
    },
    {
      value: "R. Udayigiri",
      label: "R. Udayigiri"
    },
    {
      value: "Rairakhol",
      label: "Rairakhol"
    },
    {
      value: "Rairangpur",
      label: "Rairangpur"
    },
    {
      value: "Rajgangpur",
      label: "Rajgangpur"
    },
    {
      value: "Rajkhariar",
      label: "Rajkhariar"
    },
    {
      value: "Rayagada",
      label: "Rayagada"
    },
    {
      value: "Rourkela",
      label: "Rourkela"
    },
    {
      value: "Sambalpur",
      label: "Sambalpur"
    },
    {
      value: "Sohela",
      label: "Sohela"
    },
    {
      value: "Sonapur",
      label: "Sonapur"
    },
    {
      value: "Soro",
      label: "Soro"
    },
    {
      value: "Subarnapur",
      label: "Subarnapur"
    },
    {
      value: "Sunabeda",
      label: "Sunabeda"
    },
    {
      value: "Sundergarh",
      label: "Sundergarh"
    },
    {
      value: "Surada",
      label: "Surada"
    },
    {
      value: "T. Rampur",
      label: "T. Rampur"
    },
    {
      value: "Talcher",
      label: "Talcher"
    },
    {
      value: "Telkoi",
      label: "Telkoi"
    },
    {
      value: "Titlagarh",
      label: "Titlagarh"
    },
    {
      value: "Tumudibandha",
      label: "Tumudibandha"
    },
    {
      value: "Udala",
      label: "Udala"
    },
    {
      value: "Umerkote",
      label: "Umerkote"
    }
  ],
  "Pondicherry": [
    {
      value: "Bahur",
      label: "Bahur"
    },
    {
      value: "Karaikal",
      label: "Karaikal"
    },
    {
      value: "Mahe",
      label: "Mahe"
    },
    {
      value: "Pondicherry",
      label: "Pondicherry"
    },
    {
      value: "Purnankuppam",
      label: "Purnankuppam"
    },
    {
      value: "Valudavur",
      label: "Valudavur"
    },
    {
      value: "Villianur",
      label: "Villianur"
    },
    {
      value: "Yanam",
      label: "Yanam"
    }
  ],
  "Punjab": [
    {
      value: "Abohar",
      label: "Abohar"
    },
    {
      value: "Ajnala",
      label: "Ajnala"
    },
    {
      value: "Amritsar",
      label: "Amritsar"
    },
    {
      value: "Balachaur",
      label: "Balachaur"
    },
    {
      value: "Barnala",
      label: "Barnala"
    },
    {
      value: "Batala",
      label: "Batala"
    },
    {
      value: "Bathinda",
      label: "Bathinda"
    },
    {
      value: "Chandigarh",
      label: "Chandigarh"
    },
    {
      value: "Dasua",
      label: "Dasua"
    },
    {
      value: "Dinanagar",
      label: "Dinanagar"
    },
    {
      value: "Faridkot",
      label: "Faridkot"
    },
    {
      value: "Fatehgarh Sahib",
      label: "Fatehgarh Sahib"
    },
    {
      value: "Fazilka",
      label: "Fazilka"
    },
    {
      value: "Ferozepur",
      label: "Ferozepur"
    },
    {
      value: "Garhashanker",
      label: "Garhashanker"
    },
    {
      value: "Goindwal",
      label: "Goindwal"
    },
    {
      value: "Gurdaspur",
      label: "Gurdaspur"
    },
    {
      value: "Guruharsahai",
      label: "Guruharsahai"
    },
    {
      value: "Hoshiarpur",
      label: "Hoshiarpur"
    },
    {
      value: "Jagraon",
      label: "Jagraon"
    },
    {
      value: "Jalandhar",
      label: "Jalandhar"
    },
    {
      value: "Jugial",
      label: "Jugial"
    },
    {
      value: "Kapurthala",
      label: "Kapurthala"
    },
    {
      value: "Kharar",
      label: "Kharar"
    },
    {
      value: "Kotkapura",
      label: "Kotkapura"
    },
    {
      value: "Ludhiana",
      label: "Ludhiana"
    },
    {
      value: "Malaut",
      label: "Malaut"
    },
    {
      value: "Malerkotla",
      label: "Malerkotla"
    },
    {
      value: "Mansa",
      label: "Mansa"
    },
    {
      value: "Moga",
      label: "Moga"
    },
    {
      value: "Muktasar",
      label: "Muktasar"
    },
    {
      value: "Nabha",
      label: "Nabha"
    },
    {
      value: "Nakodar",
      label: "Nakodar"
    },
    {
      value: "Nangal",
      label: "Nangal"
    },
    {
      value: "Nawanshahar",
      label: "Nawanshahar"
    },
    {
      value: "Nawanshahr",
      label: "Nawanshahr"
    },
    {
      value: "Pathankot",
      label: "Pathankot"
    },
    {
      value: "Patiala",
      label: "Patiala"
    },
    {
      value: "Patti",
      label: "Patti"
    },
    {
      value: "Phagwara",
      label: "Phagwara"
    },
    {
      value: "Phillaur",
      label: "Phillaur"
    },
    {
      value: "Phulmandi",
      label: "Phulmandi"
    },
    {
      value: "Quadian",
      label: "Quadian"
    },
    {
      value: "Rajpura",
      label: "Rajpura"
    },
    {
      value: "Raman",
      label: "Raman"
    },
    {
      value: "Rayya",
      label: "Rayya"
    },
    {
      value: "Ropar",
      label: "Ropar"
    },
    {
      value: "Rupnagar",
      label: "Rupnagar"
    },
    {
      value: "Samana",
      label: "Samana"
    },
    {
      value: "Samrala",
      label: "Samrala"
    },
    {
      value: "Sangrur",
      label: "Sangrur"
    },
    {
      value: "Sardulgarh",
      label: "Sardulgarh"
    },
    {
      value: "Sarhind",
      label: "Sarhind"
    },
    {
      value: "SAS Nagar",
      label: "SAS Nagar"
    },
    {
      value: "Sultanpur Lodhi",
      label: "Sultanpur Lodhi"
    },
    {
      value: "Sunam",
      label: "Sunam"
    },
    {
      value: "Tanda Urmar",
      label: "Tanda Urmar"
    },
    {
      value: "Tarn Taran",
      label: "Tarn Taran"
    },
    {
      value: "Zira",
      label: "Zira"
    }
  ],
  "Rajasthan": [
    {
      value: "Abu Road",
      label: "Abu Road"
    },
    {
      value: "Ahore",
      label: "Ahore"
    },
    {
      value: "Ajmer",
      label: "Ajmer"
    },
    {
      value: "Aklera",
      label: "Aklera"
    },
    {
      value: "Alwar",
      label: "Alwar"
    },
    {
      value: "Amber",
      label: "Amber"
    },
    {
      value: "Amet",
      label: "Amet"
    },
    {
      value: "Anupgarh",
      label: "Anupgarh"
    },
    {
      value: "Asind",
      label: "Asind"
    },
    {
      value: "Aspur",
      label: "Aspur"
    },
    {
      value: "Atru",
      label: "Atru"
    },
    {
      value: "Bagidora",
      label: "Bagidora"
    },
    {
      value: "Bali",
      label: "Bali"
    },
    {
      value: "Bamanwas",
      label: "Bamanwas"
    },
    {
      value: "Banera",
      label: "Banera"
    },
    {
      value: "Bansur",
      label: "Bansur"
    },
    {
      value: "Banswara",
      label: "Banswara"
    },
    {
      value: "Baran",
      label: "Baran"
    },
    {
      value: "Bari",
      label: "Bari"
    },
    {
      value: "Barisadri",
      label: "Barisadri"
    },
    {
      value: "Barmer",
      label: "Barmer"
    },
    {
      value: "Baseri",
      label: "Baseri"
    },
    {
      value: "Bassi",
      label: "Bassi"
    },
    {
      value: "Baswa",
      label: "Baswa"
    },
    {
      value: "Bayana",
      label: "Bayana"
    },
    {
      value: "Beawar",
      label: "Beawar"
    },
    {
      value: "Begun",
      label: "Begun"
    },
    {
      value: "Behror",
      label: "Behror"
    },
    {
      value: "Bhadra",
      label: "Bhadra"
    },
    {
      value: "Bharatpur",
      label: "Bharatpur"
    },
    {
      value: "Bhilwara",
      label: "Bhilwara"
    },
    {
      value: "Bhim",
      label: "Bhim"
    },
    {
      value: "Bhinmal",
      label: "Bhinmal"
    },
    {
      value: "Bikaner",
      label: "Bikaner"
    },
    {
      value: "Bilara",
      label: "Bilara"
    },
    {
      value: "Bundi",
      label: "Bundi"
    },
    {
      value: "Chhabra",
      label: "Chhabra"
    },
    {
      value: "Chhipaborad",
      label: "Chhipaborad"
    },
    {
      value: "Chirawa",
      label: "Chirawa"
    },
    {
      value: "Chittorgarh",
      label: "Chittorgarh"
    },
    {
      value: "Chohtan",
      label: "Chohtan"
    },
    {
      value: "Churu",
      label: "Churu"
    },
    {
      value: "Dantaramgarh",
      label: "Dantaramgarh"
    },
    {
      value: "Dausa",
      label: "Dausa"
    },
    {
      value: "Deedwana",
      label: "Deedwana"
    },
    {
      value: "Deeg",
      label: "Deeg"
    },
    {
      value: "Degana",
      label: "Degana"
    },
    {
      value: "Deogarh",
      label: "Deogarh"
    },
    {
      value: "Deoli",
      label: "Deoli"
    },
    {
      value: "Desuri",
      label: "Desuri"
    },
    {
      value: "Dhariawad",
      label: "Dhariawad"
    },
    {
      value: "Dholpur",
      label: "Dholpur"
    },
    {
      value: "Digod",
      label: "Digod"
    },
    {
      value: "Dudu",
      label: "Dudu"
    },
    {
      value: "Dungarpur",
      label: "Dungarpur"
    },
    {
      value: "Dungla",
      label: "Dungla"
    },
    {
      value: "Fatehpur",
      label: "Fatehpur"
    },
    {
      value: "Gangapur",
      label: "Gangapur"
    },
    {
      value: "Gangdhar",
      label: "Gangdhar"
    },
    {
      value: "Gerhi",
      label: "Gerhi"
    },
    {
      value: "Ghatol",
      label: "Ghatol"
    },
    {
      value: "Girwa",
      label: "Girwa"
    },
    {
      value: "Gogunda",
      label: "Gogunda"
    },
    {
      value: "Hanumangarh",
      label: "Hanumangarh"
    },
    {
      value: "Hindaun",
      label: "Hindaun"
    },
    {
      value: "Hindoli",
      label: "Hindoli"
    },
    {
      value: "Hurda",
      label: "Hurda"
    },
    {
      value: "Jahazpur",
      label: "Jahazpur"
    },
    {
      value: "Jaipur",
      label: "Jaipur"
    },
    {
      value: "Jaisalmer",
      label: "Jaisalmer"
    },
    {
      value: "Jalore",
      label: "Jalore"
    },
    {
      value: "Jhalawar",
      label: "Jhalawar"
    },
    {
      value: "Jhunjhunu",
      label: "Jhunjhunu"
    },
    {
      value: "Jodhpur",
      label: "Jodhpur"
    },
    {
      value: "Kaman",
      label: "Kaman"
    },
    {
      value: "Kapasan",
      label: "Kapasan"
    },
    {
      value: "Karauli",
      label: "Karauli"
    },
    {
      value: "Kekri",
      label: "Kekri"
    },
    {
      value: "Keshoraipatan",
      label: "Keshoraipatan"
    },
    {
      value: "Khandar",
      label: "Khandar"
    },
    {
      value: "Kherwara",
      label: "Kherwara"
    },
    {
      value: "Khetri",
      label: "Khetri"
    },
    {
      value: "Kishanganj",
      label: "Kishanganj"
    },
    {
      value: "Kishangarh",
      label: "Kishangarh"
    },
    {
      value: "Kishangarhbas",
      label: "Kishangarhbas"
    },
    {
      value: "Kolayat",
      label: "Kolayat"
    },
    {
      value: "Kota",
      label: "Kota"
    },
    {
      value: "Kotputli",
      label: "Kotputli"
    },
    {
      value: "Kotra",
      label: "Kotra"
    },
    {
      value: "Kotri",
      label: "Kotri"
    },
    {
      value: "Kumbalgarh",
      label: "Kumbalgarh"
    },
    {
      value: "Kushalgarh",
      label: "Kushalgarh"
    },
    {
      value: "Ladnun",
      label: "Ladnun"
    },
    {
      value: "Ladpura",
      label: "Ladpura"
    },
    {
      value: "Lalsot",
      label: "Lalsot"
    },
    {
      value: "Laxmangarh",
      label: "Laxmangarh"
    },
    {
      value: "Lunkaransar",
      label: "Lunkaransar"
    },
    {
      value: "Mahuwa",
      label: "Mahuwa"
    },
    {
      value: "Malpura",
      label: "Malpura"
    },
    {
      value: "Malvi",
      label: "Malvi"
    },
    {
      value: "Mandal",
      label: "Mandal"
    },
    {
      value: "Mandalgarh",
      label: "Mandalgarh"
    },
    {
      value: "Mandawar",
      label: "Mandawar"
    },
    {
      value: "Mangrol",
      label: "Mangrol"
    },
    {
      value: "Marwar-Jn",
      label: "Marwar-Jn"
    },
    {
      value: "Merta",
      label: "Merta"
    },
    {
      value: "Nadbai",
      label: "Nadbai"
    },
    {
      value: "Nagaur",
      label: "Nagaur"
    },
    {
      value: "Nainwa",
      label: "Nainwa"
    },
    {
      value: "Nasirabad",
      label: "Nasirabad"
    },
    {
      value: "Nathdwara",
      label: "Nathdwara"
    },
    {
      value: "Nawa",
      label: "Nawa"
    },
    {
      value: "Neem Ka Thana",
      label: "Neem Ka Thana"
    },
    {
      value: "Newai",
      label: "Newai"
    },
    {
      value: "Nimbahera",
      label: "Nimbahera"
    },
    {
      value: "Nohar",
      label: "Nohar"
    },
    {
      value: "Nokha",
      label: "Nokha"
    },
    {
      value: "Onli",
      label: "Onli"
    },
    {
      value: "Osian",
      label: "Osian"
    },
    {
      value: "Pachpadara",
      label: "Pachpadara"
    },
    {
      value: "Pachpahar",
      label: "Pachpahar"
    },
    {
      value: "Padampur",
      label: "Padampur"
    },
    {
      value: "Pali",
      label: "Pali"
    },
    {
      value: "Parbatsar",
      label: "Parbatsar"
    },
    {
      value: "Phagi",
      label: "Phagi"
    },
    {
      value: "Phalodi",
      label: "Phalodi"
    },
    {
      value: "Pilani",
      label: "Pilani"
    },
    {
      value: "Pindwara",
      label: "Pindwara"
    },
    {
      value: "Pipalda",
      label: "Pipalda"
    },
    {
      value: "Pirawa",
      label: "Pirawa"
    },
    {
      value: "Pokaran",
      label: "Pokaran"
    },
    {
      value: "Pratapgarh",
      label: "Pratapgarh"
    },
    {
      value: "Raipur",
      label: "Raipur"
    },
    {
      value: "Raisinghnagar",
      label: "Raisinghnagar"
    },
    {
      value: "Rajgarh",
      label: "Rajgarh"
    },
    {
      value: "Rajsamand",
      label: "Rajsamand"
    },
    {
      value: "Ramganj Mandi",
      label: "Ramganj Mandi"
    },
    {
      value: "Ramgarh",
      label: "Ramgarh"
    },
    {
      value: "Rashmi",
      label: "Rashmi"
    },
    {
      value: "Ratangarh",
      label: "Ratangarh"
    },
    {
      value: "Reodar",
      label: "Reodar"
    },
    {
      value: "Rupbas",
      label: "Rupbas"
    },
    {
      value: "Sadulshahar",
      label: "Sadulshahar"
    },
    {
      value: "Sagwara",
      label: "Sagwara"
    },
    {
      value: "Sahabad",
      label: "Sahabad"
    },
    {
      value: "Salumber",
      label: "Salumber"
    },
    {
      value: "Sanchore",
      label: "Sanchore"
    },
    {
      value: "Sangaria",
      label: "Sangaria"
    },
    {
      value: "Sangod",
      label: "Sangod"
    },
    {
      value: "Sapotra",
      label: "Sapotra"
    },
    {
      value: "Sarada",
      label: "Sarada"
    },
    {
      value: "Sardarshahar",
      label: "Sardarshahar"
    },
    {
      value: "Sarwar",
      label: "Sarwar"
    },
    {
      value: "Sawai Madhopur",
      label: "Sawai Madhopur"
    },
    {
      value: "Shahapura",
      label: "Shahapura"
    },
    {
      value: "Sheo",
      label: "Sheo"
    },
    {
      value: "Sheoganj",
      label: "Sheoganj"
    },
    {
      value: "Shergarh",
      label: "Shergarh"
    },
    {
      value: "Sikar",
      label: "Sikar"
    },
    {
      value: "Sirohi",
      label: "Sirohi"
    },
    {
      value: "Siwana",
      label: "Siwana"
    },
    {
      value: "Sojat",
      label: "Sojat"
    },
    {
      value: "Sri Dungargarh",
      label: "Sri Dungargarh"
    },
    {
      value: "Sri Ganganagar",
      label: "Sri Ganganagar"
    },
    {
      value: "Sri Karanpur",
      label: "Sri Karanpur"
    },
    {
      value: "Sri Madhopur",
      label: "Sri Madhopur"
    },
    {
      value: "Sujangarh",
      label: "Sujangarh"
    },
    {
      value: "Taranagar",
      label: "Taranagar"
    },
    {
      value: "Thanaghazi",
      label: "Thanaghazi"
    },
    {
      value: "Tibbi",
      label: "Tibbi"
    },
    {
      value: "Tijara",
      label: "Tijara"
    },
    {
      value: "Todaraisingh",
      label: "Todaraisingh"
    },
    {
      value: "Tonk",
      label: "Tonk"
    },
    {
      value: "Udaipur",
      label: "Udaipur"
    },
    {
      value: "Udaipurwati",
      label: "Udaipurwati"
    },
    {
      value: "Uniayara",
      label: "Uniayara"
    },
    {
      value: "Vallabhnagar",
      label: "Vallabhnagar"
    },
    {
      value: "Viratnagar",
      label: "Viratnagar"
    }
  ],
  "Sikkim": [
    {
      value: "Barmiak",
      label: "Barmiak"
    },
    {
      value: "Be",
      label: "Be"
    },
    {
      value: "Bhurtuk",
      label: "Bhurtuk"
    },
    {
      value: "Chhubakha",
      label: "Chhubakha"
    },
    {
      value: "Chidam",
      label: "Chidam"
    },
    {
      value: "Chubha",
      label: "Chubha"
    },
    {
      value: "Chumikteng",
      label: "Chumikteng"
    },
    {
      value: "Dentam",
      label: "Dentam"
    },
    {
      value: "Dikchu",
      label: "Dikchu"
    },
    {
      value: "Dzongri",
      label: "Dzongri"
    },
    {
      value: "Gangtok",
      label: "Gangtok"
    },
    {
      value: "Gauzing",
      label: "Gauzing"
    },
    {
      value: "Gyalshing",
      label: "Gyalshing"
    },
    {
      value: "Hema",
      label: "Hema"
    },
    {
      value: "Kerung",
      label: "Kerung"
    },
    {
      value: "Lachen",
      label: "Lachen"
    },
    {
      value: "Lachung",
      label: "Lachung"
    },
    {
      value: "Lema",
      label: "Lema"
    },
    {
      value: "Lingtam",
      label: "Lingtam"
    },
    {
      value: "Lungthu",
      label: "Lungthu"
    },
    {
      value: "Mangan",
      label: "Mangan"
    },
    {
      value: "Namchi",
      label: "Namchi"
    },
    {
      value: "Namthang",
      label: "Namthang"
    },
    {
      value: "Nanga",
      label: "Nanga"
    },
    {
      value: "Nantang",
      label: "Nantang"
    },
    {
      value: "Naya Bazar",
      label: "Naya Bazar"
    },
    {
      value: "Padamachen",
      label: "Padamachen"
    },
    {
      value: "Pakhyong",
      label: "Pakhyong"
    },
    {
      value: "Pemayangtse",
      label: "Pemayangtse"
    },
    {
      value: "Phensang",
      label: "Phensang"
    },
    {
      value: "Rangli",
      label: "Rangli"
    },
    {
      value: "Rinchingpong",
      label: "Rinchingpong"
    },
    {
      value: "Sakyong",
      label: "Sakyong"
    },
    {
      value: "Samdong",
      label: "Samdong"
    },
    {
      value: "Singtam",
      label: "Singtam"
    },
    {
      value: "Siniolchu",
      label: "Siniolchu"
    },
    {
      value: "Sombari",
      label: "Sombari"
    },
    {
      value: "Soreng",
      label: "Soreng"
    },
    {
      value: "Sosing",
      label: "Sosing"
    },
    {
      value: "Tekhug",
      label: "Tekhug"
    },
    {
      value: "Temi",
      label: "Temi"
    },
    {
      value: "Tsetang",
      label: "Tsetang"
    },
    {
      value: "Tsomgo",
      label: "Tsomgo"
    },
    {
      value: "Tumlong",
      label: "Tumlong"
    },
    {
      value: "Yangang",
      label: "Yangang"
    },
    {
      value: "Yumtang",
      label: "Yumtang"
    }
  ],
  "Tamil Nadu": [
    {
      value: "Ambasamudram",
      label: "Ambasamudram"
    },
    {
      value: "Anamali",
      label: "Anamali"
    },
    {
      value: "Arakandanallur",
      label: "Arakandanallur"
    },
    {
      value: "Arantangi",
      label: "Arantangi"
    },
    {
      value: "Aravakurichi",
      label: "Aravakurichi"
    },
    {
      value: "Ariyalur",
      label: "Ariyalur"
    },
    {
      value: "Arkonam",
      label: "Arkonam"
    },
    {
      value: "Arni",
      label: "Arni"
    },
    {
      value: "Aruppukottai",
      label: "Aruppukottai"
    },
    {
      value: "Attur",
      label: "Attur"
    },
    {
      value: "Avanashi",
      label: "Avanashi"
    },
    {
      value: "Batlagundu",
      label: "Batlagundu"
    },
    {
      value: "Bhavani",
      label: "Bhavani"
    },
    {
      value: "Chengalpattu",
      label: "Chengalpattu"
    },
    {
      value: "Chengam",
      label: "Chengam"
    },
    {
      value: "Chennai",
      label: "Chennai"
    },
    {
      value: "Chidambaram",
      label: "Chidambaram"
    },
    {
      value: "Chingleput",
      label: "Chingleput"
    },
    {
      value: "Coimbatore",
      label: "Coimbatore"
    },
    {
      value: "Courtallam",
      label: "Courtallam"
    },
    {
      value: "Cuddalore",
      label: "Cuddalore"
    },
    {
      value: "Cumbum",
      label: "Cumbum"
    },
    {
      value: "Denkanikoitah",
      label: "Denkanikoitah"
    },
    {
      value: "Devakottai",
      label: "Devakottai"
    },
    {
      value: "Dharampuram",
      label: "Dharampuram"
    },
    {
      value: "Dharmapuri",
      label: "Dharmapuri"
    },
    {
      value: "Dindigul",
      label: "Dindigul"
    },
    {
      value: "Erode",
      label: "Erode"
    },
    {
      value: "Gingee",
      label: "Gingee"
    },
    {
      value: "Gobichettipalayam",
      label: "Gobichettipalayam"
    },
    {
      value: "Gudalur",
      label: "Gudalur"
    },
    {
      value: "Gudiyatham",
      label: "Gudiyatham"
    },
    {
      value: "Harur",
      label: "Harur"
    },
    {
      value: "Hosur",
      label: "Hosur"
    },
    {
      value: "Jayamkondan",
      label: "Jayamkondan"
    },
    {
      value: "Kallkurichi",
      label: "Kallkurichi"
    },
    {
      value: "Kanchipuram",
      label: "Kanchipuram"
    },
    {
      value: "Kangayam",
      label: "Kangayam"
    },
    {
      value: "Kanyakumari",
      label: "Kanyakumari"
    },
    {
      value: "Karaikal",
      label: "Karaikal"
    },
    {
      value: "Karaikudi",
      label: "Karaikudi"
    },
    {
      value: "Karur",
      label: "Karur"
    },
    {
      value: "Keeranur",
      label: "Keeranur"
    },
    {
      value: "Kodaikanal",
      label: "Kodaikanal"
    },
    {
      value: "Kodumudi",
      label: "Kodumudi"
    },
    {
      value: "Kotagiri",
      label: "Kotagiri"
    },
    {
      value: "Kovilpatti",
      label: "Kovilpatti"
    },
    {
      value: "Krishnagiri",
      label: "Krishnagiri"
    },
    {
      value: "Kulithalai",
      label: "Kulithalai"
    },
    {
      value: "Kumbakonam",
      label: "Kumbakonam"
    },
    {
      value: "Kuzhithurai",
      label: "Kuzhithurai"
    },
    {
      value: "Madurai",
      label: "Madurai"
    },
    {
      value: "Madurantgam",
      label: "Madurantgam"
    },
    {
      value: "Manamadurai",
      label: "Manamadurai"
    },
    {
      value: "Manaparai",
      label: "Manaparai"
    },
    {
      value: "Mannargudi",
      label: "Mannargudi"
    },
    {
      value: "Mayiladuthurai",
      label: "Mayiladuthurai"
    },
    {
      value: "Mayiladutjurai",
      label: "Mayiladutjurai"
    },
    {
      value: "Mettupalayam",
      label: "Mettupalayam"
    },
    {
      value: "Metturdam",
      label: "Metturdam"
    },
    {
      value: "Mudukulathur",
      label: "Mudukulathur"
    },
    {
      value: "Mulanur",
      label: "Mulanur"
    },
    {
      value: "Musiri",
      label: "Musiri"
    },
    {
      value: "Nagapattinam",
      label: "Nagapattinam"
    },
    {
      value: "Nagarcoil",
      label: "Nagarcoil"
    },
    {
      value: "Namakkal",
      label: "Namakkal"
    },
    {
      value: "Nanguneri",
      label: "Nanguneri"
    },
    {
      value: "Natham",
      label: "Natham"
    },
    {
      value: "Neyveli",
      label: "Neyveli"
    },
    {
      value: "Nilgiris",
      label: "Nilgiris"
    },
    {
      value: "Oddanchatram",
      label: "Oddanchatram"
    },
    {
      value: "Omalpur",
      label: "Omalpur"
    },
    {
      value: "Ootacamund",
      label: "Ootacamund"
    },
    {
      value: "Ooty",
      label: "Ooty"
    },
    {
      value: "Orathanad",
      label: "Orathanad"
    },
    {
      value: "Palacode",
      label: "Palacode"
    },
    {
      value: "Palani",
      label: "Palani"
    },
    {
      value: "Palladum",
      label: "Palladum"
    },
    {
      value: "Papanasam",
      label: "Papanasam"
    },
    {
      value: "Paramakudi",
      label: "Paramakudi"
    },
    {
      value: "Pattukottai",
      label: "Pattukottai"
    },
    {
      value: "Perambalur",
      label: "Perambalur"
    },
    {
      value: "Perundurai",
      label: "Perundurai"
    },
    {
      value: "Pollachi",
      label: "Pollachi"
    },
    {
      value: "Polur",
      label: "Polur"
    },
    {
      value: "Pondicherry",
      label: "Pondicherry"
    },
    {
      value: "Ponnamaravathi",
      label: "Ponnamaravathi"
    },
    {
      value: "Ponneri",
      label: "Ponneri"
    },
    {
      value: "Pudukkottai",
      label: "Pudukkottai"
    },
    {
      value: "Rajapalayam",
      label: "Rajapalayam"
    },
    {
      value: "Ramanathapuram",
      label: "Ramanathapuram"
    },
    {
      value: "Rameshwaram",
      label: "Rameshwaram"
    },
    {
      value: "Ranipet",
      label: "Ranipet"
    },
    {
      value: "Rasipuram",
      label: "Rasipuram"
    },
    {
      value: "Salem",
      label: "Salem"
    },
    {
      value: "Sankagiri",
      label: "Sankagiri"
    },
    {
      value: "Sankaran",
      label: "Sankaran"
    },
    {
      value: "Sathiyamangalam",
      label: "Sathiyamangalam"
    },
    {
      value: "Sivaganga",
      label: "Sivaganga"
    },
    {
      value: "Sivakasi",
      label: "Sivakasi"
    },
    {
      value: "Sriperumpudur",
      label: "Sriperumpudur"
    },
    {
      value: "Srivaikundam",
      label: "Srivaikundam"
    },
    {
      value: "Tenkasi",
      label: "Tenkasi"
    },
    {
      value: "Thanjavur",
      label: "Thanjavur"
    },
    {
      value: "Theni",
      label: "Theni"
    },
    {
      value: "Thirumanglam",
      label: "Thirumanglam"
    },
    {
      value: "Thiruraipoondi",
      label: "Thiruraipoondi"
    },
    {
      value: "Thoothukudi",
      label: "Thoothukudi"
    },
    {
      value: "Thuraiyure",
      label: "Thuraiyure"
    },
    {
      value: "Tindivanam",
      label: "Tindivanam"
    },
    {
      value: "Tiruchendur",
      label: "Tiruchendur"
    },
    {
      value: "Tiruchengode",
      label: "Tiruchengode"
    },
    {
      value: "Tiruchirappalli",
      label: "Tiruchirappalli"
    },
    {
      value: "Tirunelvelli",
      label: "Tirunelvelli"
    },
    {
      value: "Tirupathur",
      label: "Tirupathur"
    },
    {
      value: "Tirupur",
      label: "Tirupur"
    },
    {
      value: "Tiruttani",
      label: "Tiruttani"
    },
    {
      value: "Tiruvallur",
      label: "Tiruvallur"
    },
    {
      value: "Tiruvannamalai",
      label: "Tiruvannamalai"
    },
    {
      value: "Tiruvarur",
      label: "Tiruvarur"
    },
    {
      value: "Tiruvellore",
      label: "Tiruvellore"
    },
    {
      value: "Tiruvettipuram",
      label: "Tiruvettipuram"
    },
    {
      value: "Trichy",
      label: "Trichy"
    },
    {
      value: "Tuticorin",
      label: "Tuticorin"
    },
    {
      value: "Udumalpet",
      label: "Udumalpet"
    },
    {
      value: "Ulundurpet",
      label: "Ulundurpet"
    },
    {
      value: "Usiliampatti",
      label: "Usiliampatti"
    },
    {
      value: "Uthangarai",
      label: "Uthangarai"
    },
    {
      value: "Valapady",
      label: "Valapady"
    },
    {
      value: "Valliyoor",
      label: "Valliyoor"
    },
    {
      value: "Vaniyambadi",
      label: "Vaniyambadi"
    },
    {
      value: "Vedasandur",
      label: "Vedasandur"
    },
    {
      value: "Vellore",
      label: "Vellore"
    },
    {
      value: "Velur",
      label: "Velur"
    },
    {
      value: "Vilathikulam",
      label: "Vilathikulam"
    },
    {
      value: "Villupuram",
      label: "Villupuram"
    },
    {
      value: "Virudhachalam",
      label: "Virudhachalam"
    },
    {
      value: "Virudhunagar",
      label: "Virudhunagar"
    },
    {
      value: "Wandiwash",
      label: "Wandiwash"
    },
    {
      value: "Yercaud",
      label: "Yercaud"
    }
  ],
  "Tripura": [
    {
      value: "Agartala",
      label: "Agartala"
    },
    {
      value: "Ambasa",
      label: "Ambasa"
    },
    {
      value: "Bampurbari",
      label: "Bampurbari"
    },
    {
      value: "Belonia",
      label: "Belonia"
    },
    {
      value: "Dhalai",
      label: "Dhalai"
    },
    {
      value: "Dharam Nagar",
      label: "Dharam Nagar"
    },
    {
      value: "Kailashahar",
      label: "Kailashahar"
    },
    {
      value: "Kamal Krishnabari",
      label: "Kamal Krishnabari"
    },
    {
      value: "Khopaiyapara",
      label: "Khopaiyapara"
    },
    {
      value: "Khowai",
      label: "Khowai"
    },
    {
      value: "Phuldungsei",
      label: "Phuldungsei"
    },
    {
      value: "Radha Kishore Pur",
      label: "Radha Kishore Pur"
    },
    {
      value: "Tripura",
      label: "Tripura"
    }
  ],
  "Uttar Pradesh": [
    {
      value: "Achhnera",
      label: "Achhnera"
    },
    {
      value: "Agra",
      label: "Agra"
    },
    {
      value: "Akbarpur",
      label: "Akbarpur"
    },
    {
      value: "Aliganj",
      label: "Aliganj"
    },
    {
      value: "Aligarh",
      label: "Aligarh"
    },
    {
      value: "Allahabad",
      label: "Allahabad"
    },
    {
      value: "Ambedkar Nagar",
      label: "Ambedkar Nagar"
    },
    {
      value: "Amethi",
      label: "Amethi"
    },
    {
      value: "Amiliya",
      label: "Amiliya"
    },
    {
      value: "Amroha",
      label: "Amroha"
    },
    {
      value: "Anola",
      label: "Anola"
    },
    {
      value: "Atrauli",
      label: "Atrauli"
    },
    {
      value: "Auraiya",
      label: "Auraiya"
    },
    {
      value: "Azamgarh",
      label: "Azamgarh"
    },
    {
      value: "Baberu",
      label: "Baberu"
    },
    {
      value: "Badaun",
      label: "Badaun"
    },
    {
      value: "Baghpat",
      label: "Baghpat"
    },
    {
      value: "Bagpat",
      label: "Bagpat"
    },
    {
      value: "Baheri",
      label: "Baheri"
    },
    {
      value: "Bahraich",
      label: "Bahraich"
    },
    {
      value: "Ballia",
      label: "Ballia"
    },
    {
      value: "Balrampur",
      label: "Balrampur"
    },
    {
      value: "Banda",
      label: "Banda"
    },
    {
      value: "Bansdeeh",
      label: "Bansdeeh"
    },
    {
      value: "Bansgaon",
      label: "Bansgaon"
    },
    {
      value: "Bansi",
      label: "Bansi"
    },
    {
      value: "Barabanki",
      label: "Barabanki"
    },
    {
      value: "Bareilly",
      label: "Bareilly"
    },
    {
      value: "Basti",
      label: "Basti"
    },
    {
      value: "Bhadohi",
      label: "Bhadohi"
    },
    {
      value: "Bharthana",
      label: "Bharthana"
    },
    {
      value: "Bharwari",
      label: "Bharwari"
    },
    {
      value: "Bhogaon",
      label: "Bhogaon"
    },
    {
      value: "Bhognipur",
      label: "Bhognipur"
    },
    {
      value: "Bidhuna",
      label: "Bidhuna"
    },
    {
      value: "Bijnore",
      label: "Bijnore"
    },
    {
      value: "Bikapur",
      label: "Bikapur"
    },
    {
      value: "Bilari",
      label: "Bilari"
    },
    {
      value: "Bilgram",
      label: "Bilgram"
    },
    {
      value: "Bilhaur",
      label: "Bilhaur"
    },
    {
      value: "Bindki",
      label: "Bindki"
    },
    {
      value: "Bisalpur",
      label: "Bisalpur"
    },
    {
      value: "Bisauli",
      label: "Bisauli"
    },
    {
      value: "Biswan",
      label: "Biswan"
    },
    {
      value: "Budaun",
      label: "Budaun"
    },
    {
      value: "Budhana",
      label: "Budhana"
    },
    {
      value: "Bulandshahar",
      label: "Bulandshahar"
    },
    {
      value: "Bulandshahr",
      label: "Bulandshahr"
    },
    {
      value: "Capianganj",
      label: "Capianganj"
    },
    {
      value: "Chakia",
      label: "Chakia"
    },
    {
      value: "Chandauli",
      label: "Chandauli"
    },
    {
      value: "Charkhari",
      label: "Charkhari"
    },
    {
      value: "Chhata",
      label: "Chhata"
    },
    {
      value: "Chhibramau",
      label: "Chhibramau"
    },
    {
      value: "Chirgaon",
      label: "Chirgaon"
    },
    {
      value: "Chitrakoot",
      label: "Chitrakoot"
    },
    {
      value: "Chunur",
      label: "Chunur"
    },
    {
      value: "Dadri",
      label: "Dadri"
    },
    {
      value: "Dalmau",
      label: "Dalmau"
    },
    {
      value: "Dataganj",
      label: "Dataganj"
    },
    {
      value: "Debai",
      label: "Debai"
    },
    {
      value: "Deoband",
      label: "Deoband"
    },
    {
      value: "Deoria",
      label: "Deoria"
    },
    {
      value: "Derapur",
      label: "Derapur"
    },
    {
      value: "Dhampur",
      label: "Dhampur"
    },
    {
      value: "Domariyaganj",
      label: "Domariyaganj"
    },
    {
      value: "Dudhi",
      label: "Dudhi"
    },
    {
      value: "Etah",
      label: "Etah"
    },
    {
      value: "Etawah",
      label: "Etawah"
    },
    {
      value: "Faizabad",
      label: "Faizabad"
    },
    {
      value: "Farrukhabad",
      label: "Farrukhabad"
    },
    {
      value: "Fatehpur",
      label: "Fatehpur"
    },
    {
      value: "Firozabad",
      label: "Firozabad"
    },
    {
      value: "Garauth",
      label: "Garauth"
    },
    {
      value: "Garhmukteshwar",
      label: "Garhmukteshwar"
    },
    {
      value: "Gautam Buddha Nagar",
      label: "Gautam Buddha Nagar"
    },
    {
      value: "Ghatampur",
      label: "Ghatampur"
    },
    {
      value: "Ghaziabad",
      label: "Ghaziabad"
    },
    {
      value: "Ghazipur",
      label: "Ghazipur"
    },
    {
      value: "Ghosi",
      label: "Ghosi"
    },
    {
      value: "Gonda",
      label: "Gonda"
    },
    {
      value: "Gorakhpur",
      label: "Gorakhpur"
    },
    {
      value: "Gunnaur",
      label: "Gunnaur"
    },
    {
      value: "Haidergarh",
      label: "Haidergarh"
    },
    {
      value: "Hamirpur",
      label: "Hamirpur"
    },
    {
      value: "Hapur",
      label: "Hapur"
    },
    {
      value: "Hardoi",
      label: "Hardoi"
    },
    {
      value: "Harraiya",
      label: "Harraiya"
    },
    {
      value: "Hasanganj",
      label: "Hasanganj"
    },
    {
      value: "Hasanpur",
      label: "Hasanpur"
    },
    {
      value: "Hathras",
      label: "Hathras"
    },
    {
      value: "Jalalabad",
      label: "Jalalabad"
    },
    {
      value: "Jalaun",
      label: "Jalaun"
    },
    {
      value: "Jalesar",
      label: "Jalesar"
    },
    {
      value: "Jansath",
      label: "Jansath"
    },
    {
      value: "Jarar",
      label: "Jarar"
    },
    {
      value: "Jasrana",
      label: "Jasrana"
    },
    {
      value: "Jaunpur",
      label: "Jaunpur"
    },
    {
      value: "Jhansi",
      label: "Jhansi"
    },
    {
      value: "Jyotiba Phule Nagar",
      label: "Jyotiba Phule Nagar"
    },
    {
      value: "Kadipur",
      label: "Kadipur"
    },
    {
      value: "Kaimganj",
      label: "Kaimganj"
    },
    {
      value: "Kairana",
      label: "Kairana"
    },
    {
      value: "Kaisarganj",
      label: "Kaisarganj"
    },
    {
      value: "Kalpi",
      label: "Kalpi"
    },
    {
      value: "Kannauj",
      label: "Kannauj"
    },
    {
      value: "Kanpur",
      label: "Kanpur"
    },
    {
      value: "Karchhana",
      label: "Karchhana"
    },
    {
      value: "Karhal",
      label: "Karhal"
    },
    {
      value: "Karvi",
      label: "Karvi"
    },
    {
      value: "Kasganj",
      label: "Kasganj"
    },
    {
      value: "Kaushambi",
      label: "Kaushambi"
    },
    {
      value: "Kerakat",
      label: "Kerakat"
    },
    {
      value: "Khaga",
      label: "Khaga"
    },
    {
      value: "Khair",
      label: "Khair"
    },
    {
      value: "Khalilabad",
      label: "Khalilabad"
    },
    {
      value: "Kheri",
      label: "Kheri"
    },
    {
      value: "Konch",
      label: "Konch"
    },
    {
      value: "Kumaon",
      label: "Kumaon"
    },
    {
      value: "Kunda",
      label: "Kunda"
    },
    {
      value: "Kushinagar",
      label: "Kushinagar"
    },
    {
      value: "Lalganj",
      label: "Lalganj"
    },
    {
      value: "Lalitpur",
      label: "Lalitpur"
    },
    {
      value: "Lucknow",
      label: "Lucknow"
    },
    {
      value: "Machlishahar",
      label: "Machlishahar"
    },
    {
      value: "Maharajganj",
      label: "Maharajganj"
    },
    {
      value: "Mahoba",
      label: "Mahoba"
    },
    {
      value: "Mainpuri",
      label: "Mainpuri"
    },
    {
      value: "Malihabad",
      label: "Malihabad"
    },
    {
      value: "Mariyahu",
      label: "Mariyahu"
    },
    {
      value: "Math",
      label: "Math"
    },
    {
      value: "Mathura",
      label: "Mathura"
    },
    {
      value: "Mau",
      label: "Mau"
    },
    {
      value: "Maudaha",
      label: "Maudaha"
    },
    {
      value: "Maunathbhanjan",
      label: "Maunathbhanjan"
    },
    {
      value: "Mauranipur",
      label: "Mauranipur"
    },
    {
      value: "Mawana",
      label: "Mawana"
    },
    {
      value: "Meerut",
      label: "Meerut"
    },
    {
      value: "Mehraun",
      label: "Mehraun"
    },
    {
      value: "Meja",
      label: "Meja"
    },
    {
      value: "Mirzapur",
      label: "Mirzapur"
    },
    {
      value: "Misrikh",
      label: "Misrikh"
    },
    {
      value: "Modinagar",
      label: "Modinagar"
    },
    {
      value: "Mohamdabad",
      label: "Mohamdabad"
    },
    {
      value: "Mohamdi",
      label: "Mohamdi"
    },
    {
      value: "Moradabad",
      label: "Moradabad"
    },
    {
      value: "Musafirkhana",
      label: "Musafirkhana"
    },
    {
      value: "Muzaffarnagar",
      label: "Muzaffarnagar"
    },
    {
      value: "Nagina",
      label: "Nagina"
    },
    {
      value: "Najibabad",
      label: "Najibabad"
    },
    {
      value: "Nakur",
      label: "Nakur"
    },
    {
      value: "Nanpara",
      label: "Nanpara"
    },
    {
      value: "Naraini",
      label: "Naraini"
    },
    {
      value: "Naugarh",
      label: "Naugarh"
    },
    {
      value: "Nawabganj",
      label: "Nawabganj"
    },
    {
      value: "Nighasan",
      label: "Nighasan"
    },
    {
      value: "Noida",
      label: "Noida"
    },
    {
      value: "Orai",
      label: "Orai"
    },
    {
      value: "Padrauna",
      label: "Padrauna"
    },
    {
      value: "Pahasu",
      label: "Pahasu"
    },
    {
      value: "Patti",
      label: "Patti"
    },
    {
      value: "Pharenda",
      label: "Pharenda"
    },
    {
      value: "Phoolpur",
      label: "Phoolpur"
    },
    {
      value: "Phulpur",
      label: "Phulpur"
    },
    {
      value: "Pilibhit",
      label: "Pilibhit"
    },
    {
      value: "Pitamberpur",
      label: "Pitamberpur"
    },
    {
      value: "Powayan",
      label: "Powayan"
    },
    {
      value: "Pratapgarh",
      label: "Pratapgarh"
    },
    {
      value: "Puranpur",
      label: "Puranpur"
    },
    {
      value: "Purwa",
      label: "Purwa"
    },
    {
      value: "Raibareli",
      label: "Raibareli"
    },
    {
      value: "Rampur",
      label: "Rampur"
    },
    {
      value: "Ramsanehi Ghat",
      label: "Ramsanehi Ghat"
    },
    {
      value: "Rasara",
      label: "Rasara"
    },
    {
      value: "Rath",
      label: "Rath"
    },
    {
      value: "Robertsganj",
      label: "Robertsganj"
    },
    {
      value: "Sadabad",
      label: "Sadabad"
    },
    {
      value: "Safipur",
      label: "Safipur"
    },
    {
      value: "Sagri",
      label: "Sagri"
    },
    {
      value: "Saharanpur",
      label: "Saharanpur"
    },
    {
      value: "Sahaswan",
      label: "Sahaswan"
    },
    {
      value: "Sahjahanpur",
      label: "Sahjahanpur"
    },
    {
      value: "Saidpur",
      label: "Saidpur"
    },
    {
      value: "Salempur",
      label: "Salempur"
    },
    {
      value: "Salon",
      label: "Salon"
    },
    {
      value: "Sambhal",
      label: "Sambhal"
    },
    {
      value: "Sandila",
      label: "Sandila"
    },
    {
      value: "Sant Kabir Nagar",
      label: "Sant Kabir Nagar"
    },
    {
      value: "Sant Ravidas Nagar",
      label: "Sant Ravidas Nagar"
    },
    {
      value: "Sardhana",
      label: "Sardhana"
    },
    {
      value: "Shahabad",
      label: "Shahabad"
    },
    {
      value: "Shahganj",
      label: "Shahganj"
    },
    {
      value: "Shahjahanpur",
      label: "Shahjahanpur"
    },
    {
      value: "Shikohabad",
      label: "Shikohabad"
    },
    {
      value: "Shravasti",
      label: "Shravasti"
    },
    {
      value: "Siddharthnagar",
      label: "Siddharthnagar"
    },
    {
      value: "Sidhauli",
      label: "Sidhauli"
    },
    {
      value: "Sikandra Rao",
      label: "Sikandra Rao"
    },
    {
      value: "Sikandrabad",
      label: "Sikandrabad"
    },
    {
      value: "Sitapur",
      label: "Sitapur"
    },
    {
      value: "Siyana",
      label: "Siyana"
    },
    {
      value: "Sonbhadra",
      label: "Sonbhadra"
    },
    {
      value: "Soraon",
      label: "Soraon"
    },
    {
      value: "Sultanpur",
      label: "Sultanpur"
    },
    {
      value: "Tanda",
      label: "Tanda"
    },
    {
      value: "Tarabganj",
      label: "Tarabganj"
    },
    {
      value: "Tilhar",
      label: "Tilhar"
    },
    {
      value: "Unnao",
      label: "Unnao"
    },
    {
      value: "Utraula",
      label: "Utraula"
    },
    {
      value: "Varanasi",
      label: "Varanasi"
    },
    {
      value: "Zamania",
      label: "Zamania"
    }
  ],
  "Uttarakhand": [
    {
      value: "Almora",
      label: "Almora"
    },
    {
      value: "Bageshwar",
      label: "Bageshwar"
    },
    {
      value: "Bhatwari",
      label: "Bhatwari"
    },
    {
      value: "Chakrata",
      label: "Chakrata"
    },
    {
      value: "Chamoli",
      label: "Chamoli"
    },
    {
      value: "Champawat",
      label: "Champawat"
    },
    {
      value: "Dehradun",
      label: "Dehradun"
    },
    {
      value: "Deoprayag",
      label: "Deoprayag"
    },
    {
      value: "Dharchula",
      label: "Dharchula"
    },
    {
      value: "Dunda",
      label: "Dunda"
    },
    {
      value: "Haldwani",
      label: "Haldwani"
    },
    {
      value: "Haridwar",
      label: "Haridwar"
    },
    {
      value: "Joshimath",
      label: "Joshimath"
    },
    {
      value: "Karan Prayag",
      label: "Karan Prayag"
    },
    {
      value: "Kashipur",
      label: "Kashipur"
    },
    {
      value: "Khatima",
      label: "Khatima"
    },
    {
      value: "Kichha",
      label: "Kichha"
    },
    {
      value: "Lansdown",
      label: "Lansdown"
    },
    {
      value: "Munsiari",
      label: "Munsiari"
    },
    {
      value: "Mussoorie",
      label: "Mussoorie"
    },
    {
      value: "Nainital",
      label: "Nainital"
    },
    {
      value: "Pantnagar",
      label: "Pantnagar"
    },
    {
      value: "Partapnagar",
      label: "Partapnagar"
    },
    {
      value: "Pauri Garhwal",
      label: "Pauri Garhwal"
    },
    {
      value: "Pithoragarh",
      label: "Pithoragarh"
    },
    {
      value: "Purola",
      label: "Purola"
    },
    {
      value: "Rajgarh",
      label: "Rajgarh"
    },
    {
      value: "Ranikhet",
      label: "Ranikhet"
    },
    {
      value: "Roorkee",
      label: "Roorkee"
    },
    {
      value: "Rudraprayag",
      label: "Rudraprayag"
    },
    {
      value: "Tehri Garhwal",
      label: "Tehri Garhwal"
    },
    {
      value: "Udham Singh Nagar",
      label: "Udham Singh Nagar"
    },
    {
      value: "Ukhimath",
      label: "Ukhimath"
    },
    {
      value: "Uttarkashi",
      label: "Uttarkashi"
    }
  ],
  "West Bengal": [
    {
      value: "Adra",
      label: "Adra"
    },
    {
      value: "Alipurduar",
      label: "Alipurduar"
    },
    {
      value: "Amlagora",
      label: "Amlagora"
    },
    {
      value: "Arambagh",
      label: "Arambagh"
    },
    {
      value: "Asansol",
      label: "Asansol"
    },
    {
      value: "Balurghat",
      label: "Balurghat"
    },
    {
      value: "Bankura",
      label: "Bankura"
    },
    {
      value: "Bardhaman",
      label: "Bardhaman"
    },
    {
      value: "Basirhat",
      label: "Basirhat"
    },
    {
      value: "Berhampur",
      label: "Berhampur"
    },
    {
      value: "Bethuadahari",
      label: "Bethuadahari"
    },
    {
      value: "Birbhum",
      label: "Birbhum"
    },
    {
      value: "Birpara",
      label: "Birpara"
    },
    {
      value: "Bishanpur",
      label: "Bishanpur"
    },
    {
      value: "Bolpur",
      label: "Bolpur"
    },
    {
      value: "Bongoan",
      label: "Bongoan"
    },
    {
      value: "Bulbulchandi",
      label: "Bulbulchandi"
    },
    {
      value: "Burdwan",
      label: "Burdwan"
    },
    {
      value: "Calcutta",
      label: "Calcutta"
    },
    {
      value: "Canning",
      label: "Canning"
    },
    {
      value: "Champadanga",
      label: "Champadanga"
    },
    {
      value: "Contai",
      label: "Contai"
    },
    {
      value: "Cooch Behar",
      label: "Cooch Behar"
    },
    {
      value: "Daimond Harbour",
      label: "Daimond Harbour"
    },
    {
      value: "Dalkhola",
      label: "Dalkhola"
    },
    {
      value: "Dantan",
      label: "Dantan"
    },
    {
      value: "Darjeeling",
      label: "Darjeeling"
    },
    {
      value: "Dhaniakhali",
      label: "Dhaniakhali"
    },
    {
      value: "Dhuliyan",
      label: "Dhuliyan"
    },
    {
      value: "Dinajpur",
      label: "Dinajpur"
    },
    {
      value: "Dinhata",
      label: "Dinhata"
    },
    {
      value: "Durgapur",
      label: "Durgapur"
    },
    {
      value: "Gangajalghati",
      label: "Gangajalghati"
    },
    {
      value: "Gangarampur",
      label: "Gangarampur"
    },
    {
      value: "Ghatal",
      label: "Ghatal"
    },
    {
      value: "Guskara",
      label: "Guskara"
    },
    {
      value: "Habra",
      label: "Habra"
    },
    {
      value: "Haldia",
      label: "Haldia"
    },
    {
      value: "Harirampur",
      label: "Harirampur"
    },
    {
      value: "Harishchandrapur",
      label: "Harishchandrapur"
    },
    {
      value: "Hooghly",
      label: "Hooghly"
    },
    {
      value: "Howrah",
      label: "Howrah"
    },
    {
      value: "Islampur",
      label: "Islampur"
    },
    {
      value: "Jagatballavpur",
      label: "Jagatballavpur"
    },
    {
      value: "Jalpaiguri",
      label: "Jalpaiguri"
    },
    {
      value: "Jhalda",
      label: "Jhalda"
    },
    {
      value: "Jhargram",
      label: "Jhargram"
    },
    {
      value: "Kakdwip",
      label: "Kakdwip"
    },
    {
      value: "Kalchini",
      label: "Kalchini"
    },
    {
      value: "Kalimpong",
      label: "Kalimpong"
    },
    {
      value: "Kalna",
      label: "Kalna"
    },
    {
      value: "Kandi",
      label: "Kandi"
    },
    {
      value: "Karimpur",
      label: "Karimpur"
    },
    {
      value: "Katwa",
      label: "Katwa"
    },
    {
      value: "Kharagpur",
      label: "Kharagpur"
    },
    {
      value: "Khatra",
      label: "Khatra"
    },
    {
      value: "Krishnanagar",
      label: "Krishnanagar"
    },
    {
      value: "Mal Bazar",
      label: "Mal Bazar"
    },
    {
      value: "Malda",
      label: "Malda"
    },
    {
      value: "Manbazar",
      label: "Manbazar"
    },
    {
      value: "Mathabhanga",
      label: "Mathabhanga"
    },
    {
      value: "Medinipur",
      label: "Medinipur"
    },
    {
      value: "Mekhliganj",
      label: "Mekhliganj"
    },
    {
      value: "Mirzapur",
      label: "Mirzapur"
    },
    {
      value: "Murshidabad",
      label: "Murshidabad"
    },
    {
      value: "Nadia",
      label: "Nadia"
    },
    {
      value: "Nagarakata",
      label: "Nagarakata"
    },
    {
      value: "Nalhati",
      label: "Nalhati"
    },
    {
      value: "Nayagarh",
      label: "Nayagarh"
    },
    {
      value: "Parganas",
      label: "Parganas"
    },
    {
      value: "Purulia",
      label: "Purulia"
    },
    {
      value: "Raiganj",
      label: "Raiganj"
    },
    {
      value: "Rampur Hat",
      label: "Rampur Hat"
    },
    {
      value: "Ranaghat",
      label: "Ranaghat"
    },
    {
      value: "Seharabazar",
      label: "Seharabazar"
    },
    {
      value: "Siliguri",
      label: "Siliguri"
    },
    {
      value: "Suri",
      label: "Suri"
    },
    {
      value: "Takipur",
      label: "Takipur"
    },
    {
      value: "Tamluk",
      label: "Tamluk"
    }
  ],
  "Telangana": [
    {
      "value": "Hyderabad",
      "label": "Hyderabad"
    },
    {
      "value": "Warangal",
      "label": "Warangal"
    },
    {
      "value": "Nizamabad",
      "label": "Nizamabad"
    },
    {
      "value": "Khammam",
      "label": "Khammam"
    },
    {
      "value": "Karimnagar",
      "label": "Karimnagar"
    },
    {
      "value": "Ramagundam",
      "label": "Ramagundam"
    },
    {
      "value": "Mahbubnagar",
      "label": "Mahbubnagar"
    },
    {
      "value": "Suryapet",
      "label": "Suryapet"
    },
    {
      "value": "Nalgonda",
      "label": "Nalgonda"
    },
    {
      "value": "Miryalaguda",
      "label": "Miryalaguda"
    }
  ],
  "Ladakh": [
    {
      "value": "Leh",
      "label": "Leh"
    },
    {
      "value": "Kargil",
      "label": "Kargil"
    },
    {
      "value": "Nubra",
      "label": "Nubra"
    }
  ]
}


// export const cityOptions = [
//   { value: "Prayagraj", label: "Prayagraj" },
//   { value: "Bangalore", label: "Bangalore" },
// ];

export const weekdaysOptions = [
  {
    value: "monday",
    label: "Mon",
  },
  {
    value: "tuesday",
    label: "Tue",
  },
  {
    value: "wednesday",
    label: "Wed",
  },
  {
    value: "thursday",
    label: "Thu",
  },
  {
    value: "friday",
    label: "Fri",
  },
];

export const concernOptions = [
  { value: "Account Issues", label: "Account Issues" },
  { value: "Plan Information", label: "Plan Information" },
  { value: "Billing and Payments", label: "Billing and Payments" },
  { value: "Technical Support", label: "Technical Support" },
  { value: "Feedback and Suggestions", label: "Feedback and Suggestions" },
  // { value: "Partnership Opportunity", label: "Partnership Opportunity" },
  // { value: "Media/Press Inquiry", label: "Media/Press Inquiry" },
  // { value: "Product Information", label: "Product Information" },
  // { value: "Career Opportunities", label: "Career Opportunities" },
  // { value: "Other", label: "Other" },
];

export const issueOptions = [
  {
    value: "forgot_password",
    label: "Forgot Password",
    concernId: "Account Issues",
  },
  {
    value: "profile_update",
    label: "Profile Update",
    concernId: "Account Issues",
  },
  {
    value: "login_problems",
    label: "Login Problems",
    concernId: "Account Issues",
  },
  {
    value: "plan_details_and_coverage",
    label: "Plan Details and Coverage",
    concernId: "Plan Information",
  },
  { value: "change_plan", label: "Change Plan", concernId: "Plan Information" },
  {
    value: "terminate_plan",
    label: "Terminate Plan",
    concernId: "Plan Information",
  },
  {
    value: "billing_inquiries",
    label: "Billing Inquiries",
    concernId: "Billing and Payments",
  },
  {
    value: "payment_issues",
    label: "Payment Issues",
    concernId: "Billing and Payments",
  },
  {
    value: "refund_request",
    label: "Refund Request",
    concernId: "Billing and Payments",
  },
  {
    value: "website_issues",
    label: "Website Issues",
    concernId: "Technical Support",
  },
  {
    value: "mobile_app_issues",
    label: "Mobile App Issues",
    concernId: "Technical Support",
  },
  {
    value: "technical_errors",
    label: "Technical Errors",
    concernId: "Technical Support",
  },
  {
    value: "accessibility_concerns",
    label: "Accessibility Concerns",
    concernId: "Technical Support",
  },
  {
    value: "website_feedback",
    label: "Website Feedback",
    concernId: "Feedback and Suggestions",
  },
  {
    value: "service_feedback",
    label: "Service Feedback",
    concernId: "Feedback and Suggestions",
  },
  {
    value: "improvement_suggestions",
    label: "Improvement Suggestions",
    concernId: "Feedback and Suggestions",
  },
  {
    value: "testimonial_submission",
    label: "Testimonial Submission",
    concernId: "Feedback and Suggestions",
  },
];
