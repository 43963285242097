import {
  faTableCellsLarge,
  faUserDoctor,
  faUserInjured,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type TabNames =
  | "Dietitian Evaluation"
  | "Enrolled Patient"
  | "Enrolled Dietitians";

interface TabOption {
  name: TabNames;
  icon: any;
}

const tabOptions: TabOption[] = [
  { name: "Dietitian Evaluation", icon: faTableCellsLarge },
  { name: "Enrolled Patient", icon: faUserInjured },
  { name: "Enrolled Dietitians", icon: faUserDoctor },
];

interface TabBarProps {
  onTabClick: (tab: TabNames) => void;
  activeTab: string;
}

const TabBar: React.FC<TabBarProps> = ({ activeTab, onTabClick }) => {
  return (
    <>
      <div className="bg-[#315145] flex flex-row justify-between px-8 py-4 lg:py-8">
        <img
          src="/betterNowLogo.png"
          className="h-[27px] w-[100px] lg:w-[200px] lg:h-[55px] "
          alt="Betternow-logo"
        />
        <div className="bg-[#FFFFF5] text-[10px] lg:text-[20px] text-[#315145] rounded-lg  items-center flex px-8 cursor-pointer font-normal">
          Logout
        </div>
      </div>
      <div className="sm:hidden px-8 bg-[#315145]">
        <select
          className="bg-[#315145] text-[10px] text-[#FFFFF5]  block w-full py-2.5 focus:outline-none"
          value={activeTab}
          onChange={(e) => onTabClick(e.target.value as TabNames)}
        >
          {tabOptions.map((item, index) => (
            <option key={index} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <ul className="hidden text-[20px] bg-[#315145] text-center sm:flex p-2.5 space-x-4 px-8">
        {tabOptions.map((item, index) => (
          <li key={index} className="w-full cursor-pointer">
            <div
              className={`inline-block w-full p-2 transition duration-300 ease-in-out ${
                activeTab === item.name ? "bg-[#FFFFF5]" : ""
              } ${
                activeTab === item.name ? "text-[#315145]" : "text-[#FFFFF5]"
              } rounded-lg focus:outline-none`}
              aria-current="page"
              onClick={() => onTabClick(item.name)}
            >
              <FontAwesomeIcon
                icon={item.icon}
                color={activeTab === item.name ? "#315145" : "#FFFFF5"}
                size="lg"
                className="mx-2"
              />
              {item.name}
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default TabBar;
