import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import FAQService from "../../utils/FAQService";
import FaqSchema from "../../utils/validation/FAQValidation";
export interface FAQData {
  email: string;
  faqType: string;
  faqQues: string;
  contact: string;
}
const SectionTwo: React.FC = () => {
  const [data, setData] = useState<FAQData>({
    email: "",
    faqQues: "",
    faqType: "Just Information",
    contact: "",
  });
  const [loading, setLoading] = useState(false);
  const [Msg, setMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [Error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const setDefault = () => {
    setData({
      email: "",
      faqQues: "",
      faqType: "Just Information",
      contact: "",
    });
  };
 
  // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault(); // Prevent the form from reloading the page
  
  //   // const { ...payload } = data;
  //   const payload = { payload:data };
  
  //   try {
  //     // POST request to the API endpoint
  //     const response = await axios.post("https://backend.betternow.co.in/contact", payload, {
  //       headers: {
  //         "Content-Type": "application/json", // Set content type
  //       },
  //     });
  
  //     if (response.status === 200) {
  //       toast.error("Sucessfully submitted")
  //     }
  //   } catch (error) {
  //       toast.error("error occured")
  //   }
  // };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the form from reloading the page

    const payload = {payload: data};

    setLoading(true); // Start loading

    try {
      // POST request to the API endpoint
      const response = await axios.post("https://backend.betternow.co.in/contact", payload, {
        headers: {
          "Content-Type": "application/json", // Set content type
        },
      });

      if (response.status === 200) {
        toast.success("Thank you! Your question has been successfully submitted.");

        // Clear form fields
        setDefault();
        setShowMsg(true);
        setMsg("Your question has been successfully submitted.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
      setError(true);
      setErrorMsg("An error occurred. Please try again later.");
    } finally {
      setLoading(false); // End loading
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="bg-[#FFFFF5] flex flex-col justify-center items-center pr-[2.7em] pl-[2.7em] xs:px-[20px] pb-[80px] xs:pb-[40px]"
    >
      <div className=" bg-[#EFEFEA] w-full rounded-[0.625rem] mt-8 p-[3rem] lg:w-[75vw] xs:p-[24px]">
        <div className="text-[1rem] md:text-[1.5rem] font-medium ">
          What is your Question?
        </div>
        <textarea
          rows={4}
          value={data.faqQues}
          onChange={(e: any) => setData({ ...data, faqQues: e.target.value })}
          className="border-[3px] border-[#A4A4A4] p-2.5 w-full bg-[#EFEFEA] rounded-[0.625rem] text-left placeholder-[#7B7A7A] text-[1rem] md:text-[1.25rem] mt-4 focus:outline-none"
          placeholder="Type your question here."
        />

        <div className="text-[1rem] font-medium md:text-[1.5rem] mt-6 ">
          I am looking for
        </div>
        <div className="flex items-center mt-4 xs:flex-col xs:items-start xs:gap-[10px]">
          <div className="mr-4 flex justify-center items-center">
            <input
              type="radio"
              value="Just Information"
              checked={data.faqType === "Just Information"}
              onChange={(e: any) =>
                setData({ ...data, faqType: e.target.value })
              }
              name="details"
              className="w-6 h-4 bg-gray-100 border-[#484747]"
            />
            <label className="ms-2 text-[0.9rem] md:text-[1.125rem] text-[#484747]">
              Just Information
            </label>
          </div>
          <div className="mr-4 flex justify-center items-center">
            <input
              type="radio"
              value="Treatment details"
              name="details"
              checked={data.faqType === "Treatment details"}
              onChange={(e: any) =>
                setData({ ...data, faqType: e.target.value })
              }
              className="w-6 h-4 bg-gray-100 border-[#484747]"
            />
            <label className="ms-2 text-[0.9rem] md:text-[1.125rem] text-[#484747]">
              Treatment details
            </label>
          </div>
        </div>

        <div className="text-[1rem] md:text-[1.5rem] mt-[24px] font-medium ">
          Where should we send notification?
        </div>
        <div className="flex flex-col md:flex-row gap-4 mt-2 md:mt-4 ">
  <div className="basis-[40%] px-4 flex rounded-[0.625rem] border-[3px] border-[#A4A4A4]  bg-[#EFEFEA] min-h-[45px]">
    <input
      type="email"
      className="w-full placeholder-[#7B7A7A] bg-[#EFEFEA] text-[0.9rem] md:text-[1.25rem] leading-relaxed focus:outline-none"
      placeholder="Your email address"
      value={data.email}
      onChange={(e: any) => setData({ ...data, email: e.target.value })}
    />
  </div>
  <div className="basis-[40%] flex flex-row items-center px-4  rounded-[0.625rem] border-[3px] border-[#A4A4A4]  bg-[#EFEFEA]">
    <span className=" text-[#7B7A7A] text-[0.9rem] md:text-[1.25rem]">+91</span>
    <span className="text-[#D9D9D9] mx-0.5 text-[0.9rem] md:text-[1.25rem]">|</span>
    <input
      type="tel"
      value={data.contact}
      onChange={(e: any) =>
        setData({ ...data, contact: e.target.value })
      }
      className="w-full pl-2 py-2 text-[0.9rem] md:text-[1.25rem] placeholder-[#7B7A7A] bg-[#EFEFEA] leading-relaxed focus:outline-none"
      placeholder="Your mobile number"
    />
  </div>
</div>

        {showMsg && <div className="text-s text-green-600 text-center mt-[18px]">{Msg}</div>}
        {Error && <div className="text-xs text-rose-600">{errorMsg}</div>}
        <div className=" mt-[28px] md:mt-6 flex flex-col items-center  ">
          <button
            type="submit"
            disabled={loading}
            className="bg-[#315145] text-white font-medium py-2 px-4 rounded-[2rem] text-[1rem] md:text-[1.5rem] md:w-2/5  "
          >
            {!loading ? "Ask Questions Securely" : "Loading..."}
          </button>
          <div className="pl-2flex flex-col md:flex-row  mt-4 md:w-4/5 md:self-center text-center gap-2 text-[#7A7A7A] text-[0.9rem] md:text-[1.125rem]">
            Complete privacy and anonymity guranted &nbsp;
            <span className="text-[#7A7A7A] text-[0.9rem] md:text-[1.125rem]">
              • Quick responses
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SectionTwo;
