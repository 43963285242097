import React from "react";

const contactOptions = [
  { contactMethod: "E-Mail", value: "hello@betternow.co.in" },
  // {
  //   contactMethod: "Address",
  //   value: `1st Floor, 780, Sector-40 \n Gurugram, Haryana- 122001`,
  // },
  { contactMethod: "Phone", value: "+91-9870369693" },
];
const SectionTwo: React.FC = () => {
  return (
    <div className="bg-[#FFFFF5] flex px-6 lg:px-16 py-8 text-[#FFFFFF]">
      <div className="bg-[#315145] px-4  w-full flex flex-col py-8 rounded-lg">
        <div className="flex justify-center text-[13px] lg:text-[26px] font-bold my-4">
          Let’s connect and talk.
        </div>
        <div className="flex flex-col lg:flex-row">
          {contactOptions.map((opt, index) => (
            <div
              className="flex flex-col lg:flex-row  items-center flex-1"
              key={index}
            >
              <div
                className={`h-12 w-2 bg-[#3c6455] ${
                  index !== 0 ? "hidden lg:block" : "hidden"
                }`}
              />
              <div
                className={`h-1 w-full bg-[#3c6455] mt-4 ${
                  index !== 0 ? "block lg:hidden" : "hidden"
                }`}
              />
              <div className=" flex flex-col  item-center justify-center text-[10px] lg:text-[20px] font-medium text-center flex-1">
                <div className="my-4">{opt.contactMethod}</div>
                <div className="whitespace-pre-line">{opt.value}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
