
import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import Select from "react-tailwindcss-select";
import { concernOptions, issueOptions } from "../../constants/DropDownOptions";
import styled from "styled-components";

export interface ContactUsData {
  name: string;
  email: string;
  issueType: any;
  concernType: string;
  concern: string;
  contact: string;
}

interface TextInputProps {
  placeholder?: string;
  required?: boolean;
  type?: string;
  value: string;
  onChange: (e: any) => void;
}

interface DropdownOption {
  value: string;
  label: string;
  concernId?: string;
}

interface DropdownProps {
  value: DropdownOption | null;
  onChange: (selectedOption: DropdownOption | null) => void;
  options: DropdownOption[];
  placeholder?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  placeholder = "",
  required = false,
  type = "text",
  value,
  onChange,
}) => {
  return (
    <div>
      <label className="text-[12px] lg:text-[20px] text-[#8B8B8B] px-2 mb-1 block"></label>
      <input
        type={type}
        value={value}
        onChange={onChange}
        className="border-4 border-gray-300 bg-[#EFEFEA] text-[12px] lg:text-[20px] rounded-lg w-full p-2.5 placeholder:text-[#8B8B8B]"
        placeholder={placeholder}
        required={required}
      />
    </div>
  );
};

const Dropdown: React.FC<DropdownProps> = ({
  value,
  onChange,
  options,
  placeholder,
}) => (
  <StyledDiv>
    <Select
      value={value}
      onChange={(option) => onChange(option as DropdownOption | null)}
      options={options}
      primaryColor="#8B8B8B"
      placeholder={placeholder}
      classNames={{
        menu: "absolute z-10 w-full bg-[#FFFFF5] rounded-lg p-2.5 mt-1.5 text-[10px] lg:text-[18px] text-[#8B8B8B] px-0",
        menuButton: () =>
          "flex text-[0.8rem]  md:text-lg rounded-lg bg-[#EFEFEA] border-4 border-gray-300 h-[45px] leading-100",     
       }}
    />
  </StyledDiv>
);

const ContactUsForm: React.FC = () => {
  const [selectedConcern, setSelectedConcern] = useState<DropdownOption | null>(null);
  const [selectedIssue, setSelectedIssue] = useState<DropdownOption | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [concern, setConcern] = useState("");
  const [msg, setMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);

  const handleConcernChange = (selectedOption: DropdownOption | null) => {
    setSelectedConcern(selectedOption);
    setSelectedIssue(null); // Reset the issue dropdown when concern changes
  };

  const handleIssueChange = (selectedOption: DropdownOption | null) => {
    setSelectedIssue(selectedOption);
  };


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the form from reloading the page
  
    // Construct the payload
    const payload = {
      payload: {
        name,
      email,
      contact,
      message: concern,
      concern: selectedConcern?.label,
      issue: selectedIssue?.label
      }
    };
  
    setLoading(true); // Start loading
  
    try {
      // POST request to the API endpoint
      const response = await axios.post("https://backend.betternow.co.in/contact", payload, {
        headers: {
          "Content-Type": "application/json", // Set content type
        },
      });
  
      if (response.status === 200) {
        // Show success toast
        toast.success("Thank you! Your message has been successfully submitted.");
  
        // Clear form fields
        setSelectedConcern(null);
        setSelectedIssue(null);
        setName("");
        setEmail("");
        setContact("");
        setConcern("");
        setShowMsg(true);
        setMsg("Thank you! Your message have been successfully submitted.");
      }
    } catch (error) {
      // Show error toast
      toast.error("An error occurred. Please try again later.");
      setError(true);
      setErrorMsg("An error occurred. Please try again later.");
    } finally {
      setLoading(false); // End loading
    }
  };
  const filteredIssueOptions = issueOptions.filter(
    (issue) => issue.concernId === selectedConcern?.value
  );

  return (
    <section className="bg-[#EFEFEA] w-660px rounded-lg py-6 justify-center flex">
      <div className="px-4 lg:px-16 w-[100%]">
        <div className="mb-4 text-[15px] lg:text-[30px] font-bold text-center ">
          Let’s connect and talk.
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <Dropdown
            value={selectedConcern}
            onChange={handleConcernChange}
            options={concernOptions}
            placeholder="Select your concern"
          />
          <Dropdown
            value={selectedIssue}
            onChange={handleIssueChange}
            options={filteredIssueOptions}
            placeholder="Select the issue you are facing"
          />
          <TextInput
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
          <TextInput
            required
            type="tel"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            placeholder="Phone"
          />
          <TextInput
            required
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <div>
  <textarea
    rows={4}
    value={concern}
    placeholder="Explain your concern (optional)"
    onChange={(e: any) => setConcern(e.target.value)}
    className="border-4 border-gray-300 bg-[#EFEFEA] text-[12px] lg:text-[20px] rounded-lg w-full p-2.5 placeholder:text-[#8B8B8B]"
  />
</div>

          {showMsg && <div className="text-s text-green-600">{msg}</div>}
          {error && <div className="text-xs text-rose-600">{errorMsg}</div>}
          <button
            type="submit"
            disabled={loading}
            className="bg-[#315145] text-white py-2 px-4 rounded-lg text-[12px] lg:text-[24px] font-medium w-full"
          >
            {!loading ? "Submit" : "Loading..."}
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactUsForm;
const StyledDiv = styled.div`
line-height:100% !important;
.truncate {
  line-height:100% !important;
}
.select-none {
}
`