import React from "react";
import EnrollmentForm from "../Forms/EnrollmentForm";

const SectionOne: React.FC = () => {
  return (
    <div className="bg-[#FFFFF5] ">
      <EnrollmentForm />
    </div>
  );
};

export default SectionOne;
