import React from "react";
import { FAQAll } from "../../constants/FAQData";
import FAQAccordion from "../Accordion/FAQAccordian";

const SectionOne: React.FC = () => {
  // const splittedArray = [];

  // for (let i = 0; i < FAQAll.length; i += 4) {
  //   const subArray = FAQAll.slice(i, i + 4);
  //   splittedArray.push(subArray);
  // }
  const splittedArray = [];
  const middleIndex = Math.ceil(FAQAll.length / 2); // Calculate the middle index to split into two halves

  const firstHalf = FAQAll.slice(0, middleIndex); // First half of the array
  const secondHalf = FAQAll.slice(middleIndex); // Second half of the array

  splittedArray.push(firstHalf, secondHalf);
  return (
    <div className="bg-[#FFFFF5] flex flex-col justify-center items-center pt-8 px-4">
      <div className="text-[1.75rem] font-semibold text-[#325243] xs:text-[1.4rem]">
        Frequently Asked Questions
      </div>
      {/* Conditionally render based on screen size */}
      <div className="flex flex-col md:hidden px-4 xs:px-[0px] mt-8 lg:w-[80vw] lg:px-0">
        {FAQAll.map((item, index) => (
          <FAQAccordion
            key={index}
            index={index}
            title={item.title}
            answer={item.answer}
          />
        ))}
      </div>

      {/* Render for larger screens using splittedArray */}
      <div className="hidden md:flex flex-col md:flex-row flex-wrap justify-center px-4 mt-8 lg:w-[85vw] lg:px-0">
        {splittedArray.map((item, outerIndex) => (
          <div key={outerIndex} className="w-full md:w-[50%] p-2 flex flex-col gap-[6px]">
            {item.map((faqItem, innerIndex) => (
              <FAQAccordion
                key={innerIndex}
                index={outerIndex * 4 + innerIndex}
                title={faqItem.title}
                answer={faqItem.answer}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionOne;
