import React, { useEffect, useState } from "react";
import { Dietician } from "../../model/DieticianDataModel";
import toast, { Toaster } from "react-hot-toast";
import LoginModal from "../../components/Modals/LoginModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface CheckOutProp {
  dietitianId: string | undefined;
  months: number | 0;
  dateTimeOfAppointment: string | undefined;
  dieticianData: Dietician | undefined;
  scrollTo: any;
  value: string;
  selectedSlot: any;
  userId: string;
}
const Box5: React.FC<CheckOutProp> = ({
  dietitianId,
  months,
  dateTimeOfAppointment,
  dieticianData,
  scrollTo,
  value,
  selectedSlot,
  userId,
}) => {
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  
  interface ModalProps {
    isOpen: boolean;
    onClose?: () => void;
    imageSrc: string;
    amount: string;
  }
// Modal.js or in the same file
const Modal: React.FC<ModalProps> = ({ isOpen, onClose, imageSrc, amount }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-4 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-black text-lg"
        >
          &times;
        </button>
        <img src={imageSrc} alt="Modal content" className="w-full h-[80vh]" />
        <div className="absolute top-[30px] bg-[#FFFFF5] p-[12px] xs:text-[16px]">
          Please pay ₹{amount}, we will verify and get back to you shortly with booking confirmation!
        </div>
      </div>
    </div>
  );
};

  const handleContinue = async () => {
    if (!isLoggedIn) {
      // alert("Please login")
      setIsOpenLoginModal(true)
      return;
    }
    try {
      const token = localStorage.getItem("token");

      // Step 2: Create Booking and Payment Order
      const response = await axios.post(
        "http://backend.betternow.co.in/slots/booking",
        {
          userId,
          dietitianId,
          months: months,
          slotId: selectedSlot,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // setOpenQr(true);
      const { orderId, amount, currency } = response.data;

      // Step 3: Initiate Payment Process
      const options = {
        key: "rzp_live_MkP1Hir0YW6qiv", // Replace with your Razorpay key ID
        amount,
        currency,
        name: "Betternow",
        description: "Booking Payment",
        order_id: orderId,
        handler: async function (response: any) {
          // Step 4: Verify the Payment
          const paymentData = {
            orderId: response.razorpay_order_id,
            paymentId: response.razorpay_payment_id,
            signature: response.razorpay_signature,
          };

          try {
            const verificationResponse = await axios.post(
              "http://backend.betternow.co.in/slots/verifyPayment",
              paymentData,
              {
                headers: {
                  Authorization: token,
                },
              }
            );
            if (verificationResponse.data.success) {
              // alert("Payment successful and booking confirmed!");
              navigate(`/congo?payment_id=${response.razorpay_payment_id}&name=${value}&amount=${amount/100}`);
            } else {
              alert("Payment verification failed. Please try again.");
            }
          } catch (error) {
            console.error("Payment verification error:", error);
            alert("Payment verification failed. Please try again.");
          }
        },
        prefill: {
          name: "User Name", // Replace with actual user name
          email: "user@example.com", // Replace with actual user email
          contact: "9999999999", // Replace with actual user contact number
        },
        notes: {
          address: "User Address", // Optional, add additional notes if necessary
        },
        theme: {
          color: "#F37254",
        },
      };

      const rzp = new (window as any).Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error creating booking or payment order:", error);
      alert("Error creating booking or payment order. Please try again.");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://backend.betternow.co.in/users/auth-status",
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Login status", response.data);
        if (response) {
          setIsLoggedIn(true);
        }
      })
      .catch((error) => {
        console.log("Login status error", error);
        setIsLoggedIn(false);
      });
  }, []);
  return (
    <>
      <div>
        <div>
          <Toaster position="top-center" reverseOrder={false} />
        </div>
        <div className="bg-[#EFEFEA] rounded-xl font-poppins flex flex-col items-start pl-6 gap-y-2 pt-5 pb-5">
          <button
            onClick={handleContinue}
            // onClick={handleChange}
            className={`text-white text-[18px] mt-4 h-[3rem] bg-[#315145] w-[94%] rounded-xl`}
            // disabled
          >
            Continue
          </button>
          <div className="w-[94%] text-[10px] text-[#656565] text-center pl-4 pr-4 border-b-[0.5px] border-[#727272] pt-2 pb-2">
            Select the date & time most convenient for you. You will still have
            a chance to review and edit these before final confirmation.{" "}
          </div>
          <div className="pl-6 w-[94%] pr-11">
            <div className="font-poppins font-medium text-[18px]">
              Booking Summary
            </div>
            <div className="flex justify-between items-center pt-3 font-poppins font-medium text-[#3E3E3E] text-[16px]">
              <div>Session fee </div>
              <div>
                ₹{" "}
                {value === "One time Consultation"
                  ? dieticianData?.oneTimeConsultationFee + ""
                  : dieticianData?.monthlyConsultationFee + "/month"}
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center w-[94%] pl-6 pr-10 border-b-[0.5px] border-t-[0.5px] border-[#727272] pt-2 pb-2 font-poppins font-medium">
            <div className="text-[#2B2B2B] text-[20px] ">Total</div>
            <div className="text-[#3E3E3E] text-[18px] ">
              ₹{" "}
              {value === "One time Consultation"
                ? dieticianData?.oneTimeConsultationFee + ""
                : Number(dieticianData?.monthlyConsultationFee) * months + ""}
            </div>
          </div>
          <div className="flex items-center justify-center w-full text-[10px] text-[#656565] text-center pt-2 pb-2">
            Read our policy for Reschedule & Cancellation
          </div>
        </div>
      </div>
      <LoginModal
        setIsOpenLoginModal={setIsOpenLoginModal}
        isOpenLoginModal={isOpenLoginModal}
      />
    </>
  );
};

export default Box5;
