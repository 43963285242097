import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer";

const Privacy = () => {
  return (
    <>
      <Navbar
        backgroundColor="#FFFFF5"
        textColor="rgb(50, 82, 67)"
        logo="/betterNowLogodark.png"
      />
      <div className=" pl-8 pr-8 md:pl-20 pt-10 pb-20 md:pr-20 text-justify bg-[#FFFFF5]">
        <h1 className="text-[38px] font-semibold text-[#2B2B2B]">
          Privacy Policy
        </h1>
        <p className="text-[#A1A1A1] text-[18px] mt-3">
        betternow is the author, publisher, and provider of betternow ("Service"), including but not limited to the range of betternow products that are available to users through software, web, desktop, mobile applications, or any other interface on the user's device or the World Wide Web.
        </p>
        <p className="text-[#A1A1A1] text-[18px] mt-3">
        betternow provides a database and database management service as part of our offering. This service aims to simplify the task of managing patient profiles, providing an easy and accessible way for doctors, practitioners, and hospitals to store and access patients’ medical information. 
        </p>
        <p className="text-[#A1A1A1] text-[18px] mt-3">
        This Privacy Policy explains how we collect, use, store, process, disclose, and transfer user information when the user utilizes the website, app, or services, both online and offline. This policy applies to both current and former users of the service.
        </p>
        <p className="text-[#A1A1A1] text-[18px] mt-3">
        betternow is dedicated to safeguarding the user's privacy and personal information. As a result, we have formulated this Privacy Policy.
        </p>
        <p className="text-[#A1A1A1] text-[18px] mt-3">
        The user's usage and accessibility of the services are contingent upon this Privacy Policy, Terms of Service, and Refund Policy.
        </p>

        <div className="mt-5 ml-6">
          <ol className=" list-decimal">
            <li className="text-[18px] text-[#555555] ">Definitions</li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              "Website" refers to the web portal, interface, or any other means that the User uses to access betternow and/or any range of betternow products. 
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              "User" refers to any individual or business entity, including but not limited to patients, practitioners, doctors, hospitals, and clinics, that has subscribed to or uses the betternow services, either through the website, application, or any other interface, or by visiting providers or business entities utilizing betternow services.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              "User Data" includes information (including Personal Information) related to a User, such as order, payment, or account information. 
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              "End User" denotes any person, including patients and their relatives and next of kin, whose personal information, including sensitive personal information, would be stored in betternow's databases. 
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              "Personal Information" encompasses any information related to a natural person who can be identified, directly or indirectly, especially by reference to an identifier such as a name, phone number, email address, identification number, location data, online identifier, or any other information specific to that natural person.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              "Sensitive Personal Information" refers to Personal Information that can reveal racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, sexual orientation or sex life. Sensitive Personal Information also includes biometric data that can uniquely identify a natural person, payment information (including payment card or bank account numbers), and government identifiers that uniquely identify a natural person, such as an Aadhaar, a social insurance number, or passport number.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              "Medical/Health Information" refers to details about an individual's medical history, conditions, clinical data, blood type, medications, allergies, adverse events, immunizations, social and family history, genetic information, and risk factors. This includes symptoms, diagnoses, opinions, procedures, outcomes, and any other personal information collected for providing health services or requested by the user for effective patient care.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              "Service" encompasses any services offered by betternow and related entities, whether through the betternow website or other means.
              </li>
              {/* <li className="text-[#A1A1A1] text-[18px] ml-5">
                “SAAS” stands for Software as a Service and in this case, refers
                to the services of betternow and/or other range of betternow
                products provided by the Company.
              </li> */}
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">Consent</li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              By using the services or providing us with your information, you (including end users and related entities) are considered to have read, understood, and agreed to the practices and policies outlined in this privacy policy. 
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              You consent to the collection, storage, processing, disclosure, and transfer of your personal information, including sensitive personal and medical/non-medical information, in accordance with this privacy policy.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              If you use the services on behalf of someone else, such as clients, patients, family members, minor children, or an entity, you confirm that you are authorized to (i) accept this privacy policy on their behalf and (ii) provide consent on their behalf for the collection, use, and disclosure of their information as described in this privacy policy. 

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
              Changes to the Privacy Policy
            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              We reserve the right to change, modify, add, or delete parts of this Privacy Policy at our sole discretion and at any time. 

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              If you do not agree with this privacy policy at any time, please refrain from using the services or providing us with your information, as continued use will be considered as implied consent.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">Data Collection</li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              Types of data: The data submitted by our users may include personal and financial information such as name, address, contact number, gender, demographic details, etc.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              The collected data may also include medical information, like blood type, details of medical history, allergies, medication history, or any other information that the user deems necessary for effective treatment or prescription. 

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              Additionally, betternow.co.in collects information that users post on our website or on a third-party social media site.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
              Purpose and Use Of Data Collected
            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              We (as “betternow”) make sure that the data collected from the Users is only used for purposes of maintaining a database of information for ready access and effective non-medical services to the User/End User.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              betternow does not access, process, analyze, sell or share this information and the information so collected is totally confidential and can be only accessed by the User after successfully using the login credentials.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              betternow.co.in shall use the contact information and other similar data collected to approach the User via. calls, SMS, emails and/or any other means of communication for the purposes including but not limited to sharing info about their visits, feedback, ongoing treatment, prescriptions, etc.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
              Other Relevant Data
            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              betternow.co.in may collect information about the IP address and servers used by the Users.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              If the situation deems required, we may also get access to basic information about any person from third parties like social media websites and data reservoirs. This data could include the name and email address of the Users.
              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
              Sharing Of Information with Third Parties
            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              We will share the database with third parties with whom we have tie-ups with, these third parties may be located outside of India.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              We will share data to respond to a court order or subpoena. They may also share data if a government agency or investigatory body requests or if and when they happen to be investigating potential fraud.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              We may share its data with any successor to all or part of its business.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              We assure that only User or account information of the Users, shall be shared and no sensitive personal or medical information shall be disclosed to any third-party.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
              Website Browser Cookies
            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              We use a standard technology called “cookies” on its Website & other interfaces for technical administration, research and development, and for Users’ administration. Cookies are small data files that are downloaded onto your computer when you visit a particular website for the web server to reference during the same or subsequent visit to the website.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              Cookies, however, do not store any Personal Information of the User or End User. These cookies can be disabled by turning them off from the browser. However, in the case of disabling cookies, some areas of the websites may not function properly.
              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              This Policy only applies to the Services that are owned and operated by us. We cannot control third-party websites which may be advertised on our website as sponsored content. These third-party websites may place their own cookies or other files on the Users’ computer, collect data or solicit personal information from the Users, for which we cannot be made responsible or liable.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
              Third Parties Websites
            </li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              If the user clicks on one of the links to third party websites, the User may be taken to websites that we do not control.

              </li>
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              This policy does not apply to the privacy practices of those websites. In this regard, kindly read the privacy policy of other websites carefully. Thereby, we are not in any way responsible for these third-party websites.

              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">MINOR’s Privacy</li>
            <ol className=" list-[lower-alpha]">
              <li className="text-[#A1A1A1] text-[18px] ml-5">
              betternow strongly advises parents and guardians to render supervision to their minor wards who may inadvertently use or peruse through the our website/ App and/or through the our content. The online activity of minors can be monitored using parental control tools, widely provided through various websites and/or service providers. These tools also can prevent minors from disclosing their name, address, and other Personal Information on the internet without prior permission. While we endeavor to create a minor-friendly platform by not publishing any information which could be objectionable or offensive in nature, it does not take responsibility for any adversity arising out of the use of minors.
              </li>
            </ol>

            <li className="text-[18px] text-[#555555] mt-2">
            Grievance Officer
            <li className="text-[#A1A1A1] text-[18px] ml-5">
            You can directly write us at - hello@betternow.co.in
              </li>
            </li>
            
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
