import React, { useState } from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import { useAppSelector } from "../../redux/hooks";
import { dieticianEnrollmentDataSelector } from "../../redux/selectors/userSelector";
import { updateMetaData } from "../../utils/UpdateMetadataService";
import DropDown from "../DropDown";
import { languageOptions } from "../../constants/DropDownOptions";
import { useSelector } from "react-redux";
import ChipInput from "../ChipInput/ChipInput";

interface Inputs {
  spokenLanguages?: string[];
  intro?: string;
  fee?: number | undefined;
  dietPlanPrice?: number;
  availability?: { [day: string]: string }[];
  socialMediaProfiles?: string[];
  professionalImage?: string;
  portfolioVideo?: string;
}

function EditProfile() {
  const dieticianData = useSelector(dieticianEnrollmentDataSelector);

  //   const transformAvailability = (availability: string[]): { [day: string]: string }[] => {
  //     return availability.map(day => ({ [day]: "" })); // Replace "" with the appropriate value
  // };

  // const availability = dieticianData?.availability
  //     ? transformAvailability(dieticianData.availability)
  //     : "";

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<Inputs>({
    intro: dieticianData?.intro || "",
    fee: parseInt(dieticianData?.fee + ""),
    spokenLanguages: dieticianData?.spokenLanguages || [],
    dietPlanPrice: parseInt(dieticianData?.dietPlanPrice + ""),
    professionalImage: dieticianData?.professionalImage || "",
    portfolioVideo: dieticianData?.portfolioVideo || "",
    // availability: dieticianData?.availability ? (dieticianData.availability) : "",
    socialMediaProfiles: dieticianData?.socialMediaProfiles || [],
  });

  const availabilityString = JSON.stringify(formData.availability);

  // Handle changes to the input field
  const handleAvailabilityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      // Convert input value (string) back to array
      const newAvailability = JSON.parse(e.target.value);

      // Validate if newAvailability is an array of objects
      if (
        Array.isArray(newAvailability) &&
        newAvailability.every(
          (item) => typeof item === "object" && item !== null,
        )
      ) {
        setFormData({ ...formData, availability: newAvailability });
      } else {
        console.error("Invalid format for availability");
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  const dt = useAppSelector(dieticianEnrollmentDataSelector);
  console.log(dt);
  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateMetaData({ ...formData });
      alert("Updated successfully");
      window.location.reload();
    } catch (error: any) {
      alert(error);
    }
    setLoading(false);
  };
  const handleChipInputChange = (newValue: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      socialMediaProfiles: newValue,
    }));
  };
  const handleDropdownValueChange = (fieldName: string, newValue: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };
  return (
    <DefaultLayout>
      <div className="flex flex-col h-full items-center py-10">
        <h1 className="text-left w-10/12 text-4xl font-semibold text-green-900 mb-5">
          Edit
        </h1>
        <form onSubmit={onSubmit} className="flex flex-col space-y-5 w-10/12">
          <label
            htmlFor="intro"
            className="flex flex-col font-semibold text-lg text-green-900 gap-2"
          >
            Intro
            <input
              value={formData.intro}
              name="intro"
              type="text"
              onChange={(e) =>
                setFormData({ ...formData, intro: e.target.value })
              }
              placeholder="Intro"
              className="outline-none border py-2 px-3 rounded-lg font-normal"
            />
          </label>
          <label
            htmlFor="fee"
            className="flex flex-col font-semibold text-lg text-green-900 gap-2"
          >
            Fee
            <input
              name="fee"
              onChange={(e) =>
                setFormData({ ...formData, fee: parseInt(e.target.value) })
              }
              value={formData.fee}
              type="number"
              placeholder="Fee"
              className="outline-none border py-2 px-3 rounded-lg font-normal"
            />
          </label>
          <label
            htmlFor="dietPlanPrice"
            className="flex flex-col font-semibold text-lg text-green-900 gap-2"
          >
            Diet Plan Price
            <input
              name="dietPlanPrice"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  dietPlanPrice: parseInt(e.target.value),
                })
              }
              value={formData.dietPlanPrice}
              type="number"
              placeholder="Diet plan price"
              className="outline-none border py-2 px-3 rounded-lg font-normal"
            />
          </label>
          <label
            htmlFor="availability"
            className="flex flex-col font-semibold text-lg text-green-900 gap-2"
          >
            Availability
            <input
              name="availability"
              onChange={handleAvailabilityChange}
              value={availabilityString}
              type="text"
              placeholder="Availability"
              className="outline-none border py-2 px-3 rounded-lg font-normal"
            />
          </label>
          <label
            htmlFor="professionalImage"
            className="flex flex-col font-semibold text-lg text-green-900 gap-2"
          >
            Professional Image
            <input
              name="professionalImage"
              onChange={(e) =>
                setFormData({ ...formData, professionalImage: e.target.value })
              }
              value={formData.professionalImage}
              type="text"
              placeholder="Professional Image"
              className="outline-none border py-2 px-3 rounded-lg font-normal"
            />
          </label>
          <label
            htmlFor="portfolioVideo"
            className="flex flex-col font-semibold text-lg text-green-900 gap-2"
          >
            Portfolio Video
            <input
              name="portfolioVideo"
              onChange={(e) =>
                setFormData({ ...formData, portfolioVideo: e.target.value })
              }
              value={formData.portfolioVideo}
              type="text"
              placeholder="Portfolio Video"
              className="outline-none border py-2 px-3 rounded-lg font-normal"
            />
          </label>
          <div className="flex flex-col">
            <label className="font-semibold text-lg text-green-900 gap-2">
              Languages
            </label>
            <DropDown
              required
              options={languageOptions}
              isClearable={true}
              isMultiple={true}
              isSearchable={true}
              onSelectChange={handleDropdownValueChange}
              fieldName="spokenLanguages"
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold text-lg text-green-900 gap-2">
              Social media profiles
            </label>
            <ChipInput onInputChange={handleChipInputChange} />
          </div>

          <button
            type="submit"
            className="cursor-pointer bg-green-900 text-white border-none py-2 rounded-lg"
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </form>
      </div>
    </DefaultLayout>
  );
}

export default EditProfile;
