import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

interface DropdownProps {
  items: { text: string; path: string }[];
  textColor: string;
  backgroundColor: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  items,
  textColor,
  backgroundColor,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div onClick={toggleDropdown}>
      <ul
        style={{ background: textColor }}
        className="absolute z-[50] bg-white shadow-lg rounded-lg h-[14.8rem] pt-2"
      >
        {items.map((item, index) => (
          <li key={index}>
            {window.innerWidth > 800 ? (
              <Link
                to={item.path}
                className="block py-2 px-3 text-lg text-black"
                style={{
                  color: backgroundColor,
                  background: textColor,
                  // borderRadius: '0px 0px 0.5em 0.5em'
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = backgroundColor; // Green background
                  e.currentTarget.style.color = "#FBBF24"; // Golden text color
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = textColor; // Restore default background
                  e.currentTarget.style.color = backgroundColor; // Restore default text color
                }}
                onClick={() => setIsOpen(false)}
              >
                {item.text}
              </Link>
            ) : (
              <Link
                to={item.path}
                className="block py-2 px-3 text-lg text-black"
                style={{
                  color: "#325243",
                  background: "#FFFFF5",
                  borderRadius: '0px 0px 0.5em 0.5em'

                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#325243"; // Green background
                  e.currentTarget.style.color = "#FBBF24"; // Golden text color
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#FFFFF5"; // Restore default background
                  e.currentTarget.style.color = "#325243"; // Restore default text color
                }}
                onClick={() => setIsOpen(false)}
              >
                {item.text}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
