import React, { useState } from "react";

interface AccordionProps {
  title: string;
  answer: string;
}

// const Accordion: React.FC<AccordionProps> = ({ title, answer }) => {
//   const [accordionOpen, setAccordionOpen] = useState(false);

//   return (
//     <div className="py-2  ">
//       <button
//         onClick={() => setAccordionOpen(!accordionOpen)}
//         className="flex justify-between items-center gap-[8px] w-full my-[10px] mx-[0] p-[0]"
//       >
//         <span className="pt-1 text-[#464750] text-[1rem] md:text-[1.125rem] text-left">
//           {title}
//         </span>
//         <div>
//         <svg 
//           className={`rotate-0 ${
//             accordionOpen === true
//               ? "rotate-180"
//               : ""
//           }`}
//           xmlns="http://www.w3.org/2000/svg"
//           width="16"
//           height="16"
//           viewBox="0 0 24 24"
//           color="#878787"
//         >
//           <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
//         </svg>
//         </div>
//       </button>
//       <div
//         className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
//           accordionOpen
//             ? "grid-rows-[1fr] opacity-100"
//             : "grid-rows-[0fr] opacity-0"
//         }`}
//       >
//         <div className="pl-0.3 pt-1 overflow-hidden text-[#7A7979] text-[0.9rem] md:text-[1rem]">
//           {answer}
//         </div>
//       </div>
//     </div>
//   );
// };
const Accordion: React.FC<AccordionProps> = ({ title, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="py-2  ">
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className="flex justify-between items-center gap-[8px] w-full my-[10px] mx-[0] p-[0]"
      >
        <span className="pt-1 text-[#464750] text-[1rem] md:text-[1.125rem] text-left">
          {title}
        </span>
        <div>
          <svg 
            className={`rotate-0 ${
              accordionOpen ? "rotate-180" : ""
            }`}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            color="#878787"
          >
            <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
          </svg>
        </div>
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
          accordionOpen
            ? "grid-rows-[1fr] opacity-100"
            : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div
          className="pl-0.3 pt-1 overflow-hidden text-[#7A7979] text-[0.9rem] md:text-[1rem]"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </div>
    </div>
  );
};

export default Accordion;
