import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { featureList } from "../constants/FeatureOffered";

const ComparisonTable: React.FC = () => {
  return (
    <section className="text-[#FFFFF5]">
      <div className="lg:w-[95vw] py-10  flex flex-wrap">
        <div className="w-1/2 mt-[96px]">
          <div>
            {featureList.map((item, index) => (
              <p
                key={index}
                className="h-12 px-4 flex items-center justify-start border-[#EEEDED] border-b-[0.8px] text-[11px] lg:text-[22px] text-left"
              >
                {item.name}
              </p>
            ))}
          </div>
        </div>
        <div className="flex w-1/2">
          <div className="w-1/2 bg-[#FFFFF5] pb-10 lg:pb-20 rounded-lg">
            <div className="px-2 h-[96px] flex justify-center items-center">
              <img
                src="/betterNowLogodark.png"
                className=" lg:w-[150px] lg:h-[41px] "
                alt="Betternow-logo"
              />
            </div>
            {featureList.map((item, index) => (
              <p
                key={index}
                className="h-12 text-center px-2 flex items-center justify-center border-[#EEEDED] border-b-[0.8px]"
              >
                {item.offeredByBetterNow ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color="#315145"
                    size="lg"
                    className="bg-[#c5e7b7] p-2 rounded-lg"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faXmark}
                    color="red"
                    size="lg"
                    className="p-2"
                  />
                )}
              </p>
            ))}
          </div>
          <div className="w-1/2 rounded-lg">
            <div className="px-2 h-[96px] flex justify-center items-center text-[12px] lg:text-[24px] font-semibold">
              In-Office
            </div>
            {featureList.map((item, index) => (
              <p
                key={index}
                className="h-12 text-center px-2 flex items-center justify-center border-[#EEEDED] border-b-[0.8px]"
              >
                {item.offerByInOffice ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color="#315145"
                    size="lg"
                    className="bg-[#FFFFF5] p-2 rounded-lg"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faXmark}
                    color="red"
                    size="lg"
                    className="p-2"
                  />
                )}
              </p>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComparisonTable;
