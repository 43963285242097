import React from "react";
import { useSelector } from "react-redux";
import { dieticianEnrollmentDataSelector } from "../../redux/selectors/userSelector";

const Section2 = ({ expertise, intro }: any) => {
  const d = useSelector(dieticianEnrollmentDataSelector);
  return (
    <>
      <div className="w-full   flex items-center justify-center relative font-poppins">
        <div className="w-full bg-[#F3F3F3]  pt-4  pb-4 px-8 md:px-12 rounded-xl flex flex-col gap-y-6 text-justify">
          {/* <div className="text-[20px] lg:text-[80px] h-0 md:h-[3rem] ">"</div> */}
          <div className="text-[7.5px] lg:text-[16px]">{expertise?.intro}</div>
         
        </div>
      </div>
    </>
  );
};

export default Section2;
