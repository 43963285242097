import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import {
  DATE_OF_BIRTH_REQUIRED,
  EMAIL_REQUIRED,
  FULL_NAME_REQUIRED,
  GENDER_REQUIRED,
  PASSWORD_REQUIRED,
  OTP_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  MINIMUM_LENGTH_PHONE,
} from "../../constants/ErrorMessage";
import { genderOptions } from "../../constants/DropDownOptions";
import Select from "react-tailwindcss-select";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

interface TextInputProps {
  placeholder?: string;
  required?: boolean;
  type?: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  placeholder = "",
  required = false,
  type = "text",
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  error,
}) => {
  return (
    <div className=" relative mb-4">
      <input
        type={type}
        className={` bg-[transparent] border-[3px] border-[#315145] ${
          value === "" ? "text-[#797979]" : "text-black"
        } text-[12px] lg:text-[20px] rounded-lg w-full h-[54px] p-2.5 px-5 placeholder-[#797979] ${
          name === "dob" ? "cursor-pointer" : ""
        } focus:outline-none`}
        maxLength={type === "tel" ? 10 : undefined}
        placeholder={placeholder}
        required={required}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {error && (
        <span className="absolute left-0 bottom-[-18px] text-red-500 text-sm">
          {error}
        </span>
      )}
    </div>
  );
};

interface Prop {
  setIsOpen: Function;
  userRole: string;
}
const SignupForm = ({ setIsOpen, userRole }: Prop) => {
  const [loading, setLoading] = useState({
    type: "",
    value: false,
  });
  const [dropDownVal, setDropDownVal] = useState(null);
  const location = useLocation();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const navigate = useNavigate();
  const today = new Date();
  const hundredYearsAgo = new Date(
    today.getFullYear() - 100,
    today.getMonth(),
    today.getDate(),
  );

  useEffect(() => {
    if (userRole) {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        role: userRole,
      }));
    }
  }, [location, userRole]);
  const [showOTPform, setShowOTPform] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0.2 * 60); // 30 minutes in seconds
  const [emailOtp, setEmailOTP] = useState("");

  const [otpError, setOtpError] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [formData, setFormData] = useState<any>({
    name: "",
    phone: "",
    dob: "",
    email: "",
    password: "",
    gender: "",
    role: userRole,
  });
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const handleOTP = (emailOtp: string) => {
    const regex = /^\d+$/;
    if (regex.test(emailOtp) || emailOtp === "") {
      setOtpError(false);
      setEmailOTP(emailOtp);
    } else if (emailOtp !== "") {
      setOtpError(true);
      setOtpErrorMsg(OTP_REQUIRED);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    const { name, value } = e.target;
    if (name === "phone" && (value === "" || re.test(value)))
      setFormData({ ...formData, [name]: value });
    else if (name !== "phone") setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const handleDateChange = (date: any) => {
    setFormData({
      ...formData,
      dob: date,
    });
  };
  const handleDropDownChange = (value: any) => {
    setDropDownVal(value);
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      gender: value.value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors, // Preserve existing errors
      gender: "", // Clear error for gender
    }));
  };

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.name) newErrors.name = FULL_NAME_REQUIRED;
    if (!formData.phone) newErrors.phone = PHONE_NUMBER_REQUIRED;
    if (formData.phone.length < 10) newErrors.phone = MINIMUM_LENGTH_PHONE;
    if (!formData.dob) newErrors.dob = DATE_OF_BIRTH_REQUIRED;
    if (!formData.email) newErrors.email = EMAIL_REQUIRED;
    if (!formData.password) newErrors.password = PASSWORD_REQUIRED;
    if (!formData.gender && formData.gender === "")
      newErrors.gender = GENDER_REQUIRED;
    return newErrors;
  };
  useEffect(() => {
    let timer: any;
    if (isRunning) {
      timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft === 0) {
            clearInterval(timer);
            setIsRunning(false);
            return 0;
          } else {
            return prevTimeLeft - 1;
          }
        });
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isRunning]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log(formData);
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setLoading({ type: "signup", value: true });
    try {
      const { ...payload } = formData;
      console.log("payload", payload);

      const signUpUser = async () => {
        await axios
          .post("https://backend.betternow.co.in/users", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response: any) => {
            const token = response.data?.token;
            localStorage.setItem("token", token);
            console.log(response.data);
            toast.success("Account created succesfully");
            setShowOTPform(true);
          })
          .catch((error) => {
            console.error(
              "Error:",
              error.response ? error.response.data : error.message,
            );
            toast.error(
              error.response ? error.response.data.message : error.message,
            );
          });
      };

      await signUpUser();
    } catch (error) {
      console.log("Error", error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading({ type: "", value: false }); // Ensure loading state is reset
    }
    setIsRunning(true);
  };

  const resendOtp = async () => {
    try {
      if (isRunning) {
        setIsRunning(false);
      }
      setLoading({ type: "resend_otp", value: true });

      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found.");
      }

      const response = await axios.post(
        "https://backend.betternow.co.in/users/resend-otp",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        },
      );
      console.log("Resend response", JSON.stringify(response.data));
      toast.success("OTP resent successfully.");
      setIsRunning(true);
    } catch (error: any) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message,
      );
      toast.error(error.response ? error.response.data.message : error.message);
    } finally {
      setLoading({ type: "", value: false });
    }
  };

  const handleOTPSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (emailOtp === "") {
      setOtpError(true);
      setOtpErrorMsg("OTP is required.");
      return;
    }
    if (showOTPform) {
      try {
        setEmailOTP(emailOtp);
        const verifyOtp = () => {
          const token = localStorage.getItem("token");
          axios
            .post(
              "https://backend.betternow.co.in/users/verify-email",
              { emailOtp: Number(emailOtp) },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                },
              },
            )
            .then((response) => {
              console.log("Response Data:", response.data);
              if (response.data.error) {
                setOtpError(true);
                setOtpErrorMsg(response.data.msg);
              } else if (!response.data.error) {
                setIsOtpVerified(true);
                if (response.data.userData.role === "dietitian") {
                  toast.success("Email verified succesfully");
                  navigate("/register");
                  console.log("OTP Verified Successfully");
                } else if (response.data.userData.role === "patient") {
                  toast.success("Email verified succesfully");
                  setLoading({ type: "", value: false });
                  setIsOpen(false);
                  console.log("OTP Verified Successfully");
                }
              }
            })
            .catch((error) => {
              console.error(
                "Error:",
                error.response ? error.response.data : error.message,
              );
              toast.error(
                error.response ? error.response.data.message : error.message,
              );
            });
        };
        verifyOtp();
      } catch (error: any) {
        toast.error("An error occured. Please retry after sometime.");
        console.log("Error:", error);
      }
    }
  };

  return (
    <section className="bg-[#EFEFEA] rounded-lg w-full py-6 justify-cente flex">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-4 lg:px-20 w-full">
        {!showOTPform && !isOtpVerified && (
          <>
            <div className="mb-4 text-[20px] text-[#315145] lg:text-[30px] font-bold text-center">
              Create an account
            </div>
            <form onSubmit={handleSubmit} className="lg:space-y-6 w-full">
              <TextInput
                placeholder="Full Name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={errors.name}
              />
              <TextInput
                placeholder="Phone Number"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                error={errors.phone}
              />
              <StyledDate className="mb-4">
                <DatePicker
                  selected={formData.dob}
                  onChange={handleDateChange}
                  placeholderText={"Date of Birth"}
                  className={` bg-[transparent] border-[3px] border-[#315145] "text-[#797979]" 
         text-[12px] lg:text-[20px] rounded-lg w-full h-[54px] p-2.5 px-5 placeholder-[#797979]`}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  showMonthDropdown
                  yearDropdownItemNumber={100} // Adjust to show enough years
                  scrollableYearDropdown
                  maxDate={today} // Users can't select a future date
                  minDate={hundredYearsAgo} // Limit to 100 years ago
                />
              </StyledDate>
              <div className="relative mb-4">
                <Select
                  value={dropDownVal}
                  onChange={handleDropDownChange}
                  options={genderOptions}
                  primaryColor=""
                  classNames={{
                    menu: "absolute z-10 w-full bg-white  rounded-lg py-2.5 mt-2 text-lg ",
                    menuButton: () =>
                      ` flex text-[0.8rem] md:text-lg p-[0.325rem] md:p-0.5 ${
                        !dropDownVal ? "text-[#797979]" : "text-black"
                      }  rounded-lg border-[3px] border-[#315145] cursor-pointer`,
                  }}
                  placeholder="Gender"
                />
                {errors.gender && (
                  <span className="absolute text-red-500 text-sm">
                    {errors.gender}
                  </span>
                )}
              </div>
              <TextInput
                placeholder="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={errors.email}
              />
              <TextInput
                placeholder="Password"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                error={errors.password}
              />
              <button
                type="submit"
                className="bg-[#315145] text-white py-2 rounded-lg text-[12px] lg:text-[24px] font-medium w-full"
                // className="bg-[#315145] w-full text-white py-4 px-4 rounded-lg text-[12px] lg:text-[16px] font-medium w-1/3"
              >
                {loading.type === "signup" && loading.value
                  ? "Wait..."
                  : "Sign Up"}
              </button>
            </form>
          </>
        )}
        {showOTPform && !isOtpVerified && (
          <>
            <div className="mb-8  text-[20px] lg:text-[36px] font-bold text-center text-[#315145]">
            Verify Your Phone Number
            </div>
            <form onSubmit={handleOTPSubmit} className="space-y-6">
              <input
                placeholder={`+91-${formData.phone}`}
                type="text"
                name="phone"
                disabled
                value={""}
                className={`bg-[transparent] border-[3px] border-[#315145] mb-0 text-[12px] lg:text-[20px] rounded-lg w-full p-2.5 px-4 placeholder-[#000000]`}
              />
              <div className="relative">
                <input
                  placeholder="Enter OTP"
                  type="password"
                  name="emailOtp"
                  maxLength={6}
                  value={emailOtp}
                  onChange={(e: any) => handleOTP(e.target.value)}
                  className={`bg-[#EFEFEA] text-[12px] lg:text-[20px] rounded-lg w-full p-2.5 px-4 placeholder-[#797979] border-[3px] border-[#315145] focus:outline-none ${
                    otpError ? "border-red-500 border" : ""
                  }`}
                />
                {otpError && (
                  <div className="absolute left-0 bottom-[-18px] text-red-500 text-sm">
                    {otpErrorMsg}
                  </div>
                )}
              </div>
              <div className="flex items-center gap-x-2">
                <input
                  type="checkbox"
                  value=""
                  className="bg-transparent h-7 w-7 border-[2px] border-[#A4A4A4] rounded-lg hover:cursor-pointer peer"
                />
                <label
                  htmlFor="default-checkbox"
                  className="text-[10px] lg:text-[16px]  text-[#3D3D3D]"
                >
                  Keep me signed in until I sign out
                </label>
              </div>
              <button
                type="submit"
                className="bg-[#315145] text-white py-2 rounded-lg text-[12px] lg:text-[24px] font-medium w-full"
              >
                {loading.type === "verify_otp" && loading.value
                  ? "Verifying"
                  : "Verify"}
              </button>
            </form>
            {!isRunning && (
              <div
                onClick={resendOtp}
                className="text-xs cursor-pointer pl-1 pt-1 mt-0 border"
              >
                {loading.type === "resend_otp" && loading.value
                  ? "Sending..."
                  : "Resend OTP"}
              </div>
            )}
            {isRunning && (
              <div className="text-xs pl-1 pt-1 mt-0 border">
                Resend in: {minutes}:{seconds < 30 ? `0${seconds}` : seconds}
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default SignupForm;

const StyledDate = styled.div`
  .react-datepicker-wrapper {
    width: 100% !important;
  }
`;
