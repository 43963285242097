import {
  faBorderAll,
  faCalendarDays,
  faGear,
  faMessage,
  faUser,
  faUserPen,
} from "@fortawesome/free-solid-svg-icons";

export const roles = ["patient", "dietician", "dietician-admin", "admin"];

export const DieticianDashboardOptions = [
  {
    to: "/dashboard",
    optionName: "Dashboard",
    icon: faBorderAll,
    val: "home",
  },
  {
    to: "/dashboard/chat",
    optionName: "Chat",
    icon: faMessage,
    val: "chat",
  },
  {
    to: "/dashboard/appointment",
    optionName: "Appointments",
    icon: faCalendarDays,
    val: "appointments",
  },
  {
    to: "/dashboard/profile",
    optionName: "Profile",
    icon: faUser,
    val: "profile",
  },
  {
    to: "/dashboard/settings",
    optionName: "Add Your Availabilty",
    icon: faGear,
    val: "settings",
  },
  {
    to: "/dashboard/patient-medical-form",
    optionName: "Patient Medical History",
    icon: faUserPen,
    val: "edit",
  },
];

export const UserDashboardOptions = [
  {
    to: "/dashboard",
    optionName: "Dashboard",
    icon: faBorderAll,
    val: "home",
  },
  {
    to: "/dashboard/appointment",
    optionName: "Appointments",
    icon: faCalendarDays,
    val: "appointments",
  },
  {
    to: "/dashboard/profile",
    optionName: "Profile",
    icon: faUser,
    val: "History",
  },
];
