import React, { useState } from "react";
import axios from "axios";

interface EnrollmentFormProps {
userData: any;
  onClose: () => void;
  onUpdate: (updatedUserData: any) => void;
}

const EnrollmentForm: React.FC<EnrollmentFormProps> = ({
  userData,
  onClose,
  onUpdate,
}) => {
  const [formData, setFormData] = useState(userData);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("token");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(""); // Clear any previous errors

    const config = {
      method: "put",
      url: `https://backend.betternow.co.in/users/${userData._id}`,
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      const response = await axios.request(config);
      console.log("Profile updated successfully:", response.data);
      onUpdate(response.data); // Pass the updated user data back to the parent
      onClose(); // Close the modal after successful submission
    } catch (error) {
      setErrorMessage("Failed to update profile. Please try again.");
      console.error("Error updating profile:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      {errorMessage && (
        <div className="text-red-500 text-sm mb-2">{errorMessage}</div>
      )}
      <div>
        <label htmlFor="name" className="block font-medium">
          Name
        </label>
        <input
          id="name"
          name="name"
          type="text"
          value={formData.name}
          onChange={handleInputChange}
          className="w-full p-2 border rounded"
          required
        />
      </div>

      <div>
        <label htmlFor="email" className="block font-medium">
          Email
        </label>
        <input
          id="email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleInputChange}
          className="w-full p-2 border rounded"
          required
        />
      </div>

      {/* <div>
        <label htmlFor="phone" className="block font-medium">
          Phone
        </label>
        <input
          id="phone"
          name="phone"
          type="tel"
          value={formData.phone}
          onChange={handleInputChange}
          className="w-full p-2 border rounded"
          required
        />
      </div> */}

      {/* <div>
        <label htmlFor="gender" className="block font-medium">
          Gender
        </label>
        <select
          id="gender"
          name="gender"
          value={formData.gender}
          onChange={handleInputChange}
          className="w-full p-2 border rounded"
          required
        >
          <option value="Female">Female</option>
          <option value="Male">Male</option>
          <option value="Other">Other</option>
        </select>
      </div> */}

      {/* <div>
        <label htmlFor="dob" className="block font-medium">
          Date of Birth
        </label>
        <input
          id="dob"
          name="dob"
          type="date"
          value={formData.dob}
          onChange={handleInputChange}
          className="w-full p-2 border rounded"
          required
        />
      </div> */}

      <div className="flex justify-end gap-4">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 bg-gray-500 text-white rounded"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-[#315145] text-white rounded"
          disabled={loading}
        >
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </form>
  );
};

export default EnrollmentForm;
