import React, { useState } from "react";
import Select from "react-tailwindcss-select";

interface Option {
  value: string;
  label: string;
}

interface DropdownProps {
  required?: boolean;
  options: Option[];
  isMultiple: boolean;
  isClearable: boolean;
  isSearchable: boolean;
  fieldName: string;
  onSelectChange: (fieldName: string, selectedValue: string | string[]) => void;
  error?: string;
}

const DropDown: React.FC<DropdownProps> = ({
  required,
  options,
  isMultiple,
  isClearable,
  isSearchable,
  fieldName,
  onSelectChange,
  error,
}) => {
  const [dropDownVal, setDropDownVal] = useState<Option | Option[] | null>(null);

  const handleChange = (value: any) => {
    let valueToSet: string | string[] | undefined;

    if (isMultiple && value) {
      valueToSet = (value as Option[]).map((option: Option) => option.value);
    } else if (!isMultiple && value) {
      valueToSet = value.value;
    }

    setDropDownVal(value);
    onSelectChange(fieldName, valueToSet || "");
  };

  return (
    <div className="relative">
      <Select
        value={dropDownVal}
        onChange={handleChange}
        options={options}
        primaryColor=""
        isMultiple={isMultiple}
        classNames={{
          menu: "absolute z-10 w-full bg-white shadow-lg border rounded-lg py-2.5 mt-1.5 text-lg",
          menuButton: (value) =>
            `flex items-center text-lg rounded-lg bg-[#EFEFEA] px-2 py-1 h-auto min-h-[57px] ${
              value?.isDisabled ? "opacity-50 cursor-not-allowed" : ""
            }`,
          ChevronIcon: (value) => "hidden", // Hide the dropdown arrow
        }}
        isClearable={isClearable}
        isSearchable={isSearchable}
        placeholder=""
      />
      {error && (
        <span className="absolute left-0 bottom-[-18px] text-red-500 text-sm">
          {error}
        </span>
      )}
    </div>
  );
};

export default DropDown;
