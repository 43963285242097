import DefaultLayout from "../Layout/DefaultLayout";
import { useState, useEffect } from "react";
import Modal from "react-modal";
import "./Calendar.css";
import axios from "axios";
import { getDietitian } from "../../utils/DietitianApis";

const Settings = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [timeSlots, setTimeSlots] = useState<{
    [key: string]: { saved: Array<{ from: string; to: string }>; selected: Array<{ from: string; to: string }> }
  }>({});
  
  const token = localStorage.getItem("token");

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dietitianData = await getDietitian(); // Assuming getDietitian returns a promise
        console.log("d", dietitianData);
  
        if (dietitianData) {
          // Convert the fetched data into the state format
          const formattedTimeSlots = dietitianData.specificAvailability.reduce((acc: { [key: string]: { saved: any[]; selected: any[] } }, item: any) => {
            const dateKey = item.date;
            acc[dateKey] = {
              saved: item.slots.filter((slot: any) => slot.isAvailable).map((slot: any) => ({
                from: slot.from,
                to: slot.to,
              })),
              selected: [],
            };
            return acc;
          }, {});
          setTimeSlots(formattedTimeSlots);
        }
      } catch (error) {
        console.error("Error fetching dietitian data:", error);
      }
    };
    fetchData();
  }, []); 

  const generateDates = () => {
    const today = new Date();
    const dates: string[] = [];
    for (let i = 0; i < 32; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      // Format the date as "YYYY-MM-DD"
      const formattedDate = date.toISOString().split('T')[0];
      dates.push(formattedDate);
    }
    return dates;
  };
  
  const dates = generateDates();

  const generateTimeSlots = () => {
    const slots: { from: string; to: string }[] = [];
    for (let hour = 6; hour < 22; hour++) {
      slots.push({
        from: `${hour<10 ? `0${hour}`: `${hour}`}:00`,
        to: `${hour<9? `0${hour+1}`: `${hour+1}`}:00`,
      });
    }
    return slots;
  };
  
  const handleTimeSlotClick = (slot: { from: string; to: string }) => {
    if (!selectedDay) return;
  
    const dateKey = selectedDay; // Convert to "YYYY-MM-DD" format
    const daySlots = timeSlots[dateKey] || { saved: [], selected: [] };
  
    // If the slot is already saved, don't allow it to be deselected
    if (daySlots.saved.some(s => s.from === slot.from && s.to === slot.to)) return;
  
    // Toggle the selection of the slot
    const slotIndex = daySlots.selected.findIndex(s => s.from === slot.from && s.to === slot.to);
    if (slotIndex !== -1) {
      setTimeSlots({
        ...timeSlots,
        [dateKey]: {
          ...daySlots,
          selected: daySlots.selected.filter((_, i) => i !== slotIndex),
        },
      });
    } else {
      setTimeSlots({
        ...timeSlots,
        [dateKey]: {
          ...daySlots,
          selected: [...daySlots.selected, slot],
        },
      });
    }
  };
  
  const handleSave = () => {
    if (!selectedDay) return;
  
    const dateKey = selectedDay;
    const daySlots = timeSlots[dateKey] || { saved: [], selected: [] };
  
    const newSlots = daySlots.selected.filter(
      (slot) => !daySlots.saved.some((s) => s.from === slot.from && s.to === slot.to)
    );
  
    if (newSlots.length === 0) {
      setModalIsOpen(false);
      return;
    }
  
    const updatedSavedSlots = [...daySlots.saved, ...newSlots];
  
    const updatedTimeSlots = {
      ...timeSlots,
      [dateKey]: {
        saved: updatedSavedSlots,
        selected: [],
      },
    };
  
    const updatedDataToSend = Object.keys(updatedTimeSlots).map((key) => {
      const slots = updatedTimeSlots[key];
      return {
        date: key,
        slots: slots.saved.map((slot) => ({
          from: slot.from,
          to: slot.to,
        })),
      };
    });
  
    console.log("Updated Data to Send:", updatedDataToSend);
  
    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `http://backend.betternow.co.in/dietitian/updateMetadata`,
      headers: {
        Authorization: token || '',
        "Content-Type": "application/json",
      },
      data: { specificAvailability: updatedDataToSend },
    };
  
    console.log("Request Config:", config);
  
    axios
      .request(config)
      .then((response) => {
        console.log("Response Data:", response.data);
    setTimeSlots(updatedTimeSlots);

      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  
    setModalIsOpen(false);
  };
  
  const closeModal = () => {
    if (!selectedDay) return;

    const dateKey = selectedDay;
    const daySlots = timeSlots[dateKey] || { saved: [], selected: [] };
    setTimeSlots({
      ...timeSlots,
      [dateKey]: {
        saved: daySlots.saved,
        selected: [],
      },
    });

    setModalIsOpen(false);
  };

  const handleShowMore = (date: any) => {
    setSelectedDay(date);
    setModalIsOpen(true);
  };

  return (
    <DefaultLayout>
      <div className="calendar">
        <div className="calendar-grid">
  {dates.map((date, index) => {
    const daySlots = timeSlots[date] || { saved: [], selected: [] };
    const displayedSlots = daySlots.saved.slice(0, 2).map(slot => `${slot.from} - ${slot.to}`).join(", ");
    const showMore = daySlots.saved.length > 2;

    return (
      <div
        key={index}
        className="calendar-day"
        onClick={() => handleShowMore(date)}
      >
        <div>{date}</div>
        <div className="slots-display">
          {displayedSlots || "No slots selected"}
          {showMore && (
            <button
              className="show-more"
              onClick={() => handleShowMore(date)}
            >
              Show More
            </button>
          )}
        </div>
      </div>
    );
  })}
</div>
        <Modal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  contentLabel="View and Edit Time Slots"
  className="ReactModal__Content"
>
  <button className="modal-close" onClick={closeModal}>
    &times;
  </button>
  <h2 className="mb-4">
    View Availability for{" "}
    {selectedDay ? selectedDay : ""}
  </h2>
  <div className="time-slots">
    {generateTimeSlots().map((slot, index) => {
      if (!selectedDay) return null;

      const dateKey = selectedDay; // Convert to "YYYY-MM-DD" format
      const daySlots = timeSlots[dateKey] || { saved: [], selected: [] };
      const isSaved = daySlots.saved.some(s => s.from === slot.from && s.to === slot.to);
      const isSelected = daySlots.selected.some(s => s.from === slot.from && s.to === slot.to);

      return (
        <button
          key={index}
          className={`time-slot ${isSaved ? "saved" : ""} ${isSelected ? "selected" : ""}`}
          onClick={() => handleTimeSlotClick(slot)}
          disabled={isSaved}
        >
          {`${slot.from} - ${slot.to}`}
        </button>
      );
    })}
  </div>
  <button onClick={handleSave} className="bg-[#FCCE72] py-[8px] px-[4px] min-w-[90px] rounded-[5px] text-[#000] m-[5px] mb-0" >Save</button>
</Modal>

      </div>
    </DefaultLayout>
  );
};

export default Settings;
