import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavItem from "./NavItem";
import SignupModal from "../Modals/SignupModal";
import LoginModal from "../Modals/LoginModal";
import axios from "axios";
import "./Nav.css"
import Dropdown from "../Navbar_dropdown";

interface NavbarProps {
  backgroundColor: string;
  textColor: string;
  logo?: string;
}

const Navbar: React.FC<NavbarProps> = ({
  backgroundColor,
  textColor,
  logo,
}) => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [userRole, setUserRole] = useState("patient");
  const [btn1Name, setBtn1Name] = useState("Get Started");
  const [btn2Name, setBtn2Name] = useState("Sign In");
  const [userEnrollmentStatus, setUserEnrollmentStatus] = useState(0);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const token = localStorage.getItem("token");
  useEffect(() => {
    const fetchAuthStatus = async () => {
      if (token) {
        try {
          const config = {
            method: "get",
            maxBodyLength: Infinity,
            url: "https://backend.betternow.co.in/users/auth-status",
            headers: {
              Authorization: token,
            },
          };

          const response = await axios.request(config);
          setUserRole(response.data.data.role);
          setIsLoggedIn(true);

          if (response.data.data.role === "dietitian") {
            setBtn1Name("Dashboard");
          } else {
            setBtn1Name("Profile");
          }

          setBtn2Name("Log Out");
          setUserEnrollmentStatus(response.data.data.dietitianEnrollmentStatus);
        } catch (error) {
          console.log(error);
        }
      }

      if (!isLoggedIn && location.pathname === "/dietitian-jobs") {
        setBtn1Name("Get Enrolled");
        setUserRole("dietitian");
      }

      if (isOpenModal || isOpenLoginModal) {
        setIsOpen(false);
      }
    };

    fetchAuthStatus();
  }, [
    isOpenModal,
    isOpenLoginModal,
    token,
    isLoggedIn,
    location.pathname,
    setIsOpen,
  ]);

  const handleLogout = async () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  const navItems = [
    {
      text: "Healthcare Challenge",
      path: "#",
      dropdownItems: [
        { text: "Diabetes", path: "/diabetes" },
        { text: "PCOD/PCOS", path: "/pcos" },
        { text: "Gut Health", path: "/guthealth" },
        { text: "Cholestrol", path: "/cholestrol" },
        { text: "Weight Management", path: "/Weightmanagement" },
      ],
    },
    { text: "Dietitian Jobs", path: "/dietitian-jobs" },
    { text: "FAQ", path: "/faq" },
    { text: "Contact", path: "/contact" },
  ];

  const handleProfile = () => {
    console.log("Current role = ", userRole);
    if (userRole === "patient") {
      navigate("/profile");
    } else if (userRole === "dietitian") {
      if (userEnrollmentStatus === 0) {
        navigate("/register");
      } else if (userEnrollmentStatus === 1 || userEnrollmentStatus === 3) {
        navigate("/enrollmentStatus", {
          state: { status: userEnrollmentStatus },
        });
      } else {
        navigate("/dashboard");
      }
    } else if (userRole === "dietitian-admin") {
      navigate("/dashboard/admin");
    } else {
      navigate("/");
    }
  };

  const handleOpenModal = () => {
    console.log(btn1Name)
    setIsOpenModal(true);
    if (btn1Name === "Get Started") {
      setUserRole("patient");
    } else if (btn1Name === "Get Enrolled") {
      setUserRole("dietitian");
    }
    console.log("userRole", userRole);
  };

  return (
    <nav
      style={{ background: backgroundColor }}
      className="sticky top-0 z-[10]"
    >
      <div className="navbar-container">
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="Better Now Logo" />
          </Link>
        </div>
        <div className="nav-items" style={{ color: !isOpen ? textColor : "#FFFFFF" }}
        >
          {navItems.map((item) => (
            <>
            <div
            // className=''
              onMouseLeave={() => item.path === "#" ? setIsOpenDropdown(false) : setIsOpenDropdown(false)}
            >
              <button className="nav-item" 
              onClick={() => item.path === "#"? setIsOpenDropdown(!isOpenDropdown): navigate(item.path)}
              onMouseEnter={() => item.path === "#" ? setIsOpenDropdown(true) : setIsOpenDropdown(false)}
              >
                {item.text}
              </button>
              <div style={{ top:'70px', width:'100%', display: isOpenDropdown ? 'block' : 'none'}}>
              {item?.dropdownItems &&
              <Dropdown
                  items={item?.dropdownItems}
                  textColor={textColor}
                  backgroundColor={backgroundColor} />
              }
              </div>
            </div>
          </>
          ))}
           
        </div>
        <div className="button-container">
          <button className="buttons" style={{ color: !isOpen ? textColor : "#FFFFFF" }} 
          onClick={() => {
            if (btn1Name === "Get Started" || btn1Name === "Get Enrolled") {
              handleOpenModal();
            } else {
              handleProfile();
            }
          }}
          >
            {btn1Name}
          </button>
          <button className="buttons" style={{ color: !isOpen ? textColor : "#FFFFFF" }} 
          onClick={() => {
            if (btn2Name === "Sign In") {
              setIsOpenLoginModal(true);
            } else if (btn2Name === "Log Out") {
              handleLogout();
            }
          }}
          >
            {btn2Name}
          </button>
        </div>
        <button
          onClick={toggleMenu}
          type="button"
          className="hamburger"
          aria-expanded={isOpen ? "true" : "false"}
          style={{ color: textColor }}
        >
          <svg
            className="w-4 h-4"
            aria-hidden="true"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          // className={`w-full md:block flex items-center justify-center fixed md:relative top-0 left-0 md:top-auto opacity-95 md:left-auto md:bg-transparent bg-[#325243] h-[100vh] z-50 md:h-auto ${
          className="mobile-nav-items"
          style={{display: isOpen ? "flex" : "none"}}
        >
          <div className="h-[70vh] flex flex-col justify-between">
          <button
            onClick={toggleMenu}
            type="button"
            aria-expanded={isOpen ? "true" : "false"}
            style={{ color: textColor, marginLeft: "100%" }}
          >
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="yellow"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1l12 12M13 1L1 13"
              />
            </svg>
          </button>
          
          <ul className=" font-medium text-[24px] flex justify-center flex-col items-center gap-y-5">
            {navItems.map((item, index) => (
              <NavItem
                key={index}
                title={item.text}
                textColor={!isOpen ? textColor : "#FFFFFF"}
                backgroundColor={backgroundColor}
                redirectUrl={item.path}
                dropdownItems={item.dropdownItems}
              />
            ))}
          </ul>
          <div className="flex flex-col gap-[16px]">
          <button className="buttons" style={{ color: !isOpen ? textColor : "#FFFFFF" }} 
          onClick={() => {
            if (btn1Name === "Get Started" || btn1Name === "Get Enrolled") {
              handleOpenModal();
            } else {
              handleProfile();
            }
          }}
          >
            {btn1Name}
          </button>
          <button className="buttons" style={{ color: !isOpen ? textColor : "#FFFFFF" }} 
          onClick={() => {
            if (btn2Name === "Sign In") {
              setIsOpenLoginModal(true);
            } else if (btn2Name === "Log Out") {
              handleLogout();
            }
          }}
          >
            {btn2Name}
          </button>
        </div>

          </div>
          
        </div>
      </div>
      <SignupModal
        userRole={userRole}
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
      />
      <LoginModal
        setIsOpenLoginModal={setIsOpenLoginModal}
        isOpenLoginModal={isOpenLoginModal}
      />
    </nav>
  );
};

export default Navbar;
