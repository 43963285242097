import React from "react";

const SectionOne: React.FC = () => {
  return (
    <div className="bg-[#315145] flex flex-col gap-[40px] pb-[20px] md:items-center md:justify-center">
      <div className="text-white px-[16px] m-auto text-[8vw] md:text-[3.6rem] font-semibold md:w-fit px-10 py-8 md:py-0 text-center">
        LET'S GET{" "}
        <span className="text-[#FCCE72]">EDUCATED, HEALTHY, BETTER</span>
      </div>
      <img
        src="/mainsec1.png"
        className="w-[58.5rem] h-[34.75rem] hidden md:block"
        alt="nurse-patient"
      />
      <div className="w-[100vw] flex justify-center">
        <img
          src="/mainsec1.png"
          className=" w-[80%] block md:hidden"
          alt="nurse-patient"
        />
      </div>
    </div>
  );
};

export default SectionOne;
