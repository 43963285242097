import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DefaultLayout from "../Layout/DefaultLayout";
import { faMagnifyingGlass, faUserDoctor } from "@fortawesome/free-solid-svg-icons";
import StatCard from "../StatCard";
import PatientTable from "./PatientTable";
import { useState, useEffect } from "react";
import { getDietitian } from "../../utils/DietitianApis";
import Footer from "../Footer";

type cardOptions = "one-time-consultation" | "total-patients" | "premium-consultation" | "total-revenue";

const Dashboard = () => {
  const [activeCard, setActiveCard] = useState<cardOptions>("total-patients");
  const [dietitianDetails, setDietitianDetails] = useState<any>(null); // Initially set to null
  const [loading, setLoading] = useState(true); // Loading state
  // const dashboard = dietitianDetails.dashboardData;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dietitianData = await getDietitian();
        if (dietitianData) {
          setDietitianDetails(dietitianData);
        }
      } catch (error) {
        console.error("Error fetching dietitian data:", error);
      } finally {
        setLoading(false); // Stop loading once the data is fetched
      }
    };
    fetchData();
  }, []);

  if (loading) {
    // Optionally, you can return a loading spinner or placeholder here
    return <div>Loading...</div>;
  }

  return (
    <DefaultLayout>
      {/*Windows View*/}
      <div className="">
      <div className="px-4 py-4 bg-[#FFFFF5]">
        <div className="text-[0.8rem] md:text-[1rem] ml-0.5">Namaste,</div>
        
        <div className="flex flex-row item-center text-center md:text-left md:text-[#315145] justify-between self-start">
          <div className="text-[1rem] font-semibold md:text-[2.5rem] text-left">
            {dietitianDetails?.user.gender === "Male" ? "Mr " : "Ms "} {dietitianDetails?.user.name}
          </div>
          <div className="w-fit pt-1">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <FontAwesomeIcon icon={faMagnifyingGlass} color="#315145" />
              </div>
              <input
                type="search"
                className="w-full p-2 ps-10 text-[12px] lg:text-[20px] text-[#315145] rounded-lg bg-[#EFEFEA] focus:outline-none"
                placeholder="Search"
              />
            </div>
          </div>
        </div>

        <div className="py-3">
          <div className="text-[12px] lg:text-[20px] font-medium text-[#315145]">
            Overview
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-4 my-4 gap-4 xs:grid-cols-1">
            {/* {dietitianDetails.dashboard?.map((card, index) => ( */}
              <StatCard
                title="Total Patients"
                value={dietitianDetails.dashboardData?.totalPatient}
                isRevenue={false}
              />
              <StatCard
                title="One-Time Consultations"
                value={dietitianDetails.dashboardData?.oneTimeConsulations}
                isRevenue={false}
              />
              <StatCard
                title="Premium Consultations"
                value={dietitianDetails.dashboardData?.premiumConsulations}
                isRevenue={false}
              />
              <StatCard
                title="Total Revenue"
                value={dietitianDetails.dashboardData?.totalRevenue}
                isRevenue={true}
                oneTime={dietitianDetails.dashboardData?.oneTimeRevenue}
                premium={dietitianDetails.dashboardData?.premiumRevenue}
              />
            {/* ))} */}
          </div>
          <PatientTable data={dietitianDetails}/>
        </div>
        <div></div>
      </div>
      </div>

     
    </DefaultLayout>
  );
};

export default Dashboard;
