import React from "react";

const Box1 = () => {
  return (
    <div>
      <div className="bg-[#EFEFEA] rounded-xl font-poppins flex flex-col items-start pl-6 gap-y-2 pt-5 pb-5">
        <div className="font-semibold text-[18px] xs:text-[16px] flex">
          <div>1.</div>
          <div className="pl-5">Mode Of Session</div>{" "}
        </div>
        <div className="mr-4 flex items-center pl-8">
          <label className="ms-2 text-[0.9rem] md:text-[1.125rem] text-[#505050] flex items-center gap-x-4">
            <div>
              <img src="/Gmeet.svg" alt="Google meet" />
            </div>
            <div className="text-[14px]">Google Meet</div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Box1;
