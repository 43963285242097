import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer";
import { useNavigate, useSearchParams } from "react-router-dom";

const Congo = () => {
  const [searchParams] = useSearchParams();
  const [payment_id, setPayementId] = useState("");
  const [amount, setAmount] = useState("");
  const [plan, setPlan] = useState("");
  console.log(searchParams.toString());
  const navigate = useNavigate(); 
  useEffect(() => {
  
    const params = new URLSearchParams(searchParams);

  // Extract and decode the parameters
    const payment_id = decodeURIComponent(params.get("payment_id") || "");
    const plan = decodeURIComponent(params.get("name") || "");
    const amount = decodeURIComponent(params.get("amount") || "");

    // Set state values
    setPayementId(payment_id);
    setAmount(amount);
    setPlan(plan);
    const timer = setTimeout(() => {
      navigate('/profile');
    }, 3000);

    // Cleanup function to clear the timer if the component unmounts
    return () => clearTimeout(timer);
  });
  
  return (
    <div className="bg-[#FFFFF5]">
      <div>
        <Navbar
          backgroundColor="#FFFFF5"
          textColor="#315145"
          logo="/betterNowLogodark.png"
        />
      </div>
      <div className="flex items-center justify-center w-full font-poppins mt-10 ">
        <div className="w-[85%] md:w-[80%] bg-[#EFEFEA] shadow-xl pt-9 mb-10 pl-10 md:pl-20 rounded-xl flex flex-col gap-y-2 md:gap-y-4 lg:gap-y-10">
          <div className=" font-medium text-[10px] md:text-[18px] lg:text-[25px] text-[#355E3B] w-[60%] md:w-[50%]">
            <text>
              Congratulations! You are now one step ahead to make your life
            </text>{" "}
            <text className="text-[#FFCA55]">better</text>
          </div>
          <div className="h-[0.5px] w-[33%] bg-[#727272]"></div>
          <div className="flex flex-col gap-y-2">
            <div className="text-[#ABABA7] text-[8px] md:text-[20px]">
              PAID FOR
            </div>
            <div className="text-[6px] md:text-[15px]">{plan}</div>
          </div>
          <div className="flex w-[60%] md:w-[40%] justify-between">
            <div className="flex flex-col gap-y-2">
              <div className="text-[#ABABA7] text-[8px] md:text-[20px]">
                AMOUNT PAYABLE
              </div>
              <div className="text-[6px] md:text-[15px]">INR {amount}.00</div>
            </div>
            <div className="flex flex-col gap-y-2">
              <div className="text-[#ABABA7] text-[8px] md:text-[20px]">
                PAYMENT ID
              </div>
              <div className="text-[6px] md:text-[15px]">{payment_id}</div>
            </div>
          </div>
          <div className="text-center text-[6px] md:text-[15px] mr-12 md:mr-20 mb-5 pl-5 pr-0 md:pr-5">
            Please do not close this window you will be redirected to your
            profile shortly.
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Congo;
