import React from "react";
import Accordion from "../Accordion/Accordion";
import { FAQ } from "../../constants/FAQData";

const SectionFive: React.FC = () => {
  return (
    <div className="bg-[#FFFFF5] flex flex-col items-center justify-center py-7 md:py-14">
      <div className="text-[#000000]  text-[1.2rem] md:text-[2rem] font-semibold">
        Frequently asked question
      </div>

      <div className="p-8 mt-5  md:max-w-[51.3rem]">
        {FAQ.map((question, index) => (
          <Accordion
            title={question.title}
            answer={question.answer}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionFive;
