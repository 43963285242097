import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer";

const Refund = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar
        backgroundColor="#FFFFF5"
        textColor="rgb(50, 82, 67)"
        logo="/betterNowLogodark.png"
      />
      <main className="flex-grow pl-8 pr-8 md:pl-20 pt-10 md:pr-20 text-justify bg-[#FFFFF5]">
        <h1 className="text-[38px] font-semibold text-[#2B2B2B]">
          Refund Policy
        </h1>

        <div className="mt-5">
          <ol className="list-decimal text-[#555555]">
            <div className="text-[#A1A1A1] text-[18px]">
              <strong>betternow</strong> subscriptions and bookings are non-refundable, non-exchangeable, and non-saleable.
            </div>
          </ol>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Refund;
