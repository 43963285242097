import React from "react";
import ContactUsForm from "../Forms/ContactUsForm";

const SectionOne: React.FC = () => {
  return (
    <div className="bg-[#FFFFF5]  py-6 ">
      <div className="p-4 lg:p-0 w-[80vw] md:w-[660px] m-auto xs:w-[90vw]">
        <ContactUsForm />
      </div>
    </div>
  );
};

export default SectionOne;
