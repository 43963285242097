import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer";

interface AppWrapperProps {
  children: React.ReactNode;
}

const AppWrapper: React.FC<AppWrapperProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar
        backgroundColor="#FFFFF5"
        textColor="#325243"
        logo="/betterNowLogodark.png"
      />
      <div className="flex-grow font-poppins flex flex-col m-auto justify-center bg-[#FFFFF5]">{children}</div>
      <Footer />
    </div>
  );
};

export default AppWrapper;
