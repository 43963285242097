import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { JwtPayload } from "../../utils/DietitianApis";
import { jwtDecode } from "jwt-decode";
import AppointmentsList from "./AppointmentList";
import EnrollmentForm from "./EditProfile";

const MainSection: React.FC = () => {

  const [userData, setUserData] = useState<any>({});
  const [isProfileOpen, setIsProfileOpen] = useState(true);
  const [isProfileOpenBig, setIsProfileOpenBig] = useState(true);
  const [isModalOpen, setIsModalOpen] =useState(false)
  const token = localStorage.getItem("token");
  const decoded = token? jwtDecode<JwtPayload>(token): null;

  useEffect(() => {
    fetchData();
    console.log(userData)

  }, []);

  const fetchData = async () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://backend.betternow.co.in/users/${decoded?.id}`,
      headers: {
        Authorization: `${token}`,
      },
    };

    try {
      console.log("here")
      const response = await axios.request(config);
      setUserData(response.data.userData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const calculateAge = (dob: string): number => {
    console.log("dob",dob)
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

 const handlePopup = () => {
  setIsModalOpen(!isModalOpen)
  console.log("hjhj")
 }
 const handleUpdateUserData = (updatedUserData: any) => {
      setUserData(updatedUserData.userData);
      console.log("upd",updatedUserData)
    };
  return (
    <section className="font-poppins text-[#315145] w-full bg-[#FFFFF5] px-4 md:px-10">
      {/*Windows view*/}
      <div className="hidden md:block">
        <div className="h-[85vh] w-full flex flex-col md:flex-row gap-2 md:gap-4">
          {/* Profile Section */}
          <div className="relative w-full md:w-1/3 bg-[#EFEFEA] rounded-[10px] px-2 md:px-6">
            <div className="w-full md:w-fit h-[35px] md:bg-[#315145] mx-auto md:mx-auto md:rounded-bl-[10px] md:rounded-br-[10px] flex justify-between md:justify-center items-center px-3 md:px-4 cursor-pointer md:cursor-default">
              {/* Profile Text */}
              <p className="text-[#315145] md:text-[#FFFF] text-[20px] md:text-[0.9rem] font-poppins font-medium leading-[30px] text-left md:text-center">
                Profile
              </p>

              {/* Dropdown Icon for Mobile View */}
              <div className="md:hidden">
                <img
                  src={
                    isProfileOpen
                      ? "/down-arrow-icon.png"
                      : "/side-arrow-icon.png"
                  }
                  alt="Toggle Menu"
                  className="w-6 h-6"
                />
              </div>
            </div>
            <div className="md:hidden my-2 md:my-4">
              <img src="/horizontalLine.svg" alt="horizontal line" />
            </div>
            {(isProfileOpenBig || window.innerWidth >= 768) && (
              <div>
                <img
                  className="hidden md:block absolute right-2 top-2 cursor-pointer"
                  src="/compose1.svg"
                  alt="edit icon"
                  onClick={handlePopup}
                />

                <div className="flex items-center gap-4 md:gap-6 mt-4 md:mt-6">
                  <img
                    className="h-[80px] w-[80px] md:w-[8vw] md:h-[8vw] md:max-h-[100px] md:max-w-[100px]"
                    src={userData.profileImage || "/profileIcon.svg"}
                    alt="profile icon"
                  />
                  <div>
                    <p className="text-[11px] md:text-[12px]">Namaste</p>
                    <h2 className="text-[20px] md:text-[24px] font-semibold">
                      <b>{userData.name || "Ms. Aarti Nandu"}</b>
                    </h2>
                  </div>
                </div>
                <div className="my-2 md:my-4">
                  <img src="/horizontalLine.svg" alt="horizontal line" />
                </div>
                <div>
                  <h2 className="text-4 font-medium">Details</h2>
                  <div className="text-[12px] md:text-[14px] my-2 md:my-4">
                    <p className="text-[11px] md:text-[13px]">E-Mail</p>
                    <p className="font-medium">
                      {userData.email || "nandu@gmail.com"}
                    </p>
                  </div>
                  <div className="text-[12px] md:text-[14px] my-2 md:my-4">
                    <p className="text-[11px] md:text-[13px]">Phone</p>
                    <p className="font-medium">
                      {userData.phone || "+91-983788918"}
                    </p>
                  </div>
                </div>
                <div className="my-2 md:my-4">
                  <img src="/horizontalLine.svg" alt="horizontal line" />
                </div>
                <div className="grid grid-rows-2 grid-cols-2 gap-y-2 md:gap-y-4">
                  <div>
                    <p className="text-[11px] md:text-[13px] font-regular">
                      Gender
                    </p>
                    <h2 className="text-[12px] md:text-[14px] font-medium">
                      {userData.gender || "Female"}
                    </h2>
                  </div>
                  <div>
                    <p className="text-[11px] md:text-[13px] font-regular">
                      Age
                    </p>
                    <h2 className="text-[12px] md:text-[14px] font-medium">
                      {userData.dob ? calculateAge(userData.dob) : "34"}
                    </h2>
                  </div>
                </div>
              </div>
            )}
          </div>
          {isModalOpen &&
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-full max-w-lg relative">
              {/* Close Button */}
              <button
                className="absolute top-4 right-4 text-black text-lg"
                onClick={handlePopup}
              >
                &times;
              </button>
  
              <div className="flex items-center  mb-2">
                <img src="/betterlogogreen.png" alt="Logo" className=" h-10" />
              </div>
  
              <h2 className="text-xl font-bold mb-4">Edit your details:</h2>
                  <EnrollmentForm 
                  userData={userData} // Pass current userData
                  onClose={handlePopup} // Close modal on success or cancel
                  onUpdate={handleUpdateUserData} 
                  />
            </div>
          </div>
          }
          {/* Appointments Section */}
          <div className="relative w-full md:w-2/3 bg-[#EFEFEA] rounded-[10px] px-2 md:px-6 max-h-[85vh]">
            {/*Appointments Desktop View*/}
            <div className="hidden md:block">
              <div className="md:w-fit md:h-[35px] md:bg-[#315145] md:mx-auto md:rounded-bl-[10px] md:rounded-br-[10px] md:flex md:justify-center md:items-center md:px-4 md:cursor-default">
                <p className="md:text-[#FFFF] md:text-[20px] md:text-[0.9rem] md:font-poppins md:font-medium md:leading-[30px] md:text-center">
                  Appointments
                </p>
                
            </div>
           
              <AppointmentsList appointmentType="upcoming" data={userData} isUserData={true} />
              <AppointmentsList appointmentType="past" data={userData} isUserData={true} />
             
              {(!userData?.upcomingAppointments?.length && !userData?.pastAppointments?.length)?
              <div className="flex justify-center items-center h-full py-4">
              <p className="text-center text-lg font-semibold text-gray-500">No appointments</p>
              </div>
              :
              null
              }  

          </div>  
          
        </div>
      </div>

    </div>

    {/*Mobile View*/}
    <div className="md:hidden">
  <div className="h-[85vh] w-full flex flex-col gap-2 py-5">
    
    {/* Profile Section */}
    <div className="relative w-full bg-[#EFEFEA] rounded-[10px]">
      <div
        className="w-full h-[5vh] flex justify-between items-center px-3 cursor-pointer"
      >
        <p className="text-[#315145] text-[20px] font-poppins font-medium leading-[30px]">
          Profile
        </p>
        
        
      </div>
      <div>
        <img src="/horizontalLine.svg" alt="horizontal line" />
        </div>
      {isProfileOpen && (
        <div className="px-3 pb-2">
           <img
                  className="absolute right-[2.5em] top-[4em] cursor-pointer"
                  src="/compose1.svg"
                  alt="edit icon"
                  onClick={handlePopup}
                />
          <div className="flex items-center gap-4 mt-2">
            <img
              className="h-[80px] w-[80px] rounded-full"
              src={userData.profileImage || "/profileIcon.svg"}
              alt="profile icon"
            />
            <div>
              <p className="text-[11px]">Namaste</p>
              <h2 className="text-[20px] font-semibold">
                <b>{userData.name || "Ms. Aarti Nandu"}</b>
              </h2>
            </div>
          </div>
          <div className="mt-2">
            <h2 className="text-4 font-medium">Details</h2>
            <div className="text-[12px] mt-2">
              <p className="text-[11px]">E-Mail</p>
              <p className="font-medium">
                {userData.email || "nandu@gmail.com"}
              </p>
            </div>
            <div className="text-[12px] mt-2">
              <p className="text-[11px]">Phone</p>
              <p className="font-medium">
                {userData.phone || "+91-983788918"}
              </p>
            </div>
          </div>
          <div className="grid grid-rows-2 grid-cols-2 gap-y-2 mt-2">
            <div>
              <p className="text-[11px] font-regular">Gender</p>
              <h2 className="text-[12px] font-medium">
                {userData.gender || "Female"}
              </h2>
            </div>
            <div>
              <p className="text-[11px] font-regular">Age</p>
              <h2 className="text-[12px] font-medium">
                {userData.dob ? calculateAge(userData.dob) : "34"}
              </h2>
            </div>
          </div>
          {isModalOpen &&
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-full max-w-lg relative">
              {/* Close Button */}
              <button
                className="absolute top-4 right-4 text-black text-lg"
                onClick={handlePopup}
              >
                &times;
              </button>
  
              <div className="flex items-center  mb-2">
                <img src="/betterlogogreen.png" alt="Logo" className=" h-10" />
              </div>
  
              <h2 className="text-xl font-bold mb-4">Edit your details:</h2>
                  <EnrollmentForm 
                  userData={userData} // Pass current userData
                  onClose={handlePopup} // Close modal on success or cancel
                  onUpdate={handleUpdateUserData} 
                  />
            </div>
          </div>
          }
        </div>
      )}
    </div>

    {/* Appointments Section */}
    <div className="relative w-full bg-[#EFEFEA] rounded-[10px] my-3">
      <div
        className="w-full h-[5vh] flex justify-between items-center px-3 cursor-pointer"
      >
        <p className="text-[#315145] text-[20px] font-poppins font-medium leading-[30px]">
          Appointments
        </p>
      
      </div>
      
            {
              <div className="px-3 pb-2">
                <AppointmentsList appointmentType="upcoming" data={userData} isUserData={true} />
                <AppointmentsList appointmentType="past" data={userData} isUserData={true} />
              </div>
            }
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default MainSection;


// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import moment from "moment"; // Importing moment
// import { JwtPayload } from "../../utils/DietitianApis";
// import { jwtDecode } from "jwt-decode";
// import AppointmentsList from "./AppointmentList";
// import EnrollmentForm from "./EditProfile";


// const MainSection: React.FC = () => {
//   const [userData, setUserData] = useState<any>({});
//   const [isProfileOpen, setIsProfileOpen] = useState(true);
//   const [isProfileOpenBig, setIsProfileOpenBig] = useState(true);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const token = localStorage.getItem("token");
//   const decoded = token ? jwtDecode<JwtPayload>(token) : null;

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     const config = {
//       method: "get",
//       maxBodyLength: Infinity,
//       url: `https://backend.betternow.co.in/users/${decoded?.id}`,
//       headers: {
//         Authorization: `${token}`,
//       },
//     };

//     try {
//       const response = await axios.request(config);
//       console.log('asas')
//       setUserData(response.data.userData);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const calculateAge = (dob: string): number => {
//     const birthDate = new Date(dob);
//     const today = new Date();
//     let age = today.getFullYear() - birthDate.getFullYear();
//     const monthDiff = today.getMonth() - birthDate.getMonth();

//     if (
//       monthDiff < 0 ||
//       (monthDiff === 0 && today.getDate() < birthDate.getDate())
//     ) {
//       age--;
//     }

//     return age;
//   };

//   const handlePopup = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   // Function to handle the updated user data from EnrollmentForm
//   const handleUpdateUserData = (updatedUserData: any) => {
//     setUserData(updatedUserData);
//     console.log("upd",updatedUserData)
//   };

//   return (
//     <section className="font-poppins text-[#315145] w-full bg-[#FFFFF5] px-4 md:px-10">
//       <div className="hidden md:block">
//         <div className="h-[85vh] w-full flex flex-col md:flex-row gap-2 md:gap-4">
//           <div className="relative w-full md:w-1/3 bg-[#EFEFEA] rounded-[10px] px-2 md:px-6">
//             <div className="w-full md:w-fit h-[35px] md:bg-[#315145] mx-auto md:rounded-bl-[10px] md:rounded-br-[10px] flex justify-between md:justify-center items-center px-3 md:cursor-default">
//               <p className="text-[#315145] md:text-[#FFFF] text-[20px] md:text-[0.9rem] font-poppins font-medium leading-[30px]">
//                 Profile
//               </p>
//               <div className="md:hidden">
//                 <img
//                   src={isProfileOpen ? "/down-arrow-icon.png" : "/side-arrow-icon.png"}
//                   alt="Toggle Menu"
//                   className="w-6 h-6"
//                 />
//               </div>
//             </div>
//             <div className="md:hidden my-2 md:my-4">
//               <img src="/horizontalLine.svg" alt="horizontal line" />
//             </div>

//             {(isProfileOpenBig || window.innerWidth >= 768) && (
//               <div>
//                 <img
//                   className="hidden md:block absolute right-2 top-2 cursor-pointer"
//                   src="/compose1.svg"
//                   alt="edit icon"
//                   onClick={handlePopup} // Trigger the modal
//                 />
//                 <div className="flex items-center gap-4 md:gap-6 mt-4 md:mt-6">
//                   <img
//                     className="h-[80px] w-[80px] md:h-[100px] md:w-[100px]"
//                     src={ "/profileIcon.svg"}
//                     alt="profile icon"
//                   />
//                   <div>
//                     <p className="text-[11px] md:text-[12px]">Namaste</p>
//                     <h2 className="text-[20px] md:text-[24px] font-semibold">
//                       <b>{userData.name || "Ms. Aarti Nandu"}</b>
//                     </h2>
//                   </div>
//                 </div>
//                 <div className="my-2 md:my-4">
//                   <img src="/horizontalLine.svg" alt="horizontal line" />
//                 </div>
//                 <div>
//                   <h2 className="text-4 font-medium">Details</h2>
//                   <div className="text-[12px] md:text-[14px] my-2 md:my-4">
//                     <p className="text-[11px] md:text-[13px]">E-Mail</p>
//                     <p className="font-medium">{userData.email || "nandu@gmail.com"}</p>
//                   </div>
//                   <div className="text-[12px] md:text-[14px] my-2 md:my-4">
//                     <p className="text-[11px] md:text-[13px]">Phone</p>
//                     <p className="font-medium">{userData.phone || "+91-983788918"}</p>
//                   </div>
//                 </div>
//                 <div className="my-2 md:my-4">
//                   <img src="/horizontalLine.svg" alt="horizontal line" />
//                 </div>
//                 <div className="grid grid-rows-2 grid-cols-2 gap-y-2 md:gap-y-4">
//                   <div>
//                     <p className="text-[11px] md:text-[13px] font-regular">Gender</p>
//                     <h2 className="text-[12px] md:text-[14px] font-medium">
//                       {userData.gender || "Female"}
//                     </h2>
//                   </div>
//                   <div>
//                     <p className="text-[11px] md:text-[13px] font-regular">Age</p>
//                     <h2 className="text-[12px] md:text-[14px] font-medium">
//                       {userData.dob ? calculateAge(userData.dob) : "34"}
//                     </h2>
//                   </div>
//                   <div>
//                     <p className="text-[11px] md:text-[13px] font-regular">Date of Birth</p>
//                     <h2 className="text-[12px] md:text-[14px] font-medium">
//                       {userData.dob ? moment(userData.dob).format("DD-MM-YYYY") : ""}
//                     </h2>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>

//           {/* Modal for Editing Profile */}
//           {isModalOpen && (
//             <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//               <div className="bg-white p-6 rounded-lg w-full max-w-lg relative">
//                 <button
//                   className="absolute top-4 right-4 text-black text-lg"
//                   onClick={handlePopup}
//                 >
//                   &times;
//                 </button>
//                 <h2 className="text-xl font-bold mb-4">Edit your details:</h2>
//                 {/* EnrollmentForm component */}
//                 <EnrollmentForm
//                   userData={userData} // Pass current userData
//                   onClose={handlePopup} // Close modal on success or cancel
//                   onUpdate={handleUpdateUserData} // Update user data on success
//                 />
//               </div>
//             </div>
//           )}

//           {/* Appointments Section */}
//           <div className="relative w-full md:w-2/3 bg-[#EFEFEA] rounded-[10px] px-2 md:px-6">
//             <AppointmentsList appointmentType="upcoming" data={userData} isUserData={true} />
//             <AppointmentsList appointmentType="past" data={userData} isUserData={true} />
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default MainSection;

