export const FAQ = [
  {
    title: "Who are the Dietitians?",
    answer:
      "Dietitians are healthcare professionals who help you improve your eating habits. They assess your diet, identify nutritional issues, and create a personalized plan to reach your health goals. They provide guidance on food choices, meal creation, and healthy lifestyle options. With their assistance, you can establish a sustainable and nutritious eating routine, enhancing your overall health and well-being.",
  },
  {
    title: "Is betternow right for me?",
    answer:
      "betternow is the right choice for you if you want to enhance your quality of life. If anything affects your happiness or hinders your ability to reach your dietary goals, we can assist you. Our team comprises professional and experienced dietitians who specialize in treating specific conditions such as PCOD/PCOS, diabetes, gut health, high cholesterol, weight management, and more.",
  },
  {
    title: "How much does it cost?",
    answer:
      "At betternow, session costs vary based on the dietitian's expertise and years of experience.",
  },
  {
    title: "How can I effectively communicate with my Dietitians?",
    answer:
      "a. Video conferencing with your Dietitian through Google Meet \n b. Chat support through WhatsApp",
  },
  {
    title:
      "Can I discuss my medical queries with Dietitians?",
    answer:
      "Please keep in mind that dietitians on betternow only offer non-medical services to help you maintain a healthy lifestyle. We do not allow dietitians or other healthcare providers to assist with medical inquiries. It is strongly recommended to seek in-person interaction with a registered medical practitioner for any medical concerns.",
  },
];

export const FAQAll = [
  {
    title: "Who are the Dietitians?",
    answer:
      "Dietitians are healthcare professionals who help you improve your eating habits. They assess your diet, identify nutritional issues, and create a personalized plan to reach your health goals. They provide guidance on food choices, meal creation, and healthy lifestyle options. With their assistance, you can establish a sustainable and nutritious eating routine, enhancing your overall health and well-being.",
  },
  {
    title: "Is betternow right for me?",
    answer:
      "betternow is the right choice for you if you want to enhance your quality of life. If anything affects your happiness or hinders your ability to reach your dietary goals, we can assist you. Our team comprises professional and experienced dietitians who specialize in treating specific conditions such as PCOD/PCOS, diabetes, gut health, high cholesterol, weight management, and more.",
  },
  {
    title: "How much does it cost?",
    answer:
      "At betternow, session costs vary based on the dietitian's expertise and years of experience.",
  },
  {
    title: "How can I effectively communicate with my Dietitians?",
    answer:
      "a. Video conferencing with your Dietitian through Google Meet\nb. Chat support through WhatsApp",
  },
  {
    title:
      "Can I discuss my medical queries with Dietitians?",
    answer:
      "Please keep in mind that dietitians on betternow only offer non-medical services to help you maintain a healthy lifestyle. We do not allow dietitians or other healthcare providers to assist with medical inquiries. It is strongly recommended to seek in-person interaction with a registered medical practitioner for any medical concerns.",
  },
  {
    title:
      "Who will be helping me?",
    answer:
      "Once you sign up, you will receive a list of available dietitians who match your objectives, preferences, and the challenge you are facing. Each dietitian has different approaches and areas of focus, so it's important to find the right person who can achieve the best results for you. Once you sign in and complete the payment, your appointment will be instantly confirmed. You will receive a confirmation message on WhatsApp with the name of your selected dietician and the mode of the session (selected). For your convenience, we conduct our video appointments and sessions on secure platforms like Google Meet.",
  },
  {
    title:
      "Will all my sessions/ consultations remain confidential?",
    answer:
      "Rest assured that all information shared during your consultation or session with the healthcare provider is completely confidential and is only accessible to you and your healthcare provider.",
  },
  {
    title:
      "Do dietitians only suggest high pricing food/nutrition substitutes?",
    answer:
      "The dietitians at BetterNow are compassionate professionals. They understand the importance of working within your budget and considering the availability of food and supplements. Their goal is to assist you in finding the best solutions by recommending nearby, affordable products.",
  },
  {
    title:
      "As a Dietician/Nutritionist, how can I join betternow?",
    answer:
      "You can directly apply from the Dietitian jobs page with the following procedure.",
  },
];